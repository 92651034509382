import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { PriceItem } from "../PriceItem/PriceItem";
import { Container } from "../../../../../../components/ui/Container/Container";
import { EnterpriseItem } from "../EnterpriseItem/EnterpriseItem";
import { errorToast } from "../../../../../../utils/helpers/customToast.helper";
import { ApiService } from "../../../../../../services/ApiService";
import { PriceItemSkeleton } from "../PriceItem/PriceItemSkeleton/PriceItemSkeleton";
import { UserQuantitySkeleton } from "../UserQuantity/UserQuantitySkeleton/UserQuantitySkeleton";
import { EnterpriseItemSkeleton } from "../EnterpriseItem/EnterpriseItemSkeleton/EnterpriseItemSkeleton";
import "./PriceItemsList.scss";
import { UserQuantity } from "../UserQuantity/UserQuantity";

export const PriceItemsList = ({ proxyCode, localCountry }) => {
  const { activeData } = useSelector((state) => state.content);
  const { lang } = useParams();
  const [priceItems, setPriceItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const controller = useMemo(() => new AbortController(), []);

  useEffect(() => () => {
      controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const delay = (cb, time) => {
    setTimeout(() => cb(), time);
  };

  const fetchPriceItems = async (params) => {
    setIsLoading(true);
    try {
      const { data, status, code } = await ApiService.getPriceItems(params, controller);

      if (code === "ERR_CANCELED") {
        return;
      }

      if (status !== 200) {
        throw new Error();
      }
      return data;
    } catch (e) {
      errorToast("Something went wrong", lang);
    } finally {
      delay(() => setIsLoading(false), 0);
    }
  };

  useEffect(() => {
    (async () => {
      if (!localCountry.id) {
        return;
      }

      const params = {
        countryId: localCountry.id,
        currencyId: activeData?.currency?.id,
        proxyType: proxyCode
      };

      const data = await fetchPriceItems(params) || [];
      setPriceItems(data);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyCode, localCountry.id, activeData?.currency?.id]);

  // console.log(localCountry);

  return (
    <div className="price-items-list">
      <Container>
        <div className="price-items-list__container">
          { isLoading ? (
            <>
              {
                Array(4).fill("_").map((_, index) => (
                  <PriceItemSkeleton key={index} />
                ))
              }
              <UserQuantitySkeleton />
              <EnterpriseItemSkeleton />
            </>
          ) : (
            <>
              {priceItems.map((item, idx) => (
                <PriceItem
                  key={item.id || idx}
                  proxyCode={proxyCode}
                  popular={item.popular}
                  quantity={item.count}
                  packageCountry={item.country}
                  initialPrice={{ usdAmount: item.usdAmount, usdPrice: item.usdPrice }}
                  initialRentPeriod={item.rentPeriodId}
                  localCountry={localCountry}
                  controller={controller}
                />
              ))}
              <UserQuantity
                proxyCode={proxyCode}
                localCountry={localCountry}
                controller={controller}
                packageCountry={priceItems[0]?.country}
              />
              <EnterpriseItem />
            </>
          )}
        </div>
      </Container>
    </div>
  );
};
