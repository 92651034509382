import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomModal } from "../../../../../../../../components/ui/CustomModal/CustomModal";
import { CustomTextArea } from "../../../../../../../../components/ui/CustomTextArea/CustomTextArea";
import { Button } from "../../../../../../../../components/ui/Buttons/Button/Button";
import { ButtonLoader } from "../../../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { useDispatchedActions, useTranslation } from "../../../../../../../../hooks";
import { ApiService } from "../../../../../../../../services/ApiService";
import { errorToast, successToast, warningToast } from "../../../../../../../../utils/helpers/customToast.helper";
import "./ReplacementModal.scss";
import { RoundCheckbox } from "../../../../../../../../components/ui/RoundCheckbox/RoundCheckbox";
import { classNames } from "../../../../../../../../utils/helpers/classNames";
import { useSetZIndex } from "../../../../../../../../hooks/useSetZIndex";

export const ReplacementModal = ({ open, close }) => {
  const { lang } = useParams();

  const { buttons: { send, cancel },
    cabinet: { orders: { ipReplacement: {
      title,
      description,
      commentPlaceholder,
      successfullySent,
      ipReplacementReason,
      somethingWentWrongMsg,
      tooOftenMsg,
      alreadyRequestedMsg,
      reasonTitle,
      reasons: { notWork, incorrectLocation, changeNetwork, lowSpeed, custom }
    } } } } = useTranslation();

  const { setSelectedRows } = useDispatchedActions();

  const { activeData } = useSelector((state) => state.content);
  const { selectedRows } = useSelector((state) => state.cabinet);

  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  useSetZIndex(".cabinet", "999");

  const getAllStatuses = (ipsStatuses) => {
    const statuses = ["ok", "tooOften", "alreadyRequested", "somethingWentWrong"];
    const res = {};

    for (const status of statuses) {
      res[status] = ipsStatuses.filter((ip) => ip.status === status);
    }

    return res;
  };

  const getMessage = (ipsStatuses) => {
    const { ok, tooOften, alreadyRequested, somethingWentWrong } = getAllStatuses(ipsStatuses);

    if (ok.length) {
      successToast(successfullySent, lang);
    }

    if (tooOften.length) {
      const ips = tooOften.map((ip) => ip.ip).slice(0, 5).join("; ");
      const message = `${tooOftenMsg} ${ips}`;
      warningToast(message, lang);
    }

    if (alreadyRequested.length) {
      const ips = alreadyRequested.map((ip) => ip.ip).slice(0, 5).join("; ");
      const message = `${alreadyRequestedMsg} ${ips}`;
      warningToast(message, lang);
    }

    if (somethingWentWrong.length) {
      errorToast(somethingWentWrongMsg, lang);
    }
  };

  const replaceIps = async () => {
    if (!reason) {
      return errorToast(ipReplacementReason, lang);
    }

    try {
      setIsLoading(true);
      const params = {
        comment: reason,
        ipIds: selectedRows,
        proxyType: activeData.proxy.code,
        locale: lang
      };
      const { status, data: { ipsStatuses } } = await ApiService.replaceIp(params);

      if (status !== 200) {
        throw status;
      }

      setSelectedRows([]);
      getMessage(ipsStatuses);
      close();
      setReason("");

    } catch (status) {
      errorToast("Something went wrong", lang);
    } finally {
      setIsLoading(false);
    }
  };

  const options = [notWork, incorrectLocation, changeNetwork, lowSpeed, custom];

  const handleOption = (option) => {
    if (option === custom) {
      setReason("");
    } else {
      setReason(option);
    }

    setSelectedOption(option);
  };

  return (
    <CustomModal
      open={open}
      onClose={close}
      styles={{ zIndex: "999999999" }}
    >
      <div className="ip-replace">
        <div className="ip-replace__title">{title}</div>
        <div className="ip-replace__description">{description}</div>
        <div className="ip-replace__reason-title">{reasonTitle}</div>
        <div className="ip-replace__options">
          {options.map((option, idx) => (
            <RoundCheckbox
              key={idx}
              isActive={option === selectedOption}
              label={option}
              handler={handleOption}
            />
          ))}
        </div>
        <div className={classNames(
          "ip-replace__textarea-container",
          { "ip-replace__textarea-container--hidden": selectedOption !== custom }
        )}>
          <CustomTextArea
            className="ip-replace__textarea"
            placeholder={commentPlaceholder}
            value={reason}
            onChange={setReason}
          />
        </div>
        <div className="ip-replace__actions ">
          <Button
            onClick={close}
          >
            {cancel}
          </Button>
          <Button
            tab="confirm"
            onClick={replaceIps}
            disabled={isLoading}
          >
            {isLoading ? <ButtonLoader /> : send }
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};
