import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "../../hooks";
import { Container } from "../../components/ui/Container/Container";
import { FeedbackForm } from "./components/FeedbackForm/FeedbackForm";
import { Socials } from "./components/Socials/Socials";
import "./ContactsPage.scss";

 const ContactsPage = () => {
  // *Props
  const { lang } = useParams();

  const { contacts: { title, description } } = useTranslation();

   // ** Redux State
   const { content } = useSelector((state) => state.content);

   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);

  return (
    content?.isLoaded && (
      <section className="contacts" id="contacts">
        <Container>
          <div className="contacts__wrapper">
            <div className="contacts__info">
              <div className="contacts__title">{content?.data[lang].metaDateFeedback?.title || title}</div>
              <p className="contacts__description">
                {content?.data[lang]?.metaDateFeedback?.description || description}
              </p>
              <Socials />
            </div>
            <FeedbackForm/>
          </div>
        </Container>
      </section>
    )
  );
};

export default ContactsPage;
