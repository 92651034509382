import { useEffect, useRef, useState } from "react";

import Moment from "react-moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MediaQuery from "react-responsive";
import Select, { components } from "react-select";
import { NoDataComponent } from "../../../../../components/common/NoDataComponent/NoDataComponent";
import { Pagination } from "../../../../../components/common/Pagination/Pagination";

import { useDispatchedActions } from "../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../hooks/useTranslation";

import { rowPerPageOptions } from "../../../../../utils/constants/rowPerPage.constant";

import "./History.scss";
import { errorToast } from "../../../../../utils/helpers/customToast.helper";
import { ShowMoreButton } from "../../../../../components/ui/ShowMoreButton/ShowMoreButton";
import { isSSR } from "../../../../../utils/helpers";
import { moveTo } from "../../../../../utils/helpers/moveTo";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { getBorderColor } from "../../../../../utils/helpers/getBorderColor";
import { HistoryContentLoader } from "./components/HistoryContentLoader/HistoryContentLoader";

export const History = ({ controller, setExpanded }) => {
  const { lang } = useParams();
  // Dispatch
  const { fetchBalanceTable } = useDispatchedActions();

  const { select: { noOptionsMessage } } = useTranslation();

  // **Redux State
  const { orders } = useTranslation().cabinet;
  const { notifications, cabinet: { balance } } = useTranslation();
  const { balanceTable } = useSelector((state) => state.cabinet);
  const [positionY, setPositionY] = useState(null);

  // **Local state
  const [localSize, setLocalSize] = useState(10);
  const [localCurrentPage, setLocalCurrentPage] = useState(1);

  const { skin } = useSelector((state) => state.header);

  const ref = useRef();

  const isPaginationVisible = balanceTable?.data?.totalElements > 10;
  const TOP_OF_TABLE = 300;

  useEffect(() => {
    if (isSSR()) {
      return;
    }

    if (!balanceTable.isLoaded || !positionY) {
      return;
    }

    window.scrollTo(0, positionY);

    setPositionY(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceTable]);

  useEffect(() => {
    if (ref.current) {
      ref.current.select.setValue({ label: `${localSize} ${balance?.lines}`, value: localSize });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  // Page change handler
  const handlePageChange = (page) => {
    setExpanded(false);
    setLocalCurrentPage(page);
    moveTo(0, TOP_OF_TABLE);
    fetchBalanceTable({
      page: page - 1,
      size: Number(localSize)
    });
  };

  // Rows size handler
  const handlePerRowsChange = (newPerPage) => {
    setLocalSize(newPerPage?.value);
    setLocalCurrentPage(1);
    fetchBalanceTable({
      page: 0,
      size: Number(newPerPage?.value)
    });
  };

  // Define rows per page options
  const defineRowsPerPageOptions = () =>
    [
      ...rowPerPageOptions.filter(
        (item) => item < balanceTable?.data?.totalElements
      ),
      balanceTable?.data?.totalElements
    ].map((item) => ({ label: `${item} ${balance?.lines}`, value: item }));

  const showMore = () => {
    if (isSSR()) {
      return;
    }

    fetchBalanceTable({
      page: 0,
      size: Number(localSize) + 10
    });
    setLocalSize(Number(localSize) + 10);
    setPositionY(window.scrollY);
  };
  // first fetching table data
  useEffect(() => {
    setLocalSize(10);
    setLocalCurrentPage(1);
    fetchBalanceTable({
      page: 0,
      size: 10,
      controller
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handling error
  if (balanceTable?.fetchError) {
    errorToast(notifications?.error, lang);
  }
  if (!balanceTable?.isLoaded) {
    return (
      <HistoryContentLoader />
    );
  }

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <IconSvg tag="triangleDown12" />
    </components.DropdownIndicator>
  );

  return (
    <>
      {" "}
      <div className="history__contain">
        <ul className="history__list">
          <MediaQuery minWidth={993}>
            <li key="header" className="history__list__item">
              <div className="history__list__item-contain-header">
                <div className="history__list__item-box">
                  <div className="history__list__item-date ">
                    {orders?.column?.date}
                  </div>
                  <div className="history__list__item-amount">
                    {orders?.column?.amount}
                  </div>
                </div>
                <div className="history__list__item-status">
                  {orders?.column?.balanceOperation?.title}
                </div>
              </div>
            </li>
          </MediaQuery>
          {balanceTable?.data?.totalElements > 0 ? (
            balanceTable?.data?.content.map((row) => (
              <li key={row?.id} className="history__list__item">
                <div className="history__list__item-contain">
                  <div className="history__list__item-box">
                    <div className="history__list__item-date">
                      <Moment
                        format={`DD.MM.YYYY ${
                          lang === "ru" ? "HH:mm:ss" : "hh:mm:ss A"
                        }`}
                        utc={false}
                        date={row?.date}
                      />
                    </div>
                    <div className="history__list__item-amount">
                      <span>{`$${row?.amount}`}</span>
                    </div>
                  </div>

                  <div className="history__list__item-status">
                    {row?.balanceOperation === "PLUS" &&
                      orders?.column?.balanceOperation?.plus}
                    {row?.balanceOperation === "MINUS" &&
                      orders?.column?.balanceOperation?.minus}
                    {row?.balanceOperation === "PLUS_BY_ADMIN" &&
                      orders?.column?.balanceOperation?.plusAdmin}
                    {row?.balanceOperation === "MINUS_BY_ADMIN" &&
                     orders?.column?.balanceOperation?.minusAdmin}
                  </div>
                </div>
              </li>
            ))
          ) : (
            <NoDataComponent />
          )}
        </ul>

        {/* <table className="table">
        <thead className="table-head">
          <tr className="table-head-tr">
            <th className="table-head-tr-item termin"> </th>
            <th className="table-head-tr-item country"></th>
            <th className="table-head-tr-item status"></th>
          </tr>
        </thead>
        <tbody className="table-body">
          {balanceTable?.data?.totalElements > 0 ? (
            balanceTable?.data?.content.map((row) => (
              <tr key={row?.id} className="table-body-tr">
                <td className="table-body-tr-item termin">
                  <Moment format={`DD.MM.YYYY ${lang === "ru" ? "HH:mm:ss" : "hh:mm:ss A"}`} utc={false} date={row?.date} />
                </td>
                <td className="table-body-tr-item amount">
                  <span>{`${row?.amount}$`}</span>
                </td>
                <td className="table-body-tr-item status">
                  {row?.balanceOperation === "PLUS" && table?.column?.balanceOperation?.plus}
                  {row?.balanceOperation === "MINUS" && table?.column?.balanceOperation?.minus}
                  {row?.balanceOperation === "PLUS_BY_ADMIN" && table?.column?.balanceOperation?.plusAdmin}
                  {row?.balanceOperation === "MINUS_BY_ADMIN" && table?.column?.balanceOperation?.minusAdmin}
                </td>
              </tr>
            ))
          ) : (
            <NoDataComponent />
          )}
        </tbody>
        <tfoot className="table-footer">
          <tr className="table-footer-tr">
            <td className="table-footer-info-text">{table?.rowPerPage}</td>
            <td className="table-footer-select">
              <SelectSize options={defineRowsPerPageOptions()} onChange={handlePerRowsChange} defaultValue={{ label: localSize }} />
            </td>
            <td>{calcDisplayItemsOfTabl(balanceTable?.data?.totalElements, localSize, localCurrentPage)}</td>
            <td className="table-footer-info-text">{table?.separatorText}</td>
            <td>{balanceTable?.data?.totalElements}</td>
            {balanceTable?.data?.totalElements > localSize && (
              <td className="table-footer-pagination">
                <Pagination
                  totalPages={balanceTable?.data?.totalPages}
                  currentPage={localCurrentPage}
                  onPageChange={handlePageChange}
                  hideOnSinglePage={false}
                />
              </td>
            )}
          </tr>
        </tfoot>
      </table> */}
      </div>
      {isPaginationVisible && (
        <>
          <MediaQuery minWidth={993}>
            <div className="history__list__footer">
              <Pagination
                totalPages={balanceTable?.data?.totalPages}
                currentPage={localCurrentPage}
                onPageChange={handlePageChange}
                hideOnSinglePage={false}
              />
              <Select
                className={"modal__select"}
                ref={ref}
                classNamePrefix="modal__select"
                options={defineRowsPerPageOptions()}
                defaultValue={{ label: `${localSize} ${balance?.lines}`, value: localSize }}
                menuPlacement="top"
                isSearchable={false}
                // placeholder={placeholder}
                onChange={handlePerRowsChange}
                noOptionsMessage={() => noOptionsMessage}
                components={{ DropdownIndicator }}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: getBorderColor(state, skin)
                  })
                }}
              />
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={992}>
            <ShowMoreButton action={showMore} disabled={localSize >= balanceTable?.data?.totalElements} />
          </MediaQuery>
        </>
      )}
    </>
  );
};
