import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../../services/ApiService";

export const fetchPriceCalculationModal = createAsyncThunk(
  "modal/fetchPriceCalculationModal",
  async (
    { projectId, countryId, currencyId, paymentSystemId, count, rentPeriodId, proxyType, mobileOperatorTag, rotationMin, rotationSelected = false },
    { rejectWithValue }
  ) => {
    try {
      const response = await ApiService.calculateDesktopPrice({
        projectId,
        countryId,
        currencyId,
        paymentSystemId,
        count,
        rentPeriodId,
        proxyType,
        mobileOperatorTag,
        rotationMin,
        rotationSelected
      });

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      if (!response.data.success) {
        throw response.data.success;
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);
