import { useParams } from "react-router-dom";
import "./ShowMoreButton.scss";

export const ShowMoreButton = ({ action, disabled }) => {
  const { lang } = useParams();

  const buttonText = lang === "ru" ? "Показать ещё" : "Show more";

  return (
    <button className="show-more" onClick={action} disabled={disabled}>
      {buttonText}
    </button>
  );
};
