import { useTranslation } from "../../../../../hooks/useTranslation";
import { FolderSelect } from "../FolderSelect/FolderSelect";
import { Actions } from "./components/Actions/Actions";

import { ProxyType } from "./components/ProxyType/ProxyType";

export const Top = ({ folderSelectRef, openFolderSelect, closeFolderSelect, controller, folders, setFolders }) => {
  // **Redux State
  const {
    cabinet: { orders }
  } = useTranslation();

  return (
    <>
      <div className="orders__top">
        <div className="orders__top__box-title">
          <div className="orders__title">{orders?.title}</div>
          <div className="orders__folder-select">
            <FolderSelect
              folders={folders}
              setFolders={setFolders}
              controller={controller}
              folderSelectRef={folderSelectRef}
              openFolderSelect={openFolderSelect}
              closeFolderSelect={closeFolderSelect}
            />
          </div>
        </div>

        <div className="orders__top__box-actions">
          <ProxyType />
          <Actions />
        </div>
      </div>
    </>
  );
};
