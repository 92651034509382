import { useEffect, useRef, useState } from "react";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";

import { useTranslation } from "../../../../hooks/useTranslation";

import "./AccordionItem.scss";

export const AccordionItem = ({ items, item, initialActive }) => {
  // **State
  const [isActive, setIsActive] = useState(initialActive);
  const [maxHeight, setMaxHeight] = useState(0);
  const { togglerAriaLabel } = useTranslation().reviews;

  // **Ref
  const content = useRef(null);

  const toggleAccordion = () => {
    setIsActive((prevState) => !prevState);
    setMaxHeight(!isActive ? content.current.scrollHeight : 0);
  };

  useEffect(() => {
    setMaxHeight(isActive ? content.current.scrollHeight : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialActive) {
      return;
    }

    setIsActive(false);
    setMaxHeight(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <li
      className={`accordion__item${
        isActive ? " accordion__item--active" : ""
      }`}
      onClick={toggleAccordion}
    >
      <div className="accordion__item-head">
        {item?.question}
        <button
         // onClick={toggleAccordion}
          className="accordion__item-toggler"
          aria-label={togglerAriaLabel}
        >
          <IconSvg tag="arrowDown" className="arrow"/>
        </button>
      </div>
      <div
        ref={content}
        style={{ maxHeight }}
        className="accordion__item-body"
      >
        {item?.answer}
      </div>
    </li>
  );
};
