import {errorToast} from "./customToast.helper";

export const accessErrorHandler = (error, lang) => {
  const accessErrors = {
    userNotFound: {
      en: "User not found. Please check your credentials and try again.",
      ru: "Пользователь не найден. Пожалуйста, проверьте ваши учетные данные и попробуйте снова."
    },
    loginInvalid: {
      en: "Invalid login. Please enter a valid login.",
      ru: "Неверный логин. Пожалуйста, введите правильный логин."
    },
    passwordInvalid: {
      en: "Invalid password. Please enter a valid password.",
      ru: "Неверный пароль. Пожалуйста, введите правильный пароль."
    },
    loginAlreadyExists: {
      en: "Login already exists. Please choose a different login.",
      ru: "Данный логин уже существует. Пожалуйста, выберите другой логин."
    },
    tooManyRequests: {
      en: "Too many requests. Please wait for 30 seconds before making another request.",
      ru: "Слишком много запросов. Пожалуйста, подождите 30 секунд перед отправкой нового запроса."
    },
    invalidIpAddress: {
      en: "Invalid IP address. Please provide a valid IP address.",
      ru: "Неверный IP-адрес. Пожалуйста, укажите правильный IP-адрес."
    }
  }
  
  switch (error) {
    case "user_not_found":
      return errorToast(accessErrors.userNotFound[lang], lang)

    case "login_invalid":
      return errorToast(accessErrors.loginInvalid[lang], lang)

    case "password_invalid":
      return errorToast(accessErrors.passwordInvalid[lang], lang)

    case "login_already_exists":
      return errorToast(accessErrors.loginAlreadyExists[lang], lang)

    case "too_many_requests":
      return errorToast(accessErrors.tooManyRequests[lang], lang)

    case "invalid_ip_address":
      return errorToast(accessErrors.invalidIpAddress[lang], lang)

    default:
      return errorToast("Something went wrong!", lang);
  }
};
