import {IconSvg} from "../../../../../../../utils/constants/icons/iconSvg.constant";
import {useState} from "react";
import {classNames} from "../../../../../../../utils/helpers/classNames";
import {MainFilter} from "../Filters/MainFilter/MainFilter";
import {useSearchParams} from "react-router-dom";

export const FormatCellFilter = () => {
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  
  const handleFilter = (e) => {
    e.stopPropagation();
    setIsOpen((current) => !current);
  };
  
  const isParamsEnabled = searchParams.get("dateStart")
    || searchParams.get("dateEnd")
    || searchParams.get("country")
    || searchParams.get("runningOuts")
  
  return (
    <>
      <div
        className={classNames(
          "table__filter ",
          { "table__filter--active": isOpen || isParamsEnabled !== null }
        )}
        onClick={handleFilter}
      >
        <IconSvg tag="tableFilter" className="filter-table__icon" />
      </div>
      <div className={classNames({"table__filter--hidden": !isOpen})}>
        <MainFilter isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};
