import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { EnabledProxySlider } from "../../../../components/ui/EnabledProxySlider/EnabledProxySlider";
import { GroupButtons } from "../../../../components/ui/Buttons";
import { useDispatchedActions, useTranslation } from "../../../../hooks";
import { Form } from "./components/Form";
import "./NewOrder.scss";

export const NewOrder = () => {
  const { proxies } = useSelector((state) => state.content);
  const { main } = useTranslation();
  const { setConfigModalCabinet } = useDispatchedActions();
  // **Local State
  const [localProxyType, setLocalProxyType] = useState(proxies.enabled[0]?.code || null);
  const handlerSetProxyType = (code) => setLocalProxyType(code);

  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const controller = new AbortController();

  useEffect(() => {
    setConfigModalCabinet(localProxyType);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localProxyType]);

  useEffect(() => () => {
      controller.abort();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const enabledProxyList = proxies.enabled.map((item) => ({
    ...item,
    code: item.code,
    body: main?.selectProxy[item?.code]
  }));

  return (
    <div className="new-order__container">
      <MediaQuery minWidth={993}>
        <GroupButtons
          list={enabledProxyList}
          active={localProxyType}
          handlerClick={handlerSetProxyType}
          isFormSubmitting={isFormSubmitting}
        />
      </MediaQuery>
      <MediaQuery maxWidth={992}>
        <EnabledProxySlider
          enabledProxyList={enabledProxyList}
          localProxyType={localProxyType}
          handler={handlerSetProxyType}
        />
      </MediaQuery>
      <div className="new-order__body">
        <Form
          proxyCode={localProxyType}
          controller={controller}
          isFormSubmitting={isFormSubmitting}
          setIsFormSubmitting={setIsFormSubmitting}
        />
      </div>
    </div>
  );
};
