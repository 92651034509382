import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { Button } from "../../../../../components/ui/Buttons/Button/Button";
import "./PaymentMethod.scss";
import { CreditCard } from "../../../../../components/ui/CreditCard/CreditCard";
import { useTranslation } from "../../../../../hooks";
import { errorToast } from "../../../../../utils/helpers/customToast.helper";
import { ApiService } from "../../../../../services/ApiService";
import { Skeleton } from "../../../../../components/ui/Skeleton/Skeleton";

export const PaymentMethod = () => {
  const { lang } = useParams();
  const [paddleData, setPaddleData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { cabinet: { balance: { paymentMethod: { details, noPaymentMethod, add } } } } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const { data, status } = await ApiService.getPaddleData();

        if (status !== 200) {
          throw new Error();
        }

        setPaddleData(data);
        setIsLoaded(true);

      } catch (e) {
        errorToast("Something went wrong!", lang);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiatePay = () => {
    if (!paddleData) return;

    window.Paddle.Checkout.open({
      "return_url": "/",
      product: paddleData.subscription_plan_id,
      passthrough: paddleData.passthrough,
      email: paddleData.email
    });
  };

  const updatePaddleData = () => {
    if (!paddleData) return;

    window.Paddle.Checkout.open({
      displayMode: "",
      override: paddleData.card.updateUrl
    });
  };

  const removePaddleData = () => {
    if (!paddleData) return;

    window.Paddle.Checkout.open({
      override: paddleData.card.cancelUrl
    });
  };

  return (
    <div className="payment-method">
      <div className="payment-method__card-icon">
        <IconSvg tag="creditCard" />
      </div>
      <div className="payment-method__no-card-container">
        <div className="payment-method__details">
          <div className="payment-method__info">
            <div className="payment-method__label">{details}</div>
            {!isLoaded ? (
              <div className="payment-method__text">
                <Skeleton width="100%" height={24} rounded={4} uniqueKey="payment-method__text" />
              </div>
            ) : (
              <>
                {paddleData?.card ? (
                  <CreditCard card={paddleData.card} />
                ) : (
                  <div className="payment-method__text">{noPaymentMethod}</div>
                )}
              </>
            )}
          </div>
        </div>
        {!isLoaded ? (
          <div className="payment-method__edit-buttons">
            {/*<div className="payment-method__edit">*/}
            {/*  <Skeleton width={48} height={48} rounded={8} uniqueKey="payment-method__edit" />*/}
            {/*</div>*/}
            <Skeleton width={100} height={48} rounded={8} uniqueKey="payment-method__trash" />
          </div>
        ) : (
          <>
            {paddleData?.card ? (
              <div className="payment-method__edit-buttons">
                <div className="payment-method__button payment-method__edit" onClick={updatePaddleData}>
                  <IconSvg tag="edit" />
                </div>
                <div
                  className="payment-method__button payment-method__trash"
                  onClick={removePaddleData}
                >
                  <IconSvg tag="trash" />
                </div>
              </div>
            ) : (
              <Button style={{ minHeight: 48, height: 48 }} tab="confirm" onClick={initiatePay}>
                <div className="payment-method__add-icon">
                  <IconSvg tag="addInCircle" />
                </div>
                <div>{add}</div>
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
