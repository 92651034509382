import { useEffect, useState } from "react";
import { ButtonLoader } from "../../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { Button } from "../../../../../../../components/ui/Buttons/Button/Button";
import { useTranslation } from "../../../../../../../hooks";

export const TimerButton = ({ isLoading, delay, isSubmitDisabled, setIsSubmitDisabled }) => {
  const { cabinet: { orders: { access: {
    save
  } } } } = useTranslation();

  const [seconds, setSeconds] = useState(delay);

  const startTimer = () => {
    setIsSubmitDisabled(true);

    // eslint-disable-next-line no-undef
    return setInterval(() => {
      setSeconds((current) => current - 1);
    }, 1000);
  };

  const stopTimer = (timer) => {
    setTimeout(() => {
      setIsSubmitDisabled(false);

      // eslint-disable-next-line no-undef
      clearInterval(timer);
    }, seconds * 1000);
  };

  useEffect(() => {
    if (seconds <= 0) {
      return;
    }

    const timer = startTimer();

    stopTimer(timer);

    // eslint-disable-next-line no-undef
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatTime = () => {
    if (seconds >= 3600) {
      return new Date(seconds * 1000).toISOString().slice(11, 19);
    }
    return new Date(seconds * 1000).toISOString().slice(14, 19);
  };

  const isButtonDisabled = isSubmitDisabled && seconds;

  return (
    <Button
      tab={isButtonDisabled ? "disabled" : "confirm"}
      type="submit"
      disabled={isButtonDisabled || isLoading}
    >
      {
        // eslint-disable-next-line no-nested-ternary
        isLoading
          ? <ButtonLoader />
          : isButtonDisabled ? formatTime() : save
      }
    </Button>
  );
};
