import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomModal } from "../../../../../../components/ui/CustomModal/CustomModal";
import { CheckBox } from "../../../../../../components/ui/CheckBoxes/CheckBox";
import { Button } from "../../../../../../components/ui/Buttons/Button/Button";
import { useDispatchedActions, useTranslation } from "../../../../../../hooks";
import { ApiService } from "../../../../../../services/ApiService";
import { errorToast, successToast, warningToast } from "../../../../../../utils/helpers/customToast.helper";
import { classNames } from "../../../../../../utils/helpers/classNames";
import "./RemoveAccModal.scss";

export const RemoveAccModal = ({ isRemoveAccModalOpen, onClose }) => {
  const { lang } = useParams();

  const [code, setCode] = useState("");
  const [isRemovingConfirm, setIsRemovingConfirm] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [timer, setTimer] = useState(0);

  const navigate = useNavigate();
  const { setUserAuthData } = useDispatchedActions();

  const { cabinet: { profile: { removeAccModal: {
    title,
    description,
    inputLabel,
    inputPlaceholder,
    sendCode,
    confirmMessageP1,
    confirmMessageP2,
    repeatCode,
    cancel,
    approve,
    successSendingCodeMessage,
    tooManyRequests,
    confirmationMessage,
    invalidCode
  } } } } = useTranslation();

  const logout = () => {
    localStorage.removeItem("client_profile");
    setUserAuthData({ isAuth: false, email: "" });
    navigate(`/${lang}/`);
  };

  const closeModal = () => {
    setCode("");
    setIsRemovingConfirm(false);
    onClose();
  };

  const handleChange = (e) => {
    let input = e.target.value;

    input = input.replace(/\D/g, "");

    if (input.length > 6) {
      input = input.slice(0, 6);
    }

    if (input.length > 3) {
      input = `${input.slice(0, 3) }-${ input.slice(3)}`;
    }

    setCode(input);
  };

  const calculateRemainingTime = (expireTime) => {
    if (!expireTime) return 0;

    const expireDate = new Date(expireTime).getTime();
    const currentTime = Date.now();
    const remainingTime = Math.floor((expireDate - currentTime) / 1000);
    return remainingTime > 0 ? remainingTime : 0;
  };

  const sendCodeToMail = async () => {
    try {
      const { data, status } = await ApiService.sendCodeForRemovingUser();

      if (data?.errorCode === 2) {
        return warningToast(tooManyRequests, lang);
      }

      if (status !== 200) {
        throw new Error();
      }

      const remainingTime = calculateRemainingTime(data.expireTime);

      if (remainingTime > 0) {
        localStorage.setItem("expireTime", data.expireTime);
        setIsCodeSent(true);
        setTimer(remainingTime);
      }

      successToast(successSendingCodeMessage, lang);
    } catch (e) {
      errorToast("Something went wrong", lang);
    }
  };

  const removeAccHandler = async () => {
    if (!isRemovingConfirm) {
      return warningToast(confirmationMessage, lang);
    }

    try {
      const deleteUserCode = Number(code.replace("-", ""));
      const { status, data } = await ApiService.removeAcc({ deleteUserCode });

      if (data?.errorCode === 3) {
        return warningToast(invalidCode, lang);
      }

      if (status !== 200) {
        throw new Error();
      }

      logout();

    } catch (e) {
      errorToast("Something went wrong", lang);
    }
  };

  useEffect(() => {
    const storedExpireTime = localStorage.getItem("expireTime");

    if (!storedExpireTime) return;

    const remainingTime = calculateRemainingTime(storedExpireTime);

    if (!remainingTime) {
      localStorage.removeItem("expireTime");
      return;
    }

    setIsCodeSent(true);
    setTimer(remainingTime);
  }, []);

  useEffect(() => {
    let interval;

    if (timer === 0 && isCodeSent) {
      setIsCodeSent(false);
      localStorage.removeItem("expireTime");

      return;
    }

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer, isCodeSent]);

  const getFormattedRemainingTime = () => {
    const minutes = Math.floor(timer / 60);
    const remainingSeconds = timer % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `(${formattedMinutes}:${formattedSeconds})`;
  };

  const isApproveButtonDisabled = code.length !== 7;

  return (
    <CustomModal open={isRemoveAccModalOpen} onClose={closeModal}>
      <div className="remove-acc">
        <div className="remove-acc__title">{title}</div>
        <div className="remove-acc__description">
          {description}
        </div>
        <div className="remove-acc__code-field">
          <div className="remove-acc__input-container">
            <div className="remove-acc__input-label">{inputLabel}</div>
            <input
              className="remove-acc__input"
              value={code}
              onChange={handleChange}
              placeholder={inputPlaceholder}
            />
          </div>
          {!isCodeSent && (
            <div className="remove-acc__send-code" onClick={sendCodeToMail}>{sendCode}</div>
          )}
        </div>
        <div className={classNames(
          "remove-acc__repeat-code",
          { "remove-acc__repeat-code--hide": !isCodeSent }
        )}>
          {repeatCode} {getFormattedRemainingTime()}
        </div>
        <div className="remove-acc__confirmation">
          <CheckBox
            checked={isRemovingConfirm}
            onChange={(e) => setIsRemovingConfirm(e.target.checked)}
          />
          <div className="remove-acc__confirmation-text">
            {confirmMessageP1} <span>{confirmMessageP2}</span>
          </div>
        </div>
        <div className="remove-acc__actions">
          <Button onClick={closeModal}>{cancel}</Button>
          <Button
            tab={isApproveButtonDisabled ? "disabled" : "confirm"}
            disabled={isApproveButtonDisabled}
            onClick={removeAccHandler}
          >
            {approve}
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};
