import { classNames } from "../../../utils/helpers/classNames";
import "./Radio.scss";

export const Radio = ({ isActive }) => (
    <span className={classNames(
      "radio",
      { "radio--active": isActive },
      { "radio--disabled": !isActive }
    )} />
  );
