import { createSlice } from "@reduxjs/toolkit";

const FooterReducer = createSlice({
  name: "footer",
  initialState: {
    footerModal: null
  },
  reducers: {
    setFooterModal(state, action) {
      state.footerModal = action.payload;
    }
  }
});

export const FooterReducerActions = {
  ...FooterReducer.actions
};

export default FooterReducer.reducer;
