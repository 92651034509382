import {useLayoutEffect} from "react";
import {addGoogleTagManagerNoscript} from "../utils/scripts/addGoogleTagManagerNoscript";
import {loadCookiebotScript} from "../utils/scripts/loadCookiebotScript";
import {loadGoogleAnalytics} from "../utils/scripts/loadGoogleAnalytics";
import {loadGoogleTagManager} from "../utils/scripts/loadGoogleTagManager";
import {loadPaymentMeta} from "../utils/scripts/loadPaymentMeta";
import {loadTrustpilotScript} from "../utils/scripts/loadTrustpilotScript";

export const useLoadScripts = () => {
  if (process.env.REACT_APP_RENDER_MODE !== "spa") return;
  
  useLayoutEffect(() => {
    addGoogleTagManagerNoscript()
    loadCookiebotScript()
    loadGoogleAnalytics()
    loadGoogleTagManager()
    loadPaymentMeta()
    loadTrustpilotScript()
  }, [])
};
