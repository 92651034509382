import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useEffect } from "react";

import { useDispatchedActions } from "./useDispatchedActions";

export const useLoadSeo = () => {
  // **Props
  const { lang } = useParams();

  // Dispatch
  const { fetchSiteSeo } = useDispatchedActions();

  // **Redux State
  const { seo, activeData } = useSelector((state) => state.content);

  useEffect(() => {
    if (activeData?.proxy?.code) {
      const keysSeo = Object.keys(seo?.data);
      if (keysSeo.includes(lang)) {
        const keysSeoLang = Object.keys(seo?.data[lang]);
        if (
          activeData?.proxy?.code !== "IPv4" ||
          (activeData?.proxy?.code === "IPv4" && !activeData?.country?.id)
        ) {
          if (!keysSeoLang.includes(activeData?.proxy?.code)) {
            fetchSiteSeo({
              countryId: "",
              localeCode: lang,
              proxyType: activeData?.proxy?.code
            });
          }
        } else if (
          activeData?.country?.id &&
          !keysSeoLang.includes(activeData?.country?.id)
        ) {
          fetchSiteSeo({
            countryId: activeData?.country?.id,
            localeCode: lang,
            proxyType: activeData?.proxy?.code
          });
        }
      } else if (
        activeData?.proxy?.code !== "IPv4" ||
        (activeData?.proxy?.code === "IPv4" && !activeData?.country?.id)
      ) {
        fetchSiteSeo({
          countryId: "",
          localeCode: lang,
          proxyType: activeData?.proxy?.code
        });
      } else {
        fetchSiteSeo({
          countryId: activeData?.country?.id,
          localeCode: lang,
          proxyType: activeData?.proxy?.code
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, activeData?.country?.id, activeData?.proxy?.code]);
};
