import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "../../../../../../assets/styles/pagination.min.css";
import "../../../../../../assets/styles/swiper.min.css";
import { sliceText } from "../../../../../../utils/helpers/sliceText";

import "swiper/css/pagination";

export const Slider = (props) => {
  // **Props
  const { slides } = props;

  const descriptionMaxLength = 225;

  return (
    <Swiper
      className="feedbacks__slider"
      spaceBetween={12}
      // speed={500}
      // autoplay={{ delay: 3000 }}
      watchSlidesProgress
      wrapperTag="ul"
      swipeHandler=".swiper-wrapper"
      navigation={{
        nextEl: ".feedbacks__header__btn-next",
        prevEl: ".feedbacks__header__btn-prev",
        disabledClass: "feedbacks__header__btn-disabled"
      }}
      modules={[Navigation, Pagination, Autoplay]}
      pagination={{ clickable: true }}
      breakpoints={{
        360: {
          spaceBetween: 12,
          slidesPerView: 1.1
        },
        460: {
          spaceBetween: 24,
          slidesPerView: 1.1
        },
        767: {
          slidesPerView: 2.1
        },
        993: {
          slidesPerView: 3,
          spaceBetween: 32
        }
      }}
    >
      {slides &&
        slides.map((slide) => (
          <SwiperSlide
            className={"feedbacks__slider-item"}
            tag="li"
            style={{ height: "auto" }}
            key={slide?.id}
          >
            <div className="feedbacks__top">
              {slide?.avatarUrl && (
                <img
                  className="feedbacks__avatar"
                  src={slide?.avatarUrl}
                  // width="48"
                  // height="48"
                  loading="lazy"
                  alt={slide?.name}
                />
              )}
              <div className="feedbacks__info">
                <div className="feedbacks__name">{slide?.name}</div>
                <a className="feedbacks__place" href={slide?.siteUrl} target="_blank" rel="noreferrer nofollow">
                  {slide?.siteName}
                </a>
              </div>
            </div>
            {/*<p className="feedbacks__text">{slide?.text}</p>*/}
            <p className="feedbacks__text">{sliceText(slide?.text, descriptionMaxLength)}</p>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
