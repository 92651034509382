import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import "../../../../assets/styles/pagination.min.css";
import "../../../../assets/styles/swiper.min.css";
import { useTranslation } from "../../../../hooks";
import { Container } from "../../../../components/ui/Container/Container";
import "./Tips.scss";
import { useResize } from "../../../../hooks/useResize";
import { useIsThemeDark } from "../../../../hooks/useIsThemeDark";

export const Tips = (props) => {
  // **Props
  const { advantages, sectionTitle } = props;
  const { lang } = useParams();
  const isThemeDark = useIsThemeDark();
  // const swiperRef = useRef(null);

  // ** Redux State
  const { content, activeData } = useSelector((state) => state.content);
  // *Local State
  // const [localTips, setLocalTips] = useState([]);
  const [trigerPlay, setTrigerPlay] = useState(true);
  // **Hooks
  const { width } = useResize();
  // const debounceSize = useDebounceValue(size, 3000);
  const {
    advantages: { title }
  } = useTranslation();

  const handleSwiperResize = (e) => {
    if (!e) {
      return null;
    }

    if (e.width < 993) {
      e.allowTouchMove = true;
    } else {
      e.allowTouchMove = false;
    }

    if (e.width > 944) {
      e.autoplay.stop();
      setTrigerPlay(true);
    } else if (trigerPlay) {
      e.autoplay.start();
      setTrigerPlay(false);
    }
  };

  // useEffect(() => {
  //   if (content?.isLoaded && lang) {
  //     if (advantages) {
  //       setLocalTips(
  //         content?.data[lang]?.advantages.filter((tip) =>
  //           tip?.showOnFrontType.includes(activeData?.proxy?.code)
  //         )
  //       );
  //     } else {
  //       setLocalTips(
  //         content?.data[lang]?.tips.filter((tip) =>
  //           tip?.showOnFrontType.includes(activeData?.proxy?.code)
  //         )
  //       );
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [lang, content?.data, content?.isLoaded, activeData?.proxy?.code]);

  // useEffect(() => {
  //   if (swiperRef?.current) if (size?.width > 993) {
  //       swiperRef.current.swiper.autoplay.stop();
  //     } else {
  //       swiperRef.current.swiper.autoplay.start();
  //     }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debounceSize]);

  const localTips = useMemo(() => {
    if (content?.isLoaded && lang) {
      if (advantages) {
        return content?.data[lang]?.advantages.filter((tip) =>
          tip?.showOnFrontType.includes(activeData?.proxy?.code)
        );
      } else {
        return content?.data[lang]?.tips.filter((tip) =>
          tip?.showOnFrontType.includes(activeData?.proxy?.code)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, content?.data, content?.isLoaded, activeData?.proxy?.code]);

  const getSlidesToShow = () => {
    if (!width) {
      return 1;
    }

    if (width < 767) {
      return 1.4;
    }

    if (width < 993) {
      return 2.1;
    }

    return localTips?.length < 5 ? localTips?.length : 5;
  };

  return (
    content?.isLoaded && (
      <section className={`tips ${advantages ? "tips__second" : ""} ${isThemeDark ? "tips--dark" : ""} `}>
        { sectionTitle && (
          <Container>
            <div className="feedbacks-title">
              {title}
            </div>
          </Container>
        )}
        <Swiper
          className="tips__advantages"
          spaceBetween={24}
          speed={500}
          slidesPerView={getSlidesToShow()}
          wrapperTag="ul"
          watchOverflow
          navigation
          loop={localTips?.length >= 5}
          onResize={handleSwiperResize}
          onAfterInit={handleSwiperResize}
          modules={[Navigation, Pagination, Autoplay]}
        >
          {typeof localTips === "object" &&
            localTips.map((tip) => (
              <SwiperSlide
                className="tips__advantage"
                tag="li"
                style={{ height: "auto" }}
                key={tip?.id}
              >
                <div className="icon-counter">
                  <div className="icon-shodow">
                    <IconSvg tag={tip?.imgName} />
                  </div>
                </div>
                {tip?.text}
              </SwiperSlide>
            ))}
        </Swiper>
      </section>
    )
  );
};
