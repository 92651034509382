import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Container } from "../../components/ui/Container/Container";

import { useDispatchedActions } from "../../hooks/useDispatchedActions";

import { ApiService } from "../../services/ApiService";
import { isSSR } from "../../utils/helpers";
import { Balance } from "./components/Balance/Balance";
import { Documentation } from "./components/Documentation/Documentation";
import { Orders } from "./components/Orders/Orders";
import { Profile } from "./components/Profile/Profile";

import "./CabinetPage.scss";
import { Tabs } from "./components/Tabs/Tabs";
import { Notifications } from "./components/Notifications/Notifications";
import { NewOrder } from "./components/NewOrder/NewOrder";
import { FaqTab } from "./components/FaqTab/FaqTab";
import { LogoutModal } from "./components/Modals/LogoutModal/LogoutModal";

const hashes = [
  "profile",
  "orders",
  "balance",
  "documentation",
  "notifications",
  "new-order",
  "faq"
];

const CabinetPage = () => {
  // **Props
  const { lang } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const result = searchParams.get("result");
  const proxyType = searchParams.get("proxyType");

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Dispatch
  const { setLoginModal, setActiveProxy, setUserAuthData } = useDispatchedActions();

  // **Redux State
  const { user, proxies } = useSelector((state) => state.content);

  useEffect(() => {
    if (isSSR()) {
      return;
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!user?.isAuth && user?.isAuthLoaded) {
      setLoginModal(true);
      // navigate(`/${lang}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isAuthLoaded, user?.isAuth]);

  useEffect(() => {
    if (proxies?.enabled.length > 0) {
      const defaultProxy = proxies?.enabled.find((proxy) => proxy.code === proxyType)
        || proxies?.enabled[0];
      setActiveProxy(defaultProxy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxies?.enabled]);

  useEffect(() => {
    if (
      (!hashes.includes(tab) && !result) ||
      (tab === "balance" && !proxies?.data?.balance) ||
      (tab === "documentation" && !proxies?.data?.api)
    ) {
      setSearchParams({ tab: "profile" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const logout = async () => {
    setIsLoading(true);
    try {
      const response = await ApiService.logout();

      if (response.response && response.response.status !== 200) {
        navigate(`/${lang}/dashboard/?tab=profile`);
        throw response;
      }
      localStorage.removeItem("client_profile");
      navigate(`/${lang}/`);
      // setUserAuthStatus(false);
      setUserAuthData({ isAuth: false, email: "" });
    } catch (err) {
      return err;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    user?.isAuth &&
    user?.isAuthLoaded && (
      <>
        <section className="cabinet">
          <Tabs
            active={tab}
            setSearchParams={setSearchParams}
            setIsLogoutModalOpen={setIsLogoutModalOpen}
          />
          <Container>
            <div className="cabinet__content">
              {tab === "profile" && <Profile logout={logout} />}
              {tab === "balance" && proxies?.data?.balance && <Balance />}
              {tab === "new-order" && <NewOrder />}
              {tab === "documentation" && proxies?.data?.api && <Documentation />}
              {tab === "notifications" && <Notifications />}
              {tab === "faq" && <FaqTab />}
            </div>
          </Container>
          <div className="cabinet__content">
            {tab === "orders" && <Orders />}
          </div>
        </section>
        <LogoutModal
          isOpen={isLogoutModalOpen}
          setIsOpen={setIsLogoutModalOpen}
          exitAction={logout}
          isLoading={isLoading}
        />
      </>
    )
  );
};

export default CabinetPage;
