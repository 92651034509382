import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// import { useDispatchedActions, useTranslation } from "hooks";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { useDispatchedActions } from "../../../../../../../hooks/useDispatchedActions";
import { CustomModal } from "../../../../../../../components/ui/CustomModal/CustomModal";
import { InitialForm } from "./components/InitialForm/InitialForm";
import { SecondaryForm } from "./components/SecondaryForm/SecondaryForm";

export const RecoveryModal = () => {
  // **Props
  const { hash } = useParams();

  // Dispatch
  const { setRecoveryModal } = useDispatchedActions();

  // **State
  const { isRecoveryModalOpened } = useSelector((state) => state.header);
  const {
    header: { modalRecovery }
  } = useTranslation();

  useEffect(() => {
    if (hash) {
      setRecoveryModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const handleClose = () => {
    setRecoveryModal(!isRecoveryModalOpened);
  };

  return (
    <CustomModal
      open={isRecoveryModalOpened}
      onClose={handleClose}
      className="modal__login"
      header={modalRecovery.title}
    >
      <div className="modal__login-inner">
        {hash ? <SecondaryForm /> : <InitialForm />}
      </div>
    </CustomModal>
  );
  // if (isRecoveryModalOpened) {
  //   return (
  //     <div id="owerlay" className="modal-owerlay" onClick={handleClose}>
  //       <div className="modal-container modal__login">
  //         <div className="modal__top">{modalRecovery.title}</div>
  //         <div className="modal__login-inner">
  //           {hash ? <SecondaryForm /> : <InitialForm />}
  //         </div>

  //         <button
  //           className="modal-container-close_btn"
  //           onClick={() => setRecoveryModal(!isRecoveryModalOpened)}
  //         >
  //           <IconSvg tag="close" />
  //         </button>
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return null;
  // }
};
