import { useCallback, useEffect } from "react";
import { isSSR } from "../../../../../../../../../utils/helpers";
import { useTranslation } from "../../../../../../../../../hooks";
import { classNames } from "../../../../../../../../../utils/helpers/classNames";

export const DropDown = ({ operators, setIsOpen, setOperator, selectedOperator }) => {
  const { select: { noOptionsMessage } } = useTranslation();

  const closeMenu = useCallback(({ target }) => {
    if (!target.closest(".operator-select")) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSSR()) {
      return;
    }

    window.addEventListener("click", closeMenu);

    return () => window.removeEventListener("click", closeMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectOperator = (operator) => {
    setOperator(operator);
    setIsOpen(false);
  };

  return (
    <div className="operator-select__dropdown">
      {operators.length === 0 && (
        <div className="operator-select__no-options">{noOptionsMessage}</div>
      )}
      <ul className="operator-select__operators-list">
        {operators.map((operator) => (
          <li
            className={classNames(
              "operator-select__operators-item",
              { "operator-select__operators-item--active": selectedOperator?.value === operator?.value }
            )}
            key={operator?.id}
            onClick={() => selectOperator(operator)}
          >
            {operator?.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
