import { Skeleton } from "../../../../../../../components/ui/Skeleton/Skeleton";
import { PriceItemTemplateSkeleton } from "../../PriceItemTemplate/PriceItemTemplateSkeleton/PriceItemTemplateSkeleton";
import "../UserQuantity";

export const UserQuantitySkeleton = () => (
    <PriceItemTemplateSkeleton>
      <div className="user-quantity">
        <div className="user-quantity__container">
          <span className="user-quantity__title">
            <Skeleton uniqueKey="user-quantity__title" width={126} height={45.2} rounded="2px" />
          </span>
          <span className="user-quantity__price-per-one">
            <Skeleton uniqueKey="user-quantity__price-per-one" width={50} height={14} rounded="2px" />
          </span>
        </div>
        <div className="user-quantity__params-container">
          <div className="user-quantity__days">
            <Skeleton uniqueKey="user-quantity__days" width="100%" height={64} rounded="2px" style={{ width: "100%" }} />
          </div>
          <div className="user-quantity__quantity">
            <Skeleton uniqueKey="user-quantity__quantity" width="100%" height={64} rounded="2px" style={{ width: "100%" }} />
          </div>
        </div>
        <div className="user-quantity__price-container">
          <span className="user-quantity__label">
            <Skeleton uniqueKey="user-quantity__label" width={103} height={14} rounded="2px" />
          </span>
          <span className="user-quantity__full-price">
            <Skeleton uniqueKey="user-quantity__full-price" width={40} height={31} rounded="2px" />
          </span>
        </div>
      </div>
    </PriceItemTemplateSkeleton>
  );
