import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { getCountryFlag } from "../../../../../utils/helpers/getCountryFlag";
import { useTranslation } from "../../../../../hooks";
import "./PlannedPayments.scss";
import { getCountryName } from "../../../../../utils/helpers/getCountryName";
import { classNames } from "../../../../../utils/helpers/classNames";

export const PlannedPayments = ({ plannedPayments, expanded, setExpanded }) => {
  const { lang } = useParams();
  const { cabinet: { balance: { plannedPayment: { title, country, quantityAndType } } } } = useTranslation();
  const { countries } = useSelector((state) => state.content);

  const ppCard = useRef();
  const ppContainer = useRef();

  const CARD_GAP = 20;
  const PP_SOLO = plannedPayments.length === 1;

  const getDate = (dateStr) => {
    const [date] = dateStr.split("T");
    const [year, month, day] = date.split("-");

    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    if (!ppContainer.current || !ppCard.current) return;

    const plannedPaymentsCount = plannedPayments.length;
    const gap = (CARD_GAP * plannedPaymentsCount - CARD_GAP);

    if (expanded) {
      ppContainer.current.style.height = `${ppCard.current.clientHeight * plannedPaymentsCount + gap}px`;
    } else {
      ppContainer.current.style.height = `${ppCard.current.clientHeight}px`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const getPlannedPayments = () => {
    if (PP_SOLO) return plannedPayments;

    if (!expanded) {
      return [...plannedPayments.slice(0, 5)].reverse();
    }

    return plannedPayments;
  };

  const getCardTopValue = (idx) => {
    if (!expanded || !ppCard?.current?.clientHeight) return `${idx * 10}px`;

    const gap = !idx ? 0 : CARD_GAP;
    return idx * (ppCard?.current?.clientHeight + gap);
  };

  const scrollToPPContainer = () => {
    const container = document.getElementById("pp-container");

    if (!container) return;

    container.scrollTo({ behavior: "smooth" });
  };

  const openItems = () => {
    if (PP_SOLO) return;

    setExpanded(true);
  };

  const closeItems = () => {
    if (PP_SOLO) return;

    scrollToPPContainer();
    setExpanded(false);
  };

  return (
    <div className="planned-payments__wrapper">
      <div ref={ppContainer} className={classNames("planned-payments", { "planned-payments--expanded": expanded || PP_SOLO })}>
        {getPlannedPayments().map((plannedPayment, idx) => (
          <div
            ref={idx === 0 ? ppCard : null}
            className={`planned-payment ${expanded || PP_SOLO ? "expanded" : ""}`}
            key={plannedPayment?.id || idx}
            style={{ top: getCardTopValue(idx) }}
            onClick={expanded ? closeItems : openItems}
          >
            <div className="planned-payment__right-side">
              <div className="planned-payment__icon">
                <div className="planned-payment__icon-shadow">
                  <IconSvg tag="exclamation" />
                </div>
              </div>
              <div className="planned-payment__heading">
                <div className="planned-payment__title">{title}</div>
                <div className="planned-payment__heading-about">
                  <div className="planned-payment__price">${plannedPayment.amount}</div>
                  <div className="planned-payment__date-container">
                    <IconSvg tag="clock" />
                    <span className="planned-payment__date">{getDate(plannedPayment.extendDate)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="planned-payment__left-side">
              <div className="planned-payment__country-info">
                <div className="planned-payment__label">{country}</div>
                <div className="planned-payment__country">
            <span className="planned-payment__country-flag">
              {getCountryFlag(plannedPayment.country.code)}
            </span>
                  <span className="planned-payment__country-name">
                  {getCountryName(plannedPayment.country.code, lang, countries.data)}
                </span>
                </div>
              </div>
              <div className="planned-payment__quantity">
                <div className="planned-payment__label">{quantityAndType}</div>
                <span>{plannedPayment.ipIds.length} IPs</span><span className="planned-payment__divider">|</span><span>{plannedPayment.proxyType}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
