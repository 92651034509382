import { Skeleton } from "../../../../components/ui/Skeleton/Skeleton";

export const FeedbackCardLoader = () => (
  <div className="feedback">
    <div className="feedbacks__top">
      <div className="feedback__avatar">
        <Skeleton uniqueKey="feedback__avatar" width="100%" height="100%" rounded="50%" />
      </div>
      <div className="feedbacks__info">
        <div className="feedbacks__name">
          <Skeleton uniqueKey="feedbacks__name" width={65} height={20} rounded="4px" />
        </div>
        <a
          className="feedbacks__place"
          href="https://www.facebook.com/"
          target="_blank"
          rel="noreferrer"
        >
          <Skeleton uniqueKey="feedbacks__place" width={75} height={16} rounded="4px" />
        </a>
      </div>
    </div>
    <div className="feedbacks__text">
      <Skeleton uniqueKey="feedbacks__text" width="100%" height={40} rounded="4px" />
    </div>
  </div>
);
