import { HOME_URL } from "./homeUrl";

export const FAVICON_LINKS = [
  {
    id: "favicon",
    rel: "shortcut icon",
    icons: {
      dark: `${HOME_URL}/img/favicons/favicon-dark.ico`,
      light: `${HOME_URL}/img/favicons/favicon-light.ico`
    },
    type: "image/x-icon",
    sizes: "",
    color: "",
    media: {
      dark: "(prefers-color-scheme: dark)",
      light: "(prefers-color-scheme: light)"
    }
  },
  {
    id: "appleIcon",
    rel: "apple-touch-icon",
    sizes: "180x180",
    icons: {
      dark: `${HOME_URL}/img/favicons/apple-touch-icon-dark.png`,
      light: `${HOME_URL}/img/favicons/apple-touch-icon-light.png`
    },
    type: "",
    color: "",
    media: {
      dark: "(prefers-color-scheme: dark)",
      light: "(prefers-color-scheme: light)"
    }
  },
  {
    id: "favicon32",
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    color: "",
    icons: {
      dark: `${HOME_URL}/img/favicons/favicon-32x32-dark.png`,
      light: `${HOME_URL}/img/favicons/favicon-32x32-light.png`
    },
    media: {
      dark: "(prefers-color-scheme: dark)",
      light: "(prefers-color-scheme: light)"
    }
  },
  {
    id: "favicon16",
    rel: "icon",
    type: "image/png",
    color: "",
    sizes: "16x16",
    icons: {
      dark: `${HOME_URL}/img/favicons/favicon-16x16-dark.png`,
      light: `${HOME_URL}/img/favicons/favicon-16x16-light.png`
    },
    media: {
      dark: "(prefers-color-scheme: dark)",
      light: "(prefers-color-scheme: light)"
    }
  },
  {
    id: "safariIcon",
    rel: "mask-icon",
    icons: {
      dark: `${HOME_URL}/img/favicons/safari-pinned-tab-dark.svg`,
      light: `${HOME_URL}/img/favicons/safari-pinned-tab-light.svg`
    },
    type: "",
    sizes: "",
    color: "#5bbad5",
    media: {
      dark: "(prefers-color-scheme: dark)",
      light: "(prefers-color-scheme: light)"
    }
  }
];
