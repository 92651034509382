import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { ModalInput } from "../../../../../components/ui/ModalInput/ModalInput";
import { ApiService } from "../../../../../services/ApiService";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { errorToast, successToast } from "../../../../../utils/helpers/customToast.helper";
import { authErrorsHandler } from "../../../../../utils/helpers/authErrorsHandler";
import { emailPattern } from "../../../../../utils/constants/emailPattern";

export const ChangeEmail = ({ close = () => null, logout }) => {
  // *Props
  const { lang } = useParams();

  //  *Ref
  const methods = useForm({
    mode: "onChange"
  });

  // ** Local state
  const { header, cabinet: { profile }, notifications } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  // Form submit
  const onSubmit = async (data) => {

    if (!data?.newEmail || !data?.oldPassword) {
      return;
    }

    try {
      setIsLoading(true);
      const { status } = await ApiService.changeEmail(
        data?.newEmail,
        data?.oldPassword
      );

      if (status === 206) {
        return errorToast(notifications?.tempMail, lang);
      }

      if (status !== 200) {
        throw status;
      }

      successToast(notifications?.emailChange, lang);
      logout();
    } catch (status) {
      authErrorsHandler(status, "email", lang);
    } finally {
      setIsLoading(false);
    }
  };

  // const emailIsNotValid = methods.formState.errors.newEmail?.type === "pattern";
  return (
    <FormProvider {...methods}>
      <form
        className="profile__data-item__body"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate={true}
        // autoComplete="off"
      >
        <div className="profile__data-item__body__inputs">
          <ModalInput
            label={header?.formElem.email.label}
            // placeholder={profile?.email?.enterEmail}
            type="email"
            name="newEmail"
            watch={methods.watch}
            rules={{
              required: true,
              pattern: emailPattern
            }}
          />
          <ModalInput
            label={header?.formElem.password.label}
            // placeholder={profile?.email?.enterPassword}
            type="password"
            name="oldPassword"
            watch={methods.watch}
          />
          {/*{ emailIsNotValid && (*/}
          {/*  <p className="modal__form-wrong">{notifications?.wrongEmail}</p>*/}
          {/*)}*/}
        </div>
        <div className="profile__data-item__body__form-actions">
          <button
            className="profile__data-item__action profile__data-item__action-close"
            onClick={close}
          >
            {profile?.close}
          </button>
          <button className="profile__data-item__action" type="submit" disabled={isLoading}>
            {profile?.save}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
