import { useMemo } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { defaultSeo } from "../utils/constants/defaultData.constant";

export const useCurrentSeo = () => {
  const { lang } = useParams();
  const { seo, activeData, countries } = useSelector((state) => state.content);
  
  const currentSeo = useMemo(() => {
    if (!countries?.isLoaded || !seo?.isLoaded) {
      return defaultSeo;
    }
    
    const seoLang = seo?.data[lang];
    
    if (!seoLang) {
      return defaultSeo;
    }
    
    if (activeData?.proxy?.code !== "IPv4" || (activeData?.proxy?.code === "IPv4" && !activeData?.country?.id)) {
      return seoLang[activeData?.proxy?.code] || defaultSeo;
    } else {
      return seoLang[activeData?.country?.id] || defaultSeo;
    }
  }, [lang, activeData?.proxy?.code, activeData?.country?.id, seo?.isLoaded, countries?.isLoaded])
  
  return currentSeo;
};
