import {PROXY_TYPES} from "../../../../../../../utils/constants";

export const FormatCellProtocol = ({ order, protocol }) => {
  const ipv4Value = protocol === "HTTP" ? order.proxyPort : order.socksPort;
  const ipv6Value = order.protocol === protocol ? order.port : "";
  
  return (
    <>
      {order.proxyType === PROXY_TYPES.ipv6.code ? (
        <div className="table__protocol">{ipv6Value}</div>
      ) : (
        <div className="table__protocol">{ipv4Value}</div>
      )}
    </>
  );
};
