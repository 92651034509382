import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatchedActions } from "../../../../hooks/useDispatchedActions";
import { errorToast } from "../../../../utils/helpers/customToast.helper";
import { ApiService } from "../../../../services/ApiService";
import { History } from "./History/History";
import { Top } from "./Top/Top";
import "./Balance.scss";
import { PaymentMethod } from "./PaymentMethod/PaymentMethod";
import { RemovePaymentModal } from "./PaymentMethod/components/RemovePaymentModal/RemovePaymentModal";
import { PlannedPayments } from "./PlannedPayments/PlannedPayments";

export const Balance = () => {
  const { paddleConfig, isScheduledPaymentEnabled } = useSelector((state) => state.content);
  const { fiveDollarLogick } = useSelector((state) => state.content.proxies);
  const { setUseFiveDollarLogick } = useDispatchedActions();
  const [isRemovePaymentModalOpen, setIsRemovePaymentModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [plannedPayments, setPlannedPayment] = useState([]);

  const { lang } = useParams();

  const controller = new AbortController();

  if (fiveDollarLogick?.enabled) {
    setUseFiveDollarLogick(false);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => controller.abort(), []);

  useEffect(() => {
    if (!isScheduledPaymentEnabled) return;

    (async () => {
      try {
        const { data, status } = await ApiService.getAllAutoextends();

        if (status !== 200) {
          throw new Error();
        }

        const sortedData = [...data].sort((a, b) => new Date(a.extendDate) - new Date(b.extendDate));
        setPlannedPayment(sortedData);
      } catch (e) {
        errorToast("Something went wrong", lang);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScheduledPaymentEnabled]);

  return (
    <>
      <Top controller={controller} />
      {paddleConfig?.isEnabled && (
        <PaymentMethod setIsRemovePaymentModalOpen={setIsRemovePaymentModalOpen} />
      )}
      {plannedPayments.length !== 0 && (
        <PlannedPayments
          expanded={expanded}
          setExpanded={setExpanded}
          plannedPayments={plannedPayments}
        />
      )}
      <History controller={controller} setExpanded={setExpanded} />
      {isRemovePaymentModalOpen && (
        <RemovePaymentModal
          open={isRemovePaymentModalOpen}
          onClose={() => setIsRemovePaymentModalOpen(false)}
        />
      )}
    </>
  );
};
