export const getRobotContent = () => {
  const ROBOTS = {
    ALL: "all",
    NONE: "none"
  };

  try {
    return process.env.REACT_APP_MODE === "prod" ? ROBOTS.ALL : ROBOTS.NONE;
  } catch (ignored) {
    return ROBOTS.NONE;
  }
};
