import {warningToast} from "./customToast.helper";
import {getCountryName} from "./getCountryName";

export const sendRenewalWarning = ({ code, message, renewalErrorMessages, lang, countries }) => {
  switch (code) {
    case 1:
      warningToast(renewalErrorMessages.noIp, lang);
      break;
    case 2:
      const notAvailableCountry = getCountryName(message, lang, countries);
      warningToast(renewalErrorMessages.noCountry.replace("_", (notAvailableCountry || "")), lang);
      break;
    case 3:
      warningToast(renewalErrorMessages.noMobileOperator.replace("_", message), lang);
      break;
    default:
      warningToast(renewalErrorMessages.unexpectedError, lang);
  }
};
