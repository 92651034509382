import { classNames } from "../../../utils/helpers/classNames";
import "./RoundCheckbox.scss";

export const RoundCheckbox = ({ isActive, label, handler }) => (
    <div
      onClick={() => handler(label)}
      className={classNames(
      "round-checkbox",
      { "round-checkbox--active": isActive },
      { "round-checkbox--disabled": !isActive }
    )}>
      <div className="round-checkbox__radio" />
      <div className="round-checkbox__label">{label}</div>
    </div>
  );
