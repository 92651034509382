import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CustomInput } from "../../../../../../../../components/ui/CustomInput/CustomInput";
import { Button } from "../../../../../../../../components/ui/Buttons/Button/Button";
import { useDebounceCallback, useTranslation } from "../../../../../../../../hooks";
import "./NotesFilter.scss";

export const NotesFilter = ({ isOpen, setIsOpen }) => {
  const { cabinet: { orders: { filter: { resetButton, filterByNote } } } } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get("description"));

  const resetAll = () => {
    setValue("");
    searchParams.delete("description");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!searchParams.get("description")) {
      setValue("");
    }
  }, [searchParams]);

  const close = useCallback(({ target }) => {
    if (target.closest("#filter-menu") || target.classList.contains("custom-input")
    ) { return; }

    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", close);
      return;
    }

    window.removeEventListener("click", close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const addSearchParams = (value) => {
    if (!value) {
      searchParams.delete("description");
    } else {
      searchParams.set("description", value);
    }

    setSearchParams(searchParams);
  };

  const debouncedAddSearch = useDebounceCallback(addSearchParams, 500);

  const handleChange = (value) => {
    debouncedAddSearch(value);
    setValue(value);
  };

  return (
    <div id="filter-menu" className="notes-filter">
      <div className="notes-filter__container">
        <CustomInput
          value={value}
          label={filterByNote}
          onChange={handleChange}
        />
        <div className="notes-filter__actions">
          { !!value && (
            <Button onClick={resetAll}>
              {resetButton}
            </Button>
          ) }
        </div>
      </div>
    </div>
  );
};
