import { Controller, useFormContext } from "react-hook-form";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";

import "./CheckBox.scss";

export const FormCheckBox = (props) => {
  // **Props
  const {
    id = "",
    label,
    className,
    name,
    defaultValue,
    children,
    rules,
    handleClick
  } = props;
  const { register } = useFormContext();

  // Define correct classnames
  const classNames = (className) =>
    `custom-checkbox  ${ className ? className : ""}`;

  return (
    <Controller name={name} rules={rules} render={() => (
      <div className={classNames(className)}>
        <label>
          <input
            id={id}
            type={"checkbox"}
            defaultChecked={Boolean(defaultValue)}
            { ...register(name, rules) }
            onClick={handleClick}
          />
          <div className="checked">
            <IconSvg tag="checked" />
          </div>
          {label ? label : null}
        </label>
        {children}
      </div>
    )} />
  );
};
