export const getPaymentMethods = (paymentSystems, lang) => {
  let optionsArray = [];

  if (paymentSystems) {
    const filteredArray = paymentSystems.filter(({ locale }) => locale.includes(lang));

    optionsArray = filteredArray.map((item) => ({
        id: item?.id,
        value: item?.name,
        label: item?.name,
        code: item?.code,
        minPrices: item?.minPrices
      }));
  }

  return optionsArray;
};
