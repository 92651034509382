import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import { classNames } from "../../../../../../../../utils/helpers/classNames";
import { useCurrentRentPeriod, useTranslation } from "../../../../../../../../hooks";
import { DropDown } from "./DropDown/DropDown";
import "./Days.scss";

export const Days = ({ proxyCode, selectedDay, setDays }) => {
  const { lang } = useParams();

  const [isOpen, setIsOpen] = useState(false);
  const rentPeriods = useCurrentRentPeriod(proxyCode);

  const { formPlaceholders } = useTranslation();

  useEffect(() => {
    setDays(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const handleSelect = ({ target }) => {
    if (target.closest(".days-select__dropdown")) {
      return;
    }

    setIsOpen((current) => !current);
  };

  return (
    <div className="days-select" onClick={handleSelect}>
      <div className="days-select__value">
        <div className="days-select__title">
          <IconSvg tag="calendar" className="days-select__icon" />
          {selectedDay ? (
            <div className="days-select__selected-day">{selectedDay.label}</div>
          ) : (
            <div>{formPlaceholders?.rentPeriod}</div>
          )}
        </div>
        <IconSvg
          tag="triangleDown12"
          className={classNames(
            "days-select__indicator",
            { "days-select__indicator--open": isOpen }
          )}
        />
      </div>
      {isOpen && (
        <DropDown days={rentPeriods} setIsOpen={setIsOpen} setDays={setDays} selectedDay={selectedDay} />
      )}
    </div>
  );
};
