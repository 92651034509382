import { FeedbackCard } from "../../../../components/common/Feedback/FeedbackCard/FeedbackCard";
import { FeedbackCardLoader } from "../FeedbackCardLoader/FeedbackCardLoader";
import { NoDataComponent } from "../../../../components/common/NoDataComponent/NoDataComponent";
import "./FeedbackList.scss";

export const FeedbackList = ({ reviews, isLoading }) => (
    <div className="feedback-list">
      <div className="feedback-list__container">
        {isLoading ? (
          Array(10).fill("_").map((_, idx) => (
            <FeedbackCardLoader key={idx} />
          ))
        ) : (
          <>
            {reviews?.length ? (
              reviews.map((review) => (
                <FeedbackCard key={review.id} review={review} />
              ))
            ) : (
              <NoDataComponent />
            )}
          </>
        )}
      </div>
    </div>
  );
