/* eslint-disable quotes */
export const DataIPv4 = [
  {
    title: {
      ru: "Получить список купленных ipv4 прокси",
      en: "To get the ipv4 list of the bought proxies" },
    description: false,
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/proxies" },
    requestParams: {
      proxyType: { value: "ipv4",
        ru: "тип прокси",
        en: "type of proxy" }
    },
    requestExample: { url: "/client-api/v1/<apiKey>/get/proxies?proxyType=ipv4", body: null },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD",
      proxyType: "ipv4",
      countOfProxy: 2,
      proxies: [
        {
          id: "id1",
          ip: "185.81.112.215",
          country: "GBR",
          dateStart: "2022-08-29T14:55:38.293+00:00",
          dateEnd: "2022-08-30T23:59:38.293+00:00",
          httpsPort: "49162",
          socks5Port: "49163",
          authInfo: {
            login: "authLogin123",
            password: "authPassword"
          }
        },
        {
          id: "id2",
          ip: "107.181.142.223",
          country: "GBR",
          dateStart: "2022-08-29T14:55:38.293+00:00",
          dateEnd: "2022-08-30T23:59:38.293+00:00",
          httpsPort: "49162",
          socks5Port: "49163",
          authInfo: {
            login: "authLogin123",
            password: "authPassword"
          }
        }
      ]
    },
    responseParams: {
      proxies: { ru: "список активных прокси", en: "the list of active ipv4 proxies" },
      id: { ru: "ID прокси. Этот параметр необходим для продления прокси", en: "proxy ID. This parameter is needed for extending proxy" },
      country: { ru: "альфа3 код страны", en: "alfa3 country code" },
      dateStart: { ru: "дата начала аренды", en: "the rent start date" },
      dateEnd: { ru: "дата окончания аренды", en: "the rent end date" },
      authInfo: { ru: "данные для авторизации", en: "the data for authorization" }
    }
  },
  {
    title: { ru: "Вычислить цену для покупки", en: "Calculate purchase price" },
    description: false,
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/order/price" },
    requestParams: {
      proxyType: { value: "ipv4",
        ru: "тип прокси",
        en: "type of proxy" },
      days: {
        ru: "количество дней для аренды (доступные значения можно получить другим запросом)",
        en: "the number of rental days (available values can be got by other request)"
      },
      goal: {
        ru: "цель использования прокси",
        en: "goal of using proxy" },
      country: {
        ru: "код страны в формате alpha3 (доступные значения можно получить другим запросом)",
        en: "alfa3 country code (available values can be got by other request)"
      },
      count: {
        ru: "количество прокси",
        en: "the number of proxies" }
    },
    requestExample: { url: "/client-api/v1/<apiKey>/get/order/price?proxyType=ipv4&days=7&goal=instagram&country=FRA&count=2", body: null },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD",
      proxyType: "ipv4",
      days: 7,
      count: 2,
      goal: "instagram",
      amount: 2.78,
      price: 1.39
    },
    responseParams: {
      amount: {
        ru: "общая стоимость заказа",
        en: "total amount" },
      price: {
        ru: "цена за 1 прокси",
        en: "price for one proxy" }
    }
  },
  {
    title: {
      ru: "Вычислить цену для продления",
      en: "Calculate extending price" },
    description: false,
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/extend/price" },
    requestParams: {
      proxyType: { value: "ipv4",
        ru: "тип прокси",
        en: "type of proxy" },
      days: {
        ru: "количество дней для продления (доступные значения те же, что и для покупки)",
        en: "the number of renewal days (the available values are the same as for the purchase)"
      },
      IpId: {
        ru: "ID прокси, которые необходимо продлить (разрешается указывать несколько ID через запятую)",
        en: "proxy IDs that require renewal (it is allowed to indicate several IDs separated by commas)"
      }
    },
    requestExample: { url: "/client-api/v1/<apiKey>/get/extend/price?proxyType=ipv4&days=7&IpId=id1,id2,id3", body: null },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD",
      proxyType: "ipv4",
      days: 7,
      count: 3,
      amount: 9.13,
      price: 3.04,
      ipId:["id1","id2","id3"]
    },
    responseParams: {
      amount: {
        ru: "общая стоимость",
        en: "total amount" },
      price: {
        ru: "цена за продление одного прокси",
        en: "price for extending one proxy" },
      ipId: {
        ru: "список ID прокси для продления",
        en: "the list of proxy IDs for extending" }
    }
  },
  {
    title: {
      ru: "Покупка прокси",
      en: "Placing an order" },
    description: false,
    request: { type: "POST", url: "/client-api/v1/<apiKey>/order" },
    requestParams: {
      proxyType: { value: "ipv4",
        ru: "тип прокси",
        en: "type of proxy" },
      days: {
        ru: "количество дней для аренды (доступные значения можно получить другим запросом)",
        en: "the number of rental days (available values can be got by other request)"
      },
      goal: {
        ru: "цель использования прокси",
        en: "goal of using proxy" },
      country: {
        ru: "альфа3 код страны",
        en: "alfa3 country code"
      },
      count: {
        ru: "количество прокси",
        en: "the number of proxies"
      },
      authType: {
        value: "login/ip",
        ru: "тип авторизации",
        en: "authorization type"
      },
      authIp: {
        ru: "IP адрес авторизации. Это поле является обязательным только если authType = ip",
        en: "authorization IP address. This field is required only if authType = ip"
      },
    },
    requestExample: {
      url: "/client-api/v1/<apiKey>/order",
      body: {
        proxyType: "ipv4",
        days: 90,
        goal: "instagram",
        country: "GBR",
        count: 2,
        authType: "login"
      }
    },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 4.09,
      currency: "USD",
      proxyType: "ipv4",
      orderNumber: "DV-16620211552356-jA"
    },
    responseParams: {
      orderNumber:{
        ru:"номер заказа",
        en:"order number"},
      balance:{
        ru:"сумма на балансе после покупки",
        en:"the balance after the purchase"}
    }
  },
  {
    title: {
      ru: "Продление прокси",
      en: "Extending proxy" },
    description: false,
    request: { type: "POST", url: "/client-api/v1/<apiKey>/extend" },
    requestParams: {
      proxyType: { value: "ipv4",
        ru: "тип прокси",
        en: "type of proxy" },
      days: {
        ru: "количество дней для продления (доступные значения те же, что и для покупки)",
        en: "the number of renewal days (the available values are the same as for the purchase)"
      },
      IpId: {
        ru: "список ID прокси, которые нужно продлить",
        en: "the list of proxy IDs to be extended"
      },
    },
    requestExample: {
      url: "/client-api/v1/<apiKey>/extend",
      body: {
        proxyType: "ipv4",
        days: 30,
        IpId: ["id1","id2"]
      }
    },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 4.09,
      currency: "USD"
    },
    responseParams: {
      balance:{
        ru:"сумма на балансе после продления",
        en:"the balance after extending"}
    }
  }
];
