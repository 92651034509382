import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import "./Button.scss";

export const Button = (props) => {
  const { active, icon, initClass, tab = "default", children, ...rest } = props;

  const classNames = () => {
    let className = "btn";

    switch (tab) {
      case "header":
        className += " btn__header";
        break;
      case "buy-proxy":
        className += " btn__buy-proxy";
        break;
      case "confirm":
        className += " btn__confirm";
        break;
      case "delete":
        className += " btn__delete";
        break;
      case "disabled":
        className += " btn__disabled";
        break;
      case "add-feedback":
        className += " btn__add-feedback";
        break;
      case "price-item":
        className += " btn__price-item";
        break;
      default:
        className += " btn__default";
    }

    if (active) {
      className += " btn__active";
    }

    if (initClass) {
      className += ` ${initClass}`;
    }

    return className;
  };
  return (
    <button
      {...rest}
      className={classNames()}
    >
      {children}
      {icon && <IconSvg tag="triangleDown" className="btn-icon" />}
    </button>
  );
};
