import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../../services/ApiService";
import {clearObjectEmptyValues} from "../../../utils/helpers/clearObjectEmptyValues";

export const fetchTableData = createAsyncThunk("cabinet/fetchTableData", async ({ page, size, proxyType, selectedFolder, filterParams, sort, controller, filterByDateParams }, { rejectWithValue }) => {
  try {
    clearObjectEmptyValues(filterParams);
    clearObjectEmptyValues(sort);
  
    const params = {
      proxyType,
      ipAddressFolderId: selectedFolder.value,
      ...filterParams,
    };
  
    const response = await ApiService.getAllUserTableData(page, size, params, sort, controller, filterByDateParams);

    if (response.response && response.response.status !== 200) {
      throw response;
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(true);
  }
});

export const fetchBalanceTable = createAsyncThunk("cabinet/fetchBalanceTable", async ({ page, size, controller}, { rejectWithValue }) => {
  try {
    const response = await ApiService.userBalanceTable(page, size, controller);

    if (response.response && response.response.status !== 200) {
      throw response;
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(true);
  }
});

export const fetchPriceCalculationCabinet = createAsyncThunk("cabinet/fetchPriceCalculationCabinet", async (params, { rejectWithValue }) => {
  try {
    const response = await ApiService.extendProxies(params);

    if (response.response && response.response.status !== 200) {
      throw response;
    }

    return response.data;
  } catch (err) {
    return rejectWithValue(true);
  }
});

export const fetchApiData = createAsyncThunk("cabinet/fetchApiData", async (controller, { rejectWithValue }) => {
  try {
    const response = await ApiService.getApiDataKey(controller);

    if (response.response && response.response.status !== 200) {
      throw response;
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(true);
  }
});

export const fetchNewApiKey = createAsyncThunk("cabinet/fetchNewApiKey", async (_, { rejectWithValue }) => {
  try {
    const response = await ApiService.getNewApiKey();

    if (response.response && response.response.status !== 200) {
      throw response;
    }
    return response.data;
  } catch (err) {
    return rejectWithValue(true);
  }
});
