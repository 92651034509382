import { HOME_URL } from "./homeUrl";

export const LOCALS_LINK = [
  {
    rel: "alternate",
    hrefLang: "x-default",
    href: `${HOME_URL}`
  },
  {
    rel: "alternate",
    hrefLang: "en",
    href: `${HOME_URL}/en/`
  },
  {
    rel: "alternate",
    hrefLang: "ru",
    href: `${HOME_URL}/ru/`
  }
];
