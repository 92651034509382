import { useCallback, useEffect } from "react";
import { classNames } from "../../../../../../../utils/helpers/classNames";

export const MobileDropDown = ({ isOpen, setIsOpen, children }) => {
  const onClose = useCallback(({ target }) => {
    if (target.closest(".solutions")) {
      return;
    }

    setIsOpen(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", onClose);
    } else {
      window.removeEventListener("click", onClose);
    }

    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <div className={classNames(
      "solutions__mobile-dropdown",
      { "solutions__mobile-dropdown--hidden": !isOpen },
      { "solutions__mobile-dropdown--top": isOpen }
    )}>
      {children}
    </div>
  );
};
