import { useEffect, useMemo, useState } from "react";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";
import { AddFolderModal } from "../FolderModals/AddFolderModal/AddFolderModal";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { useDispatchedActions, useTranslation } from "../../../../../hooks";
import { EditFolderModal } from "../FolderModals/EditFolderModal/EditFolderModal";
import { ApiService } from "../../../../../services/ApiService";
import { DeleteFolderModal } from "../FolderModals/DeleteFolderModal/DeleteFolderModal";
import "./FolderSelect.scss";
import { errorToast } from "../../../../../utils/helpers/customToast.helper";
import { getBorderColor } from "../../../../../utils/helpers/getBorderColor";

export const FolderSelect = ({ folderSelectRef, openFolderSelect, closeFolderSelect, controller, folders, setFolders }) => {
  const {
    cabinet: { orders: { folders: { folderSelectLabel, generalFolder, createFolder } } }
  } = useTranslation();

  const { skin } = useSelector((state) => state.header);

  const { setSelectedRows, setSelectedFolder } = useDispatchedActions();
  const { selectedFolder, selectedRows } = useSelector((state) => state.cabinet);
  const { activeData } = useSelector((state) => state.content);

  const [folderForEditing, setFolderForEditing] = useState({});
  const [folderForDeleting, setFolderForDeleting] = useState({});

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { data: loadedFolders, status, code } = await ApiService.getFolders(controller);

        if (code === "ERR_CANCELED") {
          return;
        }

        if (status !== 200) {
          throw Error();
        }

        setFolders(loadedFolders);
      } catch (e) {
        errorToast("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.proxy?.code]);

  const currentFolders = useMemo(() => folders
      .filter((folder) => folder.proxyType === activeData?.proxy?.code)
      .map((folder) => ({ value: folder.id, label: folder.name })
      ), [activeData?.proxy?.code, folders]);

  const openAddModal = () => {
    closeFolderSelect();
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const openEditModal = (folder) => {
    closeFolderSelect();
    setFolderForEditing(folder);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  const openDeleteModal = (folder) => {
    closeFolderSelect();
    setFolderForDeleting(folder);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleSelect = (value) => {
    setSelectedFolder(value);
  };

  const setDefaultFolder = () => {
    closeFolderSelect();
    handleSelect({ value: null, label: null });
  };

  const MenuList = (props) => (
      <components.MenuList {...props}>
        <div
          className={`folder-select__option folder-select__default-option ${ !selectedFolder.value ? "folder-select__default-option--active" : ""}`}
          onClick={setDefaultFolder}
        >
          {generalFolder}
        </div>
        {props.children}
        <button onClick={openAddModal} className="folder-select__add-folder">
          <span className="folder-select__add-folder--plus">+</span>
          <span>{createFolder}</span>
        </button>
      </components.MenuList>
  );

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <IconSvg tag="triangleDown12" />
    </components.DropdownIndicator>
  );

  const Option = (props) => (
    <div className="folder-select__option-container">
      <components.Option {...props}>
        <div className="folder-select__option" data-folder-id={props.data.value}>
          {props.children}
        </div>
      </components.Option>
      <div className="folder-select__option-buttons">
        <button
          className="folder-select__button folder-select__button--edit"
          onClick={() => openEditModal(props.data)}
        >
          <IconSvg tag="edit" />
        </button>
        <button
          className="folder-select__button folder-select__button--trash"
          onClick={() => openDeleteModal(props.data)}
        >
          <IconSvg tag="trash" />
        </button>
      </div>
    </div>
  );

  const dragOverHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    openFolderSelect();
  };

  const redirectToFolder = (folderId) => {
    const currentFolder = currentFolders
      .find((folder) => folder.value === folderId)
      ||
      { value: null, label: null };

    setSelectedFolder(currentFolder);
  };

  const dropHandler = async (e) => {
    if (!e.target.closest(".folder-select__option")) {
      return;
    }

    const ipAddressFolderId = e.target.dataset.folderId || null;

    if (ipAddressFolderId === selectedFolder.value) {
      return;
    }

    try {
      redirectToFolder(ipAddressFolderId);

      const params = {
        proxyType: activeData?.proxy?.code,
        ip: selectedRows,
        ipAddressFolderId
      };

      const { status } = await ApiService.editUserTableDataFolder(params);

      if (status !== 200) {
        throw Error();
      }
    } catch {
      errorToast("Something went wrong");
    } finally {
      closeFolderSelect();
    }
    setSelectedRows([]);
  };

  const getValue = () => selectedFolder.value ? selectedFolder : { value: "all", label: generalFolder };

  return (
    <div className="folder-select">
      <div
        className="folder-select__container"
        onDragOver={dragOverHandler}
        onDrop={dropHandler}
      >
        <div className="folder-select__label">{folderSelectLabel}</div>
        <Select
          ref={folderSelectRef}
          // isLoading={true}
          className="custom__select"
          classNamePrefix="custom__select"
          options={currentFolders}
          value={getValue()}
          components={{ DropdownIndicator, MenuList, Option }}
          isSearchable={false}
          onChange={handleSelect}
          openMenuOnFocus={true}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: getBorderColor(state, skin)
            })
          }}
        />
      </div>
      <AddFolderModal
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        setFolders={setFolders}
      />
      <EditFolderModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        currentFolder={folderForEditing}
        setFolders={setFolders}
      />
      <DeleteFolderModal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        currentFolder={folderForDeleting}
        setFolders={setFolders}
      />
    </div>
  );
};
