import {getCountryFlag} from "../../../../../../../../../utils/helpers/getCountryFlag";
import {getCountryName} from "../../../../../../../../../utils/helpers/getCountryName";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

export const CountryOption = ({ value }) => {
  const { countries } = useSelector((state) => state.content);
  const { lang } = useParams();
  
  return (
    <div className="main-filter__option">
      {getCountryFlag(value)}
      {getCountryName(value, lang, countries?.data)}
    </div>
  );
};
