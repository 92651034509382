import { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../../../../hooks";
import { ModalSelect } from "../../../../../components/ui/ModalSelect/ModalSelect";

export const MobileoperatorParams = (props) => {
  const {
    proxyCode,
    mobileOperator,
    mobileOperators,
    setMobileOperator,
    setModemRotationMin
  } = props;
  const ref = useRef();
  const refOperator = useRef();

  // **Redux State
  const [localOperatorRotations, setLocalOperatorRotations] = useState([]);

  // **Hooks
  const { formPlaceholders } = useTranslation();

  const handlerOperator = (data) => {
    setMobileOperator(data);
  };

  const handlerRorationMin = (data) => {
    setModemRotationMin(data);
  };

  useEffect(() => {
    if (mobileOperator?.operatorTag && mobileOperators) {
      const temp = mobileOperators.find(
        (item) => item.operatorTag === mobileOperator.operatorTag
      );
      setLocalOperatorRotations(temp?.rotationMins || []);
      if (temp?.rotationMins) {
        ref?.current.select.setValue(temp?.rotationMins[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileOperator]);

  useEffect(() => {
    if (mobileOperators && mobileOperators.length > 0 && refOperator?.current) {
      refOperator?.current.select.setValue(mobileOperators[0]);
    }
  }, [mobileOperators]);

  return proxyCode === "MOBILE" ? (
    <>
      <ModalSelect
        ref={refOperator}
        name="mobileOperator"
        rules={{ required: true }}
        options={mobileOperators}
        placeholder={formPlaceholders?.operators}
        onChange={handlerOperator}
      />
      <ModalSelect
        ref={ref}
        name="modemRotationMin"
        rules={{ required: true }}
        options={localOperatorRotations}
        placeholder={formPlaceholders?.rotation}
        onChange={handlerRorationMin}
      />
    </>
  ) : null;
};
