import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "../../../hooks/useTranslation";
import { useDispatchedActions } from "../../../hooks/useDispatchedActions";

import { CustomModal } from "../../../components/ui/CustomModal/CustomModal";
import { Button } from "../../../components/ui/Buttons";
import { Form } from "./components/Form/Form";

export const ModalCustom = () => {
  // **Props
  const { lang } = useParams();
  const navigate = useNavigate();

  // **Redux State
  const {
    isModalOpen,
    isCountryChoosed,
    isQuantityNotCurrent,
    isBalancePaymentDataNotCurrent,
    isBalanceCheck,
    paramsForReload,
    documentation,
    modalNotAuthBalance,
    modalSelectBalance
  } = useSelector((state) => state.modal);
  const {
    proxies: { fiveDollarLogick },
    user
  } = useSelector((state) => state.content);
  const {
    prices: {
      query,
      minQuantityIPv6,
      notCurrentPaymantBalanceData,
      lowBalance,
      lowAmount,
      notAuthBalance,
      lowAmountBtn,
      infoModalSelectBalance
    },
    notifications,
    cabinet: { balance }
  } = useTranslation();

  // Dispatch
  const {
    closeModal,
    setIsBalanceCheck,
    setUseFiveDollarLogick,
    setLoginModal, fetchNewApiKey, setModalSelectBalance
  } = useDispatchedActions();

  const handlerContinue = () => {
    setIsBalanceCheck(false);
    setModalSelectBalance(false);
    if (fiveDollarLogick?.enabled) {
      setUseFiveDollarLogick(false);
    }
  };

  const handlerLogin = () => {
    if (user?.isAuth) {
      navigate(`/${lang}/dashboard/?tab=profile`);
    } else {
      setUseFiveDollarLogick(false);
      setLoginModal(true);
    }
    closeModal();
  };

  const handlerAddBalance = () => {
    if (user?.isAuth) {
      navigate(`/${lang}/dashboard/?tab=balance`);
    } else {
      setUseFiveDollarLogick(false);
      setLoginModal(true);
    }

    closeModal();
  };
  const handleClose = () => {
      if (fiveDollarLogick?.enabled && fiveDollarLogick?.use) {
        setUseFiveDollarLogick(false);
      }
      closeModal();
  };

  const handlerChangeKey = () => {
    fetchNewApiKey();
    closeModal();
  };

  const renderNav = () => {
    if (fiveDollarLogick?.enabled && fiveDollarLogick?.use) {
      if (modalSelectBalance) {
        return (
          <div className="modal__body-balance  modal__body-balance-no-top">
                <p className="modal__text-info">
              {lowAmount}
            </p>
            <p className="modal__text-info modal__text-info-top">
              {infoModalSelectBalance}
            </p>
            <button
                className="prices__goal-btn modal__balance-btns-one"
                onClick={handlerContinue}
              >
                {lowBalance?.btnContinue}
              </button>
          </div>
        );
      } else return (
          <div className="modal__body-balance  modal__body-balance-no-top">
            <p className="modal__text-info modal__text-info-top">
              {modalNotAuthBalance ? notAuthBalance : lowAmount}
            </p>
            {!modalNotAuthBalance ? (
              <div className="box-btn">
                <button
                  className="prices__goal-btn box-btn-left"
                  onClick={handlerLogin}
                >
                  {lowAmountBtn?.cabinet}
                </button>
                <button
                  className="prices__goal-btn"
                  onClick={handlerAddBalance}
                >
                  {lowAmountBtn?.replenish}
                </button>
              </div>
            ) : (
              <button className="prices__goal-btn" onClick={handlerLogin}>
                {lowAmountBtn?.cabinet}
              </button>
            )}
          </div>
        );
    }

    if (isBalanceCheck) {
      return (
        <div>
          <div className="modal__top">{lowBalance?.title}</div>
          <div className="modal__body-balance">
            <div className="modal__balance">{`${lowBalance?.balance}: ${paramsForReload?.balance}$`}</div>
            <p className="modal__text-info modal__text-info-top">
              {lowBalance?.textContinue}
            </p>
            <p className="modal__text-info modal__text-info-bottom">
              {lowBalance?.textReplenish}
            </p>
            <div className="modal__balance-btns">
              <Button
                className="prices__goal-btn modal__balance-btns-one"
                onClick={handlerContinue}
              >
                {lowBalance?.btnContinue}
              </Button>
              <Button className="prices__goal-btn" onClick={handlerAddBalance}>
                {lowBalance?.btnReplenish}
              </Button>
            </div>
          </div>
        </div>
      );
    }
    if (documentation?.isError) {
      if (documentation?.errorType === "changeKey") {
        return (
          <div className="modal__body-balance">
            <p className="modal__query" style={{ fontSize: "16px", fontWeight: 600, marginBottom: "16px" }}>
              {notifications?.documentation[documentation?.errorType]}
            </p>

            <div
              className="modal__balance-btns"
              // style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "12px", marginTop: "16px" }}
            >
              <Button
                style={{ width: "100%", marginBottom: "8px" }}
                className="prices__goal-btn modal__balance-btns-one"
                onClick={handleClose}
              >
                {notifications?.documentation?.cancel}
              </Button>
              <Button
                tab="confirm"
                style={{ width: "100%" }}
                className="prices__goal-btn"
                onClick={handlerChangeKey}
              >
                {notifications?.documentation?.continue}
              </Button>
            </div>
          </div>
        );
      } else {
        return (
          <p className="modal__query">
            {notifications?.documentation[documentation?.errorType]}
          </p>
        );
      }
    }
    if (isBalancePaymentDataNotCurrent) {
      return (
        <p className="modal__query">
          {fiveDollarLogick?.enabled
            ? balance?.description
            : notCurrentPaymantBalanceData}
        </p>
      );
    }
    if (!isCountryChoosed) {
      return <p className="modal-text">{query}</p>;
    }
    if (isQuantityNotCurrent) {
      return <p className="modal-text">{minQuantityIPv6}</p>;
    }
    return <Form />;
  };

  return (
    <CustomModal
    open={isModalOpen}
    onClose={handleClose}
    // className="export"
  >
  {renderNav()}
  </CustomModal>
  );
};
