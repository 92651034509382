import { CustomModal } from "../../../../../components/ui/CustomModal/CustomModal";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { Button } from "../../../../../components/ui/Buttons/Button/Button";
import { useTranslation } from "../../../../../hooks";
import "./LogoutModal.scss";
import { ButtonLoader } from "../../../../../components/ui/ButtonLoader/ButtonLoader";

export const LogoutModal = ({ isOpen, setIsOpen, exitAction, isLoading }) => {
  const { notifications: { logout } } = useTranslation();

  const {
    cabinet: { orders: { autoExtending: { modals: { cancel: {
      cancelButton,
      confirmButton
    } } } } }
  } = useTranslation();

  return (
    <CustomModal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="logout-modal">
        <div className="logout-modal__container">
          <IconSvg tag="exitLarge" className="logout-modal__icon" />
          <div className="logout-modal__hint">
            {logout}
          </div>
        </div>
        <div className="logout-modal__actions">
          <Button
            onClick={() => setIsOpen(false)}
          >
            {cancelButton}
          </Button>
          <Button
            onClick={exitAction}
            tab="confirm"
          >
            {isLoading ? <ButtonLoader /> : confirmButton }
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};
