import { useNavigate } from "react-router-dom";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import { Button } from "../../../../../../../../components/ui/Buttons/Button/Button";
import { useDispatchedActions, useTranslation } from "../../../../../../../../hooks";
import "./NoCashModal.scss";

export const NoCashModal = () => {
  const {
    cabinet: { orders: { autoExtending: { modals: { noCash: {
      description,
      hint,
      cancel,
      topUp
    } } } } }
  } = useTranslation();

  const { setIsNoCashModalOpen } = useDispatchedActions();
  const navigate = useNavigate();

  const navigateToBalance = () => {
    setIsNoCashModalOpen(false);
    navigate({
      search: "?tab=balance"
    });
  };

  return (
    <div className="no-cash">
      <div className="no-cash__container">
        <IconSvg tag="bagOfMoney" />
        <div className="no-cash__description">
          {description}
        </div>
        <div className="no-cash__hint">
          {hint}
        </div>
      </div>
      <div className="no-cash__actions">
        <Button
          onClick={() => setIsNoCashModalOpen(false)}
        >
          {cancel}
        </Button>
        <Button
          tab="confirm"
          onClick={navigateToBalance}
        >
          {topUp}
        </Button>
      </div>
    </div>
  );
};
