import {useSearchParams} from "react-router-dom";
import {IconSvg} from "../../../../../../../utils/constants/icons/iconSvg.constant";

export const FormatCellAutoRenewalHead = ({ title }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort");
  const order = searchParams.get("order");
  
  const handleSort = () => {
    if (!sort) {
      searchParams.set("sort", "autoExtending");
      searchParams.set("order", "ASC");
      setSearchParams(searchParams);
      
      return;
    }
    
    if (order === "ASC") {
      searchParams.set("order", "DESC");
      setSearchParams(searchParams);
      
      return;
    }
  
    searchParams.delete("sort");
    searchParams.delete("order");
    setSearchParams(searchParams);
  }
  
  const sortIcon = () => {
    switch (order) {
      case "ASC":
        return <IconSvg tag="sortAsc" className="table__auto-renewal-head-icon"/>;
        
      case "DESC":
        return <IconSvg tag="sortDesc" className="table__auto-renewal-head-icon" />;
      
      default:
        return <IconSvg tag="sortDefault" className="table__auto-renewal-head-icon" />;
    }
  };
  
  return (
    <div className="table__auto-renewal-head" onClick={handleSort}>
      {title}
      {sortIcon()}
    </div>
  );
};
