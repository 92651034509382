import {useSelector} from "react-redux";
import {useTranslation} from "../../../../hooks";
import {useParams} from "react-router-dom";
import './FeedbackTitle.scss';

export const FeedbackTitle = () => {
  const { country } = useParams();
  const { activeData } = useSelector((state) => state.content);
  const { title, titleMain } = useTranslation().reviews;

  return (
    <div className="feedbacks-title">
      {activeData?.proxy?.code === "IPv4" && !country
        ? titleMain
        : title}
    </div>
  );
};
