
import { ListItemWrapperV1 } from "./ListItemWrapperV1";

import "./ListWrapperV1.scss";

export const ListWrapperV1 = ({ data, listClass, listItemClass, bodyItem }) => data && data.length > 0 ? (
    <ul className={`listV1 ${listClass ? listClass : ""}`}>
      {data.map((item, index) => (
        <ListItemWrapperV1 key={item?.id || index} data={item} bodyItem={bodyItem} listItemClass={listItemClass}/>
      ))}
    </ul>
  ) : null;
