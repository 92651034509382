import { successToast } from "./customToast.helper";

const copyForOldBrowsers = (textToCopy, notifications, lang) => {
  const textarea = document.createElement("textarea");
  textarea.value = textToCopy;
  
  textarea.style.position = "absolute";
  textarea.style.left = "-99999999px";
  
  document.body.append(textarea);
  
  textarea.select();
  
  document.execCommand("copy");
  textarea.remove();
  successToast(notifications?.copy, lang)
};

const copyForModernBrowsers = (text, notifications, lang) => {
  if (typeof ClipboardItem && navigator.clipboard.write) {
    // eslint-disable-next-line no-undef
    const copyText = new ClipboardItem({
      "text/plain": new Blob([text], { type: "text/plain" })
    });
    
    navigator.clipboard.write([copyText])
      .then(() => successToast(notifications?.copy, lang));
  } else {
    navigator.clipboard.writeText(text)
      .then(() => successToast(notifications?.copy, lang));
  }
};

export const copyText = async (text, notifications, lang) => {
  if (navigator.clipboard && window.isSecureContext) {
    copyForModernBrowsers(text, notifications, lang);

    return;
  }
  
  copyForOldBrowsers(text, notifications, lang);
};
