import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatchedActions, useTranslation } from "../../../../../../../../hooks";
import { PROXY_TYPES } from "../../../../../../../../utils/constants";
import { ApiService } from "../../../../../../../../services/ApiService";
import { Loading } from "../../../../../../../../components/ui/Loading/Loading";
import { errorToast } from "../../../../../../../../utils/helpers/customToast.helper";
import { classNames } from "../../../../../../../../utils/helpers/classNames";
import { getDateFormat } from "../../../../../../../../utils/helpers/getDateFormat";
import "./ToggleSwitch.scss";

export const ToggleSwitch = ({ order }) => {
  const { lang } = useParams();

  const {
    setOrderData,
    setOrdersForUpdate,
    setIsExtendingModalOpen,
    setIsCancelExtendingModalOpen
  } = useDispatchedActions();

  const {
    cabinet: { orders: { column: { autoRenewal: {
      disabled
    } } } }
  } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const handleSwitch = async () => {
    if (order.proxyType === PROXY_TYPES.ipv6.code) {
      setIsLoading(true);

      try {
        const { data } = await ApiService.userOrderItemsIPv6(order.id);
        const tableDataIds = data.map((item) => item.id);
        setOrdersForUpdate(tableDataIds);
      } catch {
        errorToast("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    } else {
      setOrdersForUpdate([order.id]);
    }

    setOrderData({
      id: order.id,
      proxyType: order.proxyType,
      autoExtending: !order.autoExtending,
      ipAddressFolderId: order.ipAddressFolderId,
      description: order.description
    });

    // eslint-disable-next-line no-unused-expressions
    order.autoExtending
      ? setIsCancelExtendingModalOpen(true)
      : setIsExtendingModalOpen(true);
  };

  return (
    <div className="table__auto-renewal-container">
      <label className="toggle-switch">
        <input
          type="checkbox"
          className="toggle-switch__input"
          checked={order.autoExtending}
          onClick={handleSwitch}
        />
        <span className="toggle-switch__slider" />
      </label>
      <div className={classNames(
        "toggle-switch__label",
        { "toggle-switch__label--enable": order.autoExtending }
      )}>
        {order.autoExtendingDays ? getDateFormat(+order.autoExtendingDays, lang) : disabled}
      </div>
      {isLoading && (
        <Loading tableRow absolute roller={{ transform: "scale(0.5)" }}/>
      )}
    </div>
  );
};
