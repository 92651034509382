import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ModalSelect } from "../../../../../components/ui/ModalSelect/ModalSelect";
import { useTranslation } from "../../../../../hooks";
// import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { getCountries } from "../../../../../utils/helpers";
//---------------------
// styled components
// import "./Countries.scss";

// const Dropdown = ({ children, isOpen, target, onClose }) => (
//   <div className="index__countries">
//     {target}
//     {isOpen ? <div className="index__countries__menu">{children}</div> : null}
//     {isOpen ? (
//       <div className="index__countries__blanket" onClick={onClose} />
//     ) : null}
//   </div>
// );

// const DropdownIndicator = () => (
//   <IconSvg tag={"search"} className="search__icon" />
// );

export const Countries = (props) => {
  // **Props
  const { proxyCode, setCountryParams, setMobileOperators } = props;
  const { lang } = useParams();

  // **Redux State
  const { mobileTariffs, isMobileTariffsLoaded } = useSelector(
    (state) => state.mobileProxy
  );
  const { countries, activeData } = useSelector((state) => state.content);
  const { formPlaceholders, mobileProxy } = useTranslation();

  // **Local State
  const [localCountries, setLocalCountries] = useState([]);
  /* eslint-disable */
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState();
  const handlerInput = (data) => setInputValue(data);
  /* eslint-enable */

  // Ref
  const countryRef = useRef(null);
  const selectCountry = useRef(null);

  useEffect(() => {
    setCountryParams({
      id: "",
      code: ""
    });

    if (selectCountry.current) {
      selectCountry?.current.select.setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyCode]);

  // select country
  const selectCountryHandler = (e) => {
    setCountryParams({
      id: e?.id,
      code: e?.code
    });
    setValue(e);
    setIsOpen(false);
    if (proxyCode === "MOBILE") {
      setMobileOperators(
        e?.operators
          ? e?.operators.map((item) => ({
              ...item,
              rotationMins: item?.rotationMins
                ? item?.rotationMins.map((item) => ({
                    ...item,
                    value: item?.min,
                    label:
                      item?.min === 0
                        ? `${mobileProxy?.changeIPauto}`
                        : `${item?.min} ${mobileProxy?.changeIPTime}`
                  }))
                : [],
              value: item?.operatorTag,
              label: item?.operatorName
            }))
          : []
      );
    } else {
      setMobileOperators([]);
    }
  };

  // check url country
  useEffect(() => {
    if (proxyCode !== "MOBILE") {
      if (countries?.isLoaded) {
        const currentCountries = getCountries(countries?.data, lang, {
          showOnFrontType: proxyCode
        });
        if (currentCountries && currentCountries.length > 0) {
          const country = currentCountries.find(
            ({ id }) => id === activeData?.country?.id
          );
          if (country?.code) {
            setCountryParams({
              id: country?.id,
              code: country?.code
            });
            countryRef?.current?.select.setValue(country);
            setLocalCountries(
              currentCountries.filter((item) => item.code !== "EUROPE")
            );
            // if (country.code === "EUROPE") {
            //   setLocalCountries(
            //     currentCountries.filter(
            //       (item) => item.isEurope && item.code !== "EUROPE"
            //     )
            //   );
            // } else {
            //   setLocalCountries(
            //     currentCountries.filter((item) => item.code !== "EUROPE")
            //   );
            // }
          } else {
            setLocalCountries(
              currentCountries.filter((item) => item.code !== "EUROPE")
            );
            countryRef?.current?.select.setValue(formPlaceholders?.country);
          }
        } else {
          setLocalCountries([]);
          countryRef?.current?.select.setValue(formPlaceholders?.country);
        }
      }
    } else if (isMobileTariffsLoaded && mobileTariffs[activeData?.currency?.id] && mobileTariffs[activeData?.currency?.id].length > 0) {
      setLocalCountries(
        getCountries(mobileTariffs[activeData?.currency?.id], lang, {
          countryCode: "EUROPE"
        })
      );
      countryRef?.current?.select.setValue(formPlaceholders?.country);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    proxyCode,
    activeData?.country?.id,
    activeData?.currency?.id,
    lang,
    isMobileTariffsLoaded
  ]);
  return (
    <ModalSelect
      ref={selectCountry}
      name="country"
      rules={{ required: true }}
      options={localCountries}
      placeholder={formPlaceholders?.country}
      onChange={selectCountryHandler}
    />
    //      <Select
    //     ref={countryRef}
    //     className="index__select"
    //     classNamePrefix="index__select"
    //     isSearchable={false}
    //     options={localCountries}
    //     placeholder={formPlaceholders?.country}
    //       onChange={selectCountryHandler}
    //     components={{ DropdownIndicator }}
    //   />
  );
};
