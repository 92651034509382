import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { Container } from "../../../../components/ui/Container/Container";
import { ApiService } from "../../../../services/ApiService";
import { errorToast } from "../../../../utils/helpers/customToast.helper";
import { useDispatchedActions } from "../../../../hooks";
import { resetFilterSearchParams } from "../../../../utils/helpers/resetFilterSearchParams";
import { RenewalForm } from "./RenewalForm/RenewalForm";
import { Table } from "./Table/Table";
import { Top } from "./Top/Top";
import "./Orders.scss";

export const Orders = () => {
  // **Redux State
  const {
    setFilterCountriesOptions,
    setFilterStatusOptions
  } = useDispatchedActions();

  const [searchParams, setSearchParams] = useSearchParams();

  const { lang } = useParams();

  const { selectedFolder } = useSelector((state) => state.cabinet);
  const { activeData } = useSelector((state) => state.content);

  const [folders, setFolders] = useState([]);
  const [allIpsByProxyType, setAllIpsByProxyType] = useState([]);

  const isMounted = useRef(false);

  const folderSelectRef = useRef();

  const controller = useMemo(() => new AbortController(), []);

  const openFolderSelect = () => {
    folderSelectRef.current.focus();
  };

  const closeFolderSelect = () => {
    folderSelectRef.current.blur();
  };

  useEffect(() => {
    if (activeData?.proxy.code) {
      (async function() {
        try {
          const { data, status, code } = await ApiService.getFilterParams(activeData?.proxy.code, selectedFolder.value, controller);

          if (code === "ERR_CANCELED") {
            return;
          }

          if (status !== 200) {
            throw Error();
          }

          setFilterCountriesOptions(data.countries);
          setFilterStatusOptions(data.runningOuts);

        } catch (e) {
          errorToast("Something went wrong");
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.proxy.code, selectedFolder]);

  useEffect(() => {
    if (!activeData?.proxy?.code) return;

    (async () => {
      try {
        const { data, status } = await ApiService.getOrderByProxyCode(activeData?.proxy?.code);

        if (status !== 200) {
          throw new Error();
        }

        setAllIpsByProxyType(data);
      } catch (e) {
        errorToast("Something went wrong", lang);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.proxy?.code]);

  useEffect(() => {
    if (!activeData.proxy.code) {
      return;
    }

    if (!isMounted.current) {
      isMounted.current = true;

      return;
    }

    resetFilterSearchParams(searchParams, setSearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.proxy.code, selectedFolder]);

  useEffect(() => () => {
      controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <>
      <Container>
        <Top
          folders={folders}
          setFolders={setFolders}
          controller={controller}
          folderSelectRef={folderSelectRef}
          openFolderSelect={openFolderSelect}
          closeFolderSelect={closeFolderSelect}
        />
      </Container>
      <Table
        folders={folders}
        setFolders={setFolders}
        controller={controller}
        closeFolderSelect={closeFolderSelect}
        folderSelectRef={folderSelectRef}
      />
      <Container>
        <RenewalForm allIpsByProxyType={allIpsByProxyType} />
      </Container>
    </>
  );
};
