import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import { useTranslation } from "../../../../../../../../hooks";

export const DesktopNoData = () => {
  const { noDataComponent } = useTranslation()?.cabinet?.orders;

  return (
    <div className="table__nodata-desktop">
      <IconSvg tag="cart" className="table-body__nodata-icon" />
      <div>{noDataComponent}</div>
    </div>
  );
};
