import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { useState } from "react";
import { ModalInput } from "../../../../../../../../ui/ModalInput/ModalInput";

import { ApiService } from "../../../../../../../../../services/ApiService";

import { useTranslation } from "../../../../../../../../../hooks/useTranslation";
import { useDispatchedActions } from "../../../../../../../../../hooks/useDispatchedActions";

import { Button } from "../../../../../../../../ui/Buttons/Button/Button";
import { errorToast, successToast } from "../../../../../../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../../../../../ui/ButtonLoader/ButtonLoader";
import { passwordPattern } from "../../../../../../../../../utils/constants/passwordPattern";

export const SecondaryForm = () => {
  const { hash, lang } = useParams();
  const navigate = useNavigate();

  // Dispatch
  const { setLoginModal } = useDispatchedActions();

  // **State
  const {
    cabinet: { profile },
    header: { modalRecovery, formElem },
    notifications
  } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    mode: "onChange"
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await ApiService.getRecoveryPassword(
        hash,
        data.password
      );

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      if (response?.data) {
        successToast(notifications.passwordChange, lang);

        setTimeout(() => {
          navigate("/");
          setLoginModal(true);
        }, 1500);
      } else {
        errorToast(notifications.error, lang);
      }
    } catch (err) {
      errorToast(notifications.error, lang);
    } finally {
      setIsLoading(false);
    }
  };

  const isContainSpaces = methods.formState.errors.password?.type === "pattern";
  const isLessThanSixChars = methods.formState.errors.password?.type === "validate";

  return (
    <FormProvider {...methods}>
      <form
        className="modal__login-form-rectore header__recovery-secondary"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate={true}
      >
        <ModalInput
          label={formElem.newPassword.label}
          // placeholder={formElem.newPassword.placeholder}
          type="password"
          name="password"
          watch={methods.watch}
          rules={{
            validate: (value) => value?.length >= 6,
            pattern: passwordPattern
          }}
        />
        {isContainSpaces && (
          <p className="modal__form-wrong modal__login-recovery-hint">
            {profile?.wrongPasswordChars}
          </p>
        )}
        {isLessThanSixChars && (
          <p className="modal__form-wrong modal__login-recovery-hint">
            {profile?.wrongPasswordLength}
          </p>
        )}
        <Button type="submit" disabled={isLoading}>
          {isLoading ? <ButtonLoader /> : modalRecovery.recoveryBtn }
        </Button>
      </form>
    </FormProvider>
  );
};
