import { DescriptionsAPI } from "../../components/common/DescriptionsAPI/DescriptionsAPI";
import { Container } from "../../components/ui/Container/Container";

import "./DocumentationPage.scss";

const DocumentationPage = () => (
  <section className="documentation">
    <Container>
      <div className="documentation_content">
        <DescriptionsAPI />
      </div>
    </Container>
  </section>
);

export default DocumentationPage;
