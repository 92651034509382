import { useEffect } from "react";
import { classNames } from "../../../../../../../../../utils/helpers/classNames";
import { isSSR } from "../../../../../../../../../utils/helpers";
import "../MobilePricesSelect.scss";

export const DropDown = ({ options, handleOptionSelect, isOpen, setIsOpen, dropdownRef, selectedOption }) => {

  const handleDocumentClick = (event) => {
    if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const showDropDown = () => {
    const dropDown = document.querySelector(".mobile-prices__dropdown-global");
    const { bottom } = dropDown.getBoundingClientRect();

    const notInViewport = bottom - window.innerHeight;

    const bodyRect = document.body.getBoundingClientRect();
    const offset = bottom - bodyRect.bottom;

    if (notInViewport > 0) {
      window.scrollTo(0, offset + 100);
    }
  };

  useEffect(() => {
    if (isSSR()) {
      return;
    }

    showDropDown();

    document.addEventListener("click", handleDocumentClick);
    document.addEventListener("touchmove", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
      document.removeEventListener("touchmove", handleDocumentClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStyles = () => {
    const styles = {};
    const gap = 30;
    const marginRight = 224;

    if (dropdownRef.current) {
      const { right, top } = dropdownRef.current.getBoundingClientRect();
      styles.left = right - marginRight;
      styles.top = top + window.scrollY + gap;
    }

    return styles;
  };

  return (
    <div className="mobile-prices__dropdown-global" style={getStyles()}>
      <div className="mobile-prices__dropdown">
        <div className="mobile-prices__dropdown-container">
          <ul className="mobile-prices__options-list">
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => handleOptionSelect(option)}
                className={classNames(
                  "mobile-prices__option",
                  { "mobile-prices__option--active": selectedOption?.value === option?.value }
                )}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
