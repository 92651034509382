import { IconSvg } from "../../../../../../../utils/constants/icons/iconSvg.constant";
import "./NotificationIcon.scss";

export const NotificationIcon = ({ handler, quantity }) => {
  const getClasses = () => {
    const baseClass = "user-notifications-icon";

    return quantity ? `${baseClass} user-notifications-icon--quantity` : baseClass;
  };

  return (
    <div
      onClick={handler}
      className={getClasses()}
      data-notifications-quantity={quantity}
    >
      <IconSvg tag="notification" className="user-notifications-icon__svg" />
    </div>
  );
};
