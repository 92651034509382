import { useRef, useState } from "react";
import { useTranslation } from "../../../../../../../hooks";
import { isSSR } from "../../../../../../../utils/helpers";
import { useIsomorphicLayoutEffect } from "../../../../../../../hooks/useIsomorphicLayoutEffect";

export const Quantity = (props) => {
  // ** Props
  const { quantity, inputHandler } = props;
  const { formPlaceholders } = useTranslation();
  const [space, setSpace] = useState(0);

  const input = useRef();
  const inputValue = useRef();

  if (!isSSR()) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useIsomorphicLayoutEffect(() => {
          if (inputValue.current || input.current) {
              const gap = 4;
              const padding = parseInt(window.getComputedStyle(input.current).paddingLeft);
              const { width } = inputValue.current.getBoundingClientRect();
              setSpace(width + padding + gap);
          }
      }, [quantity]);
  }

  return (
    <div className="index__input">
      <input
        ref={input}
        type="text"
        placeholder={formPlaceholders?.quantity1}
        onInput={inputHandler}
        value={quantity}
      />
      {quantity && (
        <span
          style={{
            left: `${space}px`
          }}
          className="index__input-pieces"
        >
          {formPlaceholders?.quantity2}
        </span>
      )}
      <span
        className="index__input-fake"
        ref={inputValue}
      >
        {quantity}
      </span>
    </div>
  );
};
