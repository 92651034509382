import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "../../../../../../hooks";
import { Container } from "../../../../../../components/ui/Container/Container";
import { useCurrentSeo } from "../../../../../../hooks/useCurrentSeo";
import { PROXY_TYPES } from "../../../../../../utils/constants";
import { Proxy } from "../../../../../../components/ui/Proxy/Proxy";
import { SortPrices } from "./components/SortPrices/SortPrices";
import "./Top.scss";

export const Top = (props) => {
  const { main: { sortOptions: { price, popular } } } = useTranslation();
  const { lang } = useParams();
  const { pathname } = useLocation();
  // ** Props
  const { localProxyType, setLocalProxyType, setSortParams, setCountryParams } = props;

  const { content: { activeData } } = useSelector((state) => state);

  const sortOptions = useMemo(() => (
    [
      { value: "POPULAR", label: popular },
      { value: "PRICE", label: price }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [lang]);

  // **Hooks
  const currentSeo = useCurrentSeo();

  const h2PathNames = [
    "/en/mobile/",
    "/ru/mobile/",
    "/en/ipv6/",
    "/ru/ipv6/",
    "/en/isp/",
    "/ru/isp/",
    "/en/",
    "/ru/"
  ];

  const showProxyButtons = activeData?.proxy?.code === PROXY_TYPES.ipv4.code
    || activeData?.proxy?.code === PROXY_TYPES.isp.code;

  const renderActions = () =>
    showProxyButtons ? (
      <div className="prices__top__container">
        <Proxy
          localProxyType={localProxyType}
          setLocalProxyType={setLocalProxyType}
          setCountryParams={setCountryParams}
        />
      </div>
    ) : (
      <SortPrices
        localProxyType={localProxyType}
        sortOptions={sortOptions}
        initialValue={localProxyType === PROXY_TYPES.mobile.code ? sortOptions[1] : sortOptions[0] }
        setSortParams={setSortParams}
      />
    );

  return (
    <>
      <Container>
        <div className="prices__top">
          {h2PathNames.includes(pathname) ? (
            <h2>{currentSeo?.priceText}</h2>
          ) : (
            <div className="prices__top__title">{currentSeo?.priceText}</div>
          )}
          <div className="prices__top__actions">
            <div className="media__min-width-993">{renderActions()}</div>
          </div>
        </div>
      </Container>
      <div className="media__max-width-992">{renderActions()}</div>
    </>
  );
};
