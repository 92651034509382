import { createSlice } from "@reduxjs/toolkit";

import {
  fetchBalanceTable,
  fetchPriceCalculationCabinet,
  fetchTableData,
  fetchApiData,
  fetchNewApiKey
} from "./Cabinet.thunks";

const initialPrices = {
  amount: "-",
  success: false,
  currencyCode: "",
  minPrice: null
}

const CabinetReducer = createSlice({
  name: "cabinet",
  initialState: {
    selectedFolder: {
      value: null,
      label: null
    },
    tableData: {
      content: [],
      totalElements: "",
      totalPages: ""
    },
    accessParams: {
      ipForDeleting: null,
    },
    ordersForUpdate: [],
    orderData: {},
    filterOptions: {
      countries: null,
      statuses: null,
    },
    isTableDataCount: null,
    isTableDataLoaded: false,
    isTableRowLoading: false,
    selectedRows: [],
    fetchTableDataError: null,
    balanceTable: {
      data: {
        content: [],
        totalElements: "",
        totalPages: ""
      },
      isLoaded: false,
      fetchError: null
    },
    isPasswordTheSame: true,
    prices: initialPrices,
    isPricesLoaded: false,
    fetchPriceCalculationCabinetError: null,
    exportProxy: {
      dividers: [":", ":", ":"],
      selectors: ["innerIp", "proxyPort", "authLogin", "authPassword"]
    },
    apiData: { data: {}, isLoaded: false, fetchError: null }
  },
  reducers: {
    setSelectedFolder(state, action) {
      state.selectedFolder = action.payload;
    },
    
    addActiveItem(state, action) {
      state.selectedRows.push(action.payload);
      state.isPricesLoaded = false
    },
  
    removeActiveItem(state, action) {
      state.selectedRows = state.selectedRows.filter(id => id !== action.payload);
      state.isPricesLoaded = false
    },
    
    resetPrices(state) {
      state.prices = initialPrices
      state.isPricesLoaded = false
    },
    
    setSelectedRows(state, action) {
      state.selectedRows = action.payload;
      state.isPricesLoaded = false
    },
    setTableDataContent(state, action) {
      state.tableData.content = action.payload;
    },
    setOrderData(state, action) {
      state.orderData = action.payload;
    },
    setPasswordDiff(state, action) {
      state.isPasswordTheSame = action.payload;
    },
    setExportDividers(state, action) {
      state.exportProxy.dividers = action.payload;
    },
    setExportSelectors(state, action) {
      state.exportProxy.selectors = action.payload;
    },
    updateAutoExtending(state, action) {
      state.tableData.content = state.tableData.content.map(row => {
        if (action.payload.ordersForUpdate.includes(row.id)) {
          return {
            ...row,
            autoExtending: !row.autoExtending,
            autoExtendingDays: action.payload.days
          }
        }
        
        return row;
      });
    },
    updateDescription(state, action) {
      const tableRow = state.tableData.content.find(data => data.id === action.payload.id);
      tableRow.description = action.payload.description;
    },
    updateTableAccessData(state, action) {
      state.tableData.content = state.tableData.content.map(row => {
        return { ...row, ...action.payload};
      });
    },
    setOrdersForUpdate(state, action) {
      state.ordersForUpdate = action.payload;
    },
    setTableRowLoading(state, action) {
      state.isTableRowLoading = action.payload;
    },
    setFilterCountriesOptions(state, action) {
      state.filterOptions.countries = action.payload;
    },
    setFilterStatusOptions(state, action) {
      state.filterOptions.statuses = action.payload;
    },
    setAccessParams(state, action) {
      state.accessParams = {...state.accessParams, ...action.payload}
    },
    setIpForDeleting(state, action) {
      state.accessParams.ipForDeleting = action.payload;
    }
  },
  extraReducers: {
    [fetchTableData.pending]: (state) => {
      state.fetchTableDataError = null;
      state.isTableDataLoaded = false;
    },
    [fetchTableData.fulfilled]: (state, action) => {
      state.tableData = action.payload;
      state.isTableDataLoaded = true;
      state.isTableDataFirtsLoadedSucses = true;
      state.isTableDataCount = action.payload?.totalElements;
    },
    [fetchTableData.rejected]: (state, action) => {
      state.fetchTableDataError = action.payload;
      state.isTableDataLoaded = true;
    },

    [fetchBalanceTable.pending]: (state) => {
      state.balanceTable.isLoaded = false;
      state.balanceTable.fetchError = null;
    },
    [fetchBalanceTable.fulfilled]: (state, action) => {
      state.balanceTable.data = action.payload;
      state.balanceTable.isLoaded = true;
    },
    [fetchBalanceTable.rejected]: (state, action) => {
      state.balanceTable.fetchError = action.payload;
      state.balanceTable.isLoaded = true;
    },

    [fetchPriceCalculationCabinet.pending]: (state) => {
      state.fetchPriceCalculationCabinetError = null;
      state.isPricesLoaded = false;
    },
    [fetchPriceCalculationCabinet.fulfilled]: (state, action) => {
      state.prices = action.payload;
      state.isPricesLoaded = true;
    },
    [fetchPriceCalculationCabinet.rejected]: (state, action) => {
      state.fetchPriceCalculationCabinetError = action.payload;
      state.isPricesLoaded = true;
    },

    [fetchApiData.pending]: (state) => {
      state.apiData.fetchError = null;
      state.apiData.isLoaded = false;
    },
    [fetchApiData.fulfilled]: (state, action) => {
      state.apiData.data = action.payload;
      state.apiData.isLoaded = true;
    },
    [fetchApiData.rejected]: (state, action) => {
      state.apiData.fetchError = action.payload;
      state.isLoaded = true;
    },

    [fetchNewApiKey.pending]: (state) => {
      state.apiData.fetchError = null;
      state.apiData.isLoaded = false;
    },
    [fetchNewApiKey.fulfilled]: (state, action) => {
      state.apiData.data = action.payload;
      state.apiData.isLoaded = true;
    },
    [fetchNewApiKey.rejected]: (state, action) => {
      state.apiData.fetchError = action.payload;
      state.isLoaded = true;
    }
  }
});

export const CabinetReducerActions = {
  ...CabinetReducer.actions,
  fetchTableData,
  fetchPriceCalculationCabinet,
  fetchBalanceTable,
  fetchApiData,
  fetchNewApiKey
};
export default CabinetReducer.reducer;
