export const getTimeAgo = (lang, words) => {

  const currentDate = new Date();
  const data = localStorage.getItem("client_profile");

  if (data) {
    const date = new Date(JSON.parse(data)?.lastChangePassword);
    const time = Math.abs(currentDate.getTime() - date.getTime());

    const years = Math.trunc(time / (1000 * 3600 * 24 * 365));

    if (years > 0) {
      if (years === 1) return `${years} ${words?.year || " "}`;
      if (years >= 2 && years < 5) {
        return `${years} ${words?.years || " "}`;
      }
 
      return `${years} ${words?.yearsTwo || " "}`;
    }
  
    const month = Math.trunc(time / (1000 * 3600 * 24 * (365 / 12)));
    if (month > 0) {
      if (month === 1) return `${month} ${words?.month || " "}`;
      if (month >= 2 && month < 5) {
        return `${month} ${words?.monthsTwo || " "}`;
      }
 
      return `${month} ${words?.monthsTwo || " "}`;
    }
    const days = Math.trunc(time / (1000 * 3600 * 24));
    if (days > 0) {
      if (lang === "ru") {
        if (days > 10 && days < 20) return `${days} ${words?.daysTwo || " "}`;
        if (days % 10 === 1) return `${days} ${words?.day || " "}`;
        if (days % 10 >= 2 && days % 10 < 5) {
          return `${days} ${words?.days || " "}`;
        }
        return `${days} ${words?.daysTwo || " "}`;
      } else {
        if (days === 1) return `${days} ${words?.day || " "}`;
        return `${days} ${words?.days || " "}`;
      }
    }
    return words?.today || " ";
  }
};
