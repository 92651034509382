import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useIsThemeDark } from "../../../../hooks/useIsThemeDark";
import { getCountryFlag } from "../../../../utils/helpers/getCountryFlag";
import { Container } from "../../../../components/ui/Container/Container";
import { useCurrentSeo } from "../../../../hooks/useCurrentSeo";
import { TrustBox } from "../../../../components/common/TrustBox/TrustBox";
import { PROXY_TYPES } from "../../../../utils/constants";
import { classNames } from "../../../../utils/helpers/classNames";
import { useIsomorphicLayoutEffect } from "../../../../hooks/useIsomorphicLayoutEffect";
import { Form } from "./components/Form/Form";
import "./Index.scss";

export const Index = () => {
  // **Props
  // **Hooks
  const currentSeo = useCurrentSeo();
  const isThemeDark = useIsThemeDark();
  const { lang } = useParams();
  // **Redux State
  const { activeData, countries } = useSelector((state) => state.content);

  const [isFlagVisible, setIsFlagVisible] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const countryCode = activeData?.country?.code;
  const animation = useRef();

  const paintedTitle = () => {
    if (currentSeo?.mainPageTitle) {
      if (currentSeo?.paintedTitle) {
        const title = currentSeo?.mainPageTitle.split(" ");

        return title.map((item, index) => {
          if (currentSeo?.paintedTitle.includes(item)) {
            return (
              <i
                // className={paintedWords.includes(item) ? "painted" : ""}
                key={index}
              >
                {item}{" "}
              </i>
            );
          }

          return `${item} `;
        });
      } else {
        return currentSeo?.mainPageTitle;
      }
    } else return null;
  };

  const getClass = () => {
    const className = "index";
    switch (activeData?.proxy?.code) {
      case PROXY_TYPES.ipv4.code:
        return `${className}__ipv4`;

      case PROXY_TYPES.ipv6.code:
        return `${className}__ipv6`;

      case PROXY_TYPES.isp.code:
        return `${className}__isp`;
      case PROXY_TYPES.mobile.code:
        return `${className}__mobile`;

      default:
        return `${className}__ipv4`;
    }
  };

  const getThemePrefix = () => isThemeDark ? "dark" : "light";

  const isIpv4Country = countryCode && activeData?.proxy?.code === PROXY_TYPES.ipv4.code;

  const getVideoUrl = () => {
    if (isIpv4Country) {
      return `/img/animation/3.Country_${getThemePrefix()}.mp4`;
    }

    switch (activeData.proxy.code) {
      case PROXY_TYPES.ipv4.code:
        return `/img/animation/1.IPv4_${getThemePrefix()}.mp4`;

      case PROXY_TYPES.ipv6.code:
        return `/img/animation/2.IPv6_${getThemePrefix()}.mp4`;

      case PROXY_TYPES.isp.code:
        return `/img/animation/5.Residental_${getThemePrefix()}.mp4`;

      case PROXY_TYPES.mobile.code:
        return `/img/animation/4.Mobile_${getThemePrefix()}.mp4`;

      default:
        return `/img/animation/1.IPv4_${getThemePrefix()}.mp4`;
    }
  };

  const handleVideoLoad = () => {
    if (isIpv4Country) {
      setIsFlagVisible(true);
    }
  };

  useEffect(() => {
    if (!isIpv4Country) {
      setIsFlagVisible(false);
    }
  }, [isIpv4Country]);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useIsomorphicLayoutEffect(() => {
    const MIN_SCREEN_WIDTH_FOR_VIDEO = 1200;

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= MIN_SCREEN_WIDTH_FOR_VIDEO);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className={classNames("index", { "index--dark": isThemeDark })}>
      <Container>
        <div
          // className={`index-container ${
          //   activeData?.proxy?.code === "IPv4" && !country
          //     ? "index-container-ipv4"
          //     : ""
          // }`}
          className={`index-container index-container-ipv4 ${getClass()}`}
        >
          <div className="index__video-content">
            <div className="index__video-container">
              {(isClient && isDesktop) && (
                <>
                  <video
                    ref={animation}
                    src={getVideoUrl()}
                    className="index__bg-video"
                    autoPlay
                    loop
                    muted
                    onLoadedData={handleVideoLoad}
                  >
                  </video>
                  {(isFlagVisible && isIpv4Country) && (
                    <span className="index__flag-container">
                      {getCountryFlag(countryCode, countries?.data, lang)}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="index-info">
            {isClient && <TrustBox />}
            <h1>{paintedTitle()}</h1>
            <p className="index-subtitle">{currentSeo?.mainPageDescription}</p>
          </div>
          <Form key={activeData?.proxy?.code} />
        </div>
      </Container>
    </section>
  );
};
