import { PriceItemTemplateSkeleton } from "../../PriceItemTemplate/PriceItemTemplateSkeleton/PriceItemTemplateSkeleton";
import { Skeleton } from "../../../../../../../components/ui/Skeleton/Skeleton";

export const EnterpriseItemSkeleton = ({ isThemeDark }) => (
  <>
    <PriceItemTemplateSkeleton isThemeDark={isThemeDark} >
      <div className="enterprise">
        <div className="enterprise__title">
          <Skeleton uniqueKey="enterprise__title" width={120} height={32} rounded="2px" isThemeDark={isThemeDark} />
        </div>
        <p className="enterprise__description">
          <Skeleton uniqueKey="enterprise__description" width="100%" height={65} rounded="2px" isThemeDark={isThemeDark} />
        </p>
      </div>
    </PriceItemTemplateSkeleton>
  </>
);
