import { capitalize } from "./capitalize.helper";

const getValidAltText = (countryName, code, lang) => {
  if (!countryName) return code
  
  const preparedCountryName = capitalize(countryName)
  
  return lang === "en"
    ? `Fast proxies of ${preparedCountryName}`
    : `Быстрые прокси ${preparedCountryName}`
};

export const getCountries = (countries, lang, exceptionObj = {}) => {
  let options = countries.map((item) => {
    const name = lang === "ru" ? capitalize(item?.countryName || item?.name) : capitalize(item?.countryEnName || item?.enName);
    
    const countryNameGenitiveCase = item?.nameCases?.[lang]?.genitive;
    
    return {
      ...item,
      id: item?.countryId || item?.id,
      value: item?.value || item?.countryId || item?.id,
      label: (
        <>
          <img
            src={`/img/flags/${item?.countryCode || item?.code}.svg`}
            loading="lazy"
            width="36"
            height="24"
            alt={getValidAltText(countryNameGenitiveCase, item.code, lang)}
          />
          {name}
        </>
      )
    };
  });

  const exceptionKeys = Object.keys(exceptionObj);

  if (exceptionKeys.length > 0) {
    exceptionKeys.forEach((item) => {
      options = options.filter((option) => {
        if (!option.hasOwnProperty(item)) {
          return true;
        }
        if (typeof option?.[item] === "object") {
          return option?.[item].includes(exceptionObj[item]);
        }
        return option?.[item] !== exceptionObj[item];
      });
    });
  }

  return options;
};
