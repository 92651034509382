import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "../../../../../hooks/useTranslation";

export const Credits = () => {
  // **Props
  const { lang } = useParams();
  const navigate = useNavigate();
  // Dispatch

  // **Redux State
  const { documentation } = useTranslation().cabinet;
  const { footerModal } = useSelector((state) => state.footer);
  // **State
  const { termsOfUse, publicOffer, privacyPolicy, returnPolicy, cookiePolicy } =
    useTranslation().footer;

  return (
    <ul className="footer__credits">
      <li className="footer__credit">
        <Link
          to={{ hash: "#termsOfUse" }}
          className={`footer__button ${footerModal === "termsOfUse" ? "footer__button-active" : ""}`}
        >
          {termsOfUse?.title}
        </Link>
      </li>
      <li className="footer__credit">
        <Link
          to={{ hash: "#privacyPolicy" }}
          className={`footer__button ${footerModal === "privacyPolicy" ? "footer__button-active" : ""}`}
        >
          {privacyPolicy?.title}
        </Link>
      </li>
      <li className="footer__credit">
        <Link
          to={{ hash: "#publicOffer" }}
          className={`footer__button ${footerModal === "publicOffer" ? "footer__button-active" : ""}`}
        >
          {publicOffer?.title}
        </Link>
      </li>
      <li className="footer__credit">
        <Link
          to={{ hash: "#returnPolicy" }}
          className={`footer__button ${footerModal === "returnPolicy" ? "footer__button-active" : ""}`}
        >
          {returnPolicy?.title}
        </Link>
      </li>
      <li className="footer__credit">
        <Link
          to={{ hash: "#cookiePolicy" }}
          className={`footer__button ${footerModal === "cookiePolicy" ? "footer__button-active" : ""}`}
        >
          {cookiePolicy?.title}
        </Link>
      </li>
      <li className="footer__credit">
        <button
          className="footer__button"
          onClick={() => {
            navigate(`/${lang}/documentation-api/`);
          }}
        >
          {documentation?.title}
        </button>
      </li>
    </ul>
  );
};
