import {IconSvg} from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import {Button} from "../../../../../../../../components/ui";
import {ButtonLoader} from "../../../../../../../../components/ui/ButtonLoader";
import {useDispatchedActions, useTranslation} from "../../../../../../../../hooks";
import './CancelAutoExtendingModal.scss';

export const CancelAutoExtendingModal = ({ handleConfirm, isLoading }) => {
  const { setIsCancelExtendingModalOpen, setOrdersForUpdate, setOrderData } = useDispatchedActions();
  
  const {
    cabinet: { orders: { autoExtending: { modals: { cancel: {
      confirmCanceling,
      hint,
      cancelButton,
      confirmButton
    } } } } }
  } = useTranslation();
  
  const cancelHandler = () => {
    setOrderData({});
    setOrdersForUpdate([]);
    setIsCancelExtendingModalOpen(false);
  };
  
  return (
    <div className="cancel-extending">
      <div className="cancel-extending__container">
        <IconSvg tag="warningIcon" />
        <div className="cancel-extending__title">
          {confirmCanceling}
        </div>
        <div className="cancel-extending__hint">
          {hint}
        </div>
      </div>
      <div className="cancel-extending__actions">
        <Button
          onClick={cancelHandler}
        >
          {cancelButton}
        </Button>
        <Button
          tab="confirm"
          onClick={() => handleConfirm(0)}
          disabled={isLoading}
        >
          {isLoading ? <ButtonLoader /> : confirmButton}
        </Button>
      </div>
    </div>
  );
};
