import { Skeleton } from "../../../../../../../components/ui/Skeleton/Skeleton";
import { PriceItemTemplateSkeleton } from "../../PriceItemTemplate/PriceItemTemplateSkeleton/PriceItemTemplateSkeleton";
import "../PriceItem.scss";

export const PriceItemSkeleton = () => (
  <PriceItemTemplateSkeleton>
    <div className="price-item">
      <div className="price-item__quantity-container">
        <span className="price-item__quantity-title">
          <Skeleton uniqueKey="price-item__quantity-title" width={126} height={45.2} rounded="2px" />
        </span>
        <div>
          <div className="price-item__quantity" style={{ marginBottom: "3px" }}>
            <Skeleton uniqueKey="price-item__quantity" width={67} height={28} rounded="2px" />
          </div>
          <div className="price-item__price-per-one">
            <Skeleton uniqueKey="price-item__price-per-one" width={40} height={14} rounded="2px" />
          </div>
        </div>
      </div>
      <div className="price-item__rent-period">
        <Skeleton uniqueKey="price-item__rent-period" width="100%" height={64} rounded="2px" />
      </div>
      <div className="price-item__price-container">
        <span className="price-item__price-title">
          <Skeleton uniqueKey="price-item__price-title" width={103} height={14} rounded="2px" />
        </span>
        <span className="price-item__full-price">
          <Skeleton uniqueKey="price-item__full-price" width={40} height={31} rounded="2px" />
        </span>
      </div>
    </div>
  </PriceItemTemplateSkeleton>
);
