import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isSSR } from "../utils/helpers/isSSR";

export const useRedirect = () => {
  // **Props
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  
  const cisCountries = ['az', 'am', 'by', 'kz', 'kg', 'md', 'ru', 'tj', 'tm', 'uz', 'ua'];
  
  // **Redux state
  const { translation } = useSelector((state) => state.translation);
  
  const getValidLang = (pathname) => {
    const userLang = pathname.replaceAll("/", "")
    
    if (cisCountries.includes(userLang)) {
      return "ru";
    }
    
    return "en";
  };

  useEffect(() => {
    // delete double slash in pathname
    const newPathname = pathname.replace(/\/{2,}/g, "/");
    // Adding slash to the route if route does not contain slash at the end
    if (pathname.slice(-1) !== "/") {
      navigate(`${newPathname}/`, { replace: true });
    }

    if (lang && translation?.hasOwnProperty(lang)) return;
    
    const validLang = getValidLang(pathname);

    const path = `/${validLang}/`
    navigate(path.slice(-1) === "/" ? path : `${path}/`, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, pathname]);

  if (isSSR()) {
    const newPathname = pathname.replace(/\/{2,}/g, "/");
    // Adding slash to the route if route does not contain slash at the end

    if (pathname.slice(-1) !== "/") {
      navigate(`${newPathname}/`, { replace: true });
    }

    if (lang && translation?.hasOwnProperty(lang)) return;

    const splittedPath = pathname.split("/");
    splittedPath[1] = "en";
    const path = splittedPath.join("/");
    navigate(path.slice(-1) === "/" ? path : `${path}/`, { replace: true });
  }
};
