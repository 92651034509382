import { Link, useParams } from "react-router-dom";
import { useTranslation } from "../../../../../../hooks";
import "./FaqInfo.scss";

export const FaqInfo = () => {
  const { faq } = useTranslation();
  const { lang } = useParams();

  return (
    <div className="faq-info">
      <p className="faq-info__description">
        {faq.description}
      </p>
      <Link to={`/${lang}/contacts/`} className="faq-info__send-question">
        {faq.ask}
      </Link>
    </div>
  );
};
