export const DataError = [
  {
    title: false,
    description: {
      ru: "В случае не удачного выполнения запроса, в ответ придет сообщение о ошибке",
      en: "In case of failed query, you will get an error message" },
    errorExample: {
      success: false,
      error_id: 2,
      error: "You send requests too often. No more than 60 requests per second are allowed"
    },
    errors: {
      error_id: { ru: "ID ошибки", en: "error ID" },
      error: { ru: "сообщение ошибки", en: "error message" }
    },
    errorsId: {
      1: { en: "API disabled on the server"},
      2: {en: "You send requests too often. No more than 60 requests per second are allowed"},
      3: { en: "Error auth. Your IP doesn't have access"},
      4: { en: "Method not supported"},
      5: { en: "Incorrect proxy type"},
      6: { en: "Ipv4 proxies disabled on the server"},
      7: { en: "Ipv6 proxies disabled on the server"},
      8: { en: "Mobile proxies disabled on the server"},
      9: { en: "Incorrect field [days]"},
      10: { en: "Incorrect field [country]"},
      // 11: { en: "Incorrect field [goal]"},
      12: { en: "Incorrect field [authType]"},
      13: { en: "Incorrect field [protocol]. Field must be [HTTPS] or [SOCKS5]"},
      14: { en: "Incorrect field [authIp]"},
      15: { en: "Incorrect field [count]"},
      16: { en: "Incorrect field [mobileOperator]"},
      17: { en: "Incorrect field [rotationTime]"},
      18: { en: "Incorrect field [IpId]"},
      19: { en: "Incorrect field [orderId]"},
      20: { en: "OrderId {orderId} don't have active IPs!"},
      21: { en: "ID {ip} not found!"},
      22: { en: "Request must contain [goal]"},
      23: { en: "Unknown error"},
      24: { en: "Insufficient balance"},
      25: { en: "Error key"},
      26: { en: "Length of field [goal] must be 5 characters or more"},
      27: { en: "ISP proxies disabled on the server"},
      28: { en: "Mobile tariff is not available"},
    }
  }
];
