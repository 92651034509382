import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";

export const ListItemWrapperV1 = ({ data, listItemClass, bodyItem }) => (
  <li className={`listV1-item ${listItemClass}`}>
    <div className="listV1-item-box">
      <div className={"icon-counter"}>
        <div className="icon-shodow">
          <IconSvg tag={data?.icon} />
        </div>
      </div>
      {bodyItem(data)}
    </div>
  </li>
);
