import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { IconSvg } from "../../../../../../utils/constants/icons/iconSvg.constant";

export const CountriesType = (props) => {
  // **Props
  const { title, countries, initOpen, proxy, handlerClick } = props;
  const { lang } = useParams();

  const ref = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(initOpen);
  const [maxHeight, setMaxHeight] = useState(0);

  const handlerOpen = () => {
    setDropdownOpen((state) => !state);
  };
  useEffect(() => {
    if (dropdownOpen) {
      setMaxHeight(ref?.current?.scrollHeight);
    } else {
      setMaxHeight(0);
    }
  }, [dropdownOpen]);

  return (
    <>
      {!!countries?.length && (
        <div>
          <button
            className="header__countries__list__dropdown"
            onClick={handlerOpen}
          >
            {title}
            <IconSvg
              tag="tirangleUp"
              className={`header__countries__list__dropdown-icon ${dropdownOpen ? "header__countries__list__dropdown-icon-active" : ""}`}
            />
          </button>
          <ul
            ref={ref}
            style={{ maxHeight: `${maxHeight}px` }}
            className={` header__countries__list ${
              dropdownOpen ? "header__countries__list-top" : ""
            }`}
          >
            {countries.map((item) => (
              <li
                className="header__countries__list__item"
                key={item?.id}
                onClick={(e) => handlerClick(e, proxy, item)}
              >
                <Link
                  className="header__countries__list__link"
                  to={`/${lang}/${
                    proxy?.code === "IPv4" ? `${item?.url}/` : `${proxy?.value}/?country=${item?.url}`
                  }`}
                >
                  {item?.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
