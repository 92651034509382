import { classNames } from "../../../../utils/helpers/classNames";
import "./ProgressBar.scss";

export const ProgressBar = ({ isProgress, isLoop, percentage = 0, isError, success }) => (
    <div className={classNames("progress-bar", { "progress-bar--is-error": isError })}>
      {(isLoop && !isError) && (
        <>
          <div className="progress-bar__loop"></div>
          <div className="progress-bar__loop"></div>
        </>
      )}
      {(isProgress && !isError) && (
        <div className="progress-bar__progress" style={{ width: `${percentage}%` }}></div>
      )}
      {(!isProgress && !isError) && (
        <div className="progress-bar__done" style={{ width: (success ? "100%" : "0") }}></div>
      )}
    </div>
  );
