import Cookies from 'universal-cookie';

export const setCookie = (name, value) => {
  const date = new Date();
  const nextYear = date.setFullYear(date.getFullYear() + 1);
  const expiresDate = new Date(nextYear);
  
  const cookies = new Cookies(null, { path: '/' });
  cookies.set(name, value, { expires: expiresDate });
};
