// import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ModalSelect } from "../../../../../ui/ModalSelect/ModalSelect";
import {
  useCurrentPaymantSystems,
  useDispatchedActions,
  useTranslation
} from "../../../../../../hooks";

export const PaymentSystem = ({ data, setData }) => {
  const currentPaymentSystems = useCurrentPaymantSystems();
  // **Dispatch
  const { setPaymentSystem } = useDispatchedActions();

  // **Hooks
  const { formPlaceholders } = useTranslation();

  useEffect(() => () => {
      setPaymentSystem(null);
      setData?.("payment", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
    if (
      data?.id &&
      !currentPaymentSystems.find((item) => item.id === data?.id)
    ) {
      setData?.("payment", null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaymentSystems]);

  return (
    <ModalSelect
      name="payment"
      rules={{ required: true }}
      options={currentPaymentSystems}
      placeholder={formPlaceholders?.paymentField}
      onChange={(value) => {
        setPaymentSystem(value);
      }}
      // styles={{
      //   container: (provided) => ({
      //     ...provided,
      //     backgroundColor: !paymentSystems?.data?.email && "transparent",
      //     transition: "1s"
      //   }),
      //   control: (baseStyles) => ({
      //     ...baseStyles,
      //     position: !paymentSystems?.data?.email ? "absolute" : "static",
      //     transform: !paymentSystems?.data?.email ? "translateY(-80px)" : "translateY(0px)",
      //     transition: "0.5s"
      //   })
      // }}
      // isDisabled={!paymentSystems?.data?.email}
    />
  );
};
