import {IconSvg} from "../../../../../../../utils/constants/icons/iconSvg.constant";
import {useDispatchedActions} from "../../../../../../../hooks";
import {useSelector} from "react-redux";

export const FormatCellDnd = ({ row, closeFolderSelect }) => {
  const { setSelectedRows } = useDispatchedActions();
  const { selectedRows } = useSelector((state) => state.cabinet);
  
  const addToActive = () => {
    if (selectedRows.some((id) => id === row.id)) {
      
      return;
    }
    setSelectedRows([row.id]);
  };
  
  const dragEndHandler = () => {
    closeFolderSelect();
    setSelectedRows([]);
  };
  
  return (
    <div
      draggable={true}
      onDragStart={addToActive}
      onDragEnd={dragEndHandler}
      className="dnd-container"
    >
      <IconSvg
        tag="dnd"
        className="dnd"
      />
    </div>
  );
};
