import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatchedActions, useTranslation } from "../../../../../../../../hooks";
import { useResize } from "../../../../../../../../hooks/useResize";
import { CountryButton } from "../CountryButton/CountryButton";
import { CountriesModal } from "../CountriesModal/CountriesModal";

const isLocalCountryNotSelected = (prevProps, nextProps) => !nextProps.localCountry.id;

export const CountriesDesktop = memo((props) => {
  // **Props
  const { localCountry, setLocalCountry, countries, setIsModalCountryWasSelected } = props;

  // **Hooks
  const { prices } = useTranslation();
  const { setModalCountriesOpen } = useDispatchedActions();
  const screenSize = useResize();
  const container = useRef(null);

  // **State
  const [currentContainer, setCurrentContainer] = useState();
  const [modalCountries, setModalCountries] = useState([]);

  const currentWidth = {
    current: 0
  };

  useEffect(() => {
    setModalCountries([]);
  }, [countries]);

  useEffect(() => {
    if (container.current) {
      setCurrentContainer(container.current);
      setModalCountries([]);
    }
  }, [screenSize]);

  const handlerChoiceCountry = useCallback((item) =>
    setLocalCountry({
      id: item?.id,
      code: item?.code
    }), [setLocalCountry]);

  const addToModal = useCallback((country) => {
    if (modalCountries.find((c) => c.id === country.id)) {
      return;
    }

    setModalCountries((prev) => [...prev, country]);
  }, [modalCountries]);

  const handlerMore = () => setModalCountriesOpen(true);

  // eslint-disable-next-line no-lone-blocks

  return (
    <div className="container" ref={container}>
      <ul className="countries">
        {countries.length ? countries.map((item, index) => (
          <CountryButton
            key={item.id + Date.now()}
            item={item}
            countriesLength={countries.length}
            index={index}
            localCountry={localCountry}
            handlerChoiceCountry={handlerChoiceCountry}
            currentWidth={currentWidth}
            currentContainer={currentContainer}
            addToModal={addToModal}
            modalCountries={modalCountries}
          />
        )) : null}
        {modalCountries.length > 0 ? (
          <li className="countries-item" key={"more"}>
            <button
              className={`countries-button countries-button-more ${
                modalCountries.find((item) => item.id === localCountry.id)
                  ? "countries-button-more--active"
                  : ""
              }`}
              // to={`/${lang}/${activeData?.proxy?.code === "IPv4" ? `${item?.url}/` : `${activeData?.proxy?.value}/`}`}
              onClick={handlerMore}
            >
              {`${prices?.more} +${modalCountries.length}`}
            </button>
          </li>
        ) : null}
        <CountriesModal
          localCountry={localCountry}
          modalCountries={modalCountries}
          setLocalCountry={setLocalCountry}
          setIsModalCountryWasSelected={setIsModalCountryWasSelected}
        />
      </ul>
    </div>
  );
}, isLocalCountryNotSelected);
