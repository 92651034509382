import { CheckBox } from "../../../../../../../components/ui/CheckBoxes/CheckBox";
import {useSelector} from "react-redux";
import {useDispatchedActions} from "../../../../../../../hooks";
import {ApiService} from "../../../../../../../services/ApiService";
import {errorToast} from "../../../../../../../utils/helpers/customToast.helper";
import {useParams} from "react-router-dom";
import {Loading} from "../../../../../../../components/ui";
import {useState} from "react";

export const FormatCellCheckbox = ({ row }) => {
  const { lang } = useParams();
  const { selectedRows } = useSelector((state) => state.cabinet);
  const { addActiveItem, removeActiveItem, setSelectedRows} = useDispatchedActions();
  
  const [isLoading, setIsLoading] = useState(false);
  
  const isChecked = selectedRows.some(id => id === row.id);

  const handleClick = async () => {
    switch (row.proxyType) {
      case 'IPv6':
        try {
          setIsLoading(true)
          const { data, status } = await ApiService.userOrderItemsIPv6(row.id);
  
          if (status !== 200) {
            throw Error();
          }
  
          const tableDataIds = data.map(item => item.id);
  
          if (isChecked) {
            setSelectedRows(selectedRows.filter(id => !tableDataIds.includes(id)));
    
            return;
          }
  
          setSelectedRows([...selectedRows, ...tableDataIds]);
        } catch (e) {
          errorToast("Something went wrong", lang);
        } finally {
          setIsLoading(false)
        }
  
        break;
      default:
        isChecked ? removeActiveItem(row.id) : addActiveItem(row.id);
    }
  }

  return (
    <>
      <CheckBox
        checked={isChecked}
        onChange={handleClick}
      />
      {isLoading && (
        <Loading tableRow absolute roller={{ transform: "scale(0.5)"}}/>
      )}
    </>
  );
};
