import { useCallback, useEffect } from "react";
import { isSSR } from "../../../../../../../../../utils/helpers";
import { classNames } from "../../../../../../../../../utils/helpers/classNames";

export const DropDown = ({ days, setIsOpen, setDays, selectedDay }) => {

  const closeMenu = useCallback(({ target }) => {
    if (!target.closest(".days-select")) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSSR()) {
      return;
    }

    window.addEventListener("click", closeMenu);

    return () => window.removeEventListener("click", closeMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectDay = (day) => {
    setDays(day);
    setIsOpen(false);
  };

  return (
    <div className="days-select__dropdown">
      <ul className="days-select__days-list">
        {days.map((day) => (
          <li
            className={classNames(
              "days-select__days-item",
              { "days-select__days-item--active": selectedDay?.id === day?.id }
            )}
            key={day?.id}
            onClick={() => selectDay(day)}
          >
            {day?.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
