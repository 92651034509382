import {useState} from "react";
import {useTranslation} from "../../../../../../../hooks";
import {classNames} from "../../../../../../../utils/helpers/classNames";

export const FormatCellIpStatus = ({ order }) => {
  const [isVisible, setIsVisible] = useState(false);
  
  const {
    cabinet: { orders: { column: { ip: {
      ipStatus,
      active,
      ending
    } } } }
  } = useTranslation();
  
  const handleStatus = () => {
    setIsVisible((current) => !current);
  };
  
  return (
    <div
      className={classNames("table__ip-status", {
        "table__ip-status--active": !order.runningOut,
        "table__ip-status--ending": order.runningOut,
      })}
      onMouseEnter={handleStatus}
      onMouseLeave={handleStatus}
    >
      {isVisible && (
        <div className="table__ip-status-notification">
          {ipStatus}:&nbsp; {!order.runningOut ? (
          <span className="table__ip-status-notification--active">{active}</span>
        ) : (
          <span className="table__ip-status-notification--ends">{ending}</span>
        )}
        </div>
      )}
    </div>
  );
};
