import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";

import { Container } from "../../../../components/ui/Container/Container";

import { useTranslation } from "../../../../hooks/useTranslation";

import "./ProxyManadger.scss";
import { PROXY_TYPES } from "../../../../utils/constants";
import { classNames } from "../../../../utils/helpers/classNames";
import { capitalize } from "../../../../utils/helpers";

export const ProxyManadger = () => {
  // **State

  const { lang } = useParams();
  const { proxyManager } = useTranslation();

  const { activeData, countries } = useSelector((state) => state.content);
  const { skin } = useSelector((state) => state.header);

  const isThemeDark = skin === "dark";

  const proxyType = activeData?.proxy?.code;

  const getCorrectImg = () => {
    switch (proxyType) {
      case PROXY_TYPES.ipv4.code:
        return lang === "en" ? "proxy-manager_ipv4_en.png" : "proxy-manager_ipv4_ru.png";

      case PROXY_TYPES.ipv6.code:
        return lang === "en" ? "proxy-manager_ipv6_en.png" : "proxy-manager_ipv6_ru.png";

      case PROXY_TYPES.mobile.code:
        return lang === "en" ? "proxy-manager_mobile_en.png" : "proxy-manager_mobile_ru.png";

      case PROXY_TYPES.isp.code:
        return lang === "en" ? "proxy-manager_isp_en.png" : "proxy-manager_isp_ru.png";

      default:
        return "proxy-manager_ipv4_en.png";
    }
  };

  const getIpv4Title = () => {
    const country = countries?.data?.find((country) => country?.id === activeData?.country?.id);

    const countryName = lang === "en"
      ? capitalize(country?.enName)
      : country?.ruAdjectiveForm;

    return proxyManager?.countryTypeMessage.replace("_", (countryName || ""));
  };

  const getTitle = () => {
    switch (proxyType) {
      case PROXY_TYPES.ipv4.code:
        return getIpv4Title();
      case PROXY_TYPES.ipv6.code:
        return proxyManager?.ipv6Message;
      case PROXY_TYPES.isp.code:
        return proxyManager?.ispMessage;
      case PROXY_TYPES.mobile.code:
        return proxyManager?.mobileMessage;
      default:
        return proxyManager?.title;
    }
  };

  return (
    <section className={classNames("proxy-manadger", { "proxy-manadger--dark": isThemeDark })}>
      <Container>
        <div className="proxy-manadger__box">
          <div className="proxy-manadger__img-container">
            <img
              className="proxy-manadger__image"
              src={`/img/${getCorrectImg()}`}
              loading="lazy"
            />
          </div>
          <div className="proxy-manadger__info">
            {/*<h2 className="proxy-manadger__info__title">*/}
            <h2>
              {getTitle()}
            </h2>
            {proxyManager?.list && proxyManager?.list.length > 0 ? (
              <ul className="proxy-manadger__info__list">
                {proxyManager?.list.map((item, index) => (
                  <li key={index} className="proxy-manadger__info__list-item">
                    <IconSvg tag="checkCircle" className={"item-icon"} />
                    {item}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      </Container>
    </section>
  );
};
