/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useLayoutEffect, useState} from "react";
import { load } from "@fingerprintjs/fingerprintjs";
import { useSelector } from "react-redux";

import { ContentDataErrorMesege } from "./components/common/ContentDataErrorMesege/ContentDataErrorMesege";

import { ApiService } from "./services/ApiService";

import { useDispatchedActions } from "./hooks/useDispatchedActions";
import { genString } from "./utils/helpers";
import { setClientToken } from "./utils/helpers/setClientToken";
import {LOCALS} from "./utils/constants/locals";

import 'swiper/css';
import i18n from "./i18n/config"
import { initializeTheme } from "./utils/helpers/initializeTheme";
import { useLoadScripts } from "./hooks/useLoadScripts";
import {useTheme} from "./hooks";

export const App = ({ routerProvider: RouterProvider, routerProps, lang = "en", locales }) => {
  useLoadScripts();
  useTheme();

  const {
    fetchSiteContent,
    fetchSiteProxyParams,
    fetchMobileProxyTariff,
    fetchIsUserAuth,
    setCaptchaStatus,
    fetchAllCountries,
    fetchRentPeriods,
    fetchPaymentSystems,
    setAllCountries,
    setMobileTariffs
  } = useDispatchedActions();

  const {
    countries,
    content,
    activeData,
    // isCaptchaEnabled,
    proxies,
    rentPeriods,
    paymentSystems,
    user
  } = useSelector((state) => state.content);
  
  const { mobileTariffs, isMobileTariffsLoaded } = useSelector((state) => state.mobileProxy);
  
  const [fingerprintLoaded, setFingerprintLoaded] = useState(false);
  const [checkAllLoaded, setCheckAllLoaded] = useState("load");

  const getFingerprint = async () => {
    try {
      const fp = await load();
      const result = await fp.get();
      if (result?.visitorId) {
        sessionStorage.setItem("fgpJS", result.visitorId);
      }
    } finally {
      setFingerprintLoaded(true);
    }
  };

  const downloadRestLocales = (loadedLocales) => {
    const enabledLocales = locales || Object.values(LOCALS);

    const restLocales = enabledLocales.filter(
      (locale) => !loadedLocales.includes(locale)
    );
    restLocales.forEach((locale) => fetchSiteContent(locale));
  };

  useEffect(() => {
    setClientToken();
    // Initial Fetching fingerprint
    if (!sessionStorage.reactJSId) {
      sessionStorage.reactJSId = `s${genString(
        15
      )}${new Date().getTime()}${genString(2)}`;
    }
    getFingerprint();
  }, []);


  // Initial Fetching
  useEffect(() => {
    if (fingerprintLoaded) {
      // Initial Fetching content for site
      if (!content?.isLoaded) {
        fetchSiteContent(lang);
        downloadRestLocales([lang]);
      } else {
        const loadedLocales = Object.keys(content?.data);
        downloadRestLocales(loadedLocales);
      }

      // Initial Fetching user auth
      fetchIsUserAuth();

      // Initial Fetching proxy enabled
      if (!proxies?.isLoaded) {
        fetchSiteProxyParams();
      }

      // Initial Fetching default countries
      if (!countries?.isLoaded) {
        fetchAllCountries();
      }

      // Initial Fetching default rent periods
      if (!rentPeriods?.isLoaded) {
        fetchRentPeriods();
      }

      // Checking for token status
      ApiService.tokenStatus().then((req) => {
        if (req?.data) {
          setCaptchaStatus(req.data);
        } else {
          setCaptchaStatus(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fingerprintLoaded]);

  useEffect(() => {
    if (
      content?.isLoaded &&
      proxies?.isLoaded &&
      countries?.isLoaded &&
      rentPeriods?.isLoaded &&
      fingerprintLoaded
    ) {
      if (
        content?.fetchError ||
        proxies?.fetchError ||
        countries?.fetchError ||
        rentPeriods?.fetchError
      ) {
        setCheckAllLoaded("error");
      } else {
        setCheckAllLoaded("success");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content?.isLoaded,
    proxies?.isLoaded,
    countries?.isLoaded,
    rentPeriods?.isLoaded,
    fingerprintLoaded
  ]);

  // Initial fetching payment systems
  useEffect(() => {
    if (checkAllLoaded === "success") {
      const keysMobileProxyTariff = Object.keys(mobileTariffs);
      if (!keysMobileProxyTariff.includes(activeData?.currency?.id)) {
        fetchMobileProxyTariff(activeData?.currency?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.currency, checkAllLoaded]);
  
  const getCountriesFilterCallbackByLocale = () => {
    const callbacks = {
      "UA": (country) => country?.code !== "RUS" && country?.countryCode !== "RUS",
      "RU": (country) => country?.code !== "UKR" && country?.countryCode !== "UKR"
    };
    
    return callbacks[user.locale] || (() => true);
  };
  
  useEffect(() => {
    if (!user?.locale) return;
    
    const updatedMobileTariffs = Object.fromEntries(
      Object
        .entries(mobileTariffs)
        .map(([key, countries]) => {
        const filterCallback = getCountriesFilterCallbackByLocale();
  
        // noinspection UnnecessaryLocalVariableJS
        const filteredCountries = countries.filter(filterCallback);
        return [key, filteredCountries]
      })
    );
  
    setMobileTariffs(updatedMobileTariffs)
  }, [isMobileTariffsLoaded, user?.locale])
  
  useEffect(() => {
    if (!user?.locale || !user?.isAuthLoaded || !countries?.isLoaded) return;
    
    const filterCallback = getCountriesFilterCallbackByLocale();
    
    const filteredCountries = countries.data.filter(filterCallback);
    
    setAllCountries(filteredCountries)
  }, [user?.isAuthLoaded, countries?.isLoaded, user?.locale])
  
  useEffect(() => {
    if (user?.isAuthLoaded && fingerprintLoaded) {
      if (
        !paymentSystems?.isLoaded ||
        paymentSystems?.data?.email !== user?.email
      ) {
        fetchPaymentSystems({ email: user?.email });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.isAuthLoaded, fingerprintLoaded, user?.email]);
  
  if (checkAllLoaded === "error") {
    return <ContentDataErrorMesege />;
  }
  return <RouterProvider {...routerProps} />;
};
