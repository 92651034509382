import MediaQuery from "react-responsive";
import { Button } from "../../../../components/ui/Buttons/Button/Button";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import { useTranslation } from "../../../../hooks";
import "./ReviewsList.scss";

export const ReviewsList = () => {
  const { paymentSuccessPage: { addFeedback, postReview } } = useTranslation();

  const socials = [
    { title: "Trusted reviews", iconTag: "sendPlane", buttonTitle: postReview, href: "https://trustedrevie.ws/reviews/proxy-ipv4.com" },
    { title: "OsBot", iconTag: "sendPlane", buttonTitle: postReview, href: "https://osbot.org/forum/topic/174017-%E2%98%84%EF%B8%8Fproxy-ipv4com-private-4glte-ipv4-and-ipv6-proxies-from-036-per-ip-20-locations-%E2%9C%85/" },
    { title: "Rating-Proxy", iconTag: "sendPlane", buttonTitle: postReview, href: "https://rating-proxy.info/proxy/https-proxy-ipv4-com/#characteristics" },
    { title: "BlackHatWorld", iconTag: "sendPlane", buttonTitle: postReview, href: "https://www.blackhatworld.com/seo/proxy-ipv4-com-private-4g-lte-ipv4-and-ipv6-proxies-from-0-36-per-ip-20-locations.1458546/" }
  ];

  return (
    <div className="reviews">
      <div className="reviews__reviews-info">
        <div className="reviews__reviews-title">{addFeedback}</div>
      </div>
      <div className="reviews__socials-container">
        <ul className="reviews__socials">
          {socials.map(({ title, iconTag, buttonTitle, href }) => (
            <li key={title} className="reviews__social">
              <span className="reviews__social-title">{title}</span>
              <a href={href} target="_blank" rel="noreferrer">
                <Button tab="add-feedback">
                  <MediaQuery minWidth={768}>
                    {buttonTitle}
                  </MediaQuery>
                  <MediaQuery maxWidth={767}>
                    <IconSvg tag={iconTag} />
                  </MediaQuery>
                </Button>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
