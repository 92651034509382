import { useEffect, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatchedActions, useTranslation } from "../../../../../hooks";
import "./ScrollNav.scss";
import { Solutions } from "../Solutions/Solutions";

export const ScrollNav = () => {
  const { lang } = useParams();
  const location = useLocation();
  const { setMenu } = useDispatchedActions();
  const { header: { menu } } = useTranslation();
  const delayRef = useRef(500);

  const { hash } = location;

  const closeMenu = () => setMenu(false);

  const moveToAnchor = (anchor, delay) => {
    if (!anchor) {
      return;
    }

    closeMenu();

    setTimeout(() => {
      const el = document.getElementById(anchor);

      if (!el) return;

      el.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }, delay);
  };

  const setDelay = () => {
    const pathParams = location.pathname.split("/").filter((value) => value);
    const lastParam = pathParams[pathParams.length - 1];

    if (lastParam === lang) {
      delayRef.current = 0;
    }
  };

  useEffect(() => {
    moveToAnchor(hash.slice(1), delayRef.current);
    delayRef.current = 500;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ul className="header-scroll-list">
      <li className="header-scroll-list-item">
        <Solutions onClose={closeMenu}/>
      </li>
      <li className="header-scroll-list-item">
        <Link
          to={`/${lang}/#prices`}
          className="header-scroll-link"
          onClick={setDelay}
        >
          {menu?.price}
        </Link>
      </li>
      <li className="header-scroll-list-item">
        <Link to={`/${lang}/feedback/`} className="header-scroll-link" onClick={closeMenu}>
          {menu?.feedback}
        </Link>
      </li>
      <li className="header-scroll-list-item">
        <Link
          to={`/${lang}/#faq`}
          className="header-scroll-link"
          onClick={setDelay}
        >
          {menu?.faq}
        </Link>
      </li>
      <li className="header-scroll-list-item">
        <Link to={`/${lang}/contacts/`} className="header-scroll-link" onClick={closeMenu}>
          {menu?.contacts}
        </Link>
      </li>
    </ul>
  );
};
