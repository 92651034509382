import axios from "axios";
import { isSSR } from "../utils/helpers/isSSR";
import { tokenName } from "../utils/helpers/setClientToken";

export let $api;

if (!isSSR()) {
  $api = axios.create();
  $api.interceptors.request.use((config) => {

    const fingerprint = sessionStorage.getItem("fgpJS");
    const reactJSId = sessionStorage.getItem("reactJSId");
    const clientToken = localStorage.getItem(tokenName);
    config.headers["React-App-Id"] = reactJSId || "_";
    config.headers[tokenName] = clientToken;
    if (fingerprint) {
      config.headers["fingerprint"] = fingerprint;
    }
    return config;
});
  $api.interceptors.response.use(
    (response) => {
      try {
        if (response.data.slice(0, 15) === "<!doctype html>") {
          localStorage.removeItem("token");
          localStorage.removeItem("client_profile");
          window.location.href = "/";
          return null;
        } else {
          return response;
        }
      } catch (e) {
        return response;
      }
    },
    (error) => {
      if (error?.code === "ERR_CANCELED") {
        return error;
      }
      try {
        if (error.config.url === "/api/login") {
          return error;
        }
      } catch (e) {
        console.error("Login error", e);
      }
      if (error.response === undefined) {
        localStorage.removeItem("token");
        localStorage.removeItem("client_profile");
        window.location.href = "/";
        return null;
      } else {
        return error;
      }
    }
  );
} else {
  $api = axios.create({
    baseURL: process.env.REACT_APP_SSR_API_URL
  })
}
