import { useTranslation } from "../../../../../../../hooks/useTranslation";
import {FormatCellCopy} from "./FormatCellCopy";
import {getCountryFlag} from "../../../../../../../utils/helpers/getCountryFlag";
import Moment from "react-moment";
import {IpData} from "../ui/IpData";
import {IconSvg} from "../../../../../../../utils/constants/icons/iconSvg.constant";
import {PROXY_TYPES} from "../../../../../../../utils/constants";

export const FormatCellIP = ({ row }) => {
  const { ip } = useTranslation().cabinet.orders.column;
  
  const getAuthData = () => {
    if (row?.authType === "ip" && row?.authIp) {
      return <IpData title={ip?.ip} value={row?.authIp} />
    }
    
    if (row?.authType === "ip" && !row?.authIp) {
      return (
        <>
          <IpData title={ip?.login} value={row?.authLogin} />
          <IpData title={ip?.password} value={row?.authPassword} />
        </>
      );
    }
    
    if (row?.authType === "login" && (row?.authLogin && row?.authPassword)) {
      return (
        <>
          <IpData title={ip?.login} value={row?.authLogin} />
          <IpData title={ip?.password} value={row?.authPassword} />
        </>
      );
    }

    return <IpData title={ip?.ip} value={row?.authIp} />
  };

  return (
    <div className="table__ip">
      <div className="table__ip-flag">
        {getCountryFlag(row.countryInfo.code)}
      </div>
      <div className="table__ip-container">
        <div className="table__ip-content">
          <span>{row?.innerIp}</span>
          <div className="table__ip-tooltip">
            <FormatCellCopy row={row} />
            <div className="table__ip-dropdown">
              {getAuthData()}
              {/*{row?.authType === "ip" ? (*/}
              {/*    <>*/}
              {/*      {row?.authIp ? (*/}
              {/*        <IpData title={ip?.ip} value={row?.authIp} />*/}
              {/*      ) : (*/}
              {/*        <>*/}
              {/*          <IpData title={ip?.login} value={row?.authLogin} />*/}
              {/*          <IpData title={ip?.password} value={row?.authPassword} />*/}
              {/*        </>*/}
              {/*      )}*/}
              {/*    </>*/}
              {/*) : (*/}
              {/*  <>*/}
              {/*    {row?.authLogin && row?.authPassword ? (*/}
              {/*        <>*/}
              {/*          <IpData title={ip?.login} value={row?.authLogin} />*/}
              {/*          <IpData title={ip?.password} value={row?.authPassword} />*/}
              {/*        </>*/}
              {/*    ) : (*/}
              {/*      <IpData title={ip?.ip} value={row?.authIp} />*/}
              {/*    )}*/}
              {/*  </>*/}
              {/*)}*/}
              {row?.proxyType === "IPv6" && row?.port && (
                <IpData title={ip?.protocol} value={row?.protocol} />
              )}
              {row?.proxyType !== "IPv6" && (
                <>
                  <IpData
                    title={ip?.http}
                    value={row?.proxyType === PROXY_TYPES.ipv4.code || row?.proxyType === PROXY_TYPES.isp.code ? row?.proxyPort : row?.httpPort}
                  />
                  <IpData title={ip?.socks} value={row?.socksPort} />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="table__ip-period">
          <Moment format="DD.MM.YYYY" utc date={row.dateStart} />
          &nbsp;
          —
          &nbsp;
          <Moment format="DD.MM.YYYY" utc date={row.deadLine} />
        </div>
        {row?.ipAddressFolderId && (
          <div className="table__ip-folder-container">
            <IconSvg tag="folder" className="table__ip-folder-icon" />
            <span className="table__ip-folder-name">
            {row?.ipAddressFolderName}
          </span>
          </div>
        )}
      </div>
    </div>
  );
};
