import { CustomModal } from "../../../../../../../components/ui/CustomModal/CustomModal";
import { useSetZIndex } from "../../../../../../../hooks/useSetZIndex";
import { IconSvg } from "../../../../../../../utils/constants/icons/iconSvg.constant";
import { Button } from "../../../../../../../components/ui/Buttons/Button/Button";
import "./RemovePaymentModal.scss";
import { useTranslation } from "../../../../../../../hooks";

export const RemovePaymentModal = ({ open, onClose }) => {
  useSetZIndex(".cabinet", "999");

  const { cabinet: { balance: { paymentMethod: { deleteModalText, cancel, confirm } } } } = useTranslation();

  return (
    <CustomModal open={open} onClose={onClose}>
      <div className="remove-payment-modal">
        <IconSvg tag="removeWallet" />
        <div className="remove-payment-modal__title">{deleteModalText}</div>
        <div className="remove-payment-modal__actions">
          <Button>{cancel}</Button>
          <Button tab="confirm">{confirm}</Button>
        </div>
      </div>
    </CustomModal>
  );
};
