import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";

import "./CheckBox.scss";

export const CheckBox = (props) => {
  // **Props
  const {
    label,
    className,
    defaultValue,
    checked,
    partly,
    onChange,
    disabled = false
  } = props;

  // Define correct classnames
  const classNames = (className) =>
    `custom-checkbox  ${ className ? className : ""}`;

  return (
      <div className={classNames(className)}>
        <label>
          <input
            type={"checkbox"}
            defaultChecked={Boolean(defaultValue)}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
          <div className={`checked ${partly ? "checked-partly" : ""}`}>
            <IconSvg tag="checked" />
          </div>
          {label ? label : null}
        </label>
      </div>
    );
};
