import MainPage from "../../pages/MainPage/MainPage";
import OrderProgressPage from "../../pages/OrderProgressPage/OrderProgressPage";

export const appRoutes = (ssr = false) => [
  {
    isSsr: ssr ?? false,
    path: "/:lang/",
    tag: "home",
    key: "IPv4",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: ssr
      ? MainPage
      : () => import("../../pages/MainPage/MainPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/ipv6/",
    tag: "home",
    key: "IPv6",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: ssr
      ? MainPage
      : () => import("../../pages/MainPage/MainPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/isp/",
    tag: "home",
    key: "ISP",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: ssr
      ? MainPage
      : () => import("../../pages/MainPage/MainPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/mobile/",
    key: "MOBILE",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: ssr
      ? MainPage
      : () => import("../../pages/MainPage/MainPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/:country/",
    tag: "home",
    key: "IPv4",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: ssr
      ? MainPage
      : () => import("../../pages/MainPage/MainPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/api/recovery/:hash",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: ssr
      ? MainPage
      : () => import("../../pages/MainPage/MainPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/payment/:status/",
    tag: "home",
    metaData: "seo",
    title: "Home page",
    description: "Home page",
    component: ssr
      ? MainPage
      : () => import("../../pages/MainPage/MainPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/progress/",
    tag: "progress",
    metaData: "seo",
    title: "Order progress",
    description: "Order progress",
    component: ssr
      ? OrderProgressPage
      : () => import("../../pages/OrderProgressPage/OrderProgressPage")
  }
];
