import { Link, useNavigate, useParams } from "react-router-dom";
import "./BenefitItem.scss";
import { useSelector } from "react-redux";
import { useDispatchedActions, useTranslation } from "../../../../../../hooks";

export const BenefitItem = ({ title, description, btnType, btnText }) => {
  const { user } = useSelector((state) => state.content);
  const navigate = useNavigate();
  const { lang } = useParams();

  const {
    header: { cabinet }
  } = useTranslation();

  const { setLoginModal } = useDispatchedActions();

  const loginFormHandler = () => {
    if (user?.isAuth) {
      navigate(`/${lang}/dashboard/?tab=profile`);
    } else {
      setLoginModal(true);
    }
  };

  const getButton = () => {
    // eslint-disable-next-line default-case
    switch (btnType) {
      case "authorization":
        return (
          <button
            onClick={loginFormHandler}
            className="benefit-item__link"
          >
            {user?.isAuth ? cabinet?.loginIsAuth : btnText}
          </button>
        );

      case "documentation":
        return <Link className="benefit-item__link" to="documentation-api">{btnText}</Link>;
    }
  };

  return (
    <div className="benefit-item">
      <div className="benefit-item__title">{title}</div>
      <p className="benefit-item__description">
        {description}
      </p>
      {getButton()}
    </div>
  );
};
