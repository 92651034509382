import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams } from "react-router-dom";
import { successToast } from "../../../../../../../utils/helpers/customToast.helper";

import { useTranslation } from "../../../../../../../hooks/useTranslation";
import {IconSvg} from "../../../../../../../utils/constants/icons/iconSvg.constant";

export const FormatCellLinkMobileReboot = (props) => {
  const { lang } = useParams();
  // *Props
  const { row } = props;
  // **Hooks
  const { notifications, mobileProxy } = useTranslation();
  const { copyLink, link } = useTranslation().cabinet.orders.column;
  const handlerCopy = () => successToast(notifications?.copy, lang);
  return +row?.modemRotationMin === 0 ? (
    <>
      <a
        href={row?.linkReboot}
        className="table__rotation-link"
        target="_blank"
        rel="noreferrer"
      >
        {link}
      </a>
      <CopyToClipboard text={row?.linkReboot} onCopy={handlerCopy}>
        <div>
          <IconSvg tag="copy" className="copy-ip table__copy-ip" />
        </div>
      </CopyToClipboard>
    </>
  ) : (
    <span>{`${row?.modemRotationMin} ${mobileProxy?.changeIPTime}`}</span>
  );
};
