import "./FaqSlide.scss";
import { classNames } from "../../../../../../utils/helpers/classNames";

export const FaqSlide = ({ themeName, setSelectedTheme, selectedTheme }) => (
    <div
      onClick={() => setSelectedTheme(themeName)}
      className={classNames(
        "faq-slide",
        { "faq-slide--active": selectedTheme === themeName }
      )}
    >
      {themeName}
    </div>
);
