import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { Link } from "react-router-dom";
import { useTranslation } from "../../../../hooks";

import "./Tabs.scss";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import { Container } from "../../../../components/ui/Container/Container";

export const Tabs = (props) => {
  // **Props
  const { active, setSearchParams, setIsLogoutModalOpen } = props;
  // **Redux State
  const {
    cabinet: {
      profile,
      orders,
      balance,
      documentation,
      notifications,
      newOrder,
      faq
    },
    header: { cabinet }
  } = useTranslation();

  const TABS = [
    { value: "profile", label: profile?.title },
    { value: "balance", label: balance?.title },
    { value: "orders", label: orders?.title },
    { value: "new-order", label: newOrder?.tabName },
    { value: "documentation", label: documentation?.title },
    { value: "notifications", label: notifications?.tabName },
    { value: "faq", label: faq?.tabName }
  ];

  const triggerTab = (e, tab) => {
    e.preventDefault();
    setSearchParams({ tab });
  };

  const handlerSetActiveIndex = (e) => {
    e.activeIndex = TABS.indexOf(
      TABS.find((item) => item.value === active)
    );
  };

  const handleReachStart = (e) => {
    e.el.classList.add("tabs-swip-start");
    e.el.classList.remove("tabs-swip-end");
  };

  const handleReachEnd = (e) => {
    e.el.classList.remove("tabs-swip-start");
    e.el.classList.add("tabs-swip-end");
  };

  const handleReachIndexChange = (e) => {
    if (e.progress > 0 && e.progress < 1) {
      e.el.classList.remove("tabs-swip-end", "tabs-swip-start");
    }
  };

  return (
    <>
      {/*<MediaQuery maxWidth={992}>*/}
      <div className="media__max-width-992 tabs-swiper-container">
        <Container>
          <Swiper
            className="tabs tabs-swip-start"
            spaceBetween={24}
            // speed={500}
            slidesPerView="auto"
            // autoplay={{ delay: 3000 }}
            // pagination={{ clickable: true }}
            onAfterInit={handlerSetActiveIndex}
            onRealIndexChange={handleReachIndexChange}
            onReachBeginning={handleReachStart}
            onReachEnd={handleReachEnd}
            wrapperTag="ul"
            watchOverflow
            // navigation
            // loop
            modules={[Navigation, Pagination]}
            // breakpoints={crmBreakpoints}
          >
            {TABS.map((obj, index) => (
              <SwiperSlide
                className={`tabs__item  ${
                  obj?.value === active ? "tabs__item--active" : ""
                }`}
                tag="li"
                style={{ height: "auto" }}
                key={index}
              >
                <Link
                  className={`tabs__link ${
                    obj?.value === active ? "tabs__link--active" : ""
                  }`}
                  to={"#"}
                  onClick={(e) => triggerTab(e, obj?.value)}
                >
                  {obj?.label}
                </Link>
              </SwiperSlide>
            ))}
            <SwiperSlide
              className={"tabs__item"}
              tag="li"
              style={{ height: "auto" }}
              key="exit"
            >
              <button className={"tabs__button"} onClick={() => setIsLogoutModalOpen(true)}>
                <IconSvg tag="exit" className={"exit-icon"} />
                {cabinet?.logout}
              </button>
            </SwiperSlide>
          </Swiper>
        </Container>
      </div>
      {/*<MediaQuery maxWidth={1049}>*/}
      {/*  <Container>*/}
      {/*    <Swiper*/}
      {/*      className="tabs tabs-swip-start"*/}
      {/*      spaceBetween={24}*/}
      {/*      // speed={500}*/}
      {/*      slidesPerView="auto"*/}
      {/*      // autoplay={{ delay: 3000 }}*/}
      {/*      // pagination={{ clickable: true }}*/}
      {/*      onAfterInit={handlerSetActiveIndex}*/}
      {/*      onRealIndexChange={handleReachIndexChange}*/}
      {/*      onReachBeginning={handleReachStart}*/}
      {/*      onReachEnd={handleReachEnd}*/}
      {/*      wrapperTag="ul"*/}
      {/*      watchOverflow*/}
      {/*      // navigation*/}
      {/*      // loop*/}
      {/*      modules={[Navigation]}*/}
      {/*      // breakpoints={crmBreakpoints}*/}
      {/*    >*/}
      {/*      {TABS.map((obj, index) => (*/}
      {/*        <SwiperSlide*/}
      {/*          className={`tabs__item  ${*/}
      {/*            obj?.value === active ? "tabs__item--active" : ""*/}
      {/*          }`}*/}
      {/*          tag="li"*/}
      {/*          style={{ height: "auto" }}*/}
      {/*          key={index}*/}
      {/*        >*/}
      {/*          <Link*/}
      {/*            className={`tabs__link ${*/}
      {/*              obj?.value === active ? "tabs__link--active" : ""*/}
      {/*            }`}*/}
      {/*            to={"#"}*/}
      {/*            onClick={(e) => triggerTab(e, obj?.value)}*/}
      {/*          >*/}
      {/*            {obj?.label}*/}
      {/*          </Link>*/}
      {/*        </SwiperSlide>*/}
      {/*      ))}*/}
      {/*      <SwiperSlide*/}
      {/*        className={"tabs__item"}*/}
      {/*        tag="li"*/}
      {/*        style={{ height: "auto" }}*/}
      {/*        key="exit"*/}
      {/*      >*/}
      {/*        <button className={"tabs__button"} onClick={() => setIsLogoutModalOpen(true)}>*/}
      {/*          <IconSvg tag="exit" className={"exit-icon"} />*/}
      {/*          {cabinet?.logout}*/}
      {/*        </button>*/}
      {/*      </SwiperSlide>*/}
      {/*    </Swiper>*/}
      {/*  </Container>*/}
      {/*</MediaQuery>*/}
      <div className="media__min-width-993">
        {/*<MediaQuery minWidth={1050}>*/}
        <Container>
          <ul className="tabs ">
            {TABS.map((obj, index) => (
              <li className="tabs__item" key={index}>
                <Link
                  className={`tabs__link ${
                    obj?.value === active ? "tabs__link--active" : ""
                  }`}
                  to={"#"}
                  onClick={(e) => triggerTab(e, obj?.value)}
                >
                  {obj?.label}
                </Link>
              </li>
            ))}
            <li className="tabs__item">
              <button className={"tabs__button"} onClick={() => setIsLogoutModalOpen(true)}>
                <IconSvg tag="exit" className={"exit-icon"} />
                {cabinet?.logout}
              </button>
            </li>
          </ul>
        </Container>
      </div>
    </>
  );
};
