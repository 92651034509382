import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIsThemeDark } from "../../../../hooks/useIsThemeDark";
import { Container } from "../../../../components/ui/Container/Container";
import { classNames } from "../../../../utils/helpers/classNames";
import { useTranslation } from "../../../../hooks";
import { BenefitItem } from "./components/BenefitItem/BenefitItem";
import "./Benefits.scss";

export const Benefits = () => {
  const { content, activeData } = useSelector((state) => state.content);

  const { lang } = useParams();
  const isThemeDark = useIsThemeDark();

  const {
    advantages: { title }
  } = useTranslation();

  const benefits = useMemo(() => {
    if (content?.isLoaded && lang) {
      return content?.data[lang]?.advantages
        ?.filter((tip) => tip?.showOnFrontType.includes(activeData?.proxy?.code)) || [];
    }

    return [];
  }, [lang, content?.data, content?.isLoaded, activeData?.proxy?.code]);

  if (!content?.isLoaded || !benefits) {
    return;
  }

  return (
    <div className={classNames("benefits", { "benefits--dark": isThemeDark })}>
      <Container>
        <div className="benefits__title">{title}</div>
        <div className="benefits__container">
          {benefits.map(({ id, text: title, description, btnType, btnText }) => (
            <BenefitItem
              key={id}
              title={title}
              description={description}
              btnType={btnType}
              btnText={btnText}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};
