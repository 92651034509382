import { useEffect } from 'react';
import {useSelector} from "react-redux";

const usePaddle = () => {
  const { paddleConfig } = useSelector((state) => state.content);
  
  useEffect(() => {
    if (!paddleConfig || !paddleConfig.isEnabled) return;
    
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/paddle.js';
    script.async = true;

    script.onload = () => {
      paddleConfig.sandbox && window.Paddle.Environment.set('sandbox');
      window.Paddle.Setup({
        vendor: Number(paddleConfig.id),
        eventCallback: ({ event }) => {
          if (event === "Checkout.Complete") {
            setTimeout(() => {
              document.location.reload();
            }, 3000)
          }
        }
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [paddleConfig?.id]);
};

export default usePaddle;
