import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";
import { ApiService } from "../../../../../services/ApiService";

import { useDispatchedActions } from "../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { Button } from "../../../../../components/ui/Buttons/Button/Button";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { ButtonLoader } from "../../../../../components/ui/ButtonLoader/ButtonLoader";

export const Main = ({ controller }) => {
  const inputIPsRef = useRef();
  const inputKeyRef = useRef();
  // **Redux State
  const { main } = useTranslation().cabinet.documentation;

  // Dispatch
  const { setDocumentationError, fetchApiData, fetchNewApiKey } =
    useDispatchedActions();

  // **Redux state
  const { apiData } = useSelector((state) => state.cabinet);

  const [isLoading, setIsLoading] = useState(false);
  const [whiteIps, setWhiteIps] = useState("");

  const generateKey = () => {
    if (!apiData?.data?.secretKey) {
      fetchNewApiKey();
    } else {
      setDocumentationError("changeKey");
    }
  };

  const handlerSave = () => {
    const ips = inputIPsRef.current.value;

    if (ips === whiteIps) {
      return;
    }

    let check = true;
    if (!apiData?.data?.secretKey) {
      setDocumentationError("key");
      check = false;
    }
    if (ips.length > 0 && check) {
      const temp = ips.split(",");
      if (temp.length > 3) {
        setDocumentationError("amount");
        check = false;
      } else {
        temp.forEach((item) => {
          const checkIp =
            /^([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])$/.exec(
              item.trim()
            );
          if (!checkIp) {
            setDocumentationError("ip");
            check = false;
          }
        });
      }
    }
    if (check) {
      setIsLoading(true);

      ApiService.saveSettingsUserAPI({
        secretKey: apiData?.data?.secretKey,
        accessIpAddresses: ips ? ips.split(",") : null
      }).then((req) => {
        setWhiteIps(ips);
        if (req?.data) {
          setDocumentationError("success");
        }
      }).finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    fetchApiData(controller);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiData?.isLoaded && !apiData?.fetchError) {
      const ips = apiData?.data?.accessIpAddresses
        ? apiData?.data?.accessIpAddresses.join(",")
        : "";

      setWhiteIps(ips);
      inputIPsRef.current.value = ips;

      inputKeyRef.current.value = apiData?.data?.secretKey
        ? apiData?.data?.secretKey
        : "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData?.data]);

  return (
    <div className="documentation__contain documentation__contain-mb">
      <ul className="documentation__actions">
        <li className="documentation__actions__item">
          <div className="documentation__title">API KEY</div>
          <div className="documentation__row">
            <input
              className="documentation__input"
              readOnly={true}
              ref={inputKeyRef}
              placeholder={main?.placeholderKey}
            />
            <Button
              tab="confirm"
              onClick={generateKey}
            >
              {main?.button?.generateKey}
            </Button>
          </div>
          <div className="documentation__descriptions ">
            <IconSvg tag={"info"} className="info-icon" />
            <div>{main?.descriptions?.key}</div>
          </div>
        </li>
        <li className="documentation__actions__item">
          <div className="documentation__title">{main.whiteIp}</div>
          <div className="documentation__row">
            <input
              ref={inputIPsRef}
              className="documentation__input"
              placeholder={main?.placeholderIP}
            />
            <Button
              tab="confirm"
              onClick={handlerSave}
              disabled={isLoading}
            >
              { isLoading ? <ButtonLoader /> : main?.button?.save }
            </Button>
          </div>
          <div className="documentation__descriptions ">
            <IconSvg tag={"info"} className="info-icon" />
            <div className="info-box">
              <span> {main?.descriptions?.infoOne}</span>{" "}
              <span> {main?.descriptions?.infoTwo}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
