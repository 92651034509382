import { useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../../../../utils/constants/icons/iconSvg.constant";
import { CustomModal } from "../../../../../../../components/ui/CustomModal/CustomModal";
import { CustomTextArea } from "../../../../../../../components/ui/CustomTextArea/CustomTextArea";
import { Button } from "../../../../../../../components/ui/Buttons/Button/Button";
import { useDispatchedActions, useTranslation } from "../../../../../../../hooks";
import { successToast, warningToast } from "../../../../../../../utils/helpers/customToast.helper";
import { ipPattern } from "../../../../../../../utils/constants/IpPattern";
import "./RenewalByList.scss";

export const RenewalByList = ({ allIpsByProxyType, setInitFormValues }) => {
  const { lang } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValueIpList, setInputValueIpList] = useState("");

  const { setSelectedRows } = useDispatchedActions();
  const {
    buttons: { confirm, cancel },
    cabinet: { orders: { autoExtending: {
      title, description, placeholder, noMatches, allFound, partFound
    } } }
  } = useTranslation();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleKeyPress = (e) => {
    if (e.key !== "Enter") {
      const allowedKeys = /^[0-9.:\s]$/;
      if (!allowedKeys.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  const handleChangeInputListIp = (value) => {
    const filteredValue = value.replace(/[^0-9.:\s]/g, "");
    setInputValueIpList(filteredValue);
  };

  const isValidIP = (ip) => ipPattern.test(ip);

  const validateIpList = () => {
    const validatedIps = Array.from(
      new Set(
        inputValueIpList
          .split(/[,\n\s]+/)
          .map(
            (line) =>
              line
                .trim()
                .replace(/^[.:]+|[.:]+$/g, "")
                .replace(/\s+/g, " ")
          )
          .filter((line) => line.length > 0 && isValidIP(line))
      )
    );

    return validatedIps;
  };

  const removeIpsFromString = (inputString, ipsToRemove) => {
    let cleanedString = inputString;

    ipsToRemove.forEach((ip) => {
      const regex = new RegExp(`\\b${ip}\\b\\s*`, "g");
      cleanedString = cleanedString.replace(regex, "\n");
    });

    cleanedString = cleanedString.trim().replace(/\s{2,}/g, "\n");
    return cleanedString.trim()
      ? `${cleanedString }\n\n\n${ ipsToRemove.join("\n")}`
      : ipsToRemove.join("\n");
  };

  const handleSubmit = () => {
    const startLengthIpList = inputValueIpList.trim().split(/\s+/).filter(Boolean).length;

    const validatedIps = validateIpList();
    const ordersIp = [];
    const foundIpsWithPorts = [];

    if (validatedIps.length === 0) {
      warningToast(noMatches, lang);
      return;
    }

    // eslint-disable-next-line no-undef
    const allOrdersMap = new Map();

    allIpsByProxyType.forEach((order) => {
      if (!allOrdersMap.has(order.ip)) {
        allOrdersMap.set(order.ip, []);
      }
      allOrdersMap.get(order.ip).push(order);
    });

    validatedIps.forEach((ipWithPort) => {
      const [ip, port] = ipWithPort.split(":");

      if (port) {
        const matchedOrders = allIpsByProxyType.filter(
          (order) => order.ip === ip && (order.portHttp === port || order.portSocks === port)
        );

        if (matchedOrders.length > 0) {
          ordersIp.push(matchedOrders[0]);
          foundIpsWithPorts.push(ipWithPort);
        }
      } else {
        const matchedOrders = allOrdersMap.get(ip);
        if (matchedOrders) {
          ordersIp.push(...matchedOrders);
          foundIpsWithPorts.push(ip);
        }
      }
    });

    if (ordersIp.length > 0) {
      setSelectedRows(ordersIp.map((orderIp) => orderIp.id));

      if (ordersIp.length === startLengthIpList) {
        successToast(allFound.replace("-found-", ordersIp.length), lang);
        setInitFormValues();
        closeModal();
        setInputValueIpList("");
      } else {
        successToast(
          partFound
            .replace("-found-", ordersIp.length)
            .replace("-entered-", startLengthIpList),
          lang
        );
        setInitFormValues();
        closeModal();
        const cleanedString = removeIpsFromString(inputValueIpList, foundIpsWithPorts);
        setInputValueIpList(cleanedString);
      }
    } else {
      warningToast(noMatches, lang);
    }
  };

  const isInputValueTooShort = inputValueIpList.trim().length < 7;

  return (
    <div className="renewal-by-list">
      <div className="renewal-by-list__heading" onClick={() => setIsModalOpen(true)}>
        <IconSvg tag="renewalIcon" className="renewal-by-list__icon" />
        <div className="renewal-by-list__title">{title}</div>
      </div>
      {createPortal(
        <CustomModal open={isModalOpen} onClose={closeModal}>
          <div className="renewal-by-list__modal">
            <div className="renewal-by-list__modal-title">{title}</div>
            <div className="renewal-by-list__modal-description">{description}</div>
            <CustomTextArea
              className="edit-notes__textarea"
              placeholder={placeholder}
              value={inputValueIpList}
              onChange={handleChangeInputListIp}
              onKeyPress={handleKeyPress}
            />
            <div className="renewal-by-list__modal-actions">
              <Button onClick={closeModal}>{cancel}</Button>
              <Button
                onClick={handleSubmit}
                tab={isInputValueTooShort ? "disabled" : "confirm"}
                disabled={isInputValueTooShort}
              >
                {confirm}
              </Button>
            </div>
          </div>
        </CustomModal>,
        document?.body
      )}
    </div>
  );
};
