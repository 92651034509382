export const setExpireTime = (delay) => {
  if (typeof delay !== "number") {
    return;
  }
  
  const delayInMilliseconds = delay * 1000;
  const nowTime = Date.now();
  
  const expireTime = (nowTime + delayInMilliseconds).toString();
  localStorage.setItem("expireTime", expireTime);
};
