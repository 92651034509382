import DocumentationPage from "../../pages/DocumentationPage/DocumentationPage";
import ContactsPage from "../../pages/ContactsPage/ContactsPage";
import FeedbackPage from "../../pages/FeedbackPage/FeedbackPage";
import WebMoney from "../../components/common/WebMoney/WebMoney";
import PerfectMoney from "../../components/common/PerfectMoney/PerfectMoney";

export const otherRoutes = (ssr = false) => [
  {
    isSsr: ssr ?? false,
    path: "/:lang/documentation-api/",
    tag: "documentation",
    metaData: "metaDateDocumentation",
    title: "Documentation page",
    description: "Documentation page",
    component: ssr
      ? DocumentationPage
      : () => import("../../pages/DocumentationPage/DocumentationPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/contacts/",
    tag: "contacts",
    metaData: "metaDateFeedback",
    title: "Contacts page",
    description: "Contacts page",
    component: ssr
      ? ContactsPage
      : () => import("../../pages/ContactsPage/ContactsPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/feedback/",
    tag: "feedback",
    metaData: "metaDateReviews",
    title: "Feedback page",
    description: "Feedback page",
    component: ssr
      ? FeedbackPage
      : () => import("../../pages/FeedbackPage/FeedbackPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/process/payment/webmoney/:paymentId",
    tag: "webmoney",
    metaData: "metaDateWebmoney",
    title: "Webmoney page",
    description: "Webmoney page",
    component: ssr
      ? WebMoney
      : () => import("../../components/common/WebMoney/WebMoney")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/process/payment/perfectMoney/:paymentId",
    tag: "perfectMoney",
    metaData: "metaDatePerfectMoney",
    title: "PerfectMoney page",
    description: "PerfectMoney page",
    component: ssr
      ? PerfectMoney
      : () => import("../../components/common/PerfectMoney/PerfectMoney")
  }
];
