import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PriceItemTemplate } from "../PriceItemTemplate/PriceItemTemplate";
import { DaysSelect } from "../../../../../../components/ui/DaysSelect/DaysSelect";
import { QuantitySelect } from "../../../../../../components/ui/QuantitySelect/QuantitySelect";
import { getInitialQuantity } from "../../../../../../utils/helpers/getInitialQuantity";
import { calculatePrice, inputCorrection } from "../../../../../../utils/helpers";
import { useDebounceCallback, useDispatchedActions, useTranslation } from "../../../../../../hooks";
import { getRentPeriodsUpdated } from "../../../../../../utils/helpers/getRentPeriodsUpdated.helper";
import { PROXY_TYPES } from "../../../../../../utils/constants";
import "./UserQuantity.scss";
import { isValidQuantity } from "../../../../../../utils/helpers/isValidQuantity";
import { getQuantityWarningMessage } from "../../../../../../utils/helpers/getQuantityWarningMessage";

export const UserQuantity = ({ proxyCode, localCountry, controller, packageCountry }) => {
  const { lang } = useParams();
  const { activeData, rentPeriods } = useSelector((state) => state.content);
  const { setConfigModal } = useDispatchedActions();

  const {
    formPlaceholders: { rentPeriod: rentPeriodLabel },
    prices: { priceItem: {
      price: priceLabel,
      pcs,
      yourQuantity
    } }
  } = useTranslation();

  const currentRentPeriods = getRentPeriodsUpdated(rentPeriods.data[proxyCode], proxyCode);
  const getInitialRentPeriodId = () => currentRentPeriods.en[0]?.id || null;

  const [price, setPrice] = useState({});
  const [isPriceLoading, setIsPriceLoading] = useState(false);
  const [rentPeriodId, setRentPeriodId] = useState(getInitialRentPeriodId());
  const [quantity, setQuantity] = useState(getInitialQuantity(proxyCode));
  const [debouncedQuantity, setDebouncedQuantity] = useState(getInitialQuantity(proxyCode));

  const debounceTime = 700;

  const rentPeriod = currentRentPeriods[lang].find((rentPeriod) => rentPeriod.id === rentPeriodId);
  const isEuropeSelected = proxyCode === PROXY_TYPES.ipv4.code && localCountry?.code === "EUROPE";

  const debouncedSetPrice = useDebounceCallback((currentQuantity) => {
    if (currentQuantity === quantity) {
      return;
    }

    if (!currentQuantity) {
      setDebouncedQuantity(currentQuantity);
      setPrice({});
      return;
    }

    if (!isValidQuantity(proxyCode, currentQuantity)) {
      getQuantityWarningMessage(proxyCode, lang);
      setPrice({});
    }

    setDebouncedQuantity(currentQuantity);
  }, debounceTime);

  useEffect(() => {
    if (!localCountry.id || !isValidQuantity(proxyCode, quantity)) {
      return;
    }

    const params = {
      countryId: isEuropeSelected ? packageCountry?.id : localCountry?.id,
      currencyId: activeData?.currency?.id,
      count: +debouncedQuantity,
      rentPeriodId,
      proxyType: proxyCode
    };

    calculatePrice(setPrice, params, lang, setIsPriceLoading, controller);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.currency?.id, localCountry.id, proxyCode, rentPeriodId, debouncedQuantity]);

  useEffect(() => {
    setQuantity(getInitialQuantity(proxyCode));
    setDebouncedQuantity(getInitialQuantity(proxyCode));
  }, [proxyCode]);

  const inputHandler = (e) => {
    if (isPriceLoading) {
      return;
    }

    inputCorrection(e, setQuantity);
    debouncedSetPrice(e.target.value);
  };

  const rentPeriodHandler = (data) => {
    setRentPeriodId(data.id);
  };

  const handleBuyProxy = () => {
    if (isPriceLoading) {
      return;
    }

    setConfigModal({
      goalCode: "",
      quantity,
      days: rentPeriod,
      country: isEuropeSelected ? packageCountry : localCountry,
      proxyType: proxyCode,
      prices: price,
      activeEurope: isEuropeSelected
      // isQuantityNotCurrent: proxyCode === "IPv6" && +quantity < 10
    });
  };

  return (
    <PriceItemTemplate handler={handleBuyProxy} isLoading={(isPriceLoading && price.usdPrice)}>
    <div className="user-quantity">
        <div className="user-quantity__container">
          <span className="user-quantity__title">{yourQuantity}</span>
          <span className="user-quantity__price-per-one">
            {/*{*/}
            {/*  (!price.usdPrice || isPriceLoading)*/}
            {/*  ? <Skeleton width={50} height={14} rounded="2px" />*/}
            {/*  : `$${price.usdPrice} /${pcs}`*/}
            {/*}*/}
            {price.usdPrice ? `$${price.usdPrice} /${pcs}` : `— /${pcs}`}
          </span>
        </div>
        <div className="user-quantity__params-container">
          <div className="user-quantity__quantity">
            <QuantitySelect
              quantity={quantity}
              inputHandler={inputHandler}
            />
          </div>
          <div className="user-quantity__days">
            <DaysSelect
              selectedRentPeriod={rentPeriod}
              proxyCode={proxyCode}
              onChange={rentPeriodHandler}
              label={rentPeriodLabel}
              isLoading={isPriceLoading}
            />
          </div>
        </div>
        <div className="user-quantity__price-container">
          <span className="user-quantity__label">{priceLabel}</span>
          <span className="user-quantity__full-price">
            {/*{*/}
            {/*  (!price.usdAmount || isPriceLoading)*/}
            {/*    ? <Skeleton width={50} height={31.2} rounded="2px" />*/}
            {/*    : `$${price.usdAmount}`*/}
            {/*}*/}
            {price.usdAmount ? `$${price.usdAmount}` : "—"}
          </span>
        </div>
    </div>
    </PriceItemTemplate>
  );
};
