import { renderToStaticMarkup } from "react-dom/server";

import { FAVICON_LINKS } from "../constants/favicon-links";
import { HOME_URL } from "../constants/homeUrl";
import { LOCALS_LINK } from "../constants/locals-link";

import { getRobotContent } from "./getRobotContent.helper";

export const generateMetaTagsAndLinks = (title, description, language = "en", asString = false) => {
  const metaTags = [
    <html key="html" lang={language} />,
    <title key="title">{title || "Proxy IPv4"}</title>,
    <meta key="charset" charSet="utf-8"/>,
    <meta key="theme" name="theme-color" content="#ffffff"/>,
    <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1"/>,
    <meta key="description" name="description" content={description}/>,
    <meta key="name" itemProp="name" content={title}/>,
    <meta key="description2" itemProp="description" content={description}/>,
    <meta key="robots" name="robots" content={getRobotContent()}/>,
    <meta key="og:type" property="og:type" content="website"/>,
    <meta key="og:title" property="og:title" content={title}/>,
    <meta key="og:description" property="og:description" content={description}/>,
    <meta key="twitter:title" name="twitter:title" content={title}/>,
    <meta key="twitter:description" name="twitter:description" content={description}/>,
    <link key="manifest" rel="manifest" href={`${HOME_URL}/manifest.json`}/>,
    <meta key="msapplication" name="msapplication-TileColor" content="#da532c"/>,
    <meta key="theme-color" name="theme-color" content="#ffffff"/>,
    <meta key="customTags" name="customTags" content="#customHeaderTagsFromDB"/>
  ];

  const lightFaviconLinks = FAVICON_LINKS.map(({ id, rel, href, icons, media, type, sizes, color }) => (
    <link key={id} id={id} rel={rel} href={icons.light} media={media.light} type={type} sizes={sizes} color={color} />
  ))
  
  const darkFaviconLinks = FAVICON_LINKS.map(({ id, rel, href, icons, media, type, sizes, color }) => (
    <link key={id} id={id} rel={rel} href={icons.dark} media={media.dark} type={type} sizes={sizes} color={color} />
  ))

  const localLinks = LOCALS_LINK.map(({ rel, hrefLang, href }, i) => (
    <link key={`local-${i}`} rel={rel} hrefLang={hrefLang} href={href} />
  ));
  
  const allMetaTags = [...metaTags, ...lightFaviconLinks, ...darkFaviconLinks, ...localLinks];

  if (asString) {
    return renderToStaticMarkup(<>{allMetaTags}</>);
  }

  return allMetaTags;
};
