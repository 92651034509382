import {warningToast} from "./customToast.helper";
import {PROXY_TYPES} from "../constants";

const messages = {
  ru: {
    [PROXY_TYPES.ipv4.code]: "Минимальный заказ для прокси IPv4 – 1шт.",
    [PROXY_TYPES.ipv6.code]: "Минимальный заказ для прокси IPv6 – 10шт.",
    [PROXY_TYPES.isp.code]: "Минимальный заказ для прокси ISP – 1шт.",
    [PROXY_TYPES.mobile.code]: "Минимальный заказ мобильных прокси – 1 шт.",
  },
  en: {
    [PROXY_TYPES.ipv4.code]: "The minimum order for IPv4 proxy – 1 pcs.",
    [PROXY_TYPES.ipv6.code]: "The minimum order for IPv6 proxy – 10 pcs.",
    [PROXY_TYPES.isp.code]: "The minimum order for ISP proxy – 1 pcs.",
    [PROXY_TYPES.mobile.code]: "The minimum order for mobile proxy – 1 pcs.",
  }
};

export const getQuantityWarningMessage = (proxyType, lang) => {
  return warningToast(messages[lang][proxyType], lang)
}
