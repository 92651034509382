import ContentShimmer from "react-content-shimmer";
import { useSelector } from "react-redux";

export const TableRowLoader = () => {
  const { skin } = useSelector((state) => state.header);
  const { activeData } = useSelector((state) => state.content);

  const customColorFore = skin === "dark" ? "#555555" : "#eeeeee";
  const customColorBack = skin === "dark" ? "#333333" : "#cccccc";

  return (
    <tr className="table-body-tr">
      <td className="table-body-tr-item id">
        <ContentShimmer size={{ height: 24, width: 24 }} rounded="4px" foreground={customColorFore} background={customColorBack} />
      </td>
      <td className="table-body-tr-item ip-status">
        <ContentShimmer size={{ height: 12, width: 12 }} rounded="4px" foreground={customColorFore} background={customColorBack} />
      </td>
      <td className="table-body-tr-item ip">
        <ContentShimmer size={{ height: 62, width: 187 }} rounded="4px" foreground={customColorFore} background={customColorBack} />
      </td>
      {activeData?.proxy.code === "MOBILE" && (
        <td className="table-body-tr-item country">
          <ContentShimmer size={{ height: 18.5, width: 40 }} rounded="4px" foreground={customColorFore} background={customColorBack} />
        </td>
      )}
      <td className="table-body-tr-item https">
        <ContentShimmer size={{ height: 18.5, width: 40 }} rounded="4px" foreground={customColorFore} background={customColorBack} />
      </td>
      <td className="table-body-tr-item socks">
        <ContentShimmer size={{ height: 18.5, width: 40 }} rounded="4px" foreground={customColorFore} background={customColorBack} />
      </td>
      <td className="table-body-tr-item auto-renewal">
        <ContentShimmer size={{ height: 24, width: 107 }} rounded="4px" foreground={customColorFore} background={customColorBack} />

      </td>
      <td className="table-body-tr-item notes-content">
        <ContentShimmer size={{ height: 13, width: 90 }} rounded="4px" foreground={customColorFore} background={customColorBack} />
      </td>
    </tr>
  );
};
