import { Skeleton } from "../../../../../../../components/ui/Skeleton/Skeleton";
import "../PriceItemTemplate.scss";

export const PriceItemTemplateSkeleton = ({ children }) => (
    <div className="price-item-template">
      {children}
      <div className="price-item-template__skeleton-button">
        <Skeleton uniqueKey="price-item-template__skeleton-button" width={150} height={16} rounded="2px" />
      </div>
    </div>
  );
