import { useSelector } from "react-redux";
import { Container } from "../../ui/Container/Container";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";
import { useTranslation } from "../../../hooks";
import "./SSLSecure.scss";

export const SSLSecure = () => {
  const { skin } = useSelector((state) => state.header);

  const { main: { sslSecureHeader, sslSecureDescription } } = useTranslation();

  return (
    <Container>
      <div className="ssl-secure">
        <div className="ssl-secure__icon-container">
          <div className="ssl-secure__icon-shadow">
            <IconSvg tag="lock" className="ssl-secure__lock-svg" />
          </div>
        </div>
        <div className="ssl-secure__info">
          <div className="ssl-secure__title">{sslSecureHeader}</div>
          <div className="ssl-secure__description">{sslSecureDescription}</div>
        </div>
        <div className="ssl-secure__img">
          <IconSvg tag={skin === "dark" ? "sslSecureDark" : "sslSecureLight"} className="ssl-secure__ssl-svg" />
        </div>
      </div>
    </Container>
  );
};
