import MediaQuery from "react-responsive";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IconSvg } from "../../utils/constants/icons/iconSvg.constant";
import { Button } from "../../components/ui/Buttons/Button/Button";
import { useDispatchedActions, useTranslation } from "../../hooks";
import "./PaymentSuccessPage.scss";

export const PaymentSuccessPage = () => {
  const { lang } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.content);
  const { setLoginModal } = useDispatchedActions();

  const { paymentSuccessPage:
    {
      proxyActivation,
      thanksForPayment,
      orderDetails,
      cabinetMessage,
      personalArea,
      ourAdvantages,
      securityAdvantage,
      supportAdvantage,
      privacyAdvantage,
      addFeedback,
      feedbackMessage,
      postReview
    } } = useTranslation();

  const loginFormHandler = () => {
    if (!user?.isAuth) {
      setLoginModal(true);

      return;
    }

    navigate(`/${lang}/dashboard/?tab=profile`);
  };

  const advantages = [
    { title: securityAdvantage, iconTag: "advantagesSecurity" },
    { title: supportAdvantage, iconTag: "advantagesSupport" },
    { title: privacyAdvantage, iconTag: "advantagesPrivacy" }
  ];

  const socials = [
    { title: "TrustPilot", iconTag: "sendPlane", buttonTitle: postReview, href: "https://www.trustpilot.com/" },
    { title: "TriBot", iconTag: "sendPlane", buttonTitle: postReview, href: "https://www.google.com/" },
    { title: "Facebook", iconTag: "sendPlane", buttonTitle: postReview, href: "https://www.facebook.com/" },
    { title: "BlackHatWorld", iconTag: "sendPlane", buttonTitle: postReview, href: "https://www.blackhatworld.com/" }
  ];

  return (
    <section className="success-page container">
      <div className="success-page__gratitude">
        <IconSvg tag="cartSuccess" className="success-page__cart-icon" />
        <div className="success-page__title">{thanksForPayment}</div>
        <p className="success-page__description">
          {orderDetails}
        </p>
        <div className="success-page__activation-info">
          <IconSvg tag="gratitudeArrow" className="success-page__activation-icon" />
          <span className="success-page__activation-text">
            {proxyActivation}
          </span>
        </div>
      </div>
      <div className="success-page__cabinet">
        <div className="success-page__cabinet-container">
          <div className="success-page__cabinet-description">
            <div className="success-page__info-icon">
              <IconSvg tag="cabinetInfo" />
            </div>
            <p>{cabinetMessage}</p>
          </div>
          <Button tab="confirm" onClick={loginFormHandler}>
            {personalArea}
          </Button>
        </div>
      </div>
      <div className="success-page__advantages">
        <div className="success-page__advantages-title">
          {ourAdvantages}
        </div>
        <ul className="success-page__cards">
          {advantages.map(({ title, iconTag }) => (
            <li key={iconTag} className="success-page__card">
              <div className="success-page__advantages-icon">
                <IconSvg tag={iconTag} />
              </div>
              <div>{title}</div>
            </li>
          ))}
        </ul>
      </div>
      <div className="success-page__reviews">
        <div className="success-page__reviews-info">
          <div className="success-page__reviews-title">{addFeedback}</div>
          <div className="success-page__reviews-description">
            {feedbackMessage}
          </div>
        </div>
        <div className="success-page__socials-container">
          <ul className="success-page__socials">
            {socials.map(({ title, iconTag, buttonTitle, href }) => (
              <li key={title} className="success-page__social">
                <span className="success-page__social-title">{title}</span>
                <a href={href} target="_blank" rel="noreferrer">
                  <Button tab="add-feedback">
                    <MediaQuery minWidth={768}>
                      {buttonTitle}
                    </MediaQuery>
                    <MediaQuery maxWidth={767}>
                      <IconSvg tag={iconTag} />
                    </MediaQuery>
                  </Button>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
