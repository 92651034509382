// UI
import { LoginModal, RecoveryModal, RegisterModal } from "./components";

import "./Modals.scss";

export const Modals = () => (
  <>
    <LoginModal />
    <RegisterModal />
    <RecoveryModal />
  </>
);
