/* eslint-disable quotes */
export const DataMobile = [
    {
        title: {
            ru: "Получить список доступных мобильных тарифов",
            en: "To get the list of available mobile tariffs"
        },
        description: false,
        request: {type: "GET", url: "/client-api/v1/<apiKey>/get/tariffs"},
        responseBody: {
            success: true,
            user: "client@gmail.com",
            balance: 13.09,
            currency: "USD",
            tariffs: [
                {
                    country: "GBR",
                    mobileOperator: [
                        {
                            name: "Three",
                            rotationTime: [0, 5, 10]
                        },
                        {
                            name: "O2",
                            rotationTime: [5, 10]
                        }
                    ]
                },
                {
                    country: "USA",
                    mobileOperator: [
                        {
                            name: "AT&T Wireless",
                            rotationTime: [0, 5, 10]
                        }
                    ],
                }
            ]
        },
        responseParams: {
                tariffs: {
                    ru: "список тарифов",
                    en: "the list of tariffs"},
                country: {
                    ru: "альфа3 код страны",
                    en: "alfa3 country code"
                },
                mobileOperator: {
                    ru: "список доступных мобильных операторов для этой страны",
                    en: "the list of available operators for this country"
                },
                rotationTime: {
                    ru: "доступные периоды обновления IP адреса в мин. 0 - это обновление по ссылке в любой момент",
                    en: "available rotation times of IP address in minutes. 0 is rotation by link at any moment"
                }
        }
    },
    {
        title: {
            ru: "Получить список купленных mobile прокси",
            en: "the list of active mobile proxies"
        },
        description: false,
        request: {type: "GET", url: "/client-api/v1/<apiKey>/get/proxies"},
        requestParams: {
            proxyType: {value: "mobile",
                ru: "тип прокси",
                en: "type of proxy"
            }
        },
        requestExample: {url: "/client-api/v1/<apiKey>/get/proxies?proxyType=mobile", body: null},
        responseBody: {
            success: true,
            user: "client@gmail.com",
            balance: 13.09,
            currency: "USD",
            proxyType: "mobile",
            countOfProxy: 2,
            proxies: [
                {
                    id: "id1",
                    ip: "61.62.63.64",
                    country: "FRA",
                    dateStart: "2022-08-30T14:54:25.414+00:00",
                    dateEnd: "2022-09-29T23:59:25.414+00:00",
                    httpsPort: "7781",
                    socks5Port: "8891",
                    mobileOperator: "freemobilesas_france",
                    rotationTime: 0,
                    rebootLink: "https://proxy-ipv4.com/modem/reboot/448eac96-8546-4389-8f56-54b2sb4798d12",
                    authInfo: {
                        login: "authLogin123",
                        password: "authPassword"
                    }
                },
                {
                    id: "id2",
                    ip: "61.62.63.64",
                    country: "FRA",
                    dateStart: "2022-08-30T14:54:25.414+00:00",
                    dateEnd: "2022-09-29T23:59:25.414+00:00",
                    httpsPort: "7782",
                    socks5Port: "8892",
                    mobileOperator: "freemobilesas_france",
                    rotationTime: 0,
                    rebootLink: "https://proxy-ipv4.com/modem/reboot/44cf7d39-b682-4296-8909-7038ba99008b5",
                    authInfo: {
                        login: "authLogin123",
                        password: "authPassword"
                    }
                }
            ]
        },
        responseParams: {
            proxies: {
                ru: "список активных прокси",
                en: "the list of active proxies"
            },
            id: {
                ru: "ID прокси. Этот параметр необходим для продления прокси",
                en: "proxy ID. This parameter is needed for extending proxy"
            },
            country: {
                ru: "альфа3 код страны",
                en: "alfa3 country code"
            },
            dateStart: {
                ru: "дата начала аренды",
                en: "the rent start date"
            },
            dateEnd: {
                ru: "дата окончания аренды",
                en: "the rent end date"
            },
            rotationTime: {
                ru: "время обновления IP адреса в мин. 0 - это обновление по ссылке в любой момент",
                en: "the rotation time of IP address in minutes. 0 is rotation by link at any moment"
            },
            rebootLink: {
                ru: "ссылка для обновления ip",
                en: "the link for rotation IP"
            },
            authInfo: {
                ru: "данные для авторизации",
                en: "the data for authorization"
            }
        }
    },
    {
        title: {
            ru: "Вычислить цену для покупки",
            en: "Calculate purchase price"
        },
        description: false,
        request: {type: "GET", url: "/client-api/v1/<apiKey>/get/order/price"},
        requestParams: {
            proxyType: {value: "mobile",
                ru: "тип прокси",
                en: "type of proxy"
            },
            days: {
                ru: "количество дней для аренды (доступные значения можно получить другим запросом)",
                en: "the number of rental days (available values can be got by other request)"
            },
            goal: {
                ru: "цель использования прокси (доступные значения можно получить другим запросом)",
                en: "goal of using proxy (available values can be got by other request)"
            },
            customGoal: {
                ru: "если в доступных на сайте целей не будет того что вам нужно, вы можете написать свою цель. Длинна поля должна быть 5 или больше символов (в запросе обязательно должен быть один из параметров - goal или customGoal)",
                en: "if the goal you need is not present in the goal list, you can indicate your own goal. Length of field must be 5 characters or more (one of the parameters - goal or customGoal - must be in the request)"
            },
            country: {
                ru: "код страны в формате alpha3 (доступные значения можно получить другим запросом)",
                en: "alfa3 country code (available values can be got by other request)"
            },
            count: {
                ru: "количество прокси",
                en: "the number of proxies"
            },
            mobileOperator: {
                ru: "мобильный оператор (доступные значения можно получить другим запросом)",
                en: "the mobile operator (available values can be got by other request)"
            },
            rotationTime: {
                ru: "время обновления IP адреса (доступные значения можно получить другим запросом)",
                en: "the rotation time of IP address in minutes (available values can be got by other request)"
            }
        },
        requestExample: {
            url: "/client-api/v1/<apiKey>/get/order/price?proxyType=mobile&days=7&goal=instagram&country=FRA&count=1&mobileOperator=freemobilesas_france&rotationTime=5",
            body: null
        },
        responseBody: {
            success: true,
            user: "client@gmail.com",
            balance: 13.09,
            currency: "USD",
            proxyType: "mobile",
            rotationTime: 5,
            mobileOperator: "freemobilesas_france",
            days: 7,
            count: 1,
            goal: "instagram",
            amount: 1.78,
            price: 1.78
        },
        responseParams: {
            amount: {
                ru: "общая стоимость заказа",
                en: "total amount"
            },
            price: {
                ru: "цена за 1 прокси",
                en: "price for one proxy"
            }
        }
    },
    {
        title: {
            ru: "Вычислить цену для продления",
            en: "Calculate extending price"
        },
        description: false,
        request: {type: "GET", url: "/client-api/v1/<apiKey>/get/extend/price"},
        requestParams: {
            proxyType: {value: "mobile",
                ru: "тип прокси",
                en: "type of proxy"
            },
            days: {
                ru: "количество дней для продления (доступные значения те же, что и для покупки)",
                en: "the number of renewal days (the available values are the same as for the purchase)"
            },
            IpId: {
                ru: "ID прокси, которые необходимо продлить (разрешается указывать несколько ID через запятую)",
                en: "proxy IDs that require renewal (it is allowed to indicate several IDs separated by commas)"
            }
        },
        requestExample: {
            url: "/client-api/v1/<apiKey>/get/extend/price?proxyType=mobile&days=7&IpId=id1,id2,id3",
            body: null
        },
        responseBody: {
            success: true,
            user: "client@gmail.com",
            balance: 13.09,
            currency: "USD",
            proxyType: "mobile",
            days: 7,
            count: 3,
            amount: 9.13,
            price: 3.04,
            ipId: ["id1", "id2", "id3"]
        },
        responseParams: {
            amount: {
                ru: "общая стоимость",
                en: "total amount"
            },
            price: {
                ru: "цена за продление одного прокси",
                en: "price for extending one proxy"
            },
            ipId: {
                ru: "список ID прокси для продления",
                en: "the list of proxy IDs for extending"
            }
        }
    },
    {
        title: {
            ru: "Покупка прокси",
            en: "Placing an order"
        },
        description: false,
        request: {type: "POST", url: "/client-api/v1/<apiKey>/order"},
        requestParams: {
            proxyType: {value: "mobile",
                ru: "тип прокси",
                en: "type of proxy"
            },
            days: {
                ru: "количество дней для аренды (доступные значения можно получить другим запросом)",
                en: "the number of rental days (available values can be got by other request)"
            },
            goal: {
                ru: "цель использования прокси",
                en: "goal of using proxy" ,
            },
            country: {
                ru: "альфа3 код страны",
                en: "alfa3 country code"
            },
            count: {
                ru: "количество прокси",
                en: "the number of proxies"
            },
            authType: {
                value: "login/ip",
                ru: "тип авторизации",
                en: "authorization type"
            },
            authIp: {
                ru: "IP адрес авторизации. Это поле является обязательным только если authType = ip",
                en: "authorization IP address. This field is required only if authType = ip"
            },
            mobileOperator: {
                ru: "мобильный оператор (доступные значения можно получить другим запросом)",
                en: "the mobile operator (available values can be got by other request)"
            },
            rotationTime: {
                ru: "время обновления IP адреса (доступные значения можно получить другим запросом)",
                en: "the rotation time of IP address in minutes (available values can be got by other request)"
            },
        },
        requestExample: {
            url: "/client-api/v1/<apiKey>/order",
            body: {
                proxyType: "mobile",
                days: 30,
                goal: "instagram",
                country: "FRA",
                count: 1,
                authType: "login",
                mobileOperator: "freemobilesas_france",
                rotationTime: 5
            }
        },
        responseBody: {
            success: true,
            user: "client@gmail.com",
            balance: 4.09,
            currency: "USD",
            proxyType: "mobile",
            orderNumber: "DV-21x1552356-jA"
        },
        responseParams: {
            orderNumber: {
                ru: "номер заказа",
                en: "order number"
            },
            balance: {
                ru: "сумма на балансе после покупки",
                en: "the balance after the purchase"
            }
        }
    },
    {
        title: {
            ru: "Продление прокси",
            en: "Extending proxy"
        },
        description: false,
        request: {type: "POST", url: "/client-api/v1/<apiKey>/extend"},
        requestParams: {
            proxyType: {value: "mobile",
                ru: "тип прокси",
                en: "type of proxy"
            },
            days: {
                ru: "количество дней для продления (доступные значения те же, что и для покупки)",
                en: "the number of renewal days (the available values are the same as for the purchase)"
            },
            IpId: {
                ru: "список ID прокси, которые нужно продлить",
                en: "the list of proxy IDs to be extended"
            },
        },
        requestExample: {
            url: "/client-api/v1/<apiKey>/extend",
            body: {
                proxyType: "mobile",
                days: 30,
                IpId: ["id1", "id2"]
            }
        },
        responseBody: {
            success: true,
            user: "client@gmail.com",
            balance: 4.09,
            currency: "USD"
        },
        responseParams: {
            balance: {
                ru:"сумма на балансе после продления",
                en:"the balance after extending"
            }
        }
    }
];
