import { FormProvider, useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { errorToast, successToast } from "../../../../utils/helpers/customToast.helper";
import { Button } from "../../../../components/ui/Buttons/Button/Button";
import { ModalSelect } from "../../../../components/ui/ModalSelect/ModalSelect";
import { ApiService } from "../../../../services/ApiService";
import { useTranslation } from "../../../../hooks";
import "./GoalClarifyingNew.scss";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import { classNames } from "../../../../utils/helpers/classNames";

export const GoalClarifyingNew = ({ noProject }) => {
  const { lang } = useParams();
  const [searchParams] = useSearchParams();

  const socketHash = searchParams.get("order");

  const {
    orderProgressPage: {
      save,
      purposePlaceholder,
      goalSuccessSending,
      invalidGoal,
      proxyAlreadyEnabled,
      noProjectDescription
    }
  } = useTranslation();

  const methods = useForm({
    mode: "onSubmit"
  });

  const getErrorMessage = (error) => {
    const errorList = {
      "project_not_found": invalidGoal,
      "order_enabled": proxyAlreadyEnabled
    };

    const currentError = errorList[error] || "Something went wrong";

    return errorToast(currentError, lang);
  };

  const onSubmit = async (formData) => {
    try {
      const { asyncGoals: { value: projectCode } } = formData;

      const { data: { success, text: errorText }, status } = await ApiService.sendGoal({ socketHash, projectCode });

      if (status !== 200) {
        throw new Error();
      }

      if (success) {
        successToast(goalSuccessSending, lang);
        return;
      }

      getErrorMessage(errorText);

    } catch (e) {
      errorToast("Something went wrong", lang);
    }
  };

  return (
    <div
      className={classNames(
        "goal-clarifying__menu",
        { "goal-clarifying__menu--hide": !noProject }
      )}
    >
      <div className="goal-clarifying__description-container">
        <div className="goal-clarifying__description-icon"><IconSvg tag="cabinetInfo" /></div>
        <div className="goal-clarifying__description">
          {noProjectDescription}
        </div>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="goal-clarifying__form"
          noValidate={true}
        >
          <ModalSelect
            name="asyncGoals"
            rules={{
              required: true
            }}
            placeholder={purposePlaceholder}
            isAsync
            isSelectValid
            /* eslint-disable-next-line no-empty-function */
            setOptions={() => {}}
          />
          <Button initClass="goal-clarifying__submit-button" tab="confirm">{save}</Button>

        </form>
      </FormProvider>
    </div>
  );
};
