const getRightWord = (days, lang) => {
  if (lang === "ru") {
    if (days > 0 && days < 2) {
      return "день";
    }
    
    if (days >= 2 && days < 5) {
      return "дня";
    }
    
    if (days >= 5) {
      return "дней";
    }
    
    return "дней";
  }
  
  if (lang === "en") {
    if (days > 0 && days < 2) {
      return "day";
    }
    
    if (days >= 2) {
      return "days";
    }
    
    return "days";
  }
  return null;
};

export const getRentPeriodsUpdated = (fetchedDays, proxyType) => {
  const rentDays = fetchedDays.reduce((result, { days, id }) => {
    const ruLabel = getRightWord(days, "ru");
    const enLabel = getRightWord(days, "en");
    result.en.push({ id, value: `${days} ${enLabel}`, label: `${days} ${enLabel}`, days})
    result.ru.push({ id, value: `${days} ${ruLabel}`, label: `${days} ${ruLabel}`, days})
    
    return result;
  }, { en: [], ru: [] })
  
  if (proxyType && proxyType === "IPv6") {
    return {
      en: rentDays.en.filter((item) => item.days >= 30),
      ru: rentDays.ru.filter((item) => item.days >= 30),
    }
  }
  return rentDays;
};
