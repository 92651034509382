import { useState } from "react";
import { Button } from "../../../../../../components/ui/Buttons/Button/Button";
import { CustomModal } from "../../../../../../components/ui/CustomModal/CustomModal";
import { IconSvg } from "../../../../../../utils/constants/icons/iconSvg.constant";
import { ApiService } from "../../../../../../services/ApiService";
import { useDispatchedActions, useTranslation } from "../../../../../../hooks";
import { errorToast } from "../../../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../../../components/ui/ButtonLoader/ButtonLoader";

export const DeleteFolderModal = ({ isOpen, onClose, setFolders, currentFolder }) => {
  const {
    cabinet: { orders: { folders: { modals: { deleteNotification, cancel, remove } } } }
  } = useTranslation();

  const { setSelectedFolder } = useDispatchedActions();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      const currentFolderId = currentFolder?.value;
      const { status } = await ApiService.deleteFolder(currentFolderId);

      if (status !== 200) {
        throw Error();
      }

      setSelectedFolder({ value: null, label: null });
      setFolders((prev) => [...prev].filter((folder) => folder.id !== currentFolderId));
      onClose();
    } catch {
      errorToast("Bad request");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal open={isOpen} onClose={onClose}>
      <div className="folder-modal">
        <div className="folder-modal__trash-icon">
          <IconSvg tag="largeTrash" />
        </div>
        <div className="folder-modal__info">
          {deleteNotification}
          <div className="folder-modal__folder-name">
            {`"${currentFolder?.label}" ?`}
          </div>
        </div>
        <div className="folder-modal__actions">
          <Button tab="default" onClick={onClose}>
            {cancel}
          </Button>
          <Button tab="delete" onClick={handleDelete} disabled={isLoading}>
            { isLoading ? <ButtonLoader /> : remove }
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};
