import { useEffect, useState } from "react";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import { classNames } from "../../../../../../../../utils/helpers/classNames";
import { PROXY_TYPES } from "../../../../../../../../utils/constants";
import { useTranslation } from "../../../../../../../../hooks";
import { DropDown } from "./DropDown/DropDown";
import "./SortPrices.scss";

export const SortPrices = ({ initialValue, sortOptions, localProxyType, setSortParams }) => {
  const { prices: { sort } } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(initialValue);

  useEffect(() => {
    const currentChoose = sortOptions.find((option) => option.value === selected.value);

    if (currentChoose) {
      setSelected(currentChoose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOptions]);

  const selectSortOption = (option) => {
    setSelected(option);
    setSortParams(option.value);
  };

  useEffect(() => {
    switch (localProxyType) {
      case PROXY_TYPES.ipv6.code:
        selectSortOption(sortOptions[1]);
        break;

      case PROXY_TYPES.mobile.code:
        selectSortOption(sortOptions[0]);
        break;

      default:
        selectSortOption(sortOptions[0]);
    }

    return () => setSortParams(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localProxyType]);

  const closeSelect = () => {
    setIsOpen(false);
  };

  const openSelect = (event) => {
    event.stopPropagation();

    if (isOpen) {
      return;
    }

    setIsOpen(true);
  };

  return (
    <div className="sort-prices" onClick={openSelect}>
      <div className="sort-prices__select">
        <div className="sort-prices__head">
          {sort}
        </div>
        <div className="sort-prices__title">
          {selected.label}
        </div>
        <IconSvg
          tag="triangleDown12"
          className={classNames(
            "sort-prices__dropdown-indicator",
            { "sort-prices__dropdown-indicator--active": isOpen }
          )}
        />
      </div>
      { isOpen && (
        <DropDown
          selected={selected}
          setSelected={setSelected}
          closeSelect={closeSelect}
          sortOptions={sortOptions}
          setSortParams={setSortParams}
        />
      )}
    </div>
  );
};
