import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getRentPeriods } from "../utils/helpers/getRentPeriods.helper";

export const useCurrentRentPeriod = (proxyCode) => {

  // **Props
  const { lang } = useParams();

  // **Redux State
  const { rentPeriods } = useSelector((state) => state.content);

  // local State
  const [currentRentPeriods, setCurrentRentPeriods] = useState([]);
  useEffect(() => {
    if (proxyCode && rentPeriods?.isLoaded) {
      if (rentPeriods?.data[proxyCode]) {
        setCurrentRentPeriods(getRentPeriods(rentPeriods?.data[proxyCode], lang, proxyCode));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyCode, rentPeriods?.isLoaded, lang]);
  return currentRentPeriods;
};
