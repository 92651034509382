import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";
import { useTranslation } from "../../../hooks";
import "./Search.scss";

export const Search = (props) => {
  // **Hooks
  const { formPlaceholders } = useTranslation();
  // Props
  const { className, onChange, ...rest } = props;
  return (
    <div className={`search ${ className ? className : ""}`} >
      <input
        className="search-input"
        placeholder={formPlaceholders?.enterCountryName}
        onChange={onChange}
        {...rest}
      />
      <IconSvg tag="search" className="search-icon" />
    </div>
  );
};
