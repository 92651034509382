import { useSelector } from "react-redux";
import { useDispatchedActions } from "../../../../../hooks";
import { setTheme } from "../../../../../utils/helpers/setTheme";

export const ThemeTriger = () => {
  const { skin } = useSelector((state) => state.header);

  const { setDarckTheme } = useDispatchedActions();

  const initialImg = skin === "dark" ? "/img/ui/white-moon.svg" : "/img/ui/darck-sun.svg";

  const handleThemeClick = () => {
    const animation = document.querySelector(".index__video-container");
    if (animation) {
      animation.style.opacity = 0;
      setTimeout(() => {
        animation.style.opacity = 1;
      }, 300);
    }

    const theme = skin === "dark" ? "light" : "dark";

    setTheme(setDarckTheme, theme);
  };

  const onOver = ({ currentTarget }) => {
    currentTarget.src = skin === "dark" ? "/img/ui/light-sun.svg" : "/img/ui/dark-moon.svg";
  };

  const onLeave = ({ currentTarget }) => {
    currentTarget.src = initialImg;
  };

  return (
    <button onClick={handleThemeClick}>
      <img
        alt="theme button"
        src={initialImg}
        onMouseOver={onOver}
        onMouseLeave={onLeave}
        loading="lazy"
        width="20"
        height="20"
        // alt={logoAlt}
      />
    </button>
  );
};
