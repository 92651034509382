import { createSlice } from "@reduxjs/toolkit";

import { fetchPriceCalculationModal } from "./Modal.thunks";

const initialPrices = {
  amount: "-",
  usdAmount: "-",
  usdPrice: "-",
  price: "-",
  success: true,
  currencyCode: "",
  minPrice: null,
  priceMobileOperator: null,
  priceMobileRotation: null
}

const ModalReducer = createSlice({
  name: "modal",
  initialState: {
    country: {
      id: "",
      code: ""
    },
    modals: {
      isCountriesModalOpen: false,
      isNoCashModalOpen: false,
      isExtendingModalOpen: false,
      isCancelExtendingModalOpen: false
    },
    selectedOperator: null,
    selectedRotation: null,
    operatorWithMinPrice: null,
    rotationWithMinPrice: null,
    activeEurope: false,
    days: {},
    quantity: "",
    goalCode: "",
    isModalOpen: false,
    isCountryChoosed: false,
    isQuantityCurrent: true,
    isOwnGoalVisible: false,
    isBookmakersVisible: false,
    isIpVisible: false,
    isQuantityNotCurrent: false,
    isBalanceCheck: false,
    isBalancePaymentDataNotCurrent: false,
    prices: initialPrices,
    pricesError: null,
    isPricesLoaded: false,
    proxyType: "IPv4",
    modemRotationMin: [],
    mobileOperators: [],
    paramsForReload: {
      data: {},
      balance: 0,
      isSet: false
    },
    documentation: {
      isError: false,
      errorType: ""
    },
    modalNotAuthBalance: false,
    modalSelectBalance: false
  },
  reducers: {
    setModalCountriesOpen(state, action) {
      state.modals.isCountriesModalOpen = action.payload;
    },
    setModalOpen(state) {
      state.isModalOpen = true;
    },
    setModalNotAuthBalance(state) {
      state.modalNotAuthBalance = true;
      state.isModalOpen = true;
    },
    setModalSelectBalance(state, action) {
      state.modalSelectBalance = action.payload;
    },
    resetParamsForReload(state) {
      state.paramsForReload.data = {};
      state.paramsForReload.balance = 0;
      state.paramsForReload.isSet = false;
      state.isBalanceCheck = false;
    },
    setParamsForReload(state, action) {
      state.paramsForReload.data = action.payload.data;
      state.paramsForReload.balance = action.payload.balance;
      state.paramsForReload.isSet = true;
    },
    setIsBalanceCheck(state, action) {
      state.isBalanceCheck = action.payload;
    },
    setCountryModal(state, action) {
      state.country = action.payload;
    },
    setRentPeriod(state, action) {
      state.days = action.payload;
    },
    setQuantity(state, action) {
      state.quantity = action.payload;
    },
    setIpInput(state, action) {
      state.isIpVisible = action.payload.ip;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.isBalanceCheck = false;
      state.paramsForReload.data = {};
      state.paramsForReload.balance = 0;
      state.paramsForReload.isSet = false;
      state.documentation.isError = false;
      state.modalNotAuthBalance = false;
      state.documentation.errorType = "";
    },
    setPaymentSystem(state, action) {
      state.activePaymentSystem = action.payload;
    },
    openModal(state) {
      state.isBalancePaymentDataNotCurrent = true;
      state.isModalOpen = true;
    },
    setConfigModal(state, action) {
      state.operatorWithMinPrice = action.payload.operatorWithMinPrice
      state.rotationWithMinPrice = action.payload.rotationWithMinPrice
      state.country = action.payload.country;
      state.activeEurope = action.payload.activeEurope || false;
      state.isCountryChoosed = Boolean(action.payload.country.id);
      state.isQuantityNotCurrent =
        action.payload?.isQuantityNotCurrent || false;
      state.days = action.payload.days;
      state.quantity = action.payload.quantity;
      state.goalCode = action.payload.goalCode;
      state.prices = action.payload.prices;
      state.proxyType = action.payload.proxyType;
      state.isModalOpen = true;
      state.modemRotationMin = action.payload.modemRotationMin;
      state.mobileOperators = action.payload.mobileOperators;
      state.isBalancePaymentDataNotCurrent = false;
      state.selectedOperator = action.payload.selectedOperator;
      state.selectedRotation = action.payload.selectedRotation;
    },
    setConfigModalCabinet(state, action) {
      state.country = {
        id: "",
        code: ""
      };
      state.activeEurope = false;
      state.isCountryChoosed = false;
      state.isQuantityNotCurrent = true;
      state.days = {};
      state.quantity = "";
      state.goalCode = "";
      state.prices = {
        amount: "-",
        price: "-",
        success: true,
        currencyCode: "",
        minPrice: null,
        priceMobileOperator: null,
        priceMobileRotation: null
      };
      state.proxyType = action.payload;
      state.modemRotationMin = [];
      state.mobileOperators = [];
      state.isBalancePaymentDataNotCurrent = false;
    },
    setDocumentationError(state, action) {
      state.documentation.isError = true;
      state.documentation.errorType = action.payload;
      state.isModalOpen = true;
    },
    setIsExtendingModalOpen(state, action) {
      state.modals.isExtendingModalOpen = action.payload;
    },
    setIsCancelExtendingModalOpen(state, action) {
      state.modals.isCancelExtendingModalOpen = action.payload;
    },
    setIsNoCashModalOpen(state, action) {
      state.modals.isNoCashModalOpen = action.payload;
    },
    clearPrices(state) {
      state.prices = initialPrices;
    }
  },
  extraReducers: {
    [fetchPriceCalculationModal.fulfilled]: (state, action) => {
      state.prices = action.payload;
      state.isPricesLoaded = true;
    },
    [fetchPriceCalculationModal.pending]: (state) => {
      state.pricesError = null;
      state.isPricesLoaded = false;
    },
    [fetchPriceCalculationModal.rejected]: (state, action) => {
      state.pricesError = action.payload;
      state.isPricesLoaded = true;
    }
  }
});

export const ModalReducerActions = {
  ...ModalReducer.actions,
  fetchPriceCalculationModal
};
export default ModalReducer.reducer;
