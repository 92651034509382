/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
export const getCookieByPrefix = (prefix) => {
  const cookies = document.cookie.split(";");
  let searchKey = "";
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(prefix)) {
      const keyValue = cookie.split("=");
      const value = keyValue[0];
      if (value) {
        searchKey = value;
      }
    }
  }
  return searchKey;
};

export const getSessionData = (id) => {
  const regex = new RegExp(`${id}=GS\\d\\.\\d\\.(.+?)(?:;|$)`);
  const match = document.cookie.match(regex);
  const parts = match?.[1].split(".");

  if (!parts) {
    window.setTimeout(() => getSessionData(id), 200);
    return;
  }

  return parts.shift();
};

export const getG4Data = () => {
  let trueSessionId = "";
  let dataG4 = null;
  //   let encoded = "";
  try {
    if (!trueSessionId) {
      const searchKeyBycookie = getCookieByPrefix("_ga_");
      trueSessionId = getSessionData(searchKeyBycookie) || "";
    }
    dataG4 = {
      cid: gaGlobal?.vid,
      //   sid: trueSessionId,
      session_id: trueSessionId,
      //   ul: navigator.language || navigator.userLanguage,
      //   sr: `${screen.width}x${screen.height}`,
      screen_resolution: `${screen.width}x${screen.height}`,

      uaa: google_tag_data?.uach?.architecture,
      uab: parseInt(google_tag_data?.uach?.bitness),
      uafvl: google_tag_data?.uach?.fullVersionList
        .map(({ brand, version }) => `${brand};${version}`)
        .join("| "),
      uap: google_tag_data?.uach?.platform,
      uapv: google_tag_data?.uach?.platformVersion,
      //   cu: window.cur_currency || "USD",
      //   dl: document.location.href,
      //   dr: document.referrer || "",
      //   dt: document.title,
      page_location: document.location.href,
      page_referrer: document.referrer || "",
      page_title: document.title,
      affiliation: "Online Store"
      //   en: "purchase",
    };
    // encoded = Base64.encode(JSON.stringify(dataG4));
  } catch (e) {
    console.warn(e);
  }
  return dataG4;
  //   return encoded;
};
