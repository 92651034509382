import ContentShimmer from "react-content-shimmer";
import { useSelector } from "react-redux";
// className use style CabinetPage.scss
import "../../History.scss";
import MediaQuery from "react-responsive";
export const HistoryContentLoader = () => {
  const { skin } = useSelector((state) => state.header);
  const customColorFore = skin === "dark" ? "#555555" : "#eeeeee";
  const customColorBack = skin === "dark" ? "#333333" : "#cccccc";
  return (
    <div className="history__contain">
      <ul className="history__list">
        <MediaQuery minWidth={993}>
          <li key="header" className="history__list__item">
            <div className="history__list__item-contain-header">
              <div className="history__list__item-box">
                <div className="history__list__item-date ">
                  <ContentShimmer size={{ height: 20, width: 50 }} foreground={customColorFore} background={customColorBack} />
                </div>
                <div className="history__list__item-amount">
                  <ContentShimmer size={{ height: 20, width: 80 }} foreground={customColorFore} background={customColorBack} />
                </div>
              </div>
              <div className="history__list__item-status">
                <ContentShimmer size={{ height: 20, width: 180 }} foreground={customColorFore} background={customColorBack} />
              </div>
            </div>
          </li>
        </MediaQuery>
        {
          Array(3).fill(0).map((_, idx) => (
            <li key={idx} className="history__list__item">
              <div className="history__list__item-contain">
                <div className="history__list__item-box">
                  <div className="history__list__item-date">
                    <ContentShimmer size={{ height: 15, width: 100 }} foreground={customColorFore} background={customColorBack} />
                  </div>
                  <div className="history__list__item-amount">
                    <ContentShimmer size={{ height: 15, width: 55 }} foreground={customColorFore} background={customColorBack} />
                  </div>
                </div>
                <div className="history__list__item-status">
                  <ContentShimmer size={{ height: 15, width: 180 }} foreground={customColorFore} background={customColorBack} />
                </div>
              </div>
            </li>
          ))
        }
      </ul>
      <MediaQuery minWidth={993}>
        <div className="history__list__footer">
          <div className="pagination">
            <div className="pagination__list">
              <ContentShimmer size={{ height: 50, width: 50 }} rounded="5%" foreground={customColorFore} background={customColorBack} />
              <ContentShimmer size={{ height: 50, width: 50 }} rounded="5%" foreground={customColorFore} background={customColorBack} />
            </div>
          </div>
          <ContentShimmer size={{ height: 50, width: 175 }} rounded="5%" foreground={customColorFore} background={customColorBack} />
        </div>
      </MediaQuery>
    </div>
  );
};
