import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../../../components/ui/Buttons/Button/Button";
import { emailPattern } from "../../../../utils/constants/emailPattern";
import { ApiService } from "../../../../services/ApiService";
import { errorToast, successToast, warningToast } from "../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../components/ui/ButtonLoader/ButtonLoader";
import { useTranslation } from "../../../../hooks";
import { CustomInput } from "../../../../components/ui/CustomInput/CustomInput";

export const FeedbackForm = () => {
  const { lang } = useParams();

    const {
        contacts:
          { feedbackForm:
            {
              formTitle,
              nameField,
              emailField,
              messageField,
              submit,
              success,
              invalidEmail,
              invalidMessage
            } }
    } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const clearForm = () => {
        setName("");
        setEmail("");
        setMessage("");
    };

    const onSubmit = async () => {
        if (!emailPattern.test(email)) {
            warningToast(invalidEmail, lang);

            return;
        }

        if (message.length < 10) {
            warningToast(invalidMessage, lang);

            return;
        }

        try {
            setIsLoading(true);

            const { status } = await ApiService.sendFeedback({ name, email, message });

            if (status !== 200) {
                throw status;
            }

            successToast(success, lang);
            clearForm();
        } catch (e) {
            errorToast("Something went wrong");
        } finally {
            setIsLoading(false);
        }
    };

    return (
      <div className="contacts__form">
          <div className="contacts__form__title">{formTitle}</div>
          <div className="contacts__form__box-self-data">
              <CustomInput
                // className="contacts__form__input contacts__form__name"
                label={nameField}
                value={name}
                onChange={setName}
              />
              <CustomInput
                // className="contacts__form__input contacts__form__email"
                label={emailField}
                value={email}
                onChange={setEmail}
              />
          </div>
          <textarea
            className="contacts__form__input contacts__form__message"
            placeholder={messageField}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            tab="confirm"
            onClick={onSubmit}
            initClass="contacts__form__button"
            disabled={isLoading}
          >
              {isLoading ? <ButtonLoader /> : submit }
          </Button>
      </div>
    );
};
