import { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import { classNames } from "../../../../../../../../utils/helpers/classNames";
import { DropDown } from "./DropDown/DropDown";
import "./MobilePricesSelect.scss";

const MobilePricesSelect = ({ options, handlerSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (selectedOption) {
      const currentOption = options.find((option) => option.days === selectedOption.days);
      setSelectedOption(currentOption);

      return;
    }

    setSelectedOption(options[0]);
    handlerSelect(options[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    handlerSelect(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <>
      <div className="mobile-prices" ref={dropdownRef}>
        <div className="mobile-prices__container" onClick={handleToggleDropdown}>
          {selectedOption
            ? (
              <div className="mobile-prices__value">
                {selectedOption.label}
                &nbsp;
                <IconSvg tag="triangleDown12" className={classNames(
                  "mobile-prices__dropdown-indicator",
                  { "mobile-prices__dropdown-indicator--open": isOpen }
                )} />
              </div>
            )
            : (
              <div className="mobile-prices__placeholder">Select an option</div>
            )
          }
        </div>
        { isOpen && (
          createPortal(
            <DropDown
              options={options}
              handleOptionSelect={handleOptionSelect}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              dropdownRef={dropdownRef}
              selectedOption={selectedOption}
            />,
            document.getElementById("portal"))
        )}
      </div>
    </>
  );
};

export default MobilePricesSelect;
