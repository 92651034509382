import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useCurrentSeo } from "../../../../hooks/useCurrentSeo";
import { Container } from "../../../../components/ui/Container/Container";
import { Button } from "../../../../components/ui/Buttons/Button/Button";

import "./Seo.scss";
import { useTranslation } from "../../../../hooks";
import { classNames } from "../../../../utils/helpers/classNames";
import { useIsThemeDark } from "../../../../hooks/useIsThemeDark";

export const Seo = () => {
  // **Hooks
  const currentSeo = useCurrentSeo();
  const { main: { seo: { showAllButton } } } = useTranslation();
  // ** Redux State
  const { content } = useSelector((state) => state.content);

  // *Ref
  const refText = useRef(null);

  const isThemeDark = useIsThemeDark();

  // ** State
  const [showAll, setShowAll] = useState(false);
  const [heightText, setHeightText] = useState(0);

  const handlerShowAll = () => {
    setShowAll(true);
    setHeightText(refText?.current?.scrollHeight);
  };

  return (
    content?.isLoaded && (
      <section
        className={classNames("seo", { "seo--dark": isThemeDark })}
        id="seo"
      >
        <Container>
          <div
            className={`seo__container ${
              showAll ? "" : "seo__container-overflow"
            }`}
          >
            <div
              ref={refText}
              className={`seo__text ${showAll ? "" : "seo__text-overflow"}`}
              style={{ maxHeight: showAll ? `${heightText}px` : "575px" }}

            >
              {currentSeo?.footerTitle && <h2>{currentSeo?.footerTitle}</h2>}
              <div dangerouslySetInnerHTML={{ __html: currentSeo?.footerText }}></div>
            </div>
            <div className="media__max-width-992">
              {!showAll && <div className="seo__container-shadow" />}
            </div>
          </div>
          <div className="media__max-width-992">
            {!showAll && (
              <Button onClick={handlerShowAll}>{showAllButton}</Button>
            )}
          </div>
        </Container>
      </section>
    )
  );
};
