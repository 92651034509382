import { Navigate, Route } from "react-router-dom";
import { AppLayout } from "../layout/AppLayout";
import { IndexLayout } from "../layout/indexLayout";
import PerfectMoney from "../components/common/PerfectMoney/PerfectMoney";
import WebMoney from "../components/common/WebMoney/WebMoney";
import { getPreparedRoutes } from "../utils/helpers/getPreparedRoutes";
import { appRoutes, cabinetRoutes, otherRoutes } from "./routes";

export const getAppRoutes = (lang = "en") => (
    <>
      <Route element={<IndexLayout />}>
        <Route element={<AppLayout />}>
          {getPreparedRoutes(cabinetRoutes())}
        </Route>
        <Route element={<AppLayout />}>
          {getPreparedRoutes(otherRoutes())}
        </Route>{" "}
        <Route element={<AppLayout />}>
          {getPreparedRoutes(appRoutes())}
        </Route>
      </Route>
      <Route
        path="/process/payment/webmoney/:paymentId"
        element={<WebMoney />}
      />
      <Route
        path="/process/payment/perfectMoney/:paymentId"
        element={<PerfectMoney />}
      />
      <Route
        path="*"
        element={<Navigate to={`/${lang}/`} />}
      />
    </>
  );
