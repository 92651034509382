import { useCallback, useEffect } from "react";

import "./CustomModal.scss";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";

export const CustomModal = ({ open, className, onClose, header, children, styles }) => {
  // const handleClose = (e) => {
  //   if (e.target?.id === "owerlay") {
  //     onClose();
  //   }
  // };

  const handleClose = (e) => {
    if (!e.target?.closest(".custom__modal__content")) {
      onClose();
    }
  };

  const handleBtnClose = () => {
    onClose();
  };

  const close = useCallback((e) => {
    const KEY_NAME_ESC = 27;

    if (e.keyCode === KEY_NAME_ESC) {
      // clearHashes();
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) {
      window.addEventListener("keydown", close);
      return;
    }

    window.removeEventListener("keydown", close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const { body } = document;
    const checkOwerlay = document.querySelectorAll(".custom__modal__owerlay");
    if (checkOwerlay && checkOwerlay.length > 0 && open) {
      body.classList.add("disable-scroll");
    } else if (checkOwerlay && checkOwerlay?.length === 0) {
      body.classList.remove("disable-scroll");
    }

    return () => body.classList.remove("disable-scroll");
  }, [open]);

  return open ? (
    <div id="owerlay" className="custom__modal__owerlay" onMouseDown={handleClose} style={styles}>
      <div className={`custom__modal__container ${className ? className : ""}`}>
        <button
          className="custom__modal__container__close_btn"
          onClick={handleBtnClose}
        >
          <IconSvg tag="close" />
        </button>
        <div className="custom__modal__content">
          {header && (
            <div
              className={`custom__modal__header ${
                className ? `${className}__header` : ""
              }`}
            >
              {header}
            </div>
          )}
          <div
            className={`custom__modal__body ${
              className ? `${className}__body` : ""
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
