import "./GroupButtons.scss";
import { classNames } from "../../../../utils/helpers/classNames";

export const GroupButtons = ({ list, active, handlerClick, isFormSubmitting = false, inColumn = false }) => (
    <div className={classNames(
      "group-buttons",
      { "group-buttons--in-column": inColumn }
    )}>
      {list &&
        list.length > 0 &&
        list.map((item) => (
          <button
            className={classNames(
              "group-buttons-btn",
              {
                "group-buttons-btn--in-column": inColumn,
                "group-buttons-btn-active": item.code === active
              }
            )}
            key={item.code}
            onClick={() => handlerClick(item?.code)}
            disabled={isFormSubmitting}
          >
            {item.body}
          </button>
        ))}
    </div>
  );
