import { useState } from "react";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import { classNames } from "../../../../../../../../utils/helpers/classNames";
import { useTranslation } from "../../../../../../../../hooks";
import { DropDown } from "./DropDown/DropDown";
import "./Operator.scss";

export const Operator = ({ selectedOperator, setSelectedOperator, operators }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { formPlaceholders } = useTranslation();

  const handleSelect = ({ target }) => {
    if (target.closest(".operator-select__dropdown")) {
      return;
    }

    setIsOpen((current) => !current);
  };

  return (
    <div className="operator-select" onClick={handleSelect}>
      <div className="operator-select__value">
        <div className="operator-select__title">
          <IconSvg tag="signal" className="operator-select__icon" />
          {selectedOperator ? (
            <div className="operator-select__selected-operator">{selectedOperator.label}</div>
          ) : (
            <div className="operator-select__placeholder">{formPlaceholders?.operators}</div>
          )}
        </div>
        <IconSvg
          tag="triangleDown12"
          className={classNames(
            "operator-select__indicator",
            { "operator-select__indicator--open": isOpen }
          )}
        />
      </div>
      {isOpen && (
        <DropDown
          operators={operators}
          setIsOpen={setIsOpen}
          setOperator={setSelectedOperator}
          selectedOperator={selectedOperator}
        />
      )}
    </div>
  );
};
