import {DATE_TYPES} from "../constants/dataNames";

const getEnDateNames = (type, number) => {
  switch (type) {
    case DATE_TYPES.month:
      return number > 1 ? `${number} months ` : `${number} month `;

    case DATE_TYPES.week:
      return number > 1 ? `${number} weeks ` : `${number} week `;
  
    case DATE_TYPES.day:
      return number > 1 ? `${number} days ` : `${number} day `;
  }
};

const getRuDateNames = (type, number) => {
  switch (type) {
    case DATE_TYPES.month:
      if (number === 1) return `${number} месяц `;
      if ([2, 3, 4].includes(number)) return `${number} месяца `
      if ([5, 6, 7, 8, 9, 10, 11, 12].includes(number)) return `${number} месяцев `
      break;
      
    case DATE_TYPES.week:
      if (number === 1) return `${number} неделя `
      if ([2, 3 ,4].includes(number)) return `${number} недели `;
      break;
      
    case DATE_TYPES.day:
      if (number === 1) return `${number} день `;
      if ([2, 3, 4].includes(number)) return `${number} дня `;
      if ([5, 6].includes(number)) return `${number} дней `;
      break;
    
  }
};

export const getDateNaming = (type, number, lang) => {
  if (lang === "en") {
    return getEnDateNames(type, number);
  }
  
  if (lang === "ru") {
    return getRuDateNames(type, number);
  }
  
  return "";
};
