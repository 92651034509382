import { Controller, useFormContext } from "react-hook-form";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";
import "./RulesCheckBox.scss";

export const RulesCheckBox = (props) => {
  // **Props
  const {
    id = "",
    label,
    className,
    name,
    defaultValue,
    children,
    rules,
    handleClick
  } = props;
  const { register } = useFormContext();

  // Define correct classnames
  const classNames = (className) =>
    `rules-checkbox  ${ className ? className : ""}`;

  return (
    <Controller name={name} rules={rules} render={() => (
      <div className={classNames(className)}>
        <label className="rules-checkbox__input-container">
          <input
            id={id}
            type={"checkbox"}
            defaultChecked={Boolean(defaultValue)}
            { ...register(name, rules) }
            onClick={handleClick}
          />
          <div className="checked">
            <IconSvg tag="checked" />
          </div>
        </label>
        <div className="rules-checkbox__rules-container">
          <label htmlFor={id} className="rules-checkbox__label">
            {label ? label : null}
          </label>
          {children}
        </div>
      </div>
    )} />
  );
};
