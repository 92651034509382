import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ModalInput } from "../../../../../components/ui/ModalInput/ModalInput";
import { ApiService } from "../../../../../services/ApiService";
import { useDispatchedActions } from "../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { successToast, warningToast } from "../../../../../utils/helpers/customToast.helper";
import { passwordPattern } from "../../../../../utils/constants/passwordPattern";
import { authErrorsHandler } from "../../../../../utils/helpers/authErrorsHandler";

export const ChangePassword = ({ close, logout }) => {
  // *Props
  const { lang } = useParams();

  //  *Ref
  const methods = useForm({
    mode: "onChange"
  });

  // *Hooks
  const { setPasswordDiff } = useDispatchedActions();
  const {
    header,
    cabinet: { profile },
    notifications
  } = useTranslation();

  const { setLoginModal } = useDispatchedActions();

  const { errors } = methods.formState;

  // ** Redux state
  const { isPasswordTheSame } = useSelector((state) => state.cabinet);

  const [isLoading, setIsLoading] = useState(false);

  const isLessThanSixChars = errors.newPassword?.type === "validate" || errors.repeatNewPassword?.type === "validate";

  const isContainSpaces = errors.newPassword?.type === "pattern" || errors.repeatNewPassword?.type === "pattern";

  // Form submit
  const onSubmit = async (data) => {
    if (data?.newPassword !== data?.repeatNewPassword) {
      setPasswordDiff(false);

      return;
    }

    if (data?.newPassword === data?.oldPassword) {
      warningToast(profile?.theSamePass, lang);

      return;
    }

    setPasswordDiff(true);
    setIsLoading(true);

    try {
      const { status } = await ApiService.changePassword(
        data?.oldPassword,
        data?.newPassword
      );

      if (status !== 200) {
        throw status;
      }

      successToast(notifications?.passwordChange, lang);

      setTimeout(() => {
        logout();
        setLoginModal(true);
      }, 1500);
    } catch (status) {
      authErrorsHandler(status, "password", lang);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="profile__data-item__body"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className="profile__data-item__body__inputs">
          <ModalInput
            label={header?.formElem?.password?.label}
            // placeholder={header?.formElem?.password?.placeholder}
            type="password"
            name="oldPassword"
            watch={methods.watch}
            rules={{
              validate: (value) => value?.length >= 1,
              pattern: passwordPattern
            }}
          />
          <ModalInput
            label={header?.formElem?.newPassword?.label}
            // placeholder={header?.formElem?.newPassword?.placeholder}
            type="password"
            name="newPassword"
            watch={methods.watch}
            rules={{
              validate: (value) => value?.length >= 6,
              pattern: passwordPattern
            }}
          />
          <ModalInput
            label={header?.formElem?.repeatNewPassword?.label}
            // placeholder={header?.formElem?.repeatNewPassword?.placeholder}
            type="password"
            name="repeatNewPassword"
            watch={methods.watch}
            rules={{
              validate: (value) => value?.length >= 6,
              pattern: passwordPattern
            }}
          />
          {!isPasswordTheSame && (
            <p className="modal__form-wrong">{profile?.wrongPassword}</p>
          )}
          {isContainSpaces && (
            <p className="modal__form-wrong">
              {profile?.wrongPasswordChars}
            </p>
          )}
          {isLessThanSixChars && (
            <p className="modal__form-wrong">
              {profile?.wrongPasswordLength}
            </p>
          )}
        </div>
        <div className="profile__data-item__body__form-actions">
          <button
            className="profile__data-item__action profile__data-item__action-close"
            onClick={close}
          >
            {profile?.close}
          </button>
          <button
            className="profile__data-item__action"
            disabled={isLoading}
            type="submit"
          >
            {profile?.save}
          </button>
        </div>
      </form>
    </FormProvider>
  );
};
