import { Swiper, SwiperSlide } from "swiper/react";
import { IconSvg } from "../../../../../../utils/constants/icons/iconSvg.constant";
import { FaqSlide } from "../FaqSlide/FaqSlide";
import "./FaqSlider.scss";

export const FaqSlider = ({ faqThemes, setSelectedTheme, selectedTheme }) => (
    <div className="faq-slider">
      <button className="faq-slider__button faq-slider__button--prev">
        <IconSvg tag="arrowDown" className="faq-slider__nav-icon faq-slider__nav-icon--prev"/>
      </button>
      <Swiper
        className="faq-slider__slider"
        slidesPerView="auto"
        spaceBetween={24}
        navigation={{
          nextEl: ".faq-slider__button--next",
          prevEl: ".faq-slider__button--prev",
          disabledClass: "faq-slider__button--disabled"
        }}
        style={{ marginLeft: "0" }}
      >
        {faqThemes.map((theme, idx) => (
          <SwiperSlide
            tag="li"
            style={{ height: "auto", width: "max-content" }}
            key={idx}
          >
            <FaqSlide
              themeName={theme.faqTypeName}
              setSelectedTheme={setSelectedTheme}
              selectedTheme={selectedTheme}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <button className="faq-slider__button faq-slider__button--next">
        <IconSvg tag="arrowDown" className="faq-slider__nav-icon faq-slider__nav-icon--next"/>
      </button>
    </div>
  );
