import Select, { components } from "react-select";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatchedActions, useTranslation } from "../../../../../hooks";
import { ApiService } from "../../../../../services/ApiService";
import { errorToast } from "../../../../../utils/helpers/customToast.helper";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import "../FolderSelect/FolderSelect.scss";
import "./MoveToFolderSelect.scss";

export const MoveToFolderSelect = ({ folders }) => {
  const {
    cabinet: { orders: { folders: { moveToFolderSelectLabel, generalFolder } } }
  } = useTranslation();

  const { setSelectedRows, setSelectedFolder } = useDispatchedActions();
  const { selectedFolder, selectedRows } = useSelector((state) => state.cabinet);
  const { activeData } = useSelector((state) => state.content);

  const [focused, setFocused] = useState(false);
  const moveToFolderRef = useRef();

  const defaultOption = { value: null, label: generalFolder };

  const handleSelect = () => {
    if (moveToFolderRef.current) {
      if (focused) {
        moveToFolderRef.current.blur();
        setFocused(false);
      } else {
        moveToFolderRef.current.focus();
        setFocused(true);
      }
    }
  };

  const currentFolders = useMemo(() => folders
    .filter((folder) => folder.proxyType === activeData?.proxy?.code)
    .map((folder) => ({ value: folder.id, label: folder.name })
    ), [activeData?.proxy?.code, folders]);

  const redirectToFolder = (folderId) => {
    const currentFolder = currentFolders
        .find((folder) => folder.value === folderId)
      ||
      { value: null, label: null };

    setSelectedFolder(currentFolder);
  };

  const moveToFolder = async (data) => {
    const ipAddressFolderId = data?.value;

    if (selectedRows.length === 0) {
      return;
    }

    if (ipAddressFolderId === selectedFolder.value) {
      return;
    }

    try {
      redirectToFolder(ipAddressFolderId);

      const params = {
        proxyType: activeData?.proxy?.code,
        ip: selectedRows,
        ipAddressFolderId
      };

      const { status } = await ApiService.editUserTableDataFolder(params);

      if (status !== 200) {
        throw Error();
      }
    } catch {
      errorToast("Something went wrong");
    }
    setSelectedRows([]);
  };

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <IconSvg tag="triangleDown12" />
    </components.DropdownIndicator>
  );

  const getValue = () => selectedFolder.value ? selectedFolder : defaultOption;

  return (
    <div className="folder-select move-to-folder">
      <div
        className="folder-select__container"
      >
        <div
          className="folder-select__label move-to-folder__label"
          onClick={handleSelect}
        >
          {moveToFolderSelectLabel}
        </div>
        <Select
          ref={moveToFolderRef}
          className="custom__select"
          classNamePrefix="move-to-folder__select"
          options={[defaultOption, ...currentFolders]}
          value={getValue()}
          components={{ DropdownIndicator }}
          isSearchable={false}
          onChange={moveToFolder}
          openMenuOnFocus={true}
        />
      </div>
    </div>
  );
};
