import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { getCountries } from "../../../../../utils/helpers/getCountries.helper";
import { sortFunc } from "../../../../../utils/helpers";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { CountriesType } from "./components/CountriesType";
import "./SearchCountries.scss";

export const SearchCountries = (props) => {
  // **Props
  const { proxy, handlerClick } = props;
  const { lang } = useParams();

  const { formPlaceholders } = useTranslation();

  // **Hooks
  const {
    header: { topList, subList }
  } = useTranslation();

  // ** Redux State
  const { countries } = useSelector((state) => state.content);

  // ** Local State
  const [searchData, setSearchData] = useState("");

  const handlerSearch = ({ target }) => {
    setSearchData(target.value);
  };

  // const sortPriceFunc = (a, b) => sortFunc(a, b, "minPrice");
  const sortPopularFunc = (a, b) =>
    sortFunc(a, b, `${proxy?.code.toLowerCase()}PopularIndex`);

  // eslint-disable-next-line arrow-body-style
  const filterBySearch = (country) => {
    return country.enName.toLowerCase().includes(searchData.toLowerCase()) ||
      country.name.toLowerCase().includes(searchData.toLowerCase());
  };

  const countriesByType = proxy.code
    ? getCountries(countries?.data, lang, { showOnFrontType: proxy.code })
      .sort(sortPopularFunc)
    : [];

  const popularCountries = countriesByType.slice(0, 6);
  const restCountries = countriesByType.slice(6);

  const filteredPopularCountries = popularCountries.filter(filterBySearch);
  const filteredRestCountries = restCountries.filter(filterBySearch);

  return (
    <>
      <div className="header__input-container">
        <input
          value={searchData}
          className="header__input"
          placeholder={formPlaceholders?.enterCountryName}
          onChange={handlerSearch}
        />
        <div className="header__input-search">
          <IconSvg tag="search" />
        </div>
      </div>
      <div className="header__countries__box">
        <CountriesType
          title={topList}
          countries={filteredPopularCountries}
          initOpen={true}
          proxy={proxy}
          handlerClick={handlerClick}
        />
        <CountriesType
          title={subList}
          countries={filteredRestCountries}
          initOpen={true}
          proxy={proxy}
          handlerClick={handlerClick}
        />
        <div className="shadow__box"></div>
      </div>
    </>
  );
};
