import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "./EnabledProxySlider.scss";

export const EnabledProxySlider = ({ enabledProxyList, localProxyType, handler }) => {
  const [swiper, setSwiper] = useState(null);

  const onClick = (code, index) => {
    if (swiper) {
      const slideEl = swiper.slides[index];

      if (slideEl) {
        const slideBounds = slideEl.getBoundingClientRect();
        const swiperBounds = swiper.el.getBoundingClientRect();
        const isOutsideRight = slideBounds.right > swiperBounds.right;
        const isOutsideLeft = slideBounds.left < swiperBounds.left;

        if (isOutsideRight || isOutsideLeft) {
          swiper.slideTo(index, 500);
        }
      }
    }

    handler(code);
  };

  return (
    <Swiper
      onSwiper={setSwiper}
      className="enabled-proxy__list"
      spaceBetween={8}
      slidesPerView="auto"
      watchOverflow
      navigation
      modules={[Navigation]}
    >
      {typeof enabledProxyList === "object" &&
        enabledProxyList.map((obj, index) => (
          <SwiperSlide
            className={`enabled-proxy__item  ${
              obj?.code === localProxyType
                ? "enabled-proxy__item--active"
                : ""
            }`}
            tag="button"
            style={{ height: "auto" }}
            key={index}
            onClick={() => onClick(obj?.code, index)}
          >
            {obj?.body}
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
