import CabinetPage from "../../pages/CabinetPage/CabinetPage";

export const cabinetRoutes = (ssr = false) => [
  {
    isSsr: ssr ?? false,
    path: "/:lang/dashboard/",
    tag: "cabinet",
    metaData: "dashboard",
    title: "Cabinet page",
    description: "Cabinet page",
    component: ssr
      ? CabinetPage
      : () => import("../../pages/CabinetPage/CabinetPage")
  },
  {
    isSsr: ssr ?? false,
    path: "/:lang/payment/replenishment/",
    tag: "cabinet",
    metaData: "dashboard",
    title: "Cabinet page",
    description: "Cabinet page",
    component: ssr
      ? CabinetPage
      : () => import("../../pages/CabinetPage/CabinetPage")
  }
];
