import { useState } from "react";
import { Button } from "../../../../../../../components/ui/Buttons/Button/Button";
import { useTranslation } from "../../../../../../../hooks";
import { ProxyItemBody } from "./ProxyItemBody";

export const MobileProxyItem = ({ data, handlerClick }) => {
  const [days, setDays] = useState();

  const { prices: pricesTrns } = useTranslation();

  return (
    <>
      <ProxyItemBody data={data} setDays={setDays} desctop />
      <Button initClass="mobile-list__button" onClick={() => handlerClick(data, days)}>
        {pricesTrns?.buyBtn}
      </Button>
    </>
  );
};
