import {useIsomorphicLayoutEffect} from "./useIsomorphicLayoutEffect";
import {getCookie} from "../utils/helpers/getCookie";

export const useTheme = () => {
  useIsomorphicLayoutEffect(() => {
    const defaultTheme = "dark";
    const currentTheme = document.documentElement.getAttribute('data-theme');
  
    if (currentTheme === "light" || currentTheme === "dark") return;
  
    const cookieTheme = getCookie('data-theme');
    const theme = cookieTheme || defaultTheme;
  
    document.documentElement.setAttribute('data-theme', theme);
  }, [])
};
