import { useEffect } from "react";
import { EmailSettings } from "./components/EmailSettings";
import { TelegramSettings } from "./components/TelegramSettings";
import "./Notifications.scss";
export const Notifications = () => {
  const controller = new AbortController();

  useEffect(() => () => controller.abort()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  return (
    <div className="notifications__container">
      <EmailSettings controller={controller} />
      <TelegramSettings controller={controller} />
    </div>
  );
};
