import { sliceText } from "../../../../utils/helpers/sliceText";
import "./FeedbackCard.scss";

export const FeedbackCard = ({ review }) => {
  const descriptionMaxLength = 100;

  return (
    <div className="feedback">
      <div className="feedbacks__top">
        {review?.avatarUrl && (
          <img
            className="feedbacks__avatar"
            src={review?.avatarUrl}
            loading="lazy"
            alt="person avatar"
          />
        )}
        <div className="feedbacks__info">
          <div className="feedbacks__name">{review?.name}</div>
          <a
            className="feedbacks__place"
            href={review?.siteUrl}
            target="_blank"
            rel="noreferrer nofollow"
          >
            {review?.siteName}
          </a>
        </div>
      </div>
      <p className="feedbacks__text">
        {sliceText(review?.text, descriptionMaxLength)}
      </p>
    </div>
  );
};
