import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatchedActions } from "../../../../../../hooks";
import { getCountries, sortFunc } from "../../../../../../utils/helpers";
import { PROXY_TYPES } from "../../../../../../utils/constants";
import { CountriesMobile } from "./components/CountriesMobile/CountriesMobile";
import { CountriesDesktop } from "./components/CountriesDesctop/CountriesDesktop";
import "./Countries.scss";

const arePropsEqual = (prevProps, nextProps) => prevProps.localCountry.id === nextProps.localCountry.id
  && prevProps.sortParams === nextProps.sortParams
  && prevProps.proxyCode === nextProps.proxyCode;

export const Countries = memo((
  { proxyCode, localCountry, setLocalCountry, sortParams }
) => {
  const { lang } = useParams();

  const { content: { countries, activeData } } = useSelector((state) => state);
  const { setModalCountriesOpen } = useDispatchedActions();

  // const [currentCountries, setCurrentCountries] = useState([]);
  const [isModalCountryWasSelected, setIsModalCountryWasSelected] = useState(false);

  const sortPriceFunc = (a, b) => sortFunc(a, b, "minIPv6Price");
  const sortPopularFunc = (a, b) => sortFunc(a, b, `${proxyCode.toLowerCase()}PopularIndex`);

  const handlerClick = (item) => {
    setModalCountriesOpen(false);
    setLocalCountry({
      id: item?.id,
      code: item?.code
    });
  };

  const setActiveLocalCountry = (activeCountry, currentCountries) => {
    const activeLocalCountry = currentCountries.find((country) => country.id === activeCountry.id);

    if (activeLocalCountry) {
      setLocalCountry({ ...activeLocalCountry });
    } else {
      setLocalCountry({ ...currentCountries[0] });
    }
  };

  const setCountries = (currentCountries) => {
    if (activeData?.country?.id) {
      setActiveLocalCountry(activeData.country, currentCountries);
    } else {
      setLocalCountry({ ...currentCountries[0] });
    }
  };

  // useEffect(() => {
  //   if (countries?.isLoaded && proxyCode !== PROXY_TYPES.mobile.code) {
  //     const currentCountries = getCountries(countries?.data, lang, {
  //       showOnFrontType: proxyCode
  //     });
  //
  //     switch (sortParams) {
  //       case "PRICE": {
  //         const sortedByPrice = currentCountries.sort(sortPriceFunc);
  //         setCountries(sortedByPrice);
  //         return;
  //       }
  //
  //       case "POPULAR": {
  //         const popular = currentCountries.sort(sortPopularFunc);
  //         setCountries(popular);
  //         return;
  //       }
  //
  //       default: {
  //         const popular = currentCountries.sort(sortPopularFunc);
  //         setCountries(popular);
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [proxyCode, countries?.isLoaded, sortParams, lang, activeData?.country?.id]);

  const currentCountries = useMemo(() => {
    if (countries?.isLoaded && proxyCode !== PROXY_TYPES.mobile.code) {
      const currentCountries = getCountries(countries?.data, lang, {
        showOnFrontType: proxyCode
      });

      switch (sortParams) {
        case "PRICE": {
          const sortedByPrice = currentCountries.sort(sortPriceFunc);
          // setCountries(sortedByPrice);
          return sortedByPrice;
        }

        case "POPULAR": {
          const popular = currentCountries.sort(sortPopularFunc);
          // setCountries(popular);
          return popular;
        }

        default: {
          const popular = currentCountries.sort(sortPopularFunc);
          // setCountries(popular);
          return popular;
        }
      }
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proxyCode, countries?.isLoaded, sortParams, lang, activeData?.country?.id]);

  useEffect(() => {
    if (currentCountries?.length) {
      setCountries(currentCountries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountries]);

  const sortedCountries = useMemo(() => {
    if (isModalCountryWasSelected) {
      const activeCountry = currentCountries
        .find((country) => country.id === localCountry?.id);

      const withoutActiveCountry = currentCountries
        .filter((country) => country.id !== localCountry?.id);

      if (activeCountry) {
        setIsModalCountryWasSelected(false);
        return [activeCountry, ...withoutActiveCountry];
      }
    }

    if (activeData?.country?.id) {
      const activeCountry = currentCountries
        .find((country) => country.id === activeData?.country?.id);

      const withoutActiveCountry = currentCountries
        .filter((country) => country.id !== activeData?.country?.id);

      if (activeCountry) {
        return [activeCountry, ...withoutActiveCountry];
      }
    }

    return currentCountries;
    // eslint-disable-next-line
  }, [activeData?.country?.id, currentCountries, localCountry.id]);

  return (
    <>
      <div className="media__max-width-992">
        <CountriesMobile
          countries={sortedCountries}
          localCountry={localCountry}
          showCountries={sortedCountries}
          setLocalCountry={setLocalCountry}
          setIsModalCountryWasSelected={setIsModalCountryWasSelected}
        />
      </div>
      <div className="media__min-width-993">
        <CountriesDesktop
          localCountry={localCountry}
          countries={sortedCountries}
          setLocalCountry={setLocalCountry}
          handlerClick={handlerClick}
          setIsModalCountryWasSelected={setIsModalCountryWasSelected}
        />
      </div>
    </>
  );
}, arePropsEqual);
