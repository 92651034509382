import { useEffect, useRef, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { errorToast, warningToast } from "../../../../../utils/helpers/customToast.helper";
import { ModalSelect } from "../../../../../components/ui/ModalSelect/ModalSelect";

import { ApiService } from "../../../../../services/ApiService";

import { useCurrentPaymantSystems } from "../../../../../hooks/useCurrentPaymantSystems";
import { useCurrentRentPeriod } from "../../../../../hooks/useCurrentRentPeriod";
import { useTranslation } from "../../../../../hooks/useTranslation";
import { useDispatchedActions } from "../../../../../hooks";
import { PROXY_TYPES } from "../../../../../utils/constants";

import "./RenewalForm.scss";
import { Button } from "../../../../../components/ui/Buttons";
import { sendRenewalWarning } from "../../../../../utils/helpers/sendRenewalWarning";
import { ButtonLoader } from "../../../../../components/ui/ButtonLoader/ButtonLoader";
import { RenewalByList } from "./components/RenewalByList/RenewalByList";

export const RenewalForm = ({ allIpsByProxyType }) => {
  // **Props
  const { lang } = useParams();

  // **Hooks
  const currentPaymentSystems = useCurrentPaymantSystems();
  const { fetchPriceCalculationCabinet, resetPrices, setIsNoCashModalOpen } = useDispatchedActions();
  // **State
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const isWarningChecked = useRef(false);

  const {
    formPlaceholders,
    orderForm,
    cabinet: { orders, orders: { renewalErrorMessages } },
    notifications
  } = useTranslation();
  const {
    selectedRows,
    prices,
    fetchPriceCalculationCabinetError,
    isPricesLoaded
  } = useSelector((state) => state.cabinet);
  const { activeData, countries } = useSelector((state) => state.content);

  const methods = useForm();

  const { method, rent } = methods.watch();

  // **Hooks
  const rentPeriods = useCurrentRentPeriod(activeData?.proxy?.code);

  const isExtendingProxyError = isPricesLoaded && !prices?.success;

  // Submit form handler
  const onSubmit = async (data) => {
    // eslint-disable-next-line no-shadow
    const { rent, method } = data;

    if (
      (rent?.id && prices?.amount && prices?.currencyCode && method?.id && lang,
      selectedRows.length > 0 && isPricesLoaded)
    ) {
      if (prices?.minPrice && +prices?.amount < +prices?.minPrice) {
        warningToast(
          `${notifications?.minPrice} ${prices?.minPrice} ${activeData?.currency?.label}`,
          lang
        );
      } else {
        try {
          setIsFormSubmitting(true);

          let renewLabel = "";
          switch (activeData.proxy.code) {
            case "IPv4":
              renewLabel = "renew";
              break;
            case "IPv6":
              renewLabel = "renew_ipv6";
              break;
            case "MOBILE":
              renewLabel = "renew_mobile";
              break;
            default:
              renewLabel = "renew";
              break;
          }
          // google analitika
          if (
            window.location.hostname !== "localhost" &&
            typeof window.gtag !== "undefined"
          ) {
            // eslint-disable-next-line no-undef
            window.gtag("event", "Click", {
              // eslint-disable-next-line camelcase
              event_category: "Renew_button",
              // eslint-disable-next-line camelcase
              event_label: renewLabel
            });
          }

          // yandex analitika
          if (
            window.location.hostname !== "localhost" &&
            typeof window.ym !== "undefined"
          ) {
            // eslint-disable-next-line no-undef
            ym(87392270, "reachGoal", renewLabel);
          }

          const response = await ApiService.createPaymentForExtendProxies({
            paymentSystemId: method?.id,
            amount: prices?.amount,
            currencyCode: prices?.currencyCode,
            rentPeriodId: rent?.id,
            locale: lang,
            ipsId: selectedRows,
            proxyType: activeData.proxy.code
          });

          if (response && response?.status === 205) {
            throw notifications?.errorUserPaymentSystem;
          }

          if (response.response && response.response.status !== 200) {
            throw notifications?.error;
          }

          if (response?.data === "insufficientFunds") {
            setIsNoCashModalOpen(true);
            setIsFormSubmitting(false);
            return;
          }

          if (response?.data) {
            window.location.href = response.data;
            // setIsFormSubmitting(false);
          } else {
            errorToast(notifications?.error, lang);
            setIsFormSubmitting(false);
          }
        } catch (err) {
          errorToast(err?.message || err, lang);
          setIsFormSubmitting(false);
        }
      }
    }
  };

  // eslint-disable-next-line
  useEffect(() => () => resetPrices(), []);

  // Init calculation
  useEffect(() => {
    if (rent?.id && method?.id && selectedRows.length) {

      fetchPriceCalculationCabinet({
        rentPeriodId: rent?.id,
        paymentSystemId: method?.id,
        ipsId: selectedRows,
        proxyType: activeData.proxy.code
      });

      isWarningChecked.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method?.id, rent?.id, selectedRows]);

  // Handling error
  if (fetchPriceCalculationCabinetError) {
    errorToast(notifications?.error, lang);
  }

  if (isExtendingProxyError && !isWarningChecked.current) {
    sendRenewalWarning({
      code: prices?.errorCode,
      message: prices?.errorMessage,
      renewalErrorMessages,
      lang,
      countries: countries?.data
    });

    isWarningChecked.current = true;
  }

  const setInitFormValues = () => {
    if (!rentPeriods?.length || !currentPaymentSystems?.length) return;

    const [selectedRent, selectedMethod] = methods.getValues(["rent", "method"]);

    if (!selectedRent) {
      methods.setValue("rent", rentPeriods[0]);
    }

    if (!selectedMethod) {
      methods.setValue("method", currentPaymentSystems[0]);
    }
  };

  return (
    <>
      {/*<div className={isFormSubmitting ? "renewal renewal--hide" : "renewal"}>*/}
      <div className="renewal">
        <div className="renewal__heading">
          <div className="renewal__title">{orders?.formTitle}</div>
          {activeData.proxy.code !== PROXY_TYPES.ipv6.code && (
            <RenewalByList allIpsByProxyType={allIpsByProxyType} setInitFormValues={setInitFormValues} />
          )}
        </div>
        <FormProvider {...methods}>
          <form
            className="renewal__form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="renewal__form__box-price">
              <div className="renewal__form-quantity">
                {formPlaceholders?.quantity1}
                <div className="renewal__form-quantity-value">
                  {selectedRows?.length || "-"}
                </div>
              </div>
              <div className="renewal__form-price">
                {orderForm?.fullPrice}:
                <div className="renewal__form-price-value">
                  {/*{prices?.amount} {prices?.currencyCode}*/}
                  {prices?.usdAmount ? `$${prices?.usdAmount}` : "-"}
                </div>
              </div>
            </div>

            <ModalSelect
              name="rent"
              rules={{ required: true }}
              options={rentPeriods}
              placeholder={formPlaceholders?.rentPeriod}
              // isDisabled={isExtendingProxyError}
            />
            <ModalSelect
              name="method"
              rules={{ required: true }}
              options={currentPaymentSystems}
              placeholder={formPlaceholders?.paymentField}
              // isDisabled={isExtendingProxyError}
            />

            <Button
              tab={!isExtendingProxyError ? "buy-proxy" : "disabled" }
              type="submit"
              disabled={isExtendingProxyError || isFormSubmitting}
            >
              {isFormSubmitting ? <ButtonLoader /> : orders?.formBtn }
            </Button>
          </form>
        </FormProvider>
      </div>
      {/*{isFormSubmitting && (*/}
      {/*  <Loading style={{ padding: "10px 0", maxWidth: 442 }} />*/}
      {/*)}*/}
    </>
  );
};
