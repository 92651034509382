import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatchedActions, useTranslation } from "../../../../../../hooks";
import { ModalInput } from "../../../../../../components/ui/ModalInput/ModalInput";
import { ModalSelect } from "../../../../../ui/ModalSelect/ModalSelect";
import { getAuthMethods } from "../../../../../../utils/helpers/getAuthMethods.helper";
import { ipPattern } from "../../../../../../utils/constants/IpPattern";
export const AuthParams = ({ authIp = "" }) => {
  // **Props
  const { lang } = useParams();
  // **Redux State
  const { modal } = useSelector((state) => state);

  const ipSelectRef = useRef(null);

  // **Dispatch
  const { setIpInput } = useDispatchedActions();

  // **Hooks
  const { formPlaceholders } = useTranslation();

  // Checking if ipInput is closed and set initial value to ip select
  useEffect(() => {
    if (!modal?.isIpVisible && ipSelectRef?.current && !modal?.paramsForReload?.isSet) {
      ipSelectRef?.current.select.setValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.isIpVisible, ipSelectRef]);

  return !modal?.isIpVisible ? (
    <ModalSelect
      ref={ipSelectRef}
      name="auth"
      rules={{ required: true }}
      options={getAuthMethods(lang)}
      placeholder={formPlaceholders?.authField}
      onChange={(value) => {
        setIpInput(value);
      }}
    />
  ) : (
    <ModalInput
      name="authIp"
      rules={{
        required: true,
        pattern: ipPattern
      }}
      // mask
      label="IP"
      defaultValue={authIp}
      ipMask
      handler={() => setIpInput({ ip: false })}
    />
  );
};
