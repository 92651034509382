export function loadPaymentMeta() {
  const metaTags = [
    { name: 'test' },
    { name: 'interkassa-verification', content: '000696e1559ce0c240f51e00a2e4e291' },
    { name: 'google-site-verification', content: '0wgsXM4YDqjRPb0vdl1hbi1-DJlp1mxaQYDiBtWXNbk' },
    { name: 'cryptomus', content: '66504025' },
  ];
  
  metaTags.forEach(({ name, content }) => {
    const meta = document.createElement('meta');
    meta.name = name;
    if (content) {
      meta.content = content;
    }
    document.head.appendChild(meta);
  });
}
