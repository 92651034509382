import { FormProvider, useForm } from "react-hook-form";

import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  errorToast,
  successToast
} from "../../../../../../../../../utils/helpers/customToast.helper";
import { ModalInput } from "../../../../../../../../ui/ModalInput/ModalInput";

import { ApiService } from "../../../../../../../../../services/ApiService";

import { useTranslation } from "../../../../../../../../../hooks/useTranslation";

import { Button } from "../../../../../../../../ui/Buttons/Button/Button";
import { useDispatchedActions } from "../../../../../../../../../hooks";
import { ButtonLoader } from "../../../../../../../../ui/ButtonLoader/ButtonLoader";
import { emailPattern } from "../../../../../../../../../utils/constants/emailPattern";

export const InitialForm = () => {
  const { lang } = useParams();
  // **State
  const {
    header: { modalRecovery, formElem },
    notifications
  } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const { setRecoveryModal } = useDispatchedActions();

  const methods = useForm({
    mode: "onChange"
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await ApiService.getRecoveryPasswordLink(data.email);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      if (response?.data) {
        successToast(notifications.email, lang);
        setRecoveryModal(false);
      } else {
        errorToast(notifications.registerErrorWrongEmail, lang);
      }
    } catch (err) {
      errorToast(notifications.error, lang);
    } finally {
      setIsLoading(false);
    }
  };

  const isInvalidEmail = methods.formState.errors.email?.type === "pattern";

  return (
    <FormProvider {...methods}>
      <form
        className="modal__login-form-rectore"
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate={true}
      >
        <ModalInput
          label={formElem.email.label}
          // placeholder={formElem.email.placeholder}
          type="email"
          name="email"
          watch={methods.watch}
          rules={{
            required: true,
            pattern: emailPattern
          }}
        />
        {isInvalidEmail && (
          <p className="modal__login-form-rectore--hint">
            {formElem.invalidEmail}
          </p>
        )}
        <Button type="submit" disabled={isLoading}>
          {isLoading ? <ButtonLoader /> : modalRecovery.recoveryBtn }
        </Button>
      </form>
    </FormProvider>
  );
};
