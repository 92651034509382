import { useCallback, useEffect, useMemo, useState } from "react";
import { IconSvg } from "../../../../../../../../../utils/constants/icons/iconSvg.constant";
import { useTranslation } from "../../../../../../../../../hooks";
import { PROXY_TYPES } from "../../../../../../../../../utils/constants";
import { isSSR } from "../../../../../../../../../utils/helpers";
import { classNames } from "../../../../../../../../../utils/helpers/classNames";

export const DropDown = ({ proxyCode, localCountries, setCountryParams, setMobileOperators, setSelectedCountry, setIsOpen, selectedCountry, setSelectedOperator }) => {
  const { formPlaceholders: { enterCountryName }, mobileProxy } = useTranslation();

  const [searchValue, setSearchValue] = useState("");

  const closeMenu = useCallback(({ target }) => {
    if (!target.closest(".countries-select")) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showDropDown = () => {
    if (isSSR()) {
      return;
    }

    window.addEventListener("click", closeMenu);

    const dropDown = document.querySelector(".countries-select__dropdown");
    const { bottom } = dropDown.getBoundingClientRect();

    const notInViewport = bottom - window.innerHeight;

    const bodyRect = document.body.getBoundingClientRect();
    const offset = bottom - bodyRect.bottom;

    if (notInViewport > 0) {
      window.scrollTo(0, offset + 100);
    }
  };

  useEffect(() => {
    if (isSSR()) {
      return;
    }

    showDropDown();

    return () => window.removeEventListener("click", closeMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilteredCountries = () => {
    switch (proxyCode) {
      case PROXY_TYPES.mobile.code:
        return localCountries.filter((country) => country.countryEnName.toLowerCase().includes(searchValue.toLowerCase()) || country.countryName.toLowerCase().includes(searchValue.toLowerCase()));
      default:
        return localCountries.filter((country) => country.enName.toLowerCase().includes(searchValue.toLowerCase()) || country.name.toLowerCase().includes(searchValue.toLowerCase()));
    }
  };

  const addMobileOperators = (country) => {
    if (proxyCode !== PROXY_TYPES.mobile.code) {
      setMobileOperators([]);

      return;
    }

    setMobileOperators(
      country?.operators
        ? country?.operators.map((item) => ({
          ...item,
          rotationMins: item?.rotationMins
            ? item?.rotationMins.map((item) => ({
              ...item,
              value: item?.min,
              label:
                item?.min === 0
                  ? `${mobileProxy?.changeIPauto}`
                  : `${item?.min} ${mobileProxy?.changeIPTime}`
            }))
            : [],
          value: item?.operatorTag,
          label: item?.operatorName
        }))
        : []
    );
  };

  const selectCountry = (country) => {
    setCountryParams({
      id: country?.id,
      code: country?.code
    });

    addMobileOperators(country);

    setSelectedOperator(null);
    setSelectedCountry(country);
    setSearchValue("");
    setIsOpen(false);
  };

  const filteredCountries = useMemo(getFilteredCountries,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue, localCountries]);

  return (
    <div className="countries-select__dropdown">
      <div className="countries-select__input-container">
        <input
          className="countries-select__input"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={enterCountryName}
        />
        <div className="countries-select__search">
          <IconSvg tag="search" />
        </div>
      </div>
      <ul className="countries-select__country-list">
        {filteredCountries.map((country) => (
          <li
            className={classNames(
              "countries-select__country-item",
              { "countries-select__country-item--active": selectedCountry?.id === country?.id }
            )}
            key={country?.id}
            onClick={() => selectCountry(country)}
          >
            {country?.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
