import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { CustomModal } from "../../../../../../../../components/ui/CustomModal/CustomModal";
import { useDispatchedActions, useTranslation } from "../../../../../../../../hooks";
import { Search } from "../../../../../../../../components/ui/Search/Search";

export const CountriesModal = ({ modalCountries, localCountry, setLocalCountry, setIsModalCountryWasSelected }) => {
  const {
    modal: { modals }
  } = useSelector((state) => state);
  const { prices } = useTranslation();
  const [searchData, setSearchData] = useState("");

  const { setModalCountriesOpen } = useDispatchedActions();

  const handleClose = () => {
    setSearchData("");
    setModalCountriesOpen(false);
  };

  const handlerSearch = ({ target }) => {
    setSearchData(target.value.toLowerCase());
  };

  const handlerClick = (item) => {
    handleClose();

    setIsModalCountryWasSelected(true);
    setLocalCountry({
      id: item?.id,
      code: item?.code
    });
  };

  const visibleCountries = useMemo(() => modalCountries.filter(
    (item) =>
      item.enName.toLowerCase().includes(searchData) ||
      item.name.toLowerCase().includes(searchData)
  ), [modalCountries, searchData]);

  useEffect(() => {
    const countries = document.querySelector(".countries__other");
    const countriesContainer = document.querySelector(".countries__other-container");

    if (countries && countriesContainer) {
      const { height: countriesHeight } = countries.getBoundingClientRect();
      const { height: countriesContainerHeight } = countriesContainer.getBoundingClientRect();

      if (countriesHeight > countriesContainerHeight) {
        countriesContainer.style.paddingRight = "3px";
      } else {
        countriesContainer.style.paddingRight = "0";
      }
    }

  });

  return (
    <CustomModal
      open={modals?.isCountriesModalOpen}
      onClose={handleClose}
      className="countries__modal"
      header={prices?.modalTitle}
      styles={{ zIndex: "999999999" }}
    >
      <Search className="header__search" onChange={handlerSearch} />
      <div className="countries__other-container">
        <ul className={"countries__other"}>
          {visibleCountries?.length > 0 &&
            visibleCountries.map((item) => (
              <li className="countries__other__item" key={item?.id}>
                <button
                  className={`countries-button ${
                    localCountry.id === item?.id
                      ? "countries-button--active"
                      : ""
                  }`}
                  onClick={() => handlerClick(item)}
                >
                  {item?.label}
                </button>
              </li>
            ))}
        </ul>
      </div>
    </CustomModal>
  );
};
