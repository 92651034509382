import { createAsyncThunk } from "@reduxjs/toolkit";

import { ApiService } from "../../../services/ApiService";

export const fetchMobileProxyTariff = createAsyncThunk(
  "mobileProxy/fetchMobileProxyTariff",
  // eslint-disable-next-line default-param-last
  async (currencyId, { rejectWithValue }) => {
    try {
      const response = await ApiService.getMobileProxy(currencyId);

      if (response.response && response.response.status !== 200) {
        throw response;
      }

      return { data: response.data, currencyId };
    } catch (err) {
      return rejectWithValue(true);
    }
  }
);
