import {errorToast} from "./customToast.helper";

export const authErrorsHandler = (status, type, lang) => {
  const authErrors = {
    invalidLogin: {
      en: "Login is not valid.",
      ru: "Логин неверный."
    },
    incorrectPassword: {
      en: "Current password is not correct.",
      ru: "Пароль недействителен."
    },
    invalidEmail: {
      en: "Email is not valid.",
      ru: "Email не является допустимым."
    },
    invalidPassword: {
      en: "Password is not valid.",
      ru: "Пароль не является допустимым. "
    },
    emailExists: {
      en: "User with this email already exists.",
      ru: "Пользователь с таким адресом электронной почты уже существует."
    },
  };
  
  switch (true) {
    case status === 202:
      return errorToast(authErrors.invalidLogin[lang], lang);
    case status === 203:
      return errorToast(authErrors.incorrectPassword[lang], lang);
      
    case status === 226:
      return errorToast(authErrors.invalidEmail[lang], lang);
      
    case status === 207 && type === "password":
      return errorToast(authErrors.invalidPassword[lang], lang);
  
    case status === 207 && type === "email":
      return errorToast(authErrors.emailExists[lang], lang);
  
    case status === 208:
      return errorToast(authErrors.emailExists[lang], lang);
  }
};
