import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../../../../utils/constants/icons/iconSvg.constant";
import { Button } from "../../../../../../../components/ui/Buttons/Button/Button";
import { ButtonLoader } from "../../../../../../../components/ui/ButtonLoader/ButtonLoader";
import { CustomModal } from "../../../../../../../components/ui/CustomModal/CustomModal";
import { useDispatchedActions, useTranslation } from "../../../../../../../hooks";
import { ApiService } from "../../../../../../../services/ApiService";
import { errorToast } from "../../../../../../../utils/helpers/customToast.helper";
import { accessErrorHandler } from "../../../../../../../utils/helpers/accessErrorHandler";
import { setExpireTime } from "../../../../../../../utils/helpers/setExpireTime";
import "./DeleteIpModal.scss";

export const DeleteIpModal = ({ setAdditionalIps, isOpen, onClose, onCloseAccessModal }) => {
  const { lang } = useParams();
  const { cabinet: { orders: { access: {
    deleteNotification,
    remove,
    cancel
  } } } } = useTranslation();

  const { setIpForDeleting } = useDispatchedActions();
  const { accessParams: { ipForDeleting } } = useSelector((state) => state.cabinet);

  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (removedIp) => {
    setIsLoading(true);
    try {
      const { status, data: { timeDelay, errorMessage } } = await ApiService.removeAccessIp({ ip: ipForDeleting });

      if (status !== 200) {
        throw status;
      }

      if (errorMessage) {
        accessErrorHandler(errorMessage, lang);

        return;
      }

      setExpireTime(timeDelay);

      setAdditionalIps((current) => [...current.filter((ip) => ip !== removedIp)]);
      setIpForDeleting(null);

      onClose();
      onCloseAccessModal();

    } catch (status) {
      errorToast(`Something went wrong, ${status}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CustomModal open={isOpen} onClose={onClose} styles={{ zIndex: "999999999" }}>
      <div className="delete-ip">
        <div className="delete-ip__trash-icon">
          <IconSvg tag="largeTrash" />
        </div>
        <div className="delete-ip__info">
          {deleteNotification}
          <div className="delete-ip__ip-name">
            {ipForDeleting}
          </div>
        </div>
        <div className="delete-ip__actions">
          <Button tab="default" onClick={onClose}>
            {cancel}
          </Button>
          <Button tab="delete" onClick={handleDelete}>
            { isLoading ? <ButtonLoader /> : remove }
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};
