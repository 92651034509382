import { useSelector } from "react-redux";
import Select from "react-select";
import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "../../../../../../../../hooks";
import { Button } from "../../../../../../../../components/ui/Buttons/Button/Button";
import { CustomInput } from "../../../../../../../../components/ui/CustomInput/CustomInput";
import { IconSvg } from "../../../../../../../../utils/constants/icons/iconSvg.constant";
import { warningToast } from "../../../../../../../../utils/helpers/customToast.helper";
import { resetFilterSearchParams } from "../../../../../../../../utils/helpers/resetFilterSearchParams";
import { getBorderColor } from "../../../../../../../../utils/helpers/getBorderColor";
import { CountryOption } from "./components/CountryOption";
import { StatusOption } from "./components/StatusOption";
import "./MainFilter.scss";

export const MainFilter = ({ isOpen, setIsOpen }) => {
  const { lang } = useParams();
  const { skin } = useSelector((state) => state.header);

  const {
    cabinet:
      { orders:
        { filter:
          {
            all,
            resetButton,
            dateFilter,
            dateErrorMessage,
            inValidDateRange,
            from,
            to,
            country: countryLabel, ipStatus
          } } }
  } = useTranslation();

  const { filterOptions } = useSelector((state) => state.cabinet);

  const [searchParams, setSearchParams] = useSearchParams();
  const country = searchParams.get("country");
  const status = searchParams.get("runningOuts");
  const dateStart = searchParams.get("dateStart");
  const dateEnd = searchParams.get("dateEnd");

  const getDateValue = (initialDate) => {
    if (!initialDate) {
      return "";
    }

    const date = initialDate.split("T");
    return date[0];
  };

  const [countries, setCountries] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [dateFrom, setDateFrom] = useState(getDateValue(dateStart) || "");
  const [dateTo, setDateTo] = useState(getDateValue(dateEnd) || "");

  const defaultValue = { value: null, label: all };

  const close = useCallback(({ target }) => {
    if (target.closest("#filter-menu")
      || target.classList.contains("custom__select__option")
      || target.classList.contains("main-filter__option")
      || target.classList.contains("main-filter__status")
      || target.classList.contains("main-filter__status-icon")
      || target.classList.contains("flag")
    ) {
      return;
    }

    setIsOpen(false);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", close);
      return;
    }

    window.removeEventListener("click", close);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const setCountryOptions = () => {
    const options = [defaultValue];

    for (const value of filterOptions.countries) {
      const option = {
        value,
        label: <CountryOption value={value} lang={lang} />
      };
      options.push(option);
    }

    setCountries(options);
  };

  const setStatusOptions = () => {
    const options = [defaultValue];

    for (const value of filterOptions.statuses) {
      const option = {
        value: String(value),
        label: <StatusOption value={value} />
      };
      options.push(option);
    }

    setStatuses(options);
  };

  useEffect(() => {
    if (filterOptions?.countries) {
      setCountryOptions();
    }

    if (filterOptions?.statuses) {
      setStatusOptions();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const addToSearch = (searchName, searchValue) => {
    if (searchValue === null) {
      searchParams.delete(searchName);
    } else {
      searchParams.set(searchName, searchValue);
    }

    setSearchParams(searchParams);
  };

  const handleCountrySelect = (data) => {
    addToSearch("country", data.value);
  };

  const handleStatusSelect = (data) => {
    addToSearch("runningOuts", data.value);
  };

  const handleDateStart = (date) => {
    setDateFrom(date);
  };

  const handleDateEnd = (date) => {
    setDateTo(date);
  };

  const isValidDate = () => {
    const regexDate = /^\d{4}-\d{1,2}-\d{1,2}$/;

    if (dateFrom && !regexDate.test(dateFrom)) {
      return false;
    }

    if (dateTo && !regexDate.test(dateTo)) {
      return false;
    }

    return true;
  };

  const isValidDateRange = () => {
    const from = new Date(dateFrom).getTime();
    const to = new Date(dateTo).getTime();

    return from <= to;

  };

  const filterByDate = () => {
    if (!isValidDateRange()) {
      return warningToast(inValidDateRange, lang);
    }

    if (!isValidDate()) {
      return warningToast(dateErrorMessage, lang);
    }

    if (dateFrom) {
      const date = new Date(dateFrom).toISOString();
      searchParams.set("dateStart", date);
    }

    if (dateTo) {
      const date = new Date(dateTo).toISOString();
      searchParams.set("dateEnd", date);
    }
    setSearchParams(searchParams);
    setIsOpen(false);
  };

  const getValue = (optionValue, options) => {
    if (!optionValue) {
      return defaultValue;
    }

    return options.find((o) => o.value === optionValue) || defaultValue;
  };

  const handleReset = () => {
    setDateFrom("");
    setDateTo("");
    resetFilterSearchParams(searchParams, setSearchParams);
    setIsOpen(false);
  };

  return (
    <div id="filter-menu" className="main-filter">
      <div className="main-filter__container">
        <div className="main-filter__value main-filter__select">
          <div className="folder-select__label">{countryLabel}</div>
          <Select
            className="custom__select"
            classNamePrefix="custom__select"
            options={countries}
            value={getValue(country, countries)}
            isSearchable={false}
            onChange={handleCountrySelect}
            openMenuOnFocus={true}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: getBorderColor(state, skin)
              })
            }}
          />
        </div>
        <div className="main-filter__type main-filter__select">
          <div className="folder-select__label">{ipStatus}</div>
          <Select
            // isLoading={true}
            className="custom__select"
            classNamePrefix="custom__select"
            options={statuses}
            value={getValue(status, statuses)}
            isSearchable={false}
            onChange={handleStatusSelect}
            openMenuOnFocus={true}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: getBorderColor(state, skin)
              })
            }}
          />
        </div>
        <div className="main-filter__date">
          <div className="main-filter__fields">
            <CustomInput containerClassName="main-filter__field" labelTop label={from} value={dateFrom} type="date" onChange={handleDateStart} />
            <CustomInput containerClassName="main-filter__field" labelTop label={to} value={dateTo} type="date" onChange={handleDateEnd} />
          </div>
          { (dateFrom || dateTo) && (
            <Button onClick={filterByDate} initClass="main-filter__date-filter-button">
              <div className="main-filter__date-filter-button-container">
                {dateFilter}
                <IconSvg tag="loupe" className="main-filter__loop" />
              </div>
            </Button>
          )}
        </div>
        {(country || status || dateStart || dateEnd) && (
          <div className="main-filter__actions">
            <Button onClick={handleReset}>
              {resetButton}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
