export function loadCookiebotScript() {
  const script = document.createElement('script');
  script.id = 'Cookiebot';
  script.src = 'https://consent.cookiebot.com/uc.js';
  script.setAttribute('data-cbid', '9ecc2e2e-2e4c-4bd9-94ef-8401133a6729');
  script.setAttribute('data-blockingmode', 'auto');
  script.type = 'text/javascript';
  
  document.head.appendChild(script);
}
