import { CabinetReducerActions } from "./CabinetReducer/Cabinet.reducer";
import { ContentReducerActions } from "./ContentReducer/Content.reducer";
import { FooterReducerActions } from "./FooterReducer/Footer.reducer";
import { HeaderReducerActions } from "./HeaderReducer/Header.reducer";
import { MobileProxyReducerActions } from "./MobileProxyReducer/MobileProxy.reducer";
import { ModalReducerActions } from "./ModalReducer/Modal.reducer";
import { TranslationReducerActions } from "./TranslationReducer/Translation.reducer";

export const AllActions = {
  ...CabinetReducerActions,
  ...ContentReducerActions,
  ...FooterReducerActions,
  ...HeaderReducerActions,
  ...TranslationReducerActions,
  ...ModalReducerActions,
  ...MobileProxyReducerActions
};
