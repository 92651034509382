import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import { Button } from "../../../../components/ui/Buttons/Button/Button";
import { useDispatchedActions, useTranslation } from "../../../../hooks";
import "./CabinetInfo.scss";

export const CabinetInfo = () => {
  const { lang } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.content);
  const { setLoginModal } = useDispatchedActions();

  const { paymentSuccessPage:
    { cabinetMessage, personalArea } } = useTranslation();

  const loginFormHandler = () => {
    if (!user?.isAuth) {
      setLoginModal(true);

      return;
    }

    navigate(`/${lang}/dashboard/?tab=profile`);
  };

  return (
    <div className="cabinet-info__cabinet">
      <div className="cabinet-info__cabinet-container">
        <div className="cabinet-info__cabinet-description">
          <div className="cabinet-info__info-icon">
            <IconSvg tag="cabinetInfo" />
          </div>
          <p>{cabinetMessage}</p>
        </div>
        <Button tab="confirm" onClick={loginFormHandler}>
          {personalArea}
        </Button>
      </div>
    </div>
  );
};
