/* eslint-disable camelcase */
import { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";

import { ApiService } from "../../../services/ApiService";

import { useDebounceCallback } from "../../../hooks/useDebounceCallback";
import { useTranslation } from "../../../hooks/useTranslation";
import "./ModalSelect.scss";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";
import { getBorderColor } from "../../../utils/helpers/getBorderColor";

export const ModalSelect = forwardRef((props, ref) => {
  // **Props
  const {
    name,
    rules,
    options,
    placeholder,
    onChange,
    defaultValue,
    isAsync,
    setOptions,
    isDisabled
  } = props;

  // **Hooks
  const { notifications, subgoals } = useTranslation();

  // **Redux State
  const { projects_form_crm } = useSelector(
    (state) => state.content.proxies.data
  );

  // **State
  const [inputValue, setInputValue] = useState("");
  // const [tempOptions, setTempOptions] = useState([]);

  const { skin } = useSelector((state) => state.header);

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <IconSvg tag="triangleDown12" />
    </components.DropdownIndicator>
  );

  // Loading options for select
  const loadOptions = useDebounceCallback((value, callback) => {
    ApiService.projectSearch(value.trim()).then((req) => {
      if (req?.data) {
        let options = [];
          // logik use iv4/crm goals
        if (projects_form_crm) {
          options = req.data.map((item) => ({
            label: item?.displayName && subgoals?.hasOwnProperty(item?.displayName) ? subgoals[item?.displayName] : item?.displayName,
            value: item?.displayName
          }));
        } else {
          options = req.data.map((item) => ({
            label: item?.displayName,
            value: item?.projectId
          }));
        }

        callback(options);
        setOptions(options);
      }
    });
  }, 500);

  const handleInputChange = (value, { action }, field) => {
    if (action === "input-change") {
      // const newValue = value.trim();
      if (value.length > 40) {
        return;
      }
      if (value.length > 4) {
        field.onChange({ value, label: value });
      } else {
        field.onChange(null);
      }

      setInputValue(value);
    }
  };

  const handleChange = (value, field) => {
    setInputValue(value?.label);
    field.onChange(value);
  };

  const currentValue = (value) => {
    const currentOption = options.find((option) => option?.value === value?.value);
    return currentOption ? currentOption : null;
  };

  return isAsync ? (
    <div className={"input-wrap"}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <>
            <AsyncSelect
              {...field}
              className={`modal__select modal__select--hide-indicator ${
                errors[name] ? "bordered  modal__select-error" : ""
              } `}
              ref={ref}
              classNamePrefix="modal__select"
              placeholder={placeholder}
              cacheOptions
              style={inputValue.length < 5 && { margin: "0" }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: getBorderColor(state, skin)
                })
              }}
              blurInputOnSelect={false}
              closeMenuOnSelect={false}
              inputValue={inputValue}
              loadOptions={loadOptions}
              component={{ DropdownIndicator: () => null }}
              noOptionsMessage={() => null}
              onChange={(value) => handleChange(value, field)}
              onInputChange={(value, optionsChange) => {
                handleInputChange(value, optionsChange, field);
              }}
            />
            {errors[name] && (
              <p className="modal__select-error-text">
                {notifications?.errorMinLength}
              </p>
            )}
          </>
        )}
      />
    </div>
  ) : (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Select
          {...field}
          className={`modal__select ${errors[name] ? "bordered" : ""}`}
          ref={ref}
          classNamePrefix="modal__select"
          options={options}
          defaultValue={defaultValue}
          isSearchable={false}
          placeholder={placeholder}
          components={{ DropdownIndicator }}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: getBorderColor(state, skin)
            })
          }}
          onChange={(value) => {
            field.onChange(value);
            if (onChange) {
              onChange(value);
            }
          }}
          value={currentValue(field.value)}
          isDisabled={isDisabled}
        />
      )}
    />
  );
});
