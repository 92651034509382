import { createSlice } from "@reduxjs/toolkit";

import { countries } from "../../../utils/constants/countries.constant";

import { fetchMobileProxyTariff } from "./MobileProxy.thunks";

const MobileProxyReducer = createSlice({
  name: "mobileProxy",
  initialState: {
    mobileTariffs: {},
    isMobileTariffsLoaded: false,
    fetchMobileTariffsError: null
  },
  reducers: {
    setMobileTariffs(state, action) {
      state.mobileTariffs = action.payload;
    },
    setMobileTariffsSSR(state, action) {
      state.mobileTariffs = {
        ...state.mobileTariffs,
        [action.payload.currencyId]: action.payload.data.filter((item) => countries.includes(item.countryCode))
      };
      state.isMobileTariffsLoaded = true;
    },
  },
  extraReducers: {
    [fetchMobileProxyTariff.pending]: (state) => {
      state.fetchMobileTariffsError = null;
      state.isMobileTariffsLoaded = false;
    },
    [fetchMobileProxyTariff.fulfilled]: (state, action) => {
      state.mobileTariffs = {
        ...state.mobileTariffs,
        [action.payload.currencyId]: action.payload.data.filter((item) => countries.includes(item.countryCode))
      };
      state.isMobileTariffsLoaded = true;
    },
    [fetchMobileProxyTariff.rejected]: (state, action) => {
      state.fetchMobileTariffsError = action.payload.data;
      state.isMobileTariffsLoaded = true;
    }
  }
});

export const MobileProxyReducerActions = {
  ...MobileProxyReducer.actions,
  fetchMobileProxyTariff
};
export default MobileProxyReducer.reducer;
