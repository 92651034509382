import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ModalSelect } from "../../../../../../components/ui/ModalSelect/ModalSelect";
import { useTranslation } from "../../../../../../hooks";

export const MobileoperatorParams = (props) => {
  const {
    proxyCode,
    setMobileOperator,
    setModemRotationMin
  } = props;
  const ref = useRef();
  const refOperator = useRef();

  // **Redux State
  const { modal } = useSelector((state) => state);
  const [localOperatorRotations, setLocalOperatorRotations] = useState([]);

  // **Hooks
  const { formPlaceholders } = useTranslation();

  const handlerOperator = (data) => {
    setMobileOperator(data);
    setLocalOperatorRotations(data.rotationMins);

    if (ref.current) {
      ref.current.select.setValue(null);
    }
  };

  const handlerRorationMin = (data) => {
    setModemRotationMin(data);
  };

  const setParamsFromProxyMobileCard = () => {
    const operator = modal?.mobileOperators.find((operator) => operator.operatorTag === modal?.selectedOperator.operatorTag);
    const rotations = operator.rotationMins;
    const selectedRotation = rotations.find((rotation) => rotation.value === modal?.selectedRotation);

    if (refOperator.current) {
      refOperator.current.select.setValue(operator);
    }

    if (ref.current) {
      ref.current.select.setValue(selectedRotation);
    }
  };

  const setParamsFromMainForm = () => {
    const operator = modal?.selectedOperator;
    const rotations = operator?.rotationMins;

    if (!operator && !rotations) {
      return;
    }

    if (refOperator.current) {
      refOperator.current.select.setValue(operator);
    }
  };

  useEffect(() => {
    if (modal?.selectedOperator && modal?.selectedRotation !== null) {
      setParamsFromProxyMobileCard();

      return;
    }

    setParamsFromMainForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return proxyCode === "MOBILE" ? (
    <>
      <ModalSelect
        ref={refOperator}
        name="mobileOperator"
        rules={{ required: true }}
        options={modal?.mobileOperators}
        placeholder={formPlaceholders?.operators}
        onChange={handlerOperator}
      />
      <ModalSelect
        ref={ref}
        name="modemRotationMin"
        rules={{ required: true }}
        options={localOperatorRotations}
        placeholder={formPlaceholders?.rotation}
        onChange={handlerRorationMin}
      />
    </>
  ) : null;
};
