import { useTranslation } from "../../../../hooks";
import "./FaqTitle.scss";

export const FaqTitle = () => {
  const { faq } = useTranslation();

  return (
    <div className="faq-title">{faq?.title}</div>
  );
};
