import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJSON from "./en.json";
import ruJSON from "./ru.json";

const resources = {
  en: enJSON,
  ru: ruJSON
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: ["en", "ru"],
  interpolation: {
    escapeValue: false,
  }
});

export default i18n;
