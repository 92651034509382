/* eslint-disable quotes */
export const MainData = [
  {
    title: { ru: "Получение баланса", en: "To get the balance information" },
    description: { ru: "Запрос позволяет увидеть текущее состояние баланса", en: "The request allows to see the current balance" },
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/balance" },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD"
    },
    responseParams: {
      success: { value: "true/false", ru: "флаг успешности операции", en: "operation status" },
      balance: {  ru: "текущее состояние баланса", en: "the current balance" }
    }
  },
  {
    title: { ru: "Получить весь список купленных прокси", en: "To get the full list of the bought proxies" },
    description: false,
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/proxies" },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD",
      countOfProxy: 6,
      ipv4: [
        {
          id: "id1",
          ip: "185.81.112.215",
          country: "GBR",
          dateStart: "2022-08-29T14:55:38.293+00:00",
          dateEnd: "2022-08-30T23:59:38.293+00:00",
          httpsPort: "49162",
          socks5Port: "49163",
          authInfo: {
            login: "authLogin123",
            password: "authPassword"
          }
        },
        {
          id: "id2",
          ip: "107.181.142.223",
          country: "GBR",
          dateStart: "2022-08-29T14:55:38.293+00:00",
          dateEnd: "2022-08-30T23:59:38.293+00:00",
          httpsPort: "49162",
          socks5Port: "49163",
          authInfo: {
            login: "authLogin123",
            password: "authPassword"
          }
        }
      ],
      ipv6: [
        {
          orderId: "orderId1",
          ips: [
            {
              id: "id3",
              ip: "140.82.53.23:10002",
              country: "FRA",
              dateStart: "2022-08-03T14:04:59.131+00:00",
              dateEnd: "2022-10-02T14:04:59.159+00:00",
              protocol: "SOCKS5",
              authInfo: {
                login: "authLogin123",
                password: "authPassword"
              }
            },
            {
              id: "id4",
              ip: "140.82.53.23:10004",
              country: "FRA",
              dateStart: "2022-08-03T14:04:59.131+00:00",
              dateEnd: "2022-10-02T14:04:59.159+00:00",
              protocol: "SOCKS5",
              authInfo: {
                login: "authLogin123",
                password: "authPassword"
              }
            }
          ]
        }
      ],
      isp: [
        {
          id: "id21",
          ip: "185.81.112.210",
          country: "GBR",
          dateStart: "2022-08-29T14:55:38.293+00:00",
          dateEnd: "2022-08-30T23:59:38.293+00:00",
          httpsPort: "49162",
          socks5Port: "49163",
          authInfo: {
            login: "authLogin123",
            password: "authPassword"
          }
        },
        {
          id: "id22",
          ip: "110.18.142.213",
          country: "GBR",
          dateStart: "2022-08-29T14:55:38.293+00:00",
          dateEnd: "2022-08-30T23:59:38.293+00:00",
          httpsPort: "49162",
          socks5Port: "49163",
          authInfo: {
            login: "authLogin123",
            password: "authPassword"
          }
        }
      ],
      mobile: [
        {
          id: "id5",
          ip: "61.62.63.64",
          country: "FRA",
          dateStart: "2022-08-30T14:54:25.414+00:00",
          dateEnd: "2022-09-29T23:59:25.414+00:00",
          httpsPort: "7781",
          socks5Port: "8891",
          mobileOperator: "freemobilesas_france",
          rotationTime: 0,
          rebootLink: "https://proxy-ipv4.com/modem/reboot/448eac96-8546-4389-8f56-54b2sb4798d12",
          authInfo: {
            login: "authLogin123",
            password: "authPassword"
          }
        },
        {
          id: "id6",
          ip: "61.62.63.64",
          country: "FRA",
          dateStart: "2022-08-30T14:54:25.414+00:00",
          dateEnd: "2022-09-29T23:59:25.414+00:00",
          httpsPort: "7782",
          socks5Port: "8892",
          mobileOperator: "freemobilesas_france",
          rotationTime: 0,
          rebootLink: "https://proxy-ipv4.com/modem/reboot/44cf7d39-b682-4296-8909-7038ba99008b5",
          authInfo: {
            login: "authLogin123",
            password: "authPassword"
          }
        }
      ]
    },
    responseParams: {
      ipv4: { ru: "список активных ipv4 прокси", en: "the list of active ipv4 proxies" },
      ipv6: { ru: "список активных ipv6 прокси", en: "the list of active ipv6 proxies" },
      mobile: { ru: "список активных mobile прокси", en: "the list of active mobile proxies" },
      isp: { ru: "список активных isp прокси", en: "the list of active isp proxies" },
      id: { ru: "ID прокси. Этот параметр необходим для продления прокси", en: "proxy ID. This parameter is needed for extending proxy" },
      orderId: {
        ru: "ID заказа. IPv6, в отличии от остальных типов прокси, позволяет продлевать только весь заказ целиком",
        en: "order ID. Ipv6, unlike the rest of the proxy types, allows to extend all the proxies in the order only"
      },
      country: { ru: "альфа3 код страны", en: "alfa3 country code" },
      dateStart: { ru: "дата начала аренды", en: "the rent start date" },
      dateEnd: { ru: "дата окончания аренды", en: "the rent end date" },
      authInfo: { ru: "данные для авторизации", en: "the data for authorization" },
      protocol: { value: "HTTPS/SOCKS5", ru: "протокол", en: "protocol" },
      rotationTime: {
        ru: "время обновления IP адреса в мин. 0 - это обновление по ссылке в любой момент",
        en: "rotation time of IP address in minutes. 0 is rotation by link at any moment"
      },
      rebootLink: {ru: "ссылка для обновления IP", en: "link for IP reboot"},
      mobileOperator: {
        ru: "мобильный оператор",
        en: "mobile operator"
      },
    }
  },
  {
    title: { ru: "Получить доступное количество дней для покупки/продления прокси",
      en: "To get the available number of days for a proxy purchase/renewal" },
    description: {
      ru: "Доступное количество дней может отличатся в зависимости от типа прокси",
      en: "An available number of days can differ depending on the proxy type" },
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/days" },
    requestParams: {
      proxyType: {
        value: "ipv4/ipv6/isp/mobile",
        ru: "тип прокси. Значение может быть четырех видов",
        en: "type of proxy. Value can be of four types"
      }
    },
    requestExample: { url: "/client-api/v1/<apiKey>/get/days?proxyType=ipv4", body: null },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD",
      days: [1, 7, 14, 30, 60, 90]
    }
  },
  {
    title: {
      ru: "Получить список доступных стран для покупки",
      en: "To get the list of the countries available for purchase" },
    description: {
      ru: "Список доступных стран может отличатся в зависимости от типа прокси",
      en: "The list of available countries can differ depending on the proxy type" },
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/countries" },
    requestParams: {
      proxyType: {
        value: "ipv4/ipv6/isp/mobile",
        ru: "тип прокси. Значение может быть четырех видов",
        en: "type of proxy. Value can be of four types"
      }
    },
    requestExample: { url: "/client-api/v1/<apiKey>/get/countries?proxyType=ipv4", body: null },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD",
      countries: [
        {
          name: "ENGLAND",
          alpha3code: "GBR"
        },
        {
          name: "USA",
          alpha3code: "USA"
        },
        {
          name: "FRANCE",
          alpha3code: "FRA"
        }
      ]
    },
    responseParams: {
      name: {
        ru: "имя страны",
        en: "name of country" },
      alpha3code: {
        ru: "альфа3 код страны. Значение этого поля будет использоваться для оформления заказа",
        en: "alfa3 country code. Value of this field will be used for placing an order" }
    }
  }
  // {
  //   title: {
  //     ru: "Получить список доступных целей для покупки",
  //     en: "To get the list of the available goals for purchase" },
  //   description: false,
  //   request: { type: "GET", url: "/client-api/v1/<apiKey>/get/goals" },
  //   responseBody: {
  //     success: true,
  //     user: "client@gmail.com",
  //     balance: 13.09,
  //     currency: "USD",
  //     goals: ["instagram", "facebook", "vk", "bookmakers", "avito", "amazon", "cs go", "tiktok"]
  //   },
  //   responseParams: {
  //     goals: {
  //       ru: "список доступных целей. Этот параметр необходим при оформления заказа. Если в списке нету того что вам нужно, можете при заказе использовать поле customGoal",
  //       en: "The list of available goals. This parameter is necessary for placing an order. If the list does not contain what you need, you can use the field 'customGoal' when placing an order"
  //     }
  //   }
  // }
];
