import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CustomModal } from "../../../../../components/ui/CustomModal/CustomModal";
import { useDispatchedActions } from "../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../hooks/useTranslation";

import "./FooterModal.scss";
import { isSSR } from "../../../../../utils/helpers";

export const FooterModal = () => {
  // **Props
  const { lang } = useParams();
  const navigate = useNavigate();

  // Dispatch
  const { setFooterModal } = useDispatchedActions();

  // **Redux State
  const { footerModal } = useSelector((state) => state.footer);
  const { content } = useSelector((state) => state.content);

  // **Hooks
  const { footer } = useTranslation();

  const clearHashes = () => {
    if (isSSR()) {
      return;
    }

    navigate({ hash: "" });
  };

  const handlerClose = () => {
    clearHashes();
    setFooterModal(null);
  };

  return (
    content?.isLoaded && (
      <CustomModal
      open={footerModal}
      onClose={handlerClose}
      className="modal__footer"
      header={footer[footerModal]?.title}
      styles={{ zIndex: "999999999" }}
    >
       <div
            className="modal__footer__text"
            dangerouslySetInnerHTML={{
              __html: content?.data[lang]?.[footerModal]
            }}
          ></div>
    </CustomModal>
      // <Modal
      //   open={footerModal}
      //   onClose={() => setFooterModal(null)}
      //   closeOnEsc
      //   closeOnOverlayClick
      //   center
      // >
      //   <div className="modal__top">{footer[footerModal]?.title}</div>
      //   <div className="modal__body">
      //     <div
      //       className="modal__body-inner"
      //       dangerouslySetInnerHTML={{
      //         __html: content?.data[lang]?.[footerModal]
      //       }}
      //     ></div>
      //   </div>
      // </Modal>
    )
  );
};
