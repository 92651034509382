import {IPV4_MIN_QUANTITY, IPV6_MIN_QUANTITY, ISP_MIN_QUANTITY, MOBILE_MIN_QUANTITY, PROXY_TYPES} from "../constants";

export const getValidQuantity = (proxyType, quantity) => {
  if (!proxyType) {
    return;
  }
  
  switch (proxyType) {
    case PROXY_TYPES.ipv4.code:
      return Number(quantity) < IPV4_MIN_QUANTITY ? `${IPV4_MIN_QUANTITY}` : quantity;
    
    case PROXY_TYPES.ipv6.code:
      return Number(quantity) < IPV6_MIN_QUANTITY ? `${IPV6_MIN_QUANTITY}` : quantity;
    
    case PROXY_TYPES.mobile.code:
      return Number(quantity) < MOBILE_MIN_QUANTITY ? `${MOBILE_MIN_QUANTITY}` : quantity;
      
    case PROXY_TYPES.isp.code:
      return Number(quantity) < ISP_MIN_QUANTITY ? `${ISP_MIN_QUANTITY}` : quantity;
  }
};
