import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "../../../../../../components/ui/Buttons";

import { useDebounceValue } from "../../../../../../hooks/useDebounceValue";
import { useDispatchedActions } from "../../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../../hooks/useTranslation";

import { Proxy } from "../../../../../../components/ui/Proxy/Proxy";
import { defaultPrice, initialCountryParams, PROXY_TYPES } from "../../../../../../utils/constants/defaultData.constant";
import { calculatePrice } from "../../../../../../utils/helpers/calculatePrice.helper";
import { getFilteredCountry } from "../../../../../../utils/helpers/getFilteredCountry.helper";
import { inputCorrection } from "../../../../../../utils/helpers/inputCorrection.helper";
import { DisplayPrice } from "../../../../../../components/common/OrderComponents/DisplayPrice/DisplayPrice";
import { getInitialQuantity } from "../../../../../../utils/helpers/getInitialQuantity";
import { getQuantityWarningMessage } from "../../../../../../utils/helpers/getQuantityWarningMessage";
import { isValidQuantity } from "../../../../../../utils/helpers/isValidQuantity";
import { Quantity } from "./components/Quantity";
import { Countries } from "./components/Countries/Countries";
import { Days } from "./components/Days/Days";
import { Operator } from "./components/Operator/Operator";

export const Form = () => {
  // **Props
  const { country, lang } = useParams();

  // **Redux State
  const { content, countries, activeData } = useSelector(
    (state) => state.content
  );

  const { main } = useTranslation();

  // Dispatch
  const { setConfigModal } = useDispatchedActions();

  // **Local State
  const [days, setDays] = useState(null);
  const [localProxyType, setLocalProxyType] = useState(null);
  const [countryParams, setCountryParams] = useState(initialCountryParams);
  const [mobileOperators, setMobileOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [localPrice, setLocalPrice] = useState(defaultPrice);
  const [quantity, setQuantity] = useState("");
  // const [showButtons, setShowButtons] = useState(false);
  const debouncedQuantity = useDebounceValue(quantity, 700);
  const [isCountriesOpen, setIsCountriesOpen] = useState(false);

  // useEffect(() => {
  //   if (isSSR()) {
  //     return;
  //   }
  //
  //   if (activeData.proxy.code !== PROXY_TYPES.ipv4.code) {
  //     return;
  //   }
  //
  //   const indexContainer = document.querySelector(".index-container");
  //
  //   if (width < 1200 && width > 992) {
  //     indexContainer.classList.remove("index-container-ipv4");
  //
  //     return;
  //   }
  //
  //   if (activeData.proxy.code === PROXY_TYPES.ipv4.code && !country) {
  //     indexContainer.classList.add("index-container-ipv4");
  //   }
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [width, localProxyType]);

  // Input params handling
  const inputHandler = (e) => {
    inputCorrection(e, setQuantity);
  };

  useEffect(() => {
    const initQuantity = getInitialQuantity(localProxyType);

    setQuantity(initQuantity);
  }, [localProxyType]);

  useEffect(() => {
    if (!debouncedQuantity) {
      return;
    }

    if (!isValidQuantity(localProxyType, quantity)) {
      getQuantityWarningMessage(localProxyType, lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuantity]);

  // open modal
  const submitHandler = (event) => {
    event.stopPropagation();

    if (!countryParams?.id) {
      setIsCountriesOpen(true);

      return;
    }
    let activeEurope = false;
    const filteredCountry = getFilteredCountry(countries?.data, country);
    if (activeData?.proxy?.code === "IPv4" && filteredCountry?.code === "EUROPE") {
      activeEurope = true;
    }
    setConfigModal({
      goalCode: "",
      quantity,
      days,
      country: countryParams,
      proxyType: localProxyType,
      prices: localPrice,
      mobileOperators,
      selectedOperator,
      activeEurope
    });
  };

  const isDataValid = () => {
    switch (localProxyType) {
      case PROXY_TYPES.mobile.code:
        return countryParams?.id && days?.id && selectedOperator && isValidQuantity(localProxyType, quantity) && countryParams?.code !== "EUROPE";

      default:
        return countryParams?.id && days?.id && isValidQuantity(localProxyType, quantity) && countryParams?.code !== "EUROPE";
    }
  };

  // Price calculation
  useEffect(() => {
    if (isDataValid()) {
      const searchParams = {
        countryId: countryParams?.id,
        currencyId: activeData?.currency?.id,
        count: +debouncedQuantity,
        rentPeriodId: days?.id,
        proxyType: localProxyType,
        mobileOperatorTag: selectedOperator?.operatorTag
      };

      calculatePrice(setLocalPrice, searchParams, lang);
    } else {
      setLocalPrice(defaultPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryParams?.id, days?.id, debouncedQuantity, selectedOperator]);

  useEffect(() => {
    if (activeData?.proxy?.code) {
      const initialQuantity = getInitialQuantity(activeData.proxy.code);
      setQuantity(initialQuantity);

      setLocalProxyType(activeData.proxy.code);
      // if (activeData?.proxy?.code === "IPv4" && !activeData?.country?.id) {
      //   setShowButtons(true);
      // } else {
      //   setShowButtons(false);
      // }
    }
  }, [activeData?.proxy?.code, activeData?.country?.id]);

  const changeLocalProxyType = (proxyCode) => {
    setLocalProxyType(proxyCode);
    setDays(null);
    setSelectedOperator(null);
    setCountryParams(initialCountryParams);
  };

  // const enabledProxyList = proxies.enabled.map((item) => ({
  //   ...item,
  //   code: item.code,
  //   body: main?.selectProxy[item?.code]
  // }));

  const showButtons = activeData?.proxy?.code === "IPv4" && !activeData?.country?.id;

  return (
    content?.isLoaded && (
      <div className={"index__form"}>
        {showButtons && (
          <>
            <div className="index__proxy">
              <Proxy
                localProxyType={localProxyType}
                setLocalProxyType={changeLocalProxyType}
              />
            </div>
          </>
        )}

        <div className="index__form-container-1199">
          <div className="index__order">
            {/*<Countries*/}
            {/*  proxyCode={localProxyType}*/}
            {/*  setCountryParams={setCountryParams}*/}
            {/*  setMobileOperators={setMobileOperators}*/}
            {/*  isOpen={isCountriesOpen}*/}
            {/*  setIsOpen={setIsCountriesOpen}*/}
            {/*/>*/}
            <div className="index__form-container">
              <Countries
                isOpen={isCountriesOpen}
                setIsOpen={setIsCountriesOpen}
                proxyCode={localProxyType}
                setCountryParams={setCountryParams}
                setMobileOperators={setMobileOperators}
                setSelectedOperator={setSelectedOperator}
              />
              {localProxyType === "MOBILE" && (
                <Operator
                  countryParams={countryParams}
                  operators={mobileOperators}
                  selectedOperator={selectedOperator}
                  setSelectedOperator={setSelectedOperator}
                />
              )}
              <Days
                proxyCode={localProxyType}
                setDays={setDays}
                selectedDay={days}
              />
              <Quantity quantity={quantity} inputHandler={inputHandler} />
            </div>
            <DisplayPrice
              prefixClassName="index"
              amount={localPrice?.amount}
              quantity={quantity}
            />
            <Button tab="buy-proxy" initClass="btn__buy-proxy-index" onClick={submitHandler}>{main?.buyBtn}</Button>
          </div>
        </div>
        <div className="index__form-container-1200">
          <div className="index__order">
            {/*<Countries*/}
            {/*  proxyCode={localProxyType}*/}
            {/*  setCountryParams={setCountryParams}*/}
            {/*  setMobileOperators={setMobileOperators}*/}
            {/*  isOpen={isCountriesOpen}*/}
            {/*  setIsOpen={setIsCountriesOpen}*/}
            {/*/>*/}
            <Countries
              isOpen={isCountriesOpen}
              setIsOpen={setIsCountriesOpen}
              proxyCode={localProxyType}
              setCountryParams={setCountryParams}
              setMobileOperators={setMobileOperators}
              setSelectedOperator={setSelectedOperator}
            />
            {localProxyType === "MOBILE" && (
              <Operator
                countryParams={countryParams}
                operators={mobileOperators}
                selectedOperator={selectedOperator}
                setSelectedOperator={setSelectedOperator}
              />
            )}
            <Days
              proxyCode={localProxyType}
              setDays={setDays}
              selectedDay={days}
            />
            <Quantity quantity={quantity} inputHandler={inputHandler} />
            <DisplayPrice
              prefixClassName="index"
              amount={localPrice?.amount}
              quantity={quantity}
            />
            <Button tab="buy-proxy" initClass="btn__buy-proxy-index" onClick={submitHandler}>{main?.buyBtn}</Button>
          </div>
        </div>
      </div>
    )
  );
};
