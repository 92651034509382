import "./NotificationsList.scss";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../../../../utils/constants/icons/iconSvg.constant";
import { NotificationCard } from "../NotificationCard/NotificationCard";
import { classNames } from "../../../../../../../utils/helpers/classNames";
import { useTranslation } from "../../../../../../../hooks";
import { Button } from "../../../../../../ui/Buttons/Button/Button";
import { ButtonLoader } from "../../../../../../ui/ButtonLoader/ButtonLoader";

export const NotificationsList = ({ isOpen, handler, notifications, clearNotifications, showMoreHandler, isLoading, totalCount }) => {
  const { header: { notifications: { title, clearAll, noData, showMore } } } = useTranslation();
  const { lang } = useParams();

  const data = notifications ? notifications[lang] : [];

  return (
    <div className={classNames(
      "user-notifications-list",
      { "user-notifications-list--open": isOpen }
    )}>
      <button className="user-notifications-list__close" onClick={handler}>
        <IconSvg tag="arrow" />
      </button>
      <div className="user-notifications-list__head">
        <div className="user-notifications-list__title">{title}</div>
        {!!data?.length && (
          <div
            className="user-notifications-list__clear-all"
            onClick={clearNotifications}
          >
            {clearAll}
          </div>
        )}
      </div>
      <ul className="user-notifications-list__cards-wrapper">
        {!data?.length ? (
          <div className="user-notifications-list__no-data">
            <IconSvg tag="emptyBox" />
            <div className="user-notifications-list__no-data-label">{noData}</div>
          </div>
        ) : (
          <>
            {data.map((notification, idx) => (
              <NotificationCard
                key={idx}
                notification={notification}
                isNotificationsOpen={isOpen}
              />
            ))}
            {totalCount > data.length + 1 && (
              <Button
                initClass="user-notifications-list__show-more"
                onClick={showMoreHandler}
              >
                {isLoading ? <ButtonLoader /> : showMore}
              </Button>
            )}
          </>
        )}
      </ul>
    </div>
  );
};
