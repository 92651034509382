import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Container } from "../../ui/Container/Container";
import { Languages } from "../../ui/Languages/Languages";
import { useTranslation } from "../../../hooks/useTranslation";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";
import { useDispatchedActions } from "../../../hooks";
import { modalHashes } from "../../../utils/helpers/modalHashes";
import { Credits } from "./components/Credits/Credits";
import { FooterModal } from "./components/FooterModal/FooterModal";

import "./Footer.scss";

export const Footer = () => {
  // **Props
  const { lang } = useParams();

  // **State
  const { skin } = useSelector((state) => state.header);
  const { logoAlt, logoAria } = useTranslation().footer;
  const { setFooterModal } = useDispatchedActions();
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (location.hash) {
      case `#${modalHashes.termsOfUse}`:
        setFooterModal(modalHashes.termsOfUse);
        break;

      case `#${modalHashes.privacyPolicy}`:
        setFooterModal(modalHashes.privacyPolicy);
        break;

      case `#${modalHashes.publicOffer}`:
        setFooterModal(modalHashes.publicOffer);
        break;

      case `#${modalHashes.returnPolicy}`:
        setFooterModal(modalHashes.returnPolicy);
        break;

      case `#${modalHashes.cookiePolicy}`:
        setFooterModal(modalHashes.cookiePolicy);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const company = `Proxy-IPv4 © ${new Date().getFullYear()}. All rights reserved.`;

  return (
    <>
      <footer className="footer">
        <Container>
          <div className="footer__wrapper">
            <div className="footer__top">
              <div className="footer__logo">
                <Link to={`/${lang}/`} aria-label={logoAria}>
                  <img
                    src={
                      skin === "dark"
                        ? "/img/ui/new_logo_v2.svg"
                        : "/img/ui/new_logo-light_v2.svg"
                    }
                    loading="lazy"
                    width="130"
                    // height="48"
                    alt={logoAlt}
                  />
                </Link>
              </div>
              <Credits />
            </div>
            <div className="footer__bottom">
              <div className="footer__lang-cards">
                <Languages menuDirection={"top"} />
                <IconSvg
                  tag={skin === "dark" ? "masterCard" : "masterCardDarck"}
                  className="master-card"
                />
                <IconSvg tag={skin === "dark" ? "visa" : "visaDarck"} />
              </div>
              <div>
                <p className="footer__company">
                  {company}
                </p>
                {/*<p className="footer__team">Design by Our Team.</p>*/}
                {/*<p className="footer__team">build: {process.env.REACT_APP_GH_BUILD}</p>*/}
              </div>
            </div>
          </div>
        </Container>
        <FooterModal />
      </footer>
    </>
  );
};
