import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import "./OrderIcon.scss";
import { classNames } from "../../../../utils/helpers/classNames";

export const OrderIcon = ({ success, iconTag, inProcess, isError }) => {
  const getIcon = () => {
    switch (true) {
      case success:
        return "tickIcon";
      case isError:
        return "exclamationIcon";
      default:
        return iconTag;
    }
  };

  return (
    <div className="order-icon">
      <div className={classNames(
        "order-icon__content",
        { "order-icon__content--in-process": inProcess && !isError },
        { "order-icon__content--success": success },
        { "order-icon__content--is-error": isError }
      )}>
        <IconSvg className={`order-icon__icon-${iconTag}`} tag={getIcon()} />
      </div>
    </div>
  );
};
