import {capitalize} from "./capitalize.helper";

const getValidAltText = (countryName, code, lang) => {
  if (!countryName) return code
  
  const preparedCountryName = capitalize(countryName)
  
  return lang === "en"
    ? `Secure proxies of ${preparedCountryName}`
    : `Безопасные прокси ${preparedCountryName}`
};

export const getCountryFlag = (code, countries, lang) => {
  let countryNameGenitiveCase = "";
  
  if (countries?.length) {
    const currentCountry = countries.find(country => country.code === code);
    countryNameGenitiveCase = currentCountry?.nameCases?.[lang]?.genitive;
  }
  
  return (
    <img
      className="flag" src={`/img/flags/${code}.svg`}
      loading="lazy"
      width="36"
      height="24"
      alt={getValidAltText(countryNameGenitiveCase, code, lang)}
      style={{ objectFit: "cover", borderRadius: "4px", marginRight: "12px"}}
    />
  )
};
