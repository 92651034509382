import { memo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "../../../../ui/Buttons/Button/Button";
import { useDispatchedActions, useTranslation } from "../../../../../hooks";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";
import { SearchCountries } from "../SearchCountries/SearchCountries";
import "./DesktopDropDown.scss";
import { classNames } from "../../../../../utils/helpers/classNames";

export const DesktopDropDown = memo(() => {
  // **Hooks
  const { menu } = useTranslation().header;
  const { lang } = useParams();
  const { setActiveProxy, setActiveCountry } = useDispatchedActions();

  // **State
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { proxies, activeData } = useSelector((state) => state.content);

  const debounce = (f, delay) => {
    let timerId;

    return (value) => {
      if (!value) {
        timerId = setTimeout(() => {
          f(false);
        }, delay);

        return;
      }

      clearTimeout(timerId);
      f(true);
    };
  };

  const handleMenu = debounce(setIsMenuOpen, 300);

  const handlerClick = (e, data, item) => {
    e.target.blur();
    setActiveProxy({ ...data, name: data?.code });

    const tempDataCountry = {
      id: item?.id || "",
      code: item?.code || ""
    };

    setActiveCountry(tempDataCountry);
    setIsMenuOpen(false);
  };

  return (
    <>
      <div className="header__desc">
        <Button
          tab="header"
          icon
          active={isMenuOpen}
          onTouchStart={() => handleMenu(true)}
          onMouseEnter={() => handleMenu(true)}
          onMouseLeave={() => handleMenu(false)}
          initClass={classNames("", { "header__desc--open btn__header--active": isMenuOpen })}
        >
          {menu?.proxyNav?.name}
        </Button>
        <div className={classNames(
          "header__desc__dropdown",
          { "header__desc__dropdown--en": lang === "en" },
          { "header__desc__dropdown--ru": lang === "ru" },
          { "header__desc__dropdown--hidden": !isMenuOpen },
          { "header__desc__dropdown--visible": isMenuOpen }
        )}>
          <ul className="header__desc__dropdown-list">
            {proxies?.enabled.map((data) => (
              <li
                className={`header__desc__dropdown-list-item ${
                  data?.code !== "MOBILE"
                    ? `header__desc__dropdown-list-item-${data?.value}`
                    : ""
                }`}
                // className="header__desc__dropdown-list-item"
                key={data?.value}
                onMouseEnter={() => handleMenu(true)}
                onMouseLeave={() => handleMenu(false)}
              >
                <Link
                  className={`header__desc__dropdown-list-link ${
                    activeData?.proxy?.code === data?.code
                      ? "header__desc__dropdown-list-link-active"
                      : ""
                  } ${
                    data?.code !== "MOBILE"
                      ? `header__desc__dropdown-list-link-${data?.value}`
                      : ""
                  }`}
                  to={`/${lang}/${
                    data?.code !== "IPv4" ? `${data?.value}/` : ""
                  }`}
                  onClick={(e) => handlerClick(e, data)}
                >
                  {data?.code !== "MOBILE" ? (
                    `${menu?.proxyNav?.subName} ${data?.label}`
                  ) : (
                    menu?.proxyNav?.subNameMobile
                  )}
                </Link>
                {data?.code !== "MOBILE" && (
                  <IconSvg tag="arrowRight" className={"link-icon header__desc__dropdown-list-link-arrow"} />
                )}
                {data?.code !== "MOBILE" && (
                  <div className="header__desc__country">
                    <div className="header__desc__country__box">
                      <SearchCountries
                        proxy={data}
                        handlerClick={handlerClick}
                      />
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        {/*{!isMenuOpen && (*/}
        {/*  */}
        {/*)}*/}
      </div>
      {isMenuOpen && (
        <div className="header__desc__dropdown__back" />
      )}
    </>
  );
});
