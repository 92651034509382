export const getBorderColor = (state, theme) => {
  const activeDarkGrey = "#888888";
  const activeLightGrey = "#2424247a";
  const defaultDarkGrey = "#ffffff1f";
  const defaultLightGrey = "#2424241f";
  
  if (theme === "light") {
    return state.isFocused || state.isSelected || state.menuIsOpen ? activeLightGrey : defaultLightGrey;
  }
  
  if (theme === "dark") {
    return state.isFocused || state.isSelected || state.menuIsOpen ? activeDarkGrey : defaultDarkGrey;
  }
};
