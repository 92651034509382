import { useEffect } from "react";
import { classNames } from "../../../../../../../../../utils/helpers/classNames";
import { isSSR } from "../../../../../../../../../utils/helpers";

export const DropDown = ({ closeSelect, sortOptions, selected, setSelected, setSortParams }) => {
  useEffect(() => {
    const onClose = ({ target }) => {
      if (isSSR()) {
        return;
      }

      if (target.closest(".sort-prices__dropdown")) {
        return;
      }

      closeSelect();
    };

    window.addEventListener("click", onClose);

    return () => window.removeEventListener("click", onClose);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectOption = (option) => {
    setSelected(option);
    setSortParams(option.value);
    closeSelect();
  };

  return (
    <div className="sort-prices__dropdown">
      <ul className="sort-prices__dropdown-list">
        {sortOptions.map((option, idx) => (
          <li
            key={idx}
            className={classNames(
              "sort-prices__dropdown-item",
              { "sort-prices__dropdown-item--active": selected.value === option.value }
            )}
            onClick={() => handleSelectOption(option)}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
