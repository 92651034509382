import { useState, useEffect } from "react";
import { isSSR } from "../utils/helpers/isSSR";

export const useResize = () => {
 
  const [windowSize, setWindowSize] = useState(isSSR() ? { width: 0, height: 0 } : {
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};
