import { Suspense } from "react";

import { Outlet } from "react-router-dom";

import { Loading } from "../components/ui/Loading/Loading";

export const AppLayout = () => (
  <Suspense fallback={<Loading absolute />}>
    <Outlet />
  </Suspense>
);
