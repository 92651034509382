import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { memo } from "react";
import { useDispatchedActions, useTranslation } from "../../../../../../../../hooks";
import { CountriesModal } from "../CountriesModal/CountriesModal";

// const crmBreakpoints = {
//   320: {
//     spaceBetween: 15,
//     slidesPerView: 1.25,
//   },
//   460: {
//     spaceBetween: 15,
//     slidesPerView: 1.5,
//   },
//   767: {
//     spaceBetween: 30,
//     slidesPerView: 2.5,
//   },
// };

const isLocalCountryNotSelected = (prevProps, nextProps) => !nextProps.localCountry.id;

export const CountriesMobile = memo((props) => {
  // **Props
  const { countries, localCountry, setLocalCountry, setIsModalCountryWasSelected } = props;

  // **Hooks
  const { prices } = useTranslation();
  const { setModalCountriesOpen } = useDispatchedActions();

  const handlerMore = () => setModalCountriesOpen(true);

  const handlerChoiceCountry = (item) =>
    setLocalCountry({
      id: item?.id,
      code: item?.code
    });

  // const handlerSetActiveIndex = (e) => {
  //   let indexSlide = showCountries.length;
  //   showCountries.forEach((item, index) => {
  //     if (item?.id === localCountry?.id) {
  //       indexSlide = index;
  //     }
  //   });
  //   e.activeIndex = indexSlide;
  // };

  const showCountries = countries.slice(0, 2);
  const modalCountries = countries.slice(2);

  return (
    <>
      <Swiper
        className="countries"
        spaceBetween={24}
        // speed={500}
        slidesPerView="auto"
        // autoplay={{ delay: 3000 }}
        // pagination={{ clickable: true }}
        // onAfterInit={handlerSetActiveIndex}
        wrapperTag="ul"
        watchOverflow
        navigation
        // loop
        modules={[Navigation, Pagination, Autoplay]}
        // breakpoints={crmBreakpoints}
      >
        {showCountries?.length > 0 &&
          showCountries.slice(0, 2).map((item, index) => (
            <SwiperSlide
              className="countries-item"
              tag="li"
              // style={{ height: "auto" }}
              key={index}
            >
              <span
                className={`countries-button ${
                  localCountry?.id === item?.id ? "countries-button--active" : ""
                }`}
                // to={`/${lang}/${activeData?.proxy?.code === "IPv4" ? `${item?.url}/` : `${activeData?.proxy?.value}/`}`}
                onClick={() => handlerChoiceCountry(item)}
              >
                {item?.label || item?.code}
              </span>
            </SwiperSlide>
          ))}
        {modalCountries?.length > 0 ? (
          <SwiperSlide
            className="countries-item"
            tag="li"
            // style={{ height: "auto" }}
            key={"more"}
          >
            <button
              className={`countries-button countries-button-more ${
                modalCountries.find((item) => item.id === localCountry.id)
                  ? "countries-button-more--active"
                  : ""
              }`}
              // to={`/${lang}/${activeData?.proxy?.code === "IPv4" ? `${item?.url}/` : `${activeData?.proxy?.value}/`}`}
              onClick={handlerMore}
            >
              {`${prices?.more} +${modalCountries?.length}`}
            </button>
          </SwiperSlide>
        ) : null}
      </Swiper>
      <CountriesModal
        localCountry={localCountry}
        modalCountries={modalCountries}
        setLocalCountry={setLocalCountry}
        setIsModalCountryWasSelected={setIsModalCountryWasSelected}
      />
    </>
  );
}, isLocalCountryNotSelected);
