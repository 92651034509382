import { useEffect, useState } from "react";
import { passwordPattern } from "../../../../../../../utils/constants/passwordPattern";
import { ModalInput } from "../../../../../../../components/ui/ModalInput/ModalInput";
import { useTranslation } from "../../../../../../../hooks";
import "./AccessByLogin.scss";
import { CopyData } from "../../../../../../../components/ui/CopyData/CopyData";

export const AccessByLogin = ({ watch, initData }) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const { cabinet: { orders: { access: {
    loginField,
    passwordField
    // clearData
  } } } } = useTranslation();

  // const resetForm = () => {
  //   reset((formValues) => ({
  //     ...formValues,
  //     login: "",
  //     password: ""
  //   }));
  // };

  useEffect(() => {
    setLogin(watch("login"));
    setPassword(watch("password"));
  }, [watch]);

  return (
    <div className="access-by-login" onClick={(e) => e.preventDefault()}>
      <div className="access-by-login__login">
        <ModalInput
          label={loginField}
          type="text"
          name="login"
          defaultValue={initData.login}
          watch={watch}
          readonly={true}
          rightIcon={<CopyData data={login}/>}
          rules={{
            validate: (value) => value?.length >= 4,
            pattern: passwordPattern
          }}
        />
      </div>
      <div className="access-by-login__password">
        <ModalInput
          label={passwordField}
          type="text"
          name="password"
          defaultValue={initData.password}
          watch={watch}
          readonly={true}
          rightIcon={<CopyData data={password}/>}
          rules={{
            validate: (value) => value?.length >= 6,
            pattern: passwordPattern
          }}
        />
      </div>
      {/*<div className="access-by-login__clear">*/}
      {/*  <IconSvg tag="trash" className="access-by-login__trash" />*/}
      {/*  <div className="access-by-login__clear-text" onClick={resetForm}>*/}
      {/*    {clearData}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
