import {errorToast} from "./customToast.helper";

export const isWrongLength = (text, minLength, maxLength, lang) => {
  const wrongNameLengthNotification = lang === "en"
    ? `Length must be at least ${minLength} characters and no more than ${maxLength}`
    : `Длина должна быть не менее ${minLength} символов и не более ${maxLength}`
  
  if (text.length < minLength || text.length > maxLength) {
    return errorToast(wrongNameLengthNotification, lang);
  }

  return false;
};
