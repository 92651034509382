export const tokenName = "rcGA4tests";

const createToken = () => {
  return Array(32)
    .fill("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
    .map((x) => x[Math.floor(Math.random() * x.length)])
    .join("")+new Date().getTime()+Array(4)
    .fill("ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz")
    .map((x) => x[Math.floor(Math.random() * x.length)])
    .join("")
};

export const setClientToken = () => {
  let clientToken = localStorage.getItem("clientToken");
  let rcGA4testsToken = localStorage.getItem(tokenName);

  if (clientToken) {
    localStorage.setItem(tokenName, clientToken);
    localStorage.removeItem("clientToken");
    return;
  }
  
  if (!rcGA4testsToken) {
    localStorage.setItem(tokenName, createToken());
  }
};
