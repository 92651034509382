import "./CustomTextArea.scss";

export const CustomTextArea = ({
label, value = "", onChange, placeholder, className = "", ...rest
}) => (
    <div className="custom-textarea">
      {label && (
        <label className="custom-textarea__label">
          {label}
        </label>
      )}
      <textarea
        className={`${className} custom-textarea__textarea`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
    </div>
  );
