import {forwardRef, useState} from "react";
import "./Input.scss";
import {classNames} from "../../../utils/helpers/classNames";

export const Input = forwardRef(({ label, value, onChange, placeholder, disabled, rightIcon }, ref) => {
  const [isFocused, setIsFocused] = useState(false);
  
  return (
    <div className={classNames(
      "default-input__field", {
        "default-input__field--disabled": disabled,
        "default-input__field--focused": isFocused
      })}>
      <div className="default-input__input-container">
        <div className="default-input__input-label">{label}</div>
        <input
          ref={ref}
          className="default-input__input"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
      {rightIcon && (
        <div className="default-input__right-icons">{rightIcon}</div>
      )}
    </div>
  );
});
