import {IPV4_MIN_QUANTITY, IPV6_MIN_QUANTITY, ISP_MIN_QUANTITY, MOBILE_MIN_QUANTITY, PROXY_TYPES} from "../constants";

export const getInitialQuantity = (proxyType) => {
  if (!proxyType) {
    return;
  }
  
  switch (proxyType) {
    case PROXY_TYPES.ipv4.code:
      return `${IPV4_MIN_QUANTITY}`
    
    case PROXY_TYPES.ipv6.code:
      return `${IPV6_MIN_QUANTITY}`
    
    case PROXY_TYPES.mobile.code:
      return `${MOBILE_MIN_QUANTITY}`
    case PROXY_TYPES.isp.code:
      return `${ISP_MIN_QUANTITY}`
  }
};
