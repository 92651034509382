import { Link } from "react-router-dom";
import { useDispatchedActions, useTranslation } from "../../../hooks";
import { RulesCheckBox } from "../../ui/RulesCheckBox/RulesCheckBox";
import "./AgreeWithRules.scss";

export const AgreeWithRules = ({ defaultValue, handler }) => {
  const { main: { agreeWithRules: { iAgree, and, privacyPolicy, termsOfUse } } } = useTranslation();

  const {
    setFooterModal
  } = useDispatchedActions();

  return (
    <RulesCheckBox
      name="policy"
      id="policy"
      defaultValue={defaultValue}
      label={iAgree}
      handleClick={handler}
      className="agree-with-rules__container"
    >
      <Link
        to="#"
        className="agree-with-rules__link agree-with-rules__policy"
        onClick={() => setFooterModal("privacyPolicy")}
      >
        {privacyPolicy}
      </Link>
      <label htmlFor="policy" className="agree-with-rules__divider">{and}</label>
      <Link
        to="#"
        className="agree-with-rules__link agree-with-rules__terms"
        onClick={() => setFooterModal("termsOfUse")}
      >
        {termsOfUse}
      </Link>
    </RulesCheckBox>
  );
};
