/* eslint-disable quotes */
export const DataIPv6 = [
  {
    title: {
      ru: "Получить список купленных ipv6 прокси",
      en: "To get the ipv4 list of the bought proxies" },
    description: false,
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/proxies" },
    requestParams: {
      proxyType: { value: "ipv6",
        ru: "тип прокси",
        en: "type of proxy" }
    },
    requestExample: { url: "/client-api/v1/<apiKey>/get/proxies?proxyType=ipv6", body: null },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD",
      proxyType: "ipv6",
      countOfProxy: 2,
      proxies: [
        {
          orderId: "orderId1",
          ips: [
            {
              id: "id1",
              ip: "140.82.53.23:10002",
              country: "FRA",
              dateStart: "2022-08-03T14:04:59.131+00:00",
              dateEnd: "2022-10-02T14:04:59.159+00:00",
              protocol: "SOCKS5",
              authInfo: {
                login: "authLogin123",
                password: "authPassword"
              }
            },
            {
              id: "id2",
              ip: "140.82.53.23:10004",
              country: "FRA",
              dateStart: "2022-08-03T14:04:59.131+00:00",
              dateEnd: "2022-10-02T14:04:59.159+00:00",
              protocol: "SOCKS5",
              authInfo: {
                login: "authLogin123",
                password: "authPassword"
              }
            }
          ]
        }
      ]
    },
    responseParams: {
      proxies: {
        ru: "список активных заказов",
        en: "the list of active ipv4 proxies" },
      orderId: {
        ru: "ID заказа. IPv6, в отличие от остальных типов прокси, позволяет продлевать только весь заказ целиком",
        en: "//too"
      },
      id: {
        ru: "ID прокси",
        en: "proxy ID" },
      country: {
        ru: "альфа3 код страны",
        en: "alfa3 country code" },
      dateStart: {
        ru: "дата начала аренды",
        en: "the rent start date" },
      dateEnd: {
        ru: "дата окончания аренды",
        en: "the rent end date" },
      protocol: { value: "HTTPS/SOCKS5",
        ru: "протокол",
        en: "protocol" },
      authInfo: {
        ru: "данные для авторизации",
        en: "the data for authorization" }
    }
  },
  {
    title: {
      ru: "Вычислить цену для покупки",
      en: "Calculate purchase price" },
    description: false,
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/order/price" },
    requestParams: {
      proxyType: { value: "ipv6",
        ru: "тип прокси",
        en: "type of proxy" },
      days: {
        ru: "количество дней для аренды (доступные значения можно получить другим запросом)",
        en: "the number of rental days (available values can be got by other request)"
      },
      goal: {
        ru: "цель использования прокси",
        en: "goal of using proxy",
      },
      country: {
        ru: "код страны в формате alpha3 (доступные значения можно получить другим запросом)",
        en: "alfa3 country code (available values can be got by other request)"
      },
      count: {
        ru: "количество прокси. Значение должно быть от 10 штук",
        en: "the number of proxy. The value must be more then 10" }
    },
    requestExample: {
      url: "/client-api/v1/<apiKey>/get/order/price?proxyType=ipv6&days=30&goal=instagram&country=FRA&count=10",
      body: null
    },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD",
      proxyType: "ipv6",
      days: 30,
      count: 10,
      goal: "instagram",
      amount: 7.25,
      price: 0.72
    },
    responseParams: {
      amount: {
        ru: "общая стоимость заказа",
        en: "total amount" },
      price: {
        ru: "цена за 1 прокси",
        en: "price for one proxy" }
    }
  },
  {
    title: {
      ru: "Вычислить цену для продления",
      en: "Calculate extending price" },
    description: false,
    request: { type: "GET", url: "/client-api/v1/<apiKey>/get/extend/price" },
    requestParams: {
      proxyType: { value: "ipv6",
        ru: "тип прокси",
        en: "type of proxy" },
      days: {
        ru: "количество дней для продления (доступные значения те же, что и для покупки)",
        en: "the number of renewal days (the available values are the same as for the purchase)"
      },
      orderId: {
        ru: "ID заказов, которые необходимо продлить (разрешается указывать несколько ID через запятую)",
        en: "order IDs that require renewal (it is allowed to indicate several IDs separated by commas)"
      }
    },
    requestExample: { url: "/client-api/v1/<apiKey>/get/extend/price?proxyType=ipv6&days=30&orderId=orderId1,orderId2", body: null },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 13.09,
      currency: "USD",
      proxyType: "ipv6",
      days: 30,
      count: 20,
      amount: 123.42,
      price: 6.17,
      ordersId:["orderId1","orderId2"],
      ipId:["id1","id2","id3","id4","id5","id6","id7","id8","id9","id10","id11","id12","id13","id14","id15","id16","id17","id18","id19","id20"]
    },
    responseParams: {
      amount: {
        ru: "общая стоимость",
        en: "total amount" },
      price: {
        ru: "цена за продление одного прокси",
        en: "price for extending one proxy" },
      ordersId: {
        ru: "список ID заказов для продления",
        en: "the list of order IDs for extending" },
      ipId: {
        ru: "список ID прокси для продления",
        en: "the list of proxy IDs for extending" }
    }
  },
  {
    title: { ru: "Покупка прокси", en: "Placing an order" },
    description: false,
    request: { type: "POST", url: "/client-api/v1/<apiKey>/order" },
    requestParams: {
      proxyType: { value: "ipv6",
        ru: "тип прокси",
        en: "type of proxy" },
      days: {
        ru: "количество дней для аренды (доступные значения можно получить другим запросом)",
        en: "the number of rental days (available values can be got by other request)"
      },
      goal: {
        ru: "цель использования прокси",
        en: "goal of using proxy" },
      country: {
        ru: "альфа3 код страны",
        en: "alfa3 country code"
      },
      count: {
        ru: "количество прокси. Значение должно быть от 10 штук",
        en: "the number of proxies. Value can be more then 10"
      },
      authType: {
        value: "login/ip",
        ru: "тип авторизации",
        en: "authorization type"
      },
      authIp: {
        ru: "IP адрес авторизации. Это поле является обязательным только если authType = ip",
        en: "authorization IP address. This field is required only if authType = ip"
      },
      protocol: { value: "HTTPS/SOCKS5",
        ru: "протокол",
        en: "protocol" },

    },
    requestExample: {
      url: "/client-api/v1/<apiKey>/order",
      body: {
        proxyType: "ipv6",
        days: 30,
        goal: "instagram",
        country: "GBR",
        count: 10,
        authType: "login",
        protocol:"SOCKS5"
      }
    },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 4.09,
      currency: "USD",
      proxyType: "ipv6",
      orderNumber: "DV-211552356-jA"
    },
    responseParams: {
      orderNumber:{
        ru:"номер заказа",
        en:"order number"},
      balance:{
        ru:"сумма на балансе после покупки",
        en:"the balance after the purchase"}
    }
  },
  {
    title: {
      ru: "Продление прокси",
      en: "Extending proxy" },
    description: false,
    request: { type: "POST", url: "/client-api/v1/<apiKey>/extend" },
    requestParams: {
      proxyType: { value: "ipv6",
        ru: "тип прокси",
        en: "type of proxy" },
      days: {
        ru: "количество дней для продления (доступные значения те же, что и для покупки)",
        en: "the number of renewal days (the available values are the same as for the purchase)"
      },
      orderId: {
        ru: "список ID заказов, которые нужно продлить",
        en: "the list of order IDs to be extended"
      },
    },
    requestExample: {
      url: "/client-api/v1/<apiKey>/extend",
      body: {
        proxyType: "ipv6",
        days: 30,
        orderId: ["orderId1"]
      }
    },
    responseBody: {
      success: true,
      user: "client@gmail.com",
      balance: 4.09,
      currency: "USD"
    },
    responseParams: {
      balance:{
        ru:"сумма на балансе после продления",
        en:"the balance after extending"}
    }
  }
];
