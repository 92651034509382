import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getPaymentMethods } from "../utils/helpers/getPaymentMethods.helper";

export const useCurrentPaymantSystems = () => {
  // **Props
  const { lang } = useParams();

  // **Redux State
  const { paymentSystems, user, proxies } = useSelector((state) => state.content);

  // local State
  const [currentPaySys, setCurrentPaySys] = useState([]);
  useEffect(() => {
    if (proxies?.fiveDollarLogick?.enabled) {
      if (paymentSystems?.isLoaded && proxies?.isLoaded) {
        if (paymentSystems?.data[lang]) {
          const paySysLang = paymentSystems?.data[lang];
          if (proxies?.data?.balance) {
            setCurrentPaySys(getPaymentMethods(paySysLang, lang));
          } else {
            const filtredPaySys = paySysLang.filter((item) => item?.code !== "balance");
            setCurrentPaySys(getPaymentMethods(filtredPaySys, lang));
          }
        }
      }
    } else if (paymentSystems?.isLoaded && user?.isAuthLoaded && proxies?.isLoaded) {
      if (paymentSystems?.data[lang]) {
        const paySysLang = paymentSystems?.data[lang];
        if (proxies?.data?.balance && user?.isAuth) {
          setCurrentPaySys(getPaymentMethods(paySysLang, lang));
        } else {
          const filtredPaySys = paySysLang.filter((item) => item?.code !== "balance");
          setCurrentPaySys(getPaymentMethods(filtredPaySys, lang));
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, proxies?.isLoaded, user?.isAuthLoaded, paymentSystems?.isLoaded]);
  return currentPaySys;
};
