export const inputCorrection = (e, setResult) => {
  // only number [0...9]
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
  
  if (String(e.target.value).startsWith("0")) {
    e.target.value = e.target.value.slice(1);
    
    return;
  }
  

  // slice value if length > 5
  if (e.target.value.length > 5) {
    e.target.value = e.target.value.slice(0, 5);
    
    return;
  }
  
  if (e.target.value === "0" && e.target.value.length === 1) {
    return;
  }
  
  setResult(e.target.value);
};
