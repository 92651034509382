import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRentPeriods } from "../../../utils/helpers";
import { CustomSelect } from "../CustomSelect/CustomSelect";
import "./DaysSelect.scss";

// eslint-disable-next-line no-empty-function
export const DaysSelect = ({ selectedRentPeriod, proxyCode, label, onChange = () => {}, isLoading }) => {
  const { lang } = useParams();

  const { rentPeriods } = useSelector((state) => state.content);

  // const currentRentPeriods = getRentPeriods(rentPeriods.data[proxyCode], lang, proxyCode);
  const currentRentPeriods = getRentPeriods(rentPeriods.data[proxyCode], lang, proxyCode);

  // useEffect(() => {
  //   if (!isMounted.current) {
  //     isMounted.current = true;
  //     return;
  //   }
  //
  //   onChange(currentRentPeriods[0]);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [proxyCode]);

  // const handlerSetDays = (data) => {
  //   setDays(data);
  //   fetchPrice({ rentId: data.id });
  // };

  return (
    <div className="custom-days-select">
      <CustomSelect
        label={label}
        value={selectedRentPeriod || currentRentPeriods[0]}
        // className="index__select"
        // classNamePrefix="index__select"
        isSearchable={false}
        changeFunc={onChange}
        options={currentRentPeriods}
        // placeholder={formPlaceholders?.rentPeriod}
        disabled={isLoading}
      />
    </div>
  );
};
