import { useSelector } from "react-redux";
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams, useSearchParams
} from "react-router-dom";

import { useEffect } from "react";
import { getFilteredCountry } from "../utils/helpers/getFilteredCountry.helper";
import { getCountries } from "../utils/helpers";
import { useDispatchedActions } from "./useDispatchedActions";
import {PROXY_TYPES} from "../utils/constants";

export const useCheckPathParams = () => {
  // **Props
  const { lang, country } = useParams();
  const [ searchParams ] = useSearchParams()
  const location = useLocation();
  const navigate = useNavigate();
  const matchISP = useMatch("/:lang/isp");
  const matchIPv6 = useMatch("/:lang/ipv6");
  const matchMobile = useMatch("/:lang/mobile");
  const matchIPv4 = useMatch("/:lang");
  const matchCabinet = useMatch("/:lang/dashboard");
  const matchIPv4WithCountry = useMatch("/:lang/:country");
  const matchRecoveryPassword = useMatch("/:lang/api/recovery/:hash");
  const ispOrIpv6Country = searchParams.get("country");
  // const matchIPv6WithCountry = useMatch("/:lang/ipv6/:country");
  // const matchMobileWithCountry = useMatch("/:lang/mobile/:country");
  // Dispatch
  const { setActiveProxy, setActiveCountry } = useDispatchedActions();
  
  // **Redux State
  const { countries, activeData, proxies } = useSelector(
    (state) => state.content
  );

  const { mobileTariffs, isMobileTariffsLoaded } = useSelector(
    (state) => state.mobileProxy
  );
  
  const checkParams = (proxyCode, countryValue = false) => {
    if (!countries?.isLoaded || !proxies?.isLoaded) return;
    
    const checkedProxy = proxies?.enabled.find(
      (item) => item?.code === proxyCode
    );
    
    if (!checkedProxy) {
      navigate(`/${lang}/`);
      return null;
    }
    const proxyCountry =
      checkedProxy?.code !== "MOBILE"
        ? getCountries(countries?.data, lang).filter((item) =>
          item?.showOnFrontType.includes(checkedProxy?.code)
        )
        : getCountries(mobileTariffs[activeData?.currency?.id], lang, {
          countryCode: "EUROPE"
        });
    
    if (!countryValue) {
      if (activeData?.proxy?.code !== checkedProxy?.code) {
        setActiveProxy(checkedProxy);
      }
      return null;
    } else {
      // find country
      const filteredCountry = getFilteredCountry(proxyCountry, countryValue);
      if (filteredCountry) {
        // save country params
        if (
          activeData?.proxy?.code !== checkedProxy?.code ||
          (activeData?.country?.id !== filteredCountry?.id)
        ) {
          setActiveCountry({ id: filteredCountry.id, code: filteredCountry.code });
          setActiveProxy(checkedProxy);
        }
        return null;
      } else {
        switch (proxyCode) {
          case PROXY_TYPES.ipv4.code:
            setActiveCountry({ id: "", code: "" });
            navigate(`/${lang}/`);
            break;
          
          case PROXY_TYPES.ipv6.code:
            setActiveCountry({ id: "", code: "" });
            navigate(`/${lang}/ipv6/`);
            break;
          
          case PROXY_TYPES.isp.code:
            setActiveCountry({ id: "", code: "" });
            navigate(`/${lang}/isp/`);
            break;
          
          case PROXY_TYPES.mobile.code:
            setActiveCountry({ id: "", code: "" });
            navigate(`/${lang}/mobile/`);
            break;
          
          default:
            navigate(`/${lang}/`);
        }
        return null;
      }
    }
  };
  
  useEffect(() => {
    // if (matchIPv6WithCountry) {
    //   return checkParams("IPv6", country);
    // }
    
    if (matchIPv6) {
      checkParams("IPv6", ispOrIpv6Country);
      return ;
    }
    
    if (matchISP) {
      checkParams("ISP", ispOrIpv6Country);
      return ;
    }
    
    if (matchMobile && mobileTariffs[activeData?.currency?.id]) {
      checkParams("MOBILE");
      return ;
    }
    
    // if (matchMobileWithCountry && mobileTariffs[activeData?.currency?.id]) {
    //   return checkParams("MOBILE", country);
    // }
    
    if (matchIPv4WithCountry && !matchIPv6 && !matchMobile && !matchCabinet) {
      checkParams("IPv4", country);
      return ;
    }
    
    if (matchIPv4 || matchRecoveryPassword) {
      checkParams("IPv4");
      return ;
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, location.pathname, country, isMobileTariffsLoaded]);
};
