export function addGoogleTagManagerNoscript() {
  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-TJ5WHXZ';
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  
  noscript.appendChild(iframe); // Додаємо iframe в noscript
  document.body.appendChild(noscript); // Додаємо noscript в body
}
