import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Portal } from "../../../Portal/Portal";
import { errorToast } from "../../../../../utils/helpers/customToast.helper";
import { ApiService } from "../../../../../services/ApiService";
import { NotificationIcon } from "./components/NotificationIcon/NotificationIcon";
import { NotificationsList } from "./components/NotificationsList/NotificationsList";
import "./Notifications.scss";

const clickOnChild = (target) => target.closest(".user-notifications-list") || target.closest(".user-notifications");

export const Notifications = () => {
  const [isNotificationListOpen, setIsNotificationListOpen] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  const { lang } = useParams();

  const { user: { isAuth }, isNotificationsEnabled } = useSelector((state) => state.content);

  const setLoadedData = (newNotifications, unread, total) => {
    if (notifications) {
      setNotifications((current) => ({
          en: [...current.en, ...newNotifications.en],
          ru: [...current.ru, ...newNotifications.ru]
        }));
    } else {
      setNotifications(newNotifications);
    }
    setQuantity(unread);
    setTotalCount(total);
  };

  const setNotificationAsRead = async () => {
    if (!notifications || !notifications[lang]?.length) return;

    const unreadNotifications = notifications[lang]
      .filter((notifications) => !notifications?.read)
      .map((notifications) => notifications?.id);

    if (!unreadNotifications?.length) return;

    try {
      const { data: { newNotificationCount }, status } = await ApiService.setNotificationsAsRead({
        ids: unreadNotifications
      });

      if (status !== 200) {
        throw new Error();
      }

      setNotifications((current) => ({
          en: current.en.map((notification) => ({ ...notification, read: true })),
          ru: current.ru.map((notification) => ({ ...notification, read: true }))
        }));

      setQuantity(newNotificationCount);

    } catch (e) {
      errorToast("Something went wrong", lang);
    }
  };

  const clearNotifications = async () => {
    if (!notifications || isLoading) return;

    setIsLoading(true);
    try {
      const { status } = await ApiService.removeNotifications();

      if (status !== 200) {
        throw new Error();
      }

      setNotifications(null);
      setQuantity(0);

    } catch (e) {
      errorToast("Something went wrong", lang);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuth) return;

    const params = {
      page,
      size: 10
    };

    setIsLoading(true);
    (async () => {
      try {
        const { data: { content, newNotificationCount, totalElements }, status } = await ApiService.getNotifications(params);

        if (status !== 200) throw new Error();

        setLoadedData(content, newNotificationCount, totalElements);

      } catch (e) {
        errorToast("Something went wrong", lang);
      } finally {
        setIsLoading(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, page]);

  const openNotifications = () => {
    setIsNotificationListOpen(true);
    // setupScroll("add");
  };

  const closeNotifications = () => {
    setIsNotificationListOpen(false);
    setNotificationAsRead();
    // setupScroll("remove");
  };

  useEffect(() => {
    const close = ({ target }) => {
      if (!isNotificationListOpen) return;

      if (clickOnChild(target)) {
        return;
      }

      closeNotifications();
    };

    document.addEventListener("click", close);

    return () => document.removeEventListener("click", close);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, isNotificationListOpen]);

  const showMoreHandler = () => {
    setPage((current) => current + 1);
  };

  if (!isAuth || !isNotificationsEnabled) {
    return;
  }
  return (
    <div className="user-notifications">
      <NotificationIcon
        handler={openNotifications}
        quantity={quantity}
      />
      <Portal
        node={document.body}
        components={<NotificationsList notifications={notifications} isOpen={isNotificationListOpen} handler={closeNotifications} clearNotifications={clearNotifications} showMoreHandler={showMoreHandler} isLoading={isLoading} totalCount={totalCount} />}
      />
    </div>
  );
};
