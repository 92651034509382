import { useState } from "react";
import { Button } from "../../../../../../../components/ui/Buttons/Button/Button.jsx";
import { useTranslation } from "../../../../../../../hooks";
import { MobileProxyItem } from "./MobileProxyItem";

export const DesctopProxyList = ({ data, handlerClick }) => {
  const INIT_CARDS_DISPLAY_COUNT = 6;
  const CARDS_DISPLAY_STEP = 3;

  const [cardsDisplayCount, setCardsDisplayCount] = useState(INIT_CARDS_DISPLAY_COUNT);

  const { header: { notifications: { showMore } } } = useTranslation();

  const showMoreHandler = () => {
    setCardsDisplayCount((current) => current + CARDS_DISPLAY_STEP);
  };

  return (
    <>
      <ul className="mobile-list">
        { data && data?.length > 0 ? data.slice(0, cardsDisplayCount).map((item) => (
          <li
            className="mobile-list__item"
            key={item?.id}
          >
            <MobileProxyItem data={item} handlerClick={handlerClick} />
          </li>
        )) : null}
      </ul>
      {data?.length > cardsDisplayCount && (
        <Button onClick={showMoreHandler} initClass="mobile-list__show-more">{showMore}</Button>
      ) }
    </>
  );
};
