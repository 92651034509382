import { useEffect } from "react";

export const DropDown = ({ closeSelect, children }) => {
  useEffect(() => {
    const onClose = ({ target }) => {
      if (target.closest(".solutions")) {
        return;
      }

      closeSelect();
    };

    window.addEventListener("click", onClose);

    return () => window.removeEventListener("click", onClose);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="solutions__dropdown">
      {children}
    </div>
  );
};
