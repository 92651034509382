import {IconSvg} from "../../../../../../../utils/constants/icons/iconSvg.constant";
import {NotesFilter} from "../Filters/NotesFilter";
import {classNames} from "../../../../../../../utils/helpers/classNames";
import {useState} from "react";

export const FormatCellNotesHead = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const handleOpen = (e) => {
    e.stopPropagation();
  
    if (!e.target.closest(".notes-filter")) {
      setIsOpen((current) => !current);
    }
  };
  
  return (
    <div className="table__notes-head" onClick={handleOpen}>
      <div className="table__notes-head__container">
        {title}
        <div className="table__notes-head__icon-container">
          <IconSvg tag="loupe" className="table__notes-head__icon" />
          <div className={classNames({"table__filter--hidden": !isOpen})}>
            <NotesFilter isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
        </div>
      </div>
    </div>
  );
};
