import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDebounceValue, useDispatchedActions, useTranslation } from "../../../../../../hooks";
import { ModalInput } from "../../../../../../components/ui/ModalInput/ModalInput";
import { ApiService } from "../../../../../../services/ApiService";
import { errorToast } from "../../../../../../utils/helpers/customToast.helper";
import { emailPattern } from "../../../../../../utils/constants/emailPattern";

export const ClientEmail = ({ methods }) => {
  // **Props
  const { lang } = useParams();

  const [clientEmail, setClientEmail] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const debounsedEmail = useDebounceValue(clientEmail, 500);
  const { paymentSystems } = useSelector((state) => state.content);
    // **Hooks
    const {
      header: { formElem },
      formPlaceholders
    } = useTranslation();
      // **Dispatch
  const { fetchPaymentSystems } = useDispatchedActions();

  const { errors } = methods.formState;

    const handleInputChange = (e) => {
    e.preventDefault();
    setClientEmail(e.target.value);
  };
  // send mail for back

  useEffect(() => {
    // (async () => {
      if (clientEmail === debounsedEmail && debounsedEmail !== paymentSystems?.data?.email) {
        const testEmail = emailPattern;
        const checkEmail = testEmail.test(debounsedEmail.toLowerCase());
        if (checkEmail) {
          try {
            // setIsLoading(true);

            ApiService.sendEmailInBase({
              email: debounsedEmail,
              localeCode: lang
            });

            fetchPaymentSystems({ email: debounsedEmail });
          } catch (e) {
            errorToast("Something went wrong");
          } finally {
            // setIsLoading(false);
          }
        }
      }
    // })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounsedEmail]);

  const isInvalidEmail = errors.email?.type === "pattern";

  return (
    <>
      <ModalInput
        type="email"
        name="mail"
        defaultValue={JSON.parse(localStorage.getItem("client_profile"))?.email}
        readonly={!!JSON.parse(localStorage.getItem("client_profile"))?.email}
        handler={handleInputChange}
        placeholder={formPlaceholders?.email}
        rules={{
          required: true,
          pattern: emailPattern
        }}
        // disabled={isLoading}
        // loader={isLoading}
      />
      {isInvalidEmail && (
        <p className="modal__form-wrong modal__login-hint">
          {formElem.invalidEmail}
        </p>
      )}
    </>
  );
};
