import {classNames} from "../../../../../../../../../utils/helpers/classNames";
import {useTranslation} from "../../../../../../../../../hooks";

export const StatusOption = ({value}) => {
  const {
    cabinet: { orders: { column: { ip: { active, ending } } } }
  } = useTranslation();
  
  return (
    <div
      className={classNames("main-filter__status", {
        "menu__status--active": !value,
        "menu__status--ending": value
      })}
    >
      <span
        className={classNames("main-filter__status-icon", {
          "main-filter__status-icon--active": !value,
          "main-filter__status-icon--ending": value
        })}
      />
      {value ? ending : active}
    </div>
  );
};
