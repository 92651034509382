import { useEffect } from "react";
import { DescriptionsAPI } from "../../../../components/common/DescriptionsAPI/DescriptionsAPI";
import { Main } from "./Main/Main";
import "./Documentation.scss";

export const Documentation = () => {
  const controller = new AbortController();

  useEffect(() => () => controller.abort()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  , []);

  return (
    <>
      <Main controller={controller} />
      <DescriptionsAPI />
    </>
  );
};
