import { combineReducers, configureStore } from "@reduxjs/toolkit";

import CabinetReducer from "./reducers/CabinetReducer/Cabinet.reducer";
import ContentReducer from "./reducers/ContentReducer/Content.reducer";
import FooterReducer from "./reducers/FooterReducer/Footer.reducer";
import HeaderReducer from "./reducers/HeaderReducer/Header.reducer";
import MobileProxyReducer from "./reducers/MobileProxyReducer/MobileProxy.reducer";
import ModalReducer from "./reducers/ModalReducer/Modal.reducer";
import TranslationReducer from "./reducers/TranslationReducer/Translation.reducer";

const rootReducer = combineReducers({
  content: ContentReducer,
  mobileProxy: MobileProxyReducer,
  translation: TranslationReducer,
  header: HeaderReducer,
  footer: FooterReducer,
  cabinet: CabinetReducer,
  modal: ModalReducer
});

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false
        // {
        //   // Ignore these action types
        //   ignoredPaths: ["translation", "prices", "index", "content"]
        // }
      }),
    // devTools: process.env?.NODE_ENV === "development",
    preloadedState
  });
