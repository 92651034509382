export const sortFunc = (a, b, param, sortParam = null) => {
  if (sortParam === "MIN") {
    if (a?.[param] > b?.[param]) return 1;
    if (a?.[param] === b?.[param]) return 0;
    if (a?.[param] < b?.[param]) return -1;
    return 0;
  } else {
    if (a?.[param] < b?.[param]) return 1;
    if (a?.[param] === b?.[param]) return 0;
    if (a?.[param] > b?.[param]) return -1;
    return 0;
  }
};
