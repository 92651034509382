import { capitalize } from "./capitalize.helper";

export const getCountryName = (code, lang, countries) => {
  if (!countries) {
    return "";
  }
  
  const country = countries.find((c) => c.code === code)
  
  const countryName = lang === "en" ? capitalize(country?.enName) : capitalize(country?.name);
  
  return countryName || "";
};
