import { Link, useParams } from "react-router-dom";
import { useTranslation } from "../../../hooks/useTranslation";

import { DataError } from "../../../utils/constants/endpoints/errors.constant";
import { DataIPv4 } from "../../../utils/constants/endpoints/IPv4.constant";
import { DataIPv6 } from "../../../utils/constants/endpoints/IPv6.constant";
import { DataMobile } from "../../../utils/constants/endpoints/Mobile.constant";
import { MainData } from "../../../utils/constants/endpoints/main.constant";
import { DataISP } from "../../../utils/constants/endpoints/ISP.constant";

import { Endpoints } from "./components/Endpoints/Endpoints";

import "./DescriptionsAPI.scss";

export const DescriptionsAPI = () => {
  // **Redux State
  const { lang } = useParams();
  const { descriptionsAPI } = useTranslation().cabinet.documentation;

  const generateDescription = (item, index) => {
    switch (index) {
      case 1:
        return <span key={item} className="documentation__body-red">{item}</span>;
      case 3:
        return <Link key={item} to={`/${lang}/contacts/`} className="documentation__body-to-contacts">{item}</Link>;
      default:
        return item;
    }
  };

  return (
    <div className="documentation__body">
      <div className="documentation__body__header">
        <div className="documentation__body__title">
          {descriptionsAPI?.title}
        </div>
        <p className="documentation__body__descriptions">
          {descriptionsAPI?.info
            .split("#")
            .map((item, index) => generateDescription(item, index)
            )}
        </p>
      </div>
      <div className="documentation__body__api">
          <Endpoints data={MainData} title={descriptionsAPI?.mainTitle} />
          <Endpoints data={DataIPv4} title={descriptionsAPI?.ipv4Title} />
          <Endpoints data={DataIPv6} title={descriptionsAPI?.ipv6Title} />
          <Endpoints data={DataISP} title={descriptionsAPI?.ispTitle} />
          <Endpoints data={DataMobile} title={descriptionsAPI?.mobileTitle} />
          <Endpoints data={DataError} title={descriptionsAPI?.errorsTitle} />
      </div>
    </div>
  );
};
