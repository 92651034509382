import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { errorToast } from "../../../../../../utils/helpers/customToast.helper";
import { sortFunc } from "../../../../../../utils/helpers";
import { Container } from "../../../../../../components/ui/Container/Container";
import { useDispatchedActions } from "../../../../../../hooks/useDispatchedActions";
import { useTranslation } from "../../../../../../hooks/useTranslation";
import { NoDataComponent } from "../../../../../../components/common/NoDataComponent/NoDataComponent";
import { defaultPrice } from "../../../../../../utils/constants/defaultData.constant";
import { getCountries } from "../../../../../../utils/helpers/getCountries.helper";
import { Search } from "../../../../../../components/ui/Search/Search";
import { DesctopProxyList } from "./components/DesctopProxyList";
import { MobileProxyList } from "./components/MobileProxyList";

import "./MobileProxy.scss";

export const MobileProxy = (props) => {
  // **Props
  const { sortParams } = props;
  const { lang } = useParams();

  // ** Redux State
  const { activeData } = useSelector((state) => state.content);
  const { mobileTariffs, fetchMobileTariffsError, isMobileTariffsLoaded } =
    useSelector((state) => state.mobileProxy);
  const { mobileProxy, notifications } = useTranslation();

  // **Dispatch
  const { setConfigModal } = useDispatchedActions();

  // *State
  const [searchData, setSearchData] = useState("");
  const [localMobileProxies, setLocalMobileProxies] = useState([]);
  const [localFiltredMobileProxies, setLocalFiltredMobileProxies] = useState(
    []
  );

  // **Local State
  const localConfig = {
    quantity: "1",
    goalCode: "",
    prices: defaultPrice
  };

  const handlerSearch = (e) => {
    setSearchData(e.target.value.toLowerCase());
  };

  // open modal
  const submitHandler = (data, days) => {
    setConfigModal({
      ...localConfig,
      selectedOperator: data?.selectedOperator || data?.operatorWithMinPrice,
      selectedRotation: data?.selectedRotation >= 0 ? data?.selectedRotation : data?.rotationWithMinPrice,
      prices: {
        ...localConfig?.prices,
        amount: data?.minPrice,
        currencyCode: data?.currencyCode
      },
      days,
      country: { id: data.countryId, code: data.countryCode },
      proxyType: "MOBILE",
      mobileOperators:
        data?.operators && data?.operators?.length > 0
          ? data?.operators?.map?.((item) => ({
            ...item,
            rotationMins: item?.rotationMins
              ? item?.rotationMins.map((item) => ({
                ...item,
                value: item?.min,
                label:
                  item?.min === 0
                    ? `${mobileProxy?.changeIPauto}`
                    : `${item?.min} ${mobileProxy?.changeIPTime}`
              }))
              : [],
            value: item?.operatorTag,
            label: item?.operatorName
          }))
          : []
    });
  };

  const sortPriceFunc = (a, b) => sortFunc(a, b, "minPrice", "MIN");
  const sortPopularFunc = (a, b) => sortFunc(a, b, "popularIndex");

  useEffect(() => {
    if (mobileTariffs[activeData?.currency?.id] && mobileTariffs[activeData?.currency?.id]?.length > 0) {
      const tempCountries = getCountries(
        mobileTariffs[activeData?.currency?.id],
        lang,
        {
          countryCode: "EUROPE"
        }
      );
      if (sortParams === "PRICE") {
        setLocalMobileProxies(tempCountries.sort(sortPriceFunc));
      } else if (sortParams === "POPULAR") {
        setLocalMobileProxies(tempCountries.sort(sortPopularFunc));
      } else setLocalMobileProxies(tempCountries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, activeData?.currency?.id, isMobileTariffsLoaded, sortParams]);

  useEffect(() => {
    if (localMobileProxies && localMobileProxies?.length > 0) {
      if (searchData) {
        setLocalFiltredMobileProxies(
          localMobileProxies.filter(
            (item) =>
              item.countryEnName.toLowerCase().includes(searchData) ||
              item.countryName.toLowerCase().includes(searchData)
          )
        );
      } else setLocalFiltredMobileProxies(localMobileProxies);
    }
  }, [searchData, localMobileProxies]);

  if (fetchMobileTariffsError) {
    errorToast(notifications?.error, lang);
  }
  return (
    <>
      <Container>
        <Search className="mobile-country-search" onChange={handlerSearch} />
      </Container>

      {localFiltredMobileProxies?.length > 0 ? (
        <>
          <div className="mobile-list__desktop-container">
            <Container>
              <DesctopProxyList
                data={localFiltredMobileProxies}
                handlerClick={submitHandler}
              />
            </Container>
          </div>
          <div className="mobile-list__mobile-container">
            <MobileProxyList
              data={localFiltredMobileProxies}
              handlerClick={submitHandler}
            />
          </div>
        </>
      ) : <NoDataComponent/>}
    </>
  );
};
