export const getRentPeriods = (fetchedDays, lang, proxyType) => {
  const getRightWord = (days) => {
    if (lang === "ru") {
      if (days > 0 && days < 2) {
        return "день";
      }

      if (days >= 2 && days < 5) {
        return "дня";
      }

      if (days >= 5) {
        return "дней";
      }

      return "дней";
    }

    if (lang === "en") {
      if (days > 0 && days < 2) {
        return "day";
      }

      if (days >= 2) {
        return "days";
      }

      return "days";
    }
    return null;
  };

  const rentDays = fetchedDays.map(({ days, id }) => {
    const word = getRightWord(days);
    return { id, value: `${days} ${word}`, label: `${days} ${word}`, days };
  });
  if (proxyType && proxyType === "IPv6") {
    return rentDays.filter((item) => item.days >= 30);
  }
  return rentDays;
};
