import {useIsomorphicLayoutEffect} from "./useIsomorphicLayoutEffect";

export const useSetZIndex = (selector, index) => {
  return useIsomorphicLayoutEffect(() => {
    const element = document.querySelector(selector);
    if (!element) return;
  
    element.style.zIndex = index;
  
    // eslint-disable-next-line no-return-assign
    return () => element.style.zIndex = "1";
  }, []);
};
