import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../utils/constants/icons/iconSvg.constant";
import { ListWrapperV1 } from "../../../../components/ui/ListWrapperV1/ListWrapperV1";
import { successToast } from "../../../../utils/helpers/customToast.helper";
import { useTranslation } from "../../../../hooks";
// import { classNames } from "../../../../utils/helpers/classNames";

export const Socials = () => {
  // *Props
  const { lang } = useParams();
  // **State
  const { notifications } = useTranslation();

  const { content } = useSelector((state) => state.content);
  const handleCopy = () => successToast(notifications?.copy, lang);

  return (
    content?.isLoaded && (
      <>
        <ListWrapperV1
          listClass="contacts__socials"
          listItemClass="contacts__socials-item"
          data={[
            {
              icon: "telegram",
              name: "Telegram",
              link: content?.data[lang]?.telegramLink,
              linkParams: null,
              linkName: "proxyipv4com"
            },
            {
              icon: "envelope",
              name: "Email",
              link: content?.data[lang]?.email,
              linkParams: "mailto:",
              linkName: "support@proxy-ipv4.com"
            }
          ]}
          bodyItem={(data) => (
            <>
              <div className="contacts__socials-name">{data.name}</div>
              <div className="contacts__socials-link-box">
                <a
                  target="_blank"
                  href={`${data?.linkParams ? data.linkParams : ""}${
                    data?.link ? data?.link : ""
                  }`}
                  // className={classNames("contacts__socials-link", { "contacts__socials-link--email": data.name === "Email" })}
                  className="contacts__socials-link"
                  rel="noreferrer"
                >
                  {data.linkName}
                </a>
                <CopyToClipboard
                  text={data.link}
                  onCopy={handleCopy}
                >
                  <span>
                    <IconSvg tag="copy" className="contacts__socials-copy" />
                  </span>
                </CopyToClipboard>
              </div>
            </>
          )}
        />
        {/* <Button
          font="accent"
          onClick={() => {
            // eslint-disable-next-line no-undef
            jivo_api.open();
            return false;
          }}
        >
          {common?.onlineChat}
        </Button> */}
      </>
    )
  );
};
