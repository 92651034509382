import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CustomModal } from "../../../../../components/ui/CustomModal/CustomModal";
import { Button, GroupButtons } from "../../../../../components/ui/Buttons";
import { ApiService } from "../../../../../services/ApiService";
import { useSetZIndex } from "../../../../../hooks/useSetZIndex";
import { errorToast, successToast, warningToast } from "../../../../../utils/helpers/customToast.helper";
import { getInvalidIp } from "../../../../../utils/validation/ipValidation";
import { isEqualArray } from "../../../../../utils/helpers/isEqualArray";
import { Loading } from "../../../../../components/ui/Loading/Loading";
import { useDispatchedActions, useTranslation } from "../../../../../hooks";
import { setExpireTime } from "../../../../../utils/helpers/setExpireTime";
import { classNames } from "../../../../../utils/helpers/classNames";
import { accessErrorHandler } from "../../../../../utils/helpers/accessErrorHandler";
import { AccessByIp } from "./components/AccessByIp/AccessByIp";
import { AccessByLogin } from "./components/AccessByLogin/AccessByLogin";
import { DeleteIpModal } from "./components/DeleteIpModal/DeleteIpModal";
import { TimerButton } from "./components/TimerButton/TimerButton";
import "./AccessModal.scss";

export const AccessModal = ({ open, close }) => {
  const { lang } = useParams();
  const { notifications, cabinet: { orders: { access: {
      title,
      descriptionLoginPass,
      descriptionIp,
      loginTabTitle,
      accessDataSaved,
      cancel
    } } } } = useTranslation();

  const { updateTableAccessData } = useDispatchedActions();

  const [isDeleteIpModalOpen, setIsDeleteIpModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const [initData, setInitData] = useState({});
  const [additionalIps, setAdditionalIps] = useState([]);
  const [newIps, setNewIps] = useState([]);

  const [accessType, setAccessType] = useState("login");

  useSetZIndex(".cabinet", "999");

  const accessTabs = [
    { code: "login", body: loginTabTitle },
    // { code: "ip", body: ipTabTitle }
    { code: "ip", body: "Whitelist IP" }
  ];

  const methods = useForm({
    mode: "onChange"
  });

  const NEW_IP_PREFIX = "newIp";
  const ADDITIONAL_IP_PREFIX = "additionalIp";

  useEffect(() => {
    if (!open) {
      return;
    }

    (async function () {
      try {
        const { status, data: { login, password, mainIp, additionalIps: ips } } = await ApiService.getAccessModalData();

        if (status !== 200) {
          throw status;
        }

        setAdditionalIps(ips.map((ip, idx) => ({ id: `${ADDITIONAL_IP_PREFIX}-${idx}`, value: ip })));
        setInitData({
          login,
          password,
          mainIp,
          ips
        });

        // methods.setValue("login", login);
        // methods.setValue("password", password);
        // methods.setValue("generalIp", mainIp);
      } catch {
        errorToast("Something went wrong");
      } finally {
        setIsLoaded(true);
      }
    })();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeAccessType = (accessCode) => {
    setAccessType(accessCode);
  };

  const deleteModalHandler = () => {
    setIsDeleteIpModalOpen((current) => !current);
  };

  const onClose = () => {
    close();
    setAccessType("login");
    setIsLoaded(false);
  };

  const getDelay = () => {
    const expireTime = localStorage.getItem("expireTime");

    if (!expireTime || isNaN(+expireTime)) {
      return 0;
    }

    const nowTime = Date.now();
    const delay = Math.floor((expireTime - nowTime + 3000) / 1000);

    if (delay < 0) {
      return 0;
    }

    return delay;
  };

  const isDataTheSame = (data, additionalIps) => {
    if (accessType === "login") {
      return data.login === initData.login && data.password === initData.password;
    }

    if (accessType === "ip") {
      return data.generalIp === initData.mainIp && isEqualArray(additionalIps, initData.ips);
    }
  };

  // const checkAvailableLogin = async (login) => {
  //   if (accessType !== "login") {
  //     return true;
  //   }
  //
  //   const { data: isAvailable } = await ApiService.checkAccessLogin(login);
  //
  //   return isAvailable;
  // };

  const getIpsByPrefix = (data, prefix) => {
      if (!data) return [];

    // eslint-disable-next-line no-unused-vars
      const dataEntries = Object.entries(data).filter(([_, value]) => value);
      const ipsEntries = dataEntries.filter(([key]) => key.startsWith(prefix));

    // eslint-disable-next-line no-unused-vars
      return ipsEntries.map(([_, ip]) => ip);
  };

  const onSubmit = async (data) => {
    const { login, password, generalIp } = data;

    const newIps = getIpsByPrefix(data, NEW_IP_PREFIX);
    const additionalIps = getIpsByPrefix(data, ADDITIONAL_IP_PREFIX);

    if (isDataTheSame(data, [...newIps, ...additionalIps])) {
      onClose();
      return;
    }

    const invalidIp = getInvalidIp([generalIp, ...newIps, ...additionalIps]);

    if (invalidIp) {
      return warningToast(`${notifications?.invalidIp}: ${invalidIp}`, lang);
    }

    setIsLoading(true);

    try {
      // const isAvailableLogin = await checkAvailableLogin(login);
      //
      // if (!isAvailableLogin) {
      //   errorToast(loginExist, lang);
      //   return;
      // }

      const params = {
        login,
        password,
        mainIp: generalIp,
        additionalIps: [...newIps, ...additionalIps]
      };

      const {
        status,
        data: { timeDelay, errorMessage }
      } = await ApiService.changeAccessData(params);

      if (status !== 200) {
        throw status;
      }

      if (errorMessage) {
        accessErrorHandler(errorMessage, lang);
        return;
      }

      setExpireTime(timeDelay);
      updateTableAccessData({ authLogin: login, authPassword: password, authIp: generalIp });
      onClose();
      successToast(accessDataSaved, lang);

    } catch (status) {
      errorToast("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const clearFormFields = (keyForRemove) => {
    const fields = methods.getValues();
    const fieldNamesForRemove = Object.entries(fields)
      .map(([key]) => key)
      .filter((key) => key.startsWith(keyForRemove));

    fieldNamesForRemove.forEach((fieldName) => methods.unregister(fieldName));
  };

  const resetIpAccess = () => {
    methods.unregister("generalIp");
    clearFormFields(NEW_IP_PREFIX);
    clearFormFields(ADDITIONAL_IP_PREFIX);
    setNewIps([]);
    setAdditionalIps(initData?.ips?.map((ip, idx) => ({ id: `${ADDITIONAL_IP_PREFIX}-${idx}`, value: ip })));
  };

  const resetLoginAccess = () => {
    methods.unregister("login");
    methods.unregister("password");
  };

  useEffect(() => {
    if (accessType === "ip") {
      resetLoginAccess();
    } else {
      resetIpAccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessType]);

  return (
    <>
      <CustomModal
        open={open}
        onClose={onClose}
        styles={{ zIndex: "999999999" }}
      >
        <div className="access-modal">
          <div className={classNames(
            "access-modal__container",
            { "access-modal__container--loading": !isLoaded }
          )}>
            { !isLoaded ? (
              <Loading fixed />
            ) : (
              <>
                <div className="access-modal__header">
                  <div className="access-modal__title">{title}</div>
                  <GroupButtons
                    list={accessTabs}
                    active={accessType}
                    handlerClick={changeAccessType}
                  />
                </div>
                <div className="access-modal__description">
                  {accessType === "login" ? descriptionLoginPass : descriptionIp}
                </div>
                <div className="access-modal__body">
                  <FormProvider {...methods}>
                    <form
                      onSubmit={methods.handleSubmit(onSubmit)}
                    >
                      {accessType === "ip" && (
                        <AccessByIp
                          isOpen={open}
                          initMainIp={initData?.mainIp}
                          additionalIps={additionalIps}
                          setAdditionalIps={setAdditionalIps}
                          newIps={newIps}
                          setNewIps={setNewIps}
                          close={onClose}
                          watch={methods.watch}
                          deleteModalHandler={deleteModalHandler}
                          isDisabled={isSubmitDisabled}
                          newIpPrefix={NEW_IP_PREFIX}
                          methods={methods}
                        />
                      )}
                      {accessType === "login" && (
                        <AccessByLogin
                          initData={initData}
                          watch={methods.watch}
                          reset={methods.reset}
                          close={onClose}
                        />
                      )}
                      {/*<div className={classNames(*/}
                      {/*  "access-modal__ip",*/}
                      {/*  { "access-modal__ip--open": accessType === "ip" }*/}
                      {/*)}>*/}
                      {/*  */}
                      {/*</div>*/}
                      {/*<div className={classNames(
                        "access-modal__login",
                        { "access-modal__login--open": accessType === "login" }
                      )}>
                      </div>*/}
                      <div className="access-modal__actions">
                        <Button tab="default" onClick={close}>
                          {cancel}
                        </Button>
                        <TimerButton
                          isLoading={isLoading}
                          delay={getDelay()}
                          isSubmitDisabled={isSubmitDisabled}
                          setIsSubmitDisabled={setIsSubmitDisabled}
                        />
                      </div>
                    </form>
                  </FormProvider>
                </div>
              </>
            ) }
          </div>
        </div>
      </CustomModal>
      <DeleteIpModal
        isOpen={isDeleteIpModalOpen}
        onClose={deleteModalHandler}
        onCloseAccessModal={onClose}
        additionalIps={additionalIps}
        setAdditionalIps={setAdditionalIps}
      />
    </>
  );
};
