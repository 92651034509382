import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useTranslation } from "../../../../../../../hooks";
import { CustomSelect } from "../../../../../../../components/ui/CustomSelect/CustomSelect";
import { useDispatchedActions } from "../../../../../../../hooks/useDispatchedActions";
import { useResize } from "../../../../../../../hooks/useResize";

export const ProxyType = () => {
  // Dispatch
  const { setActiveProxy, setSelectedFolder } = useDispatchedActions();

  // **State
  const { activeData, proxies } = useSelector((state) => state.content);
  const { proxyNav } = useTranslation().header.menu;
  const [searchParams, setSearchParams] = useSearchParams();
  const { width } = useResize();

  const ref = useRef();

  const addToSearch = (value) => {
    searchParams.set("proxyType", value);
    setSearchParams(searchParams);
  };

  const resetFilter = () => {
    searchParams.delete("country");
    searchParams.delete("runningOuts");
    searchParams.delete("description");
    setSearchParams(searchParams);
  };

  const resetFolder = () => {
    setSelectedFolder({ value: null, label: null });
  };

  const desktopHandler = (e, obj) => {
    e.preventDefault();
    setActiveProxy(obj);
    addToSearch(obj?.code);
    resetFilter();
    resetFolder();
  };

  const mobileHandler = (item) => {
    setActiveProxy(item);
    addToSearch(item?.code);
    resetFilter();
    resetFolder();
  };

  useEffect(() => {
    if (width > 993) {
      return;
    }

    if (ref.current && activeData?.proxy?.value) {
      const defaultValue = proxies?.enabled.find((proxy) => proxy.value === activeData?.proxy?.value) || proxies?.enabled[0];

      const currentValue = ref.current.select.getValue();

      if (currentValue[0]?.value !== defaultValue?.value) {

        ref.current.select.setValue(defaultValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeData?.proxy?.value, width]);

  return (
    <>
      <MediaQuery minWidth={993}>
        <ul
          className="proxies__advantages"
        >
          {proxies?.enabled.map((obj) => (
            <li
              key={obj?.value}
              className={`proxies__advantage  ${
                obj?.value === activeData?.proxy?.value
                  ? "proxies__advantage--active"
                  : ""
              }`}
            >
              <Link
                className={`proxies__link ${
                  obj?.value === activeData?.proxy?.value
                    ? "proxies__link--active"
                    : ""
                }`}
                to={"#"}
                onClick={(e) => desktopHandler(e, obj)}
              >
                <span className="proxies__link-text">
                  {obj?.code !== "MOBILE"
                    ? `${proxyNav?.subName} ${obj?.label}`
                    : proxyNav?.subNameMobile}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </MediaQuery>
      <MediaQuery maxWidth={992}>
        <CustomSelect
          ref={ref}
          label={"label"}
          options={proxies?.enabled}
          placeholder={""}
          changeFunc={mobileHandler}
        />
      </MediaQuery>
    </>
  );
};
