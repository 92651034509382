import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";
import "./CreditCard.scss";
import { getPaymentProviderIcon } from "../../../utils/helpers/getPaymentProviderIcon";
import { useTranslation } from "../../../hooks";

export const CreditCard = ({ card }) => {
  const isPayPal = card.cardType === "paypal";

  const { creditCard: { card: cardLabel, paypal: paypalLabel } } = useTranslation();

  return (
    <div className="credit-card">
      <div className="credit-card__heading">
        <div className="credit-card__icon">
          <IconSvg tag={getPaymentProviderIcon(card.cardType)} />
        </div>
        <div className="credit-card__name">{isPayPal ? paypalLabel : cardLabel }</div>
      </div>
      {!isPayPal && (
        <div className="credit-card__card-details">
          <div className="credit-card__number">**** **** {card.lastFourDigits}</div>
          <div className="credit-card__date">EXP {card.expiryDate}</div>
        </div>
      )}
    </div>
  );
};
