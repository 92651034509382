import { ModalInput } from "../../../../../../../components/ui/ModalInput/ModalInput";
import { ipPattern } from "../../../../../../../utils/constants/IpPattern";
import { useTranslation } from "../../../../../../../hooks";
import "./AccessByIp.scss";
import { IconSvg } from "../../../../../../../utils/constants/icons/iconSvg.constant";

export const AccessByIp = ({ methods, newIps, setNewIps, additionalIps, setAdditionalIps, initMainIp, watch, newIpPrefix }) => {
  // const { setIpForDeleting } = useDispatchedActions();

  const { cabinet: { orders: { access: { addIp } } } } = useTranslation();

  // const updateNewIps = (values) => {
  //   if (!values) return;
  //   const valuesEntries = Object.entries(values);
  //   const ipsEntries = valuesEntries.filter(([key]) => key.startsWith(additionalIpPrefix));
  //   const newIpsUpdated = ipsEntries.map(([key, value]) => ({ id: key, value }));
  //   console.log(newIpsUpdated);
  // };

  // useEffect(() => {
  //   watch((values) => {
  //     // updateNewIps(values);
  //
  //     const { generalIp } = values;
  //     if (defaultIp !== values?.generalIp) {
  //       setIp([generalIp, ...ipList]);
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [watch]);

  // const removeIpHandler = (ip) => {
  //   setIpForDeleting(ip);
  //   deleteModalHandler();
  // };

  const addIpHandler = () => {
    const newIp = { id: `${newIpPrefix}-${newIps.length}`, value: "" };
    setNewIps((current) => [...current, newIp].sort((a, b) => b.id.split("-")[1] - a.id.split("-")[1]));
  };

  const removeIpHandler = (id, setter) => {
    setter((current) => current.filter((newIp) => newIp.id !== id));
    methods.unregister(id);
  };

  return (
    <div className="access-by-ip">
      <div className="access-by-ip__container">
        <div className="access-by-ip__selected">
          <ModalInput
            label="IP"
            ipMask
            name="generalIp"
            defaultValue={initMainIp || ""}
            watch={watch}
            rules={{
              required: true,
              pattern: ipPattern
            }}
          />
        </div>
        <div className="access-by-ip__add-more" onClick={addIpHandler}>
          <span>+</span>
          &nbsp;
          <span>{addIp}</span>
        </div>
        <div className="access-by-ip__list-container">
          <ul className="access-by-ip__ips-list">
            {newIps.map((newIp) => (
              <div className="access-by-ip__additional-ip" key={newIp.id}>
                <ModalInput
                  label="IP"
                  ipMask
                  name={newIp.id}
                  defaultValue={newIp.value}
                  watch={watch}
                  rightIcon={
                    <div onClick={() => removeIpHandler(newIp.id, setNewIps)}>
                      <IconSvg tag="trashV2" className="access-by-ip__remove-ip" />
                    </div>
                  }
                  rules={{
                    required: true,
                    pattern: ipPattern
                  }}
                />
              </div>
            ))}
            {additionalIps.map((ip) => (
              <div key={ip.id} className="access-by-ip__additional-ip">
                <ModalInput
                  label="IP"
                  ipMask
                  name={ip.id}
                  defaultValue={ip.value}
                  watch={watch}
                  rightIcon={
                    <div onClick={() => removeIpHandler(ip.id, setAdditionalIps)}>
                      <IconSvg tag="trashV2" className="access-by-ip__remove-ip" />
                    </div>
                  }
                  rules={{
                    required: true,
                    pattern: ipPattern
                  }}
                />
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
