import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CustomModal } from "../../../../../../components/ui/CustomModal/CustomModal";
import { ModalInput } from "../../../../../../components/ui/ModalInput/ModalInput";
import { CustomSelect } from "../../../../../../components/ui/CustomSelect/CustomSelect";
import { Button } from "../../../../../../components/ui/Buttons/Button/Button";
import { useTranslation } from "../../../../../../hooks";
import { ApiService } from "../../../../../../services/ApiService";
import { errorToast, successToast } from "../../../../../../utils/helpers/customToast.helper";
import { ButtonLoader } from "../../../../../../components/ui/ButtonLoader/ButtonLoader";
import "./EnterpriseModal.scss";

export const EnterpriseModal = ({ isOpen, onClose }) => {
  const methods = useForm({ mode: "onChange" });
  const { lang } = useParams();

  const {
    prices: { priceItem: { enterpriseModal: {
      name,
      selectSocials,
      contacts,
      describeResources,
      companyActivity,
      other,
      send,
      successfulSendingMessage
    } } }
  } = useTranslation();

  const socials = [
    { value: "telegram", label: "Telegram" },
    { value: "whatsapp", label: "WhatsApp" },
    { value: "e-mail", label: "E-mail" },
    { value: "other", label: other }
  ];

  const [selectedSocial, setSelectedSocial] = useState(socials[0]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSocials = (social) => {
    setSelectedSocial(social);
  };

  const clearForm = () => {
    methods.reset();
  };

  const handleClose = () => {
    clearForm();
    onClose();
  };

  const sendMessage = async (data) => {
    setIsLoading(true);
    try {
      const { status } = await ApiService.sendEnterpriseSuggestion(data);

      if (status !== 200) {
        throw new Error();
      }
    } catch {
      errorToast("Something went wrong", lang);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (formData) => {
    const params = {
      ...formData,
      contactType: selectedSocial.value
    };

    await sendMessage(params);
    handleClose();
    successToast(successfulSendingMessage, lang);
  };

  return (
    <div className="enterprise-modal">
      <CustomModal
        open={isOpen}
        onClose={handleClose}
        styles={{ zIndex: "999999999" }}
      >
        <FormProvider {...methods}>
          <div className="enterprise-modal__title">{companyActivity}</div>
          <form className="enterprise-modal__form" onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalInput
              label={name}
              name="name"
              rules={{
                required: true
              }}
            />
            <CustomSelect
              label={selectSocials}
              options={socials}
              changeFunc={handleSocials}
              value={selectedSocial}
            />
            <ModalInput
              label={contacts}
              name="contact"
              rules={{
                required: true
              }}
            />
            <ModalInput
              type="textarea"
              name="necessaryResources"
              placeholder={describeResources}
              rules={{
                required: true
              }}
            />
            <ModalInput
              type="textarea"
              name="activityOfTheCompany"
              placeholder={companyActivity}
              rules={{
                required: true
              }}
            />
            <Button
              type="submit"
              tab="buy-proxy"
              disabled={isLoading}
            >
              {isLoading ? <ButtonLoader /> : send}
            </Button>
          </form>
        </FormProvider>
      </CustomModal>
    </div>
  );
};
