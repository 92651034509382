export const authOptions = {
  ru: [
    { value: "login", label: "Логин/Пароль" },
    { value: "ip", label: "IP", ip: true }
  ],
  en: [
    { value: "login", label: "Login/Password" },
    { value: "ip", label: "IP", ip: true }
  ]
};
