import { useTranslation } from "../../../hooks/useTranslation";

import "./ContentDataErrorMesege.scss";

export const ContentDataErrorMesege = () => {
  // **State
  const { serverError } = useTranslation().notifications;

  return (
    <section className="serverError" style={{ backgroundImage: `url(${"/img/ui/planet-cart.png"})` }}>
      <div className="serverError-Mesege">{serverError}</div>
    </section>
  );
};
