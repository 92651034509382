import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams } from "react-router-dom";
import { IconSvg } from "../../../utils/constants/icons/iconSvg.constant";
import { successToast } from "../../../utils/helpers/customToast.helper";
import { useTranslation } from "../../../hooks";

export const CopyData = ({ data }) => {
  const { lang } = useParams();
  const { notifications } = useTranslation();

  const handlerCopy = () => {
    successToast(notifications?.copy, lang);
  };

  return (
    <CopyToClipboard text={data} onCopy={handlerCopy}>
      <button className="copy-btn">
        <IconSvg tag="copy" className={"copy-ip"} />
      </button>
    </CopyToClipboard>
  );
};
