import { AccordionItem } from "../AccordionItem/AccordionItem";
import "./FaqAccordion.scss";

export const FaqAccordion = ({ items }) => (
    <ul className="faq-accordion">
      {items && items.map((item, idx) => (
        <AccordionItem
          key={item?.id || idx}
          item={item}
          items={items}
          initialActive={idx === 0}
        />
      ))}
    </ul>
  );
