import {useDispatchedActions, useTranslation} from "../../../../../../../hooks";
import {sliceText} from "../../../../../../../utils/helpers/sliceText";

export const FormatCellNotesData = ({ order, setIsEditModalOpen }) => {
  const { setOrderData } = useDispatchedActions();
  
  const descriptionMaxLength = 30;
  
  const {
    cabinet: { orders: { column: { notes: { placeholder } } } }
  } = useTranslation();
  
  const handleEdit = () => {
    setOrderData({
      ...order
    });
    setIsEditModalOpen(true);
  };
  
  return (
    <div className="table__notes" onClick={handleEdit}>
      <div className="table__notes-label">
        {sliceText(order?.description, descriptionMaxLength) || (
          <span className="table__notes-label--placeholder">{placeholder}</span>
        )}
      </div>
    </div>
  );
};
