/* eslint-disable quotes */
import { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { IconSvg } from "../../../../../utils/constants/icons/iconSvg.constant";

import { useTranslation } from "../../../../../hooks/useTranslation";

export const Endpoints = (props) => {
  // **Props
  const { data, title } = props;
  const { lang } = useParams();
  // *Ref
  const balanceRef = useRef(null);

  // **Redux State
  const { descriptionsAPI } = useTranslation().cabinet.documentation;

  // **State
  const [collapsed, setCollapsed] = useState(false);
  const [heightInfo, setHeightInfo] = useState("");

  const requestShow = (request = null, type = null) => {
    if (request === null) {
      return null;
    }
    const keys = [];
    const tempData = request
      .replace(/<(.+?)>/g, (str) => {
        keys.push(str);
        return "##";
      })
      .split("##");

    return (
      <div className="api-item-request">
        {type && <div className="type">{type}</div>}
        <span>
          {tempData.map((item, index) => {
            if (item === "" && (index === 0 || index === tempData.length - 1)) {
              return keys[index] ? <span key={item} className="key">{keys[index]}</span> : null;
            }
            return (
              <>
                {item}
                {keys[index] && <span key={item} className="key">{keys[index]}</span>}
              </>
            );
          })}
        </span>
      </div>
    );
  };

  const currentShowData = (params, data) => {
    const type = typeof params[data];
    let resultData = params[data];
    if (type === "boolean") {
      resultData = params[data] ? "true" : "false";
    }
    if (type === "string") {
      resultData = `"${params[data]}"`;
    }
    if (type === "object") {
      return "OBJECT";
    }
    return <span className="data">{resultData}</span>;
  };
  const currentDescriptionLang = (data) => {
    if (lang && data[lang]) {
      return data[lang];
    }
    if (data?.en) {
      return data.en;
    }
    return null;
  };

  const showResponsParams = (params) => {
    if (!params) {
      return null;
    }
    const keys = Object.keys(params);

    return (
      <ul className="api-item-list">
        {keys.map((item, index) => {
          const param = params[item];
          return (
            <li className="list-item" key={item}>
              <span className="list-item-params respons-params">{`"${item}"`}</span>
              <span className="respons-descriptions">{` - ${currentDescriptionLang(param)}`}</span>
              {param?.value && (
                <>
                  {` -`}
                  {currentShowData(param, "value")}
                </>
              )}
              {index !== keys.length - 1 ? "," : "."}
            </li>
          );
        })}
      </ul>
    );
  };

  const showParams = (params, isLastItem = true, key = true) => {
    const keys = Object.keys(params);
    const check = +keys[keys.length - 1] !== keys.length - 1;
    if (!check) {
      return (
        <div>
          <span>{"["}</span>
          {keys.map((item, index) => {
            const checkIndex = index !== keys.length - 1;
            const result = currentShowData(params, item);
            if (result !== "OBJECT") {
              return (
                <div key={item}>
                  {currentShowData(params, item)}
                  {checkIndex && ","}
                </div>
              );
            }
            return (
              <div key={item}>
                <div className={"object"}>{showParams(params[item], !checkIndex, false)}</div>
              </div>
            );
          })}
          <span>
            {" ]"}
            {!isLastItem && ","}
          </span>
        </div>
      );
    }
    return (
      <div className={key ? "api-item-bg-darck" : "recurs"}>
        <span>{"{"}</span>
        {keys.map((item, index) => {
          const checkIndex = index !== keys.length - 1;
          const result = currentShowData(params, item);
          if (result !== "OBJECT") {
            return (
              <div key={item}>
                <span className="params">
                  {`"${item}"`}
                  {":"}
                </span>

                {currentShowData(params, item)}
                {checkIndex && ","}
              </div>
            );
          }
          return (
            <div className="object-params" key={item}>
              <span className="params">
                {`"${item}"`}
                {":"}
              </span>
              <div className={"recurs"}>{showParams(params[item], !checkIndex, false)}</div>
            </div>
          );
        })}
        <span>
          {" }"}
          {!isLastItem && ","}
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (collapsed) {
      setHeightInfo(balanceRef?.current?.scrollHeight);
    } else {
      setHeightInfo("0px");
    }
  }, [collapsed]);

  return (
    <div className="api-item">
      <button
        className={`api-item-button ${collapsed ? "api-item-button--active" : ""}`}
        onClick={() => {
          setCollapsed(!collapsed);
        }}
      >
        <div className="api-item-name">
          <IconSvg tag="document" className={"api-icon"}/>
          {title}
        </div>
        <IconSvg tag="arrowDown18" className={`api-arrow ${collapsed ? "api-arrow-visible" : ""}`} />
      </button>
      <div ref={balanceRef} style={collapsed ? { maxHeight: heightInfo } : { maxHeight: "0" }} className="api-item-dropdown">
        <div className="api-item-dropdown-container">
          {data.map((item, index) => (
            <div key={index} className={`api-item-contain ${index !== data.length - 1 ? "mb-48" : ""}`}>
              {item?.title[lang] && (
                <>
                  <span className="title">{item?.title[lang]}</span>
                  <div className="line"></div>
                </>
              )}
              {item?.description[lang] && (
                <p className="descriptions">
                  <IconSvg tag={"info"} className="info-icon" />
                  {item?.description[lang]}
                </p>
              )}
              {item?.request && requestShow(item?.request?.url, item?.request?.type)}
              {item?.requestParams && (
                <>
                  <span className="sub-title"> {descriptionsAPI?.reqwestParams}:</span>
                  {showResponsParams(item?.requestParams)}
                </>
              )}
              {item?.requestExample && (
                <>
                  <span className="sub-title">{descriptionsAPI?.reqwestExample}:</span>
                  {requestShow(item?.requestExample?.url)}
                  {item?.requestExample?.body && (
                    <>
                      <span className="sub-title">{descriptionsAPI?.reqwestExampleBody}:</span>
                      {showParams(item?.requestExample?.body)}
                    </>
                  )}
                </>
              )}
              {item?.responseBody && (
                <>
                  <span className="sub-title"> {descriptionsAPI?.responseBodyExample}:</span>
                  {showParams(item?.responseBody)}
                </>
              )}
              {item?.responseParams && (
                <>
                  <span className="sub-title"> {descriptionsAPI?.responseParams}:</span>
                  {showResponsParams(item?.responseParams)}
                </>
              )}
              {item?.errorExample && (
                <>
                  <span className="sub-title"> {descriptionsAPI?.errorExample}:</span>
                  {showParams(item?.errorExample)}
                </>
              )}
              {item?.errors && showResponsParams(item?.errors)}
              {item?.errorsId && (
                <>
                  <span className="sub-title"> {descriptionsAPI?.errorIds}:</span>
                  {showResponsParams(item?.errorsId)}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
