import { createSlice } from "@reduxjs/toolkit";
import {getThemeInitState} from "../../../utils/helpers/getThemeInitState";

const HeaderReducer = createSlice({
  name: "header",
  initialState: {
    skin: getThemeInitState(),
    isLoginModalOpened: false,
    isRecoveryModalOpened: false,
    isRegisterModalOpened: false,
    isMenuOpened: false,
    isMobileDropdownOpened: false,
    mobileCountriesDropdownOpened: ""
  },
  reducers: {
    setDarckTheme(state, action) {
      state.skin = action.payload;
    },
    setLoginModal(state, action) {
      state.isRecoveryModalOpened = false;
      state.isRegisterModalOpened = false;
      state.isLoginModalOpened = action.payload;
    },
    setMenu(state, action) {
      state.isMenuOpened = action.payload;
    },
    setDropdownMenu(state, action) {
      state.isMobileDropdownOpened = action.payload;
    },
    setMobileCountriesDropdownMenu(state, action) {
      state.mobileCountriesDropdownOpened = action.payload;
    },
    setRegisterModal(state, action) {
      state.isRecoveryModalOpened = false;
      state.isLoginModalOpened = false;
      state.isRegisterModalOpened = action.payload;
    },
    setRecoveryModal(state, action) {
      state.isRegisterModalOpened = false;
      state.isLoginModalOpened = false;
      state.isRecoveryModalOpened = action.payload;
    }
  }
});

export const HeaderReducerActions = {
  ...HeaderReducer.actions
};
export default HeaderReducer.reducer;
