import {getDateNaming} from "./getDateNaming";
import {DATE_TYPES} from "../constants/dataNames";

export const getDateFormat = (days, lang = "en") => {
  let res = "";
  const months = parseInt(days / 30);
  days = days - months * 30;
  const weeks = parseInt(days / 7);
  days = days - weeks * 7;
  
  if (months) {
    res += getDateNaming(DATE_TYPES.month, months, lang);
  }
  
  if (weeks) {
    res += getDateNaming(DATE_TYPES.week, weeks, lang);
  }
  
  if (days) {
    res += getDateNaming(DATE_TYPES.day, days, lang);
  }
  
  return res;
};
